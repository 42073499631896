import React, { ReactNode } from "react";
import { RouteComponentProps } from "@reach/router";
import { useAppSelector } from "../utils/store";
import { profilesSelector } from "../features/profile-slice";
import { Link, navigate } from "gatsby";
import Layout from "./layout";
import CustomerBadge from "./customer-badge";

interface CustomerPageLayoutProps extends RouteComponentProps {
    customerId: string;
    title: string;
    prevUrl: string;
    children: ReactNode;
}

export default function CustomerPageLayout(props: CustomerPageLayoutProps) {
    const { customerId, title, prevUrl, children } = props;

    const customerProfile = useAppSelector((state) => profilesSelector.selectById(state, customerId ?? ""));

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    return (
        <Layout title={title}>
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to={prevUrl}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>{title}</h1>
                </div>
                <div className="divider mb-3" />
                {children}
            </div>
        </Layout>
    );
}
