import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { compareDesc, format, subWeeks } from "date-fns";
import { Link, navigate } from "gatsby";
import { useErrorHandler } from "../components/error-snackbar";
import { getPromisetoPayHistory, profilesSelector } from "../features/profile-slice";
import { toTitleCase } from "../utils/helpers";
import { useAppSelector, useUnwrapAsyncThunk } from "../utils/store";
import { PromiseToPayHistoryRes } from "../utils/types";
import Layout from "../components/layout";
import CustomerBadge from "../components/customer-badge";
import Modal from "../components/modal";
import DayPicker, { DateUtils, RangeModifier } from "react-day-picker";
import { Helmet } from "react-helmet";
import { Button } from "../components/button";

export interface PromiseToPayHistoryProps extends RouteComponentProps {
    customerId: string;
}

function PromiseToPayHistory(props: PromiseToPayHistoryProps) {
    const { customerId } = props;

    const customerProfile = useAppSelector((state) => profilesSelector.selectById(state, customerId ?? ""));

    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const [promiseToPayHistoryRes, setPromiseToPayHistoryRes] = useState<PromiseToPayHistoryRes>();
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [startDate, setStartDate] = useState(subWeeks(new Date(), 1));
    const [endDate, setEndDate] = useState(new Date());

    async function fetchPromiseToPayHistory() {
        if (startDate && endDate) {
            try {
                const res = await unwrap(
                    getPromisetoPayHistory({
                        customerId,
                        startDate: format(startDate, "yyyy-MM-dd"),
                        endDate: format(endDate, "yyyy-MM-dd"),
                    })
                );
                setPromiseToPayHistoryRes(
                    res.sort((a, b) => compareDesc(new Date(a.createdDate), new Date(b.createdDate)))
                );
            } catch (error) {
                console.log(error);
                if (error.resCode === 40039) {
                    setPromiseToPayHistoryRes([]);
                } else {
                    handleError(error);
                }
            }
        }
    }

    useEffect(() => {
        fetchPromiseToPayHistory();
    }, [startDate, endDate]);

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    return (
        <Layout title="Promise to Pay history">
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to={`/profile/${customerId}`}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>Promise to Pay history</h1>
                    <div className="font-family-semibold">
                        {format(startDate, "MMM dd, yyyy")} - {format(endDate, "MMM dd, yyyy")}{" "}
                        <span
                            className="reach-cs-calendar text-secondary cursor-pointer ml-2"
                            style={{ fontSize: 18 }}
                            onClick={() => setShowDatePicker(true)}
                        />
                    </div>
                </div>
                <div className="divider mb-3" />

                <div className="row font-family-bold py-2 mx-0" style={{ backgroundColor: "#fbfbfb" }}>
                    <div className="col">Action Date</div>
                    <div className="col">Bill Date</div>
                    <div className="col">Bill Due Date</div>
                    <div className="col">Promise to Pay Date</div>
                    <div className="col">Bill Amount</div>
                    <div className="col">Promise to Pay Amount</div>
                    <div className="col">Status</div>
                    <div className="col">Bill Paid</div>
                </div>
                {promiseToPayHistoryRes == null ? (
                    <div className="d-flex justify-content-center mt-3">
                        <div className="spinner-border text-primary" />
                    </div>
                ) : promiseToPayHistoryRes.length === 0 ? (
                    <div className="d-flex justify-content-center font-family-semibold mt-3">
                        No records for the given date range
                    </div>
                ) : (
                    promiseToPayHistoryRes?.map((item) => (
                        <div className="row font-family-semibold py-2 mx-0 divider" key={item.createdDate}>
                            <div className="col font-family-bold">
                                {format(new Date(item.createdDate), "MMM dd, yyyy")}{" "}
                                <span className="font-family-semibold">
                                    {format(new Date(item.createdDate), "hh:mma")}
                                </span>
                            </div>
                            <div className="col">{format(new Date(item.billMonth), "MMM dd, yyyy")}</div>
                            <div className="col">{format(new Date(item.billDueDate), "MMM dd, yyyy")}</div>
                            <div className="col font-family-bold">
                                {format(new Date(item.ptpScheduledDate), "MMM dd, yyyy")}
                            </div>
                            <div className="col">{item.actualBillAmount}</div>
                            <div className="col">{item.ptpBillAmount}</div>
                            <div className="col">{item.valid ? item.status : "CANCELLED"}</div>
                            <div className="col">{item.billPaid ? "Paid" : "Pending"}</div>
                        </div>
                    ))
                )}
            </div>

            <Modal title="Select date range" show={showDatePicker} size="sm" onHide={() => setShowDatePicker(false)}>
                <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onRangeSelect={(from, to) => {
                        setStartDate(from);
                        setEndDate(to);
                        setShowDatePicker(false);
                    }}
                />
            </Modal>
        </Layout>
    );
}

function DateRangePicker(props: { startDate: Date; endDate: Date; onRangeSelect: (from: Date, to: Date) => void }) {
    const [state, setState] = useState<RangeModifier>({
        from: props.startDate,
        to: props.endDate,
    });

    const { from, to } = state;

    function getInitialState() {
        return {
            from: undefined,
            to: undefined,
        };
    }

    function handleDayClick(day: Date) {
        const range = DateUtils.addDayToRange(day, state);
        setState((prev) => ({ ...prev, ...range }));
    }

    function handleResetClick() {
        setState((prev) => ({ ...prev, ...getInitialState() }));
    }

    const modifiers = { start: from, end: to };

    return (
        <div className="d-flex flex-column">
            <DayPicker
                className="Selectable"
                showOutsideDays
                numberOfMonths={1}
                selectedDays={[from ?? undefined, { from, to }]}
                disabledDays={[{ after: new Date() }]}
                month={from ?? undefined}
                // @ts-ignore
                modifiers={modifiers}
                onDayClick={handleDayClick}
            />
            <Button
                color="secondary"
                className="align-self-center"
                onClick={() => {
                    if (from && to) {
                        props.onRangeSelect(from, to);
                    }
                }}>
                Confirm
            </Button>
            <Helmet>
                <style>{`
    .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      background-color: #f0f8ff !important;
      color: #4a90e2;
    }
    .Selectable .DayPicker-Day {
      border-radius: 0 !important;
    }
    .Selectable .DayPicker-Day--start {
      border-top-left-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
    }
    .Selectable .DayPicker-Day--end {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
  `}</style>
            </Helmet>
        </div>
    );
}

export default PromiseToPayHistory;
