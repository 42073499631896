import React, { useEffect, useState } from "react";
import { RouteComponentProps, useLocation } from "@reach/router";
import { compareDesc, format, subWeeks } from "date-fns";
import { Link, navigate } from "gatsby";
import { useErrorHandler } from "../components/error-snackbar";
import { getAccountHistory, getAlertsHistory, profilesSelector } from "../features/profile-slice";
import { toTitleCase } from "../utils/helpers";
import { useAppSelector, useUnwrapAsyncThunk } from "../utils/store";
import { AccountHistoryRes, MDNAlertsRes } from "../utils/types";
import Layout from "../components/layout";
import CustomerBadge from "../components/customer-badge";
import Modal from "../components/modal";
import DayPicker, { DateUtils, RangeModifier } from "react-day-picker";
import { Helmet } from "react-helmet";
import { Button } from "../components/button";
import Select from "../components/select";

export interface AccountHistoryProps extends RouteComponentProps {
    customerId: string;
}
enum SearchFilter {
    ALL = "ALL",
    THROTTLE = "Throttled",
    REMOVE_THROTTLE = "De-Throttled",
    ENABLE_DATA_SERVICE = "Enable data dervice",
    DISABLE_DATA_SERVICE = "Disable data service",
    SUSPEND = "Suspend",
    HOTLINE = "Hotline",
    RESTORE = "Restore",
    AUTO_TOP_UP_ENABLE = "Auto Topup Enabled",
    AUTO_TOP_UP_DISABLE = "Auto Topup Disabled",
    ACTIVATE_IR = "IR Activated",
    DEACTIVATE_IR = "IR Deactivated",
    ACTIVATE_ILD = "ILD Activated",
    DEACTIVATE_ILD = "ILD Deactivated",
    DEVICE_SWAP = "Device change",
    RECONNECT = "Reconnect MDN",
    PORTED_OUT_DISCONNECT = "Primary disconnected with active secondary",
    ADD_CIDB = "Add CIDB pack",
    REMOVE_CIDB = "Remove CIDB pack",
    REPORT_GENERATION = "CDR report generation",
    ORDER_CREATION = "Shipment order creation",
    PAYMENT_CALLBACK = "Payment callback",
    RESET = "Customer reset",
    BILLING = "Bill generation",
    IR_TROUBLESHOOT = "IR Trobleshoot",
    RETRY_DATA_ENABLE_SERVICE = "Retry Data Enable",
}
export enum AccountActivityType {
    THROTTLE = "Throttled",
    REMOVE_THROTTLE = "De-Throttled",
    ENABLE_DATA_SERVICE = "Enable data service",
    DISABLE_DATA_SERVICE = "Disable data service",
    SUSPEND = "Suspend",
    HOTLINE = "Hotline",
    RESTORE = "Restore",
    AUTO_TOP_UP_ENABLE = "Auto Topup Enabled",
    AUTO_TOP_UP_DISABLE = "Auto Topup Disabled",
    ACTIVATE_IR = "IR Activated",
    DEACTIVATE_IR = "IR Deactivated",
    ACTIVATE_ILD = "ILD Activated",
    DEACTIVATE_ILD = "ILD Deactivated",
    DEVICE_SWAP = "Device change",
    RECONNECT = "Reconnect MDN",
    PORTED_OUT_DISCONNECT = "Primary disconnected with active secondary",
    ADD_CIDB = "Add CIDB pack",
    REMOVE_CIDB = "Remove CIDB pack",
    REPORT_GENERATION = "CDR report generation",
    ORDER_CREATION = "Shipment order creation",
    PAYMENT_CALLBACK = "Payment callback",
    RESET = "Customer reset",
    BILLING = "Bill generation",
    IR_TROUBLESHOOT = "IR Trobleshoot",
    RETRY_DATA_ENABLE_SERVICE = "Retry Data Enable",
}

function AccountHistory(props: AccountHistoryProps) {
    const { customerId } = props;

    const customerProfile = useAppSelector((state) => profilesSelector.selectById(state, customerId ?? ""));

    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const [accountHistoryRes, setAccountHistoryRes] = useState<AccountHistoryRes>();
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [startDate, setStartDate] = useState(subWeeks(new Date(), 1));
    const [endDate, setEndDate] = useState(new Date());

    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const filterParam = searchParams.get("filter") as keyof typeof SearchFilter;

    const [filterValue, setFilterValue] = useState<SearchFilter>(
        filterParam && SearchFilter[filterParam] ? SearchFilter[filterParam] : SearchFilter.ALL
    );

    async function fetchAccountHistory() {
        if (startDate && endDate) {
            try {
                const res = await unwrap(
                    getAccountHistory({
                        customerId,
                        startDate: format(startDate, "yyyy-MM-dd"),
                        endDate: format(endDate, "yyyy-MM-dd"),
                    })
                );
                if (filterValue == SearchFilter.ALL) {
                    setAccountHistoryRes(
                        res.sort((a, b) => compareDesc(new Date(a.createdDate), new Date(b.createdDate)))
                    );
                } else {
                    setAccountHistoryRes(
                        res
                            .sort((a, b) => compareDesc(new Date(a.createdDate), new Date(b.createdDate)))
                            .filter((item) => item.activity == getAccountActivityFromFilter(filterValue))
                    );
                }
            } catch (error) {
                console.log(error);
                if (error.resCode === 40196) {
                    setAccountHistoryRes([]);
                } else {
                    handleError(error);
                }
            }
        }
    }

    useEffect(() => {
        fetchAccountHistory();
    }, [startDate, endDate, filterValue]);

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    return (
        <Layout title="Account history">
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to={`/profile/${customerId}`}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>Account history</h1>
                    <div className="font-family-semibold d-flex align-items-center">
                        {format(startDate, "MMM dd, yyyy")} - {format(endDate, "MMM dd, yyyy")}{" "}
                        <span
                            className="reach-cs-calendar text-secondary cursor-pointer mx-2"
                            style={{ fontSize: 18 }}
                            onClick={() => setShowDatePicker(true)}
                        />
                        <Select
                            className=""
                            value={filterValue}
                            onChange={({ target: { value } }) => setFilterValue(value as SearchFilter)}>
                            <option value={SearchFilter.ALL}>Filter by</option>
                            <optgroup label="Auto Topup">
                                <option value={SearchFilter.AUTO_TOP_UP_ENABLE}>AutoTopup Enabled</option>
                                <option value={SearchFilter.AUTO_TOP_UP_DISABLE}>AutoTopup Disabled</option>
                            </optgroup>
                            <optgroup label="ILD">
                                <option value={SearchFilter.ACTIVATE_ILD}>ILD Enabled</option>
                                <option value={SearchFilter.DEACTIVATE_ILD}>ILD Disabled</option>
                            </optgroup>
                            <optgroup label="Data Cut-off">
                                <option value={SearchFilter.ENABLE_DATA_SERVICE}>Data Cutoff Disabled</option>
                                <option value={SearchFilter.DISABLE_DATA_SERVICE}>Data Cutoff Enabled</option>
                            </optgroup>
                            <option value={SearchFilter.THROTTLE}>Throttle</option>
                            <option value={SearchFilter.REMOVE_THROTTLE}>De-Throttle</option>
                            <option value={SearchFilter.HOTLINE}>Hotline</option>
                        </Select>
                    </div>
                </div>
                <div className="divider mb-3" />

                <div className="row font-family-bold py-2 mx-0" style={{ backgroundColor: "#fbfbfb" }}>
                    <div className="col">Date</div>
                    <div className="col">Action</div>
                    <div className="col-5">Description</div>
                </div>
                {accountHistoryRes == null ? (
                    <div className="d-flex justify-content-center mt-3">
                        <div className="spinner-border text-primary" />
                    </div>
                ) : accountHistoryRes.length === 0 ? (
                    <div className="d-flex justify-content-center font-family-semibold mt-3">
                        No history for the given date range{filterValue != SearchFilter.ALL && " and selected filter"}
                    </div>
                ) : (
                    accountHistoryRes?.map((item) => (
                        <div className="row font-family-semibold py-2 mx-0 divider" key={item.createdDate}>
                            <div className="col font-family-bold">
                                {format(new Date(item.createdDate), "MMM dd, yyyy")}{" "}
                                <span className="font-family-semibold">
                                    {format(new Date(item.createdDate), "hh:mma")}
                                </span>
                            </div>
                            <div className="col">{AccountActivityType[item.activity]}</div>
                            <div className="col-5">{item.description}</div>
                        </div>
                    ))
                )}
            </div>

            <Modal title="Select date range" show={showDatePicker} size="sm" onHide={() => setShowDatePicker(false)}>
                <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onRangeSelect={(from, to) => {
                        setStartDate(from);
                        setEndDate(to);
                        setShowDatePicker(false);
                    }}
                />
            </Modal>
        </Layout>
    );
}

function getAccountActivityFromFilter(filterValue: SearchFilter) {
    switch (filterValue) {
        case SearchFilter.ALL:
            break;
        case SearchFilter.AUTO_TOP_UP_ENABLE:
            return "AUTO_TOP_UP_ENABLE";
            break;
        case SearchFilter.AUTO_TOP_UP_DISABLE:
            return "AUTO_TOP_UP_DISABLE";
            break;
        case SearchFilter.ACTIVATE_ILD:
            return "ACTIVATE_ILD";
            break;
        case SearchFilter.DEACTIVATE_ILD:
            return "DEACTIVATE_ILD";
            break;
        case SearchFilter.ENABLE_DATA_SERVICE:
            return "ENABLE_DATA_SERVICE";
            break;
        case SearchFilter.DISABLE_DATA_SERVICE:
            return "DISABLE_DATA_SERVICE";
            break;
        case SearchFilter.HOTLINE:
            return "HOTLINE";
            break;
        case SearchFilter.THROTTLE:
            return "THROTTLE";
            break;
        case SearchFilter.REMOVE_THROTTLE:
            return "REMOVE_THROTTLE";
            break;
    }
}

function DateRangePicker(props: { startDate: Date; endDate: Date; onRangeSelect: (from: Date, to: Date) => void }) {
    const [state, setState] = useState<RangeModifier>({
        from: props.startDate,
        to: props.endDate,
    });

    const { from, to } = state;

    function getInitialState() {
        return {
            from: undefined,
            to: undefined,
        };
    }

    function handleDayClick(day: Date) {
        const range = DateUtils.addDayToRange(day, state);
        setState((prev) => ({ ...prev, ...range }));
    }

    function handleResetClick() {
        setState((prev) => ({ ...prev, ...getInitialState() }));
    }

    const modifiers = { start: from, end: to };

    return (
        <div className="d-flex flex-column">
            <DayPicker
                className="Selectable"
                showOutsideDays
                numberOfMonths={1}
                selectedDays={[from ?? undefined, { from, to }]}
                disabledDays={[{ after: new Date() }]}
                month={from ?? undefined}
                // @ts-ignore
                modifiers={modifiers}
                onDayClick={handleDayClick}
            />
            <Button
                color="secondary"
                className="align-self-center"
                onClick={() => {
                    if (from && to) {
                        props.onRangeSelect(from, to);
                    }
                }}>
                Confirm
            </Button>
            <Helmet>
                <style>{`
    .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      background-color: #f0f8ff !important;
      color: #4a90e2;
    }
    .Selectable .DayPicker-Day {
      border-radius: 0 !important;
    }
    .Selectable .DayPicker-Day--start {
      border-top-left-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
    }
    .Selectable .DayPicker-Day--end {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
  `}</style>
            </Helmet>
        </div>
    );
}

export default AccountHistory;
