import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import Layout from "../components/layout";
import { Link, navigate } from "gatsby";
import { useSelector } from "react-redux";
import { RootState, useUnwrapAsyncThunk } from "../utils/store";
import { getDiscountAnalytics, profilesSelector } from "../features/profile-slice";
import { Card } from "../components/card";
import { Button } from "../components/button";
import Modal from "../components/modal";
import { DiscountInfo, DiscountRes } from "../utils/types";
import { isFuture } from "date-fns/esm";
import { format, formatDistanceStrict } from "date-fns";
import { useErrorHandler } from "../components/error-snackbar";
import CustomerBadge from "../components/customer-badge";
import { usePermissions } from "../features/hooks/use-permissions";
import { CustomerStatus } from "../utils/types/customer";

export interface DiscountsProps extends RouteComponentProps {
    customerId: string;
}

export default function Discounts(props: DiscountsProps) {
    const { customerId } = props;

    const customerProfile = useSelector((state: RootState) => profilesSelector.selectById(state, customerId));

    const [selectedDiscount, setSelectedDiscount] = useState<DiscountInfo>();

    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const [discountRes, setDiscountRes] = useState<DiscountRes>();

    const { hasOnePermission } = usePermissions();

    useEffect(() => {
        async function fetchDiscounts() {
            try {
                const res = await unwrap(getDiscountAnalytics(customerId));
                setDiscountRes(res);
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
        fetchDiscounts();
    }, []);

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    const primaryCustomer = customerProfile.groupLines.find((customer) => customer.isPrimary)!;

    if (!discountRes) {
        return (
            <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - Discounts`}>
                <div className="container col-10 d-flex flex-column my-3 px-0">
                    <div className="d-flex justify-content-between">
                        <Link className="text-cta mb-3" to={`/profile/${customerId}`}>
                            <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                            Back
                        </Link>
                    </div>
                    <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                        <div>
                            {customerProfile.firstName} {customerProfile.lastName}{" "}
                            <CustomerBadge isPrimary={customerProfile.isPrimary} />
                        </div>
                        <div>{customerProfile.emailId}</div>
                    </div>
                    <Card>
                        <div className="spinner-border text-primary" />
                    </Card>
                </div>
            </Layout>
        );
    }

    const percentageDiscounts = discountRes.discounts?.filter(
        (discount) => discount.primaryDiscount != null || discount.secondaryDiscount != null
    );

    return (
        <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - Discounts`}>
            <div className="container col-10 d-flex flex-column my-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to={`/profile/${customerId}`}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>

                <Card>
                    <div className="font-family-bold align-self-start" style={{ fontSize: 16 }}>
                        All discounts
                    </div>
                    <div className="font-family-semibold">Earned discounts</div>
                    <div className="font-family-bold" style={{ fontSize: 21 }}>
                        ${discountRes.totalEarned}
                    </div>
                    {percentageDiscounts?.map((discount, index) =>
                        discount.primaryDiscount && discount.secondaryDiscount ? (
                            <div key={index}>
                                {discount.primaryDiscount}% for primary and {discount.secondaryDiscount}% for secondary
                                customer for{" "}
                                {formatDistanceStrict(new Date(discount.expireDate!), new Date(discount.startDate), {
                                    unit: "month",
                                })}
                            </div>
                        ) : (
                            <div key={index}>
                                {discount.primaryDiscount}%/month for{" "}
                                {formatDistanceStrict(new Date(discount.expireDate!), new Date(discount.startDate), {
                                    unit: "month",
                                })}
                            </div>
                        )
                    )}
                    <div className="divider my-2" />
                    <div className="font-family-semibold">Available discounts</div>
                    <div className="font-family-bold" style={{ fontSize: 21 }}>
                        ${discountRes.totalAvailable}
                    </div>
                    {percentageDiscounts?.map((discount, index) =>
                        discount.primaryDiscount && discount.secondaryDiscount ? (
                            <div key={index}>
                                {discount.primaryDiscount}% for primary and {discount.secondaryDiscount}% for secondary
                                customer for{" "}
                                {formatDistanceStrict(
                                    new Date(discount.expireDate!),
                                    new Date(discount.lastModifiedDate!),
                                    {
                                        unit: "month",
                                    }
                                )}
                            </div>
                        ) : (
                            <div key={index}>
                                {discount.primaryDiscount}%/month for{" "}
                                {formatDistanceStrict(
                                    new Date(discount.expireDate!),
                                    new Date(discount.lastModifiedDate!),
                                    {
                                        unit: "month",
                                    }
                                )}
                            </div>
                        )
                    )}
                    <Button
                        color="secondary"
                        className="mt-2 col-10 col-md-4"
                        disabled={
                            !hasOnePermission("billing.credit.create") ||
                            (customerProfile.status === CustomerStatus.DISCONNECTED && customerProfile.skipBilling)
                        }
                        onClick={() => navigate(`/add-credit/${customerId}`)}>
                        Add credits
                    </Button>
                </Card>

                {discountRes.discounts && discountRes.discounts.length > 0 && (
                    <Card className="mt-3">
                        <div className="font-family-bold align-self-start" style={{ fontSize: 16 }}>
                            All discounts
                        </div>
                        <table className="table table-sm font-family-semibold mb-0" style={{ fontSize: 12 }}>
                            <thead>
                                <tr className="font-family-bold">
                                    <th style={{ width: "49%" }}>Discount name</th>
                                    <th style={{ width: "49%" }}>Value</th>
                                    <th style={{ width: 120 }}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {discountRes.discounts.map((discount, index) => (
                                    <tr
                                        key={index}
                                        className="cursor-pointer"
                                        onClick={() => setSelectedDiscount(discount)}>
                                        <td>
                                            <div>
                                                {discount.code && `${discount.code} - `}
                                                {discount.name}
                                            </div>
                                            <div style={{ fontSize: 10 }}>
                                                {format(new Date(discount.createdDate), "MMM dd, yyyy")}
                                            </div>
                                        </td>
                                        {discount.primaryDiscount && discount.secondaryDiscount ? (
                                            <td>
                                                {discount.primaryDiscount}% for primary and {discount.secondaryDiscount}
                                                % for secondary customer for{" "}
                                                {formatDistanceStrict(
                                                    new Date(discount.expireDate!),
                                                    new Date(discount.lastModifiedDate!),
                                                    { unit: "month" }
                                                )}
                                            </td>
                                        ) : discount.primaryDiscount ? (
                                            <td>
                                                {discount.primaryDiscount}%/month for{" "}
                                                {formatDistanceStrict(
                                                    new Date(discount.expireDate!),
                                                    new Date(discount.lastModifiedDate!),
                                                    { unit: "month" }
                                                )}
                                            </td>
                                        ) : (
                                            <td>
                                                ${discount.available}/${discount.earned}
                                            </td>
                                        )}
                                        <td style={{ whiteSpace: "nowrap" }}>
                                            <div className="d-flex align-items-center justify-content-between">
                                                {isFuture(new Date(discount.startDate)) ? (
                                                    <div>
                                                        <div>Will be available</div>
                                                        <div style={{ fontSize: 10 }}>
                                                            {format(new Date(discount.startDate), "MMM dd, yyyy")}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    discount.lastModifiedDate && (
                                                        <div>
                                                            <div>Last earned</div>
                                                            <div style={{ fontSize: 10 }}>
                                                                {format(
                                                                    new Date(discount.lastModifiedDate),
                                                                    "MMM dd, yyyy"
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                                <div className="reach-cs-arrow-right ml-2 text-secondary" />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Card>
                )}
                {selectedDiscount && (
                    <Modal
                        title={selectedDiscount.name}
                        show={selectedDiscount != null}
                        onHide={() => setSelectedDiscount(undefined)}
                        size="sm">
                        {selectedDiscount.code && <div className="h2">{selectedDiscount.code}</div>}
                        {!selectedDiscount.primaryDiscount && !selectedDiscount.secondaryDiscount && (
                            <>
                                <div className="font-family-semibold">Earned discounts</div>
                                <div className="font-family-bold" style={{ fontSize: 16 }}>
                                    ${selectedDiscount?.earned}
                                </div>
                                <div className="divider my-2" />
                                <div className="font-family-semibold">Available discounts</div>
                                <div className="font-family-bold" style={{ fontSize: 16 }}>
                                    ${selectedDiscount?.available}
                                </div>
                            </>
                        )}
                        {selectedDiscount.expireDate &&
                            (selectedDiscount.discountInDollar ? (
                                <div className="font-family-semibold mt-2" style={{ fontSize: 12 }}>
                                    <span className="font-family-bold">Note:</span> ${selectedDiscount.discountInDollar}
                                    /Month credit will be applied till{" "}
                                    {format(new Date(selectedDiscount.expireDate), "MMM dd, yyyy")}
                                </div>
                            ) : selectedDiscount.primaryDiscount && selectedDiscount.secondaryDiscount ? (
                                <div className="font-family-semibold mt-2" style={{ fontSize: 12 }}>
                                    <span className="font-family-bold">Note:</span> {selectedDiscount.primaryDiscount}%
                                    for primary and {selectedDiscount.secondaryDiscount}% for secondary customer will be
                                    applied till {format(new Date(selectedDiscount.expireDate), "MMM dd, yyyy")}
                                </div>
                            ) : (
                                <div className="font-family-semibold mt-2" style={{ fontSize: 12 }}>
                                    <span className="font-family-bold">Note:</span> {selectedDiscount.primaryDiscount}%
                                    /Month credit will be applied till{" "}
                                    {format(new Date(selectedDiscount.expireDate), "MMM dd, yyyy")}
                                </div>
                            ))}
                    </Modal>
                )}
            </div>
        </Layout>
    );
}
