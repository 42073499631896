import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { useSelector } from "react-redux";
import { RootState, useUnwrapAsyncThunk } from "../utils/store";
import { generateCDRReport, getCDRReports, profilesSelector } from "../features/profile-slice";
import Layout from "../components/layout";
import { Link, navigate } from "gatsby";
import { Card } from "../components/card";
import { Input } from "../components/input";
import { Checkbox } from "../components/checkbox";
import Toggle from "../components/toggle";
import { Button } from "../components/button";
import { addDays, format, subDays, subMonths } from "date-fns";
import { useForm } from "react-hook-form";
import { useErrorHandler } from "../components/error-snackbar";
import Modal from "../components/modal";
import { CDRReportRes } from "../utils/types";
import CustomerBadge from "../components/customer-badge";

export interface CDRReportProps extends RouteComponentProps {
    customerId: string;
}

interface FormData {
    cs_cdr_reports_domestic_calls: boolean;
    cs_cdr_reports_domestic_texts: boolean;
    cs_cdr_reports_domestic_datas: boolean;
    cs_cdr_reports_intl_calls: boolean;
    cs_cdr_reports_intl_texts: boolean;
    cs_cdr_reports_intl_romaing_calls: boolean;
    cs_cdr_reports_intl_romaing_texts: boolean;
    cs_cdr_reports_intl_romaing_datas: boolean;
    cs_cdr_reports_domestic_roaming_calls: boolean;
    cs_cdr_reports_domestic_roaming_datas: boolean;
    cs_cdr_reports_mms: boolean;
    groupAction: boolean;
    startDate: string;
    endDate: string;
    all: boolean;
}

export default function CDRReports(props: CDRReportProps) {
    const { customerId } = props;

    const customerProfile = useSelector((state: RootState) => profilesSelector.selectById(state, customerId));

    const endDate = new Date();
    const startDate = subMonths(endDate, 2);

    const { handleSubmit, register, formState, errors, setValue } = useForm<FormData>({
        mode: "onBlur",
    });

    const [showModal, setShowModal] = useState(false);
    const [showNoCDRModal, setShowNoCDRModal] = useState(false);

    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const [cdrReports, setCDRReports] = useState<CDRReportRes[]>();
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data: FormData) => {
        console.log(data);
        try {
            const { startDate, endDate, all, groupAction, ...rest } = data;
            await unwrap(
                generateCDRReport({
                    customerId,
                    startDate,
                    endDate,
                    groupAction,
                    reportTypes: (Object.keys(rest) as Array<keyof typeof rest>).filter((key) => rest[key]),
                })
            );
            await fetchCDRReports();
            setShowModal(true);
        } catch (error) {
            console.log(error);
            if (error.resCode === 40271) {
                setShowNoCDRModal(true);
            } else {
                handleError(error);
            }
        }
    };

    async function fetchCDRReports() {
        setLoading(true);
        try {
            const res = await unwrap(getCDRReports(customerId));
            setCDRReports(res);
        } catch (error) {
            console.log(error);
            if (error.resCode !== 40272) {
                handleError(error);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchCDRReports();
    }, []);

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    return (
        <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - CDR reports generator`}>
            <div className="container col-10 d-flex flex-column my-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to={`/profile/${customerId}`}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>
                <h1 className="">CDR reports generator</h1>
                <Card>
                    <div className="w-100 align-self-start">
                        <div className="row row-cols-1 row-cols-md-2">
                            <div className="col">
                                <div className="font-family-semibold">Start date</div>
                                <Input
                                    type="date"
                                    register={register({
                                        required: {
                                            value: true,
                                            message: "Date is required",
                                        },
                                    })}
                                    name="startDate"
                                    showError={errors.startDate != null}
                                    errorMessage={errors.startDate?.message}
                                    min={format(startDate, "yyyy-MM-dd")}
                                    max={format(subDays(endDate, 1), "yyyy-MM-dd")}
                                />
                            </div>
                            <div className="col mt-3 mt-md-0">
                                <div className="font-family-semibold">End date</div>
                                <Input
                                    type="date"
                                    register={register({
                                        required: {
                                            value: true,
                                            message: "Date is required",
                                        },
                                    })}
                                    name="endDate"
                                    showError={errors.endDate != null}
                                    errorMessage={errors.endDate?.message}
                                    min={format(addDays(startDate, 1), "yyyy-MM-dd")}
                                    max={format(endDate, "yyyy-MM-dd")}
                                />
                            </div>
                        </div>

                        <div className="font-family-semibold mt-3">CDR Reports required</div>
                        <div className="row row-cols-1 row-cols-md-2 mt-3">
                            <div className="col">
                                <Checkbox
                                    label="All"
                                    id="all"
                                    name="all"
                                    defaultChecked={false}
                                    register={register}
                                    onChange={({ target: { checked } }) => {
                                        setValue("cs_cdr_reports_domestic_calls", checked);
                                        setValue("cs_cdr_reports_domestic_texts", checked);
                                        setValue("cs_cdr_reports_domestic_datas", checked);
                                        setValue("cs_cdr_reports_intl_calls", checked);
                                        setValue("cs_cdr_reports_intl_texts", checked);
                                        setValue("cs_cdr_reports_intl_romaing_calls", checked);
                                        setValue("cs_cdr_reports_intl_romaing_texts", checked);
                                        setValue("cs_cdr_reports_intl_romaing_datas", checked);
                                        setValue("cs_cdr_reports_domestic_roaming_calls", checked);
                                        setValue("cs_cdr_reports_domestic_roaming_datas", checked);
                                        setValue("cs_cdr_reports_mms", checked);
                                    }}
                                />
                                <Checkbox
                                    label="Domestic call"
                                    id="cs_cdr_reports_domestic_calls"
                                    name="cs_cdr_reports_domestic_calls"
                                    defaultChecked={false}
                                    onChange={({ target: { checked } }) => {
                                        setValue("all", false);
                                    }}
                                    register={register}
                                />
                                <Checkbox
                                    label="Domestic text"
                                    id="cs_cdr_reports_domestic_texts"
                                    name="cs_cdr_reports_domestic_texts"
                                    defaultChecked={false}
                                    onChange={({ target: { checked } }) => {
                                        setValue("all", false);
                                    }}
                                    register={register}
                                />
                                <Checkbox
                                    label="Domestic data"
                                    id="cs_cdr_reports_domestic_datas"
                                    name="cs_cdr_reports_domestic_datas"
                                    defaultChecked={false}
                                    onChange={({ target: { checked } }) => {
                                        setValue("all", false);
                                    }}
                                    register={register}
                                />
                                <Checkbox
                                    label="Domestic roaming call"
                                    id="cs_cdr_reports_intl_calls"
                                    name="cs_cdr_reports_intl_calls"
                                    defaultChecked={false}
                                    onChange={({ target: { checked } }) => {
                                        setValue("all", false);
                                    }}
                                    register={register}
                                />
                                <Checkbox
                                    label="Domestic roaming data"
                                    id="cs_cdr_reports_intl_texts"
                                    name="cs_cdr_reports_intl_texts"
                                    defaultChecked={false}
                                    onChange={({ target: { checked } }) => {
                                        setValue("all", false);
                                    }}
                                    register={register}
                                />
                            </div>
                            <div className="col">
                                <Checkbox
                                    label="International call"
                                    id="cs_cdr_reports_intl_romaing_calls"
                                    name="cs_cdr_reports_intl_romaing_calls"
                                    defaultChecked={false}
                                    onChange={({ target: { checked } }) => {
                                        setValue("all", false);
                                    }}
                                    register={register}
                                />
                                <Checkbox
                                    label="International text"
                                    id="cs_cdr_reports_intl_romaing_texts"
                                    name="cs_cdr_reports_intl_romaing_texts"
                                    defaultChecked={false}
                                    onChange={({ target: { checked } }) => {
                                        setValue("all", false);
                                    }}
                                    register={register}
                                />
                                <Checkbox
                                    label="International roaming call"
                                    id="cs_cdr_reports_intl_romaing_datas"
                                    name="cs_cdr_reports_intl_romaing_datas"
                                    defaultChecked={false}
                                    onChange={({ target: { checked } }) => {
                                        setValue("all", false);
                                    }}
                                    register={register}
                                />
                                <Checkbox
                                    label="International roaming data"
                                    id="cs_cdr_reports_domestic_roaming_calls"
                                    name="cs_cdr_reports_domestic_roaming_calls"
                                    defaultChecked={false}
                                    onChange={({ target: { checked } }) => {
                                        setValue("all", false);
                                    }}
                                    register={register}
                                />
                                <Checkbox
                                    label="International roaming text"
                                    id="cs_cdr_reports_domestic_roaming_datas"
                                    name="cs_cdr_reports_domestic_roaming_datas"
                                    defaultChecked={false}
                                    onChange={({ target: { checked } }) => {
                                        setValue("all", false);
                                    }}
                                    register={register}
                                />
                                <Checkbox
                                    label="MMS"
                                    id="cs_cdr_reports_mms"
                                    name="cs_cdr_reports_mms"
                                    defaultChecked={false}
                                    onChange={({ target: { checked } }) => {
                                        setValue("all", false);
                                    }}
                                    register={register}
                                />
                            </div>
                        </div>

                        {/* <Toggle
                            className="mt-3"
                            id="groupAction"
                            name="groupAction"
                            label="Select for the whole group"
                            register={register}
                            defaultChecked={false}
                        /> */}
                    </div>

                    <Button
                        color="secondary"
                        className="col-10 col-md-6"
                        loading={formState.isSubmitting}
                        onClick={handleSubmit(onSubmit)}>
                        Submit
                    </Button>
                </Card>

                {loading && !cdrReports && (
                    <div className="mt-3 d-flex justify-content-center">
                        <div className="spinner-border text-primary" />
                    </div>
                )}

                {cdrReports && cdrReports.length > 0 && (
                    <Card className="mt-3">
                        <div className="w-100 align-self-start">
                            <div className="h1">CDR report history</div>
                        </div>
                        <div className="w-100 mt-3">
                            <table className="table table-sm font-family-semibold mb-0">
                                <thead>
                                    <tr className="font-family-bold">
                                        <th>Start date</th>
                                        <th>End date</th>
                                        <th style={{ width: "80%" }}>Link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cdrReports.map((item, index) => (
                                        <tr className="font-family-medium py-2" key={index} style={{ fontSize: 12 }}>
                                            <td className="py-2">{format(new Date(item.startDate), "MM/dd/yyyy")}</td>
                                            <td className="py-2">{format(new Date(item.endDate), "MM/dd/yyyy")}</td>
                                            <td className="py-2 d-flex align-items-center">
                                                <span
                                                    className="d-table"
                                                    style={{ tableLayout: "fixed", width: "100%" }}>
                                                    <a
                                                        className="d-block text-cta text-truncate"
                                                        href={item.filePath}
                                                        target="_blank"
                                                        rel="noreferrer">
                                                        {item.filePath}
                                                    </a>
                                                </span>
                                                <span
                                                    className="reach-cs-copy ml-1 text-secondary cursor-pointer"
                                                    onClick={() => navigator.clipboard.writeText(item.filePath)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {/*  <div className="row divider mx-n2">
                                <div className="col-3 font-family-bold">Start Date</div>
                                <div className="col-3 font-family-bold">End Date</div>
                                <div className="col font-family-bold">Link</div>
                            </div>
                            {cdrReports.map((item, index) => (
                                <div className="row divider py-2" key={index}>
                                    <div className="col-3">{format(new Date(item.startDate), "MM/dd/yyyy")}</div>
                                    <div className="col-3">{format(new Date(item.endDate), "MM/dd/yyyy")}</div>
                                    <div className="col-6" style={{}}>
                                        <a
                                            className="text-cta d-inline-block text-truncate"
                                            href={item.filePath}
                                            target="_blank">
                                            {item.filePath}
                                        </a>
                                        <span className="reach-cs-copy ml-1 text-secondary" />
                                    </div>
                                </div>
                            ))} */}
                        </div>
                    </Card>
                )}
            </div>
            <Modal title="CDR reports generator" size="sm" show={showModal} onHide={() => setShowModal(false)}>
                <span className="font-family-bold text-primary text-center" style={{ fontSize: 16 }}>
                    We emailed the CDR report to {customerProfile.emailId}
                </span>
            </Modal>

            <Modal
                title="CDR reports generator"
                size="sm"
                show={showNoCDRModal}
                onHide={() => setShowNoCDRModal(false)}>
                <span className="text-center" style={{ fontSize: 16 }}>
                    No CDR Report generated
                </span>
            </Modal>
        </Layout>
    );
}
