import React, { useEffect, useRef } from "react";
import { Input, InputProps } from "./input";

export interface PlacesAutocompleteInputProps extends InputProps {
    onPlaceChanged: (addressComponents?: google.maps.GeocoderAddressComponent[]) => void;
}

export default function PlacesAutocompleteInput(props: PlacesAutocompleteInputProps) {
    const { onPlaceChanged, ...rest } = props;
    const ref = useRef<HTMLInputElement>();

    useEffect(() => {
        if (window.google) {
            const autocomplete = new google.maps.places.Autocomplete(ref.current!, {
                types: ["geocode"],
            });

            autocomplete.setFields(["address_component"]);
            autocomplete.setComponentRestrictions({ country: ["us"] });

            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();

                onPlaceChanged(place.address_components);
            });

            return () => google.maps.event.clearInstanceListeners(autocomplete);
        }
    }, []);

    return <Input {...rest} register={ref} />;
}
