import React, { Fragment, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import CustomerPageLayout from "../components/customer-page-layout";
import { useAppSelector, useUnwrapAsyncThunk } from "../utils/store";
import { cancelReachPlan, getPlanHistory, profilesSelector } from "../features/profile-slice";
import { navigate } from "gatsby";
import { compareDesc, format, isFuture } from "date-fns";
import { useErrorHandler } from "../components/error-snackbar";
import { PlanHistoryItem } from "../utils/types";
import Modal from "../components/modal";
import { plansSelector } from "../features/plans-slice";
import { useForm } from "react-hook-form";
import { Button } from "../components/button";

export interface PlanHistoryProps extends RouteComponentProps {
    customerId: string;
}

function PlanHistory(props: PlanHistoryProps) {
    const { customerId } = props;

    const customerProfile = useAppSelector((state) => profilesSelector.selectById(state, customerId ?? ""));
    const plans = useAppSelector(plansSelector.selectAll);

    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const [planHistoryRes, setPlanHistoryRes] = useState<PlanHistoryItem[]>();
    const [showCancelModal, setShowCancelModal] = useState(false);

    const { handleSubmit, formState } = useForm({
        mode: "onBlur",
    });

    async function fetchReachPlans() {
        try {
            const res = await unwrap(getPlanHistory(customerId));
            console.log(res);
            setPlanHistoryRes(res.sort((a, b) => compareDesc(new Date(a.createdDate), new Date(b.createdDate))));
        } catch (error) {
            console.log(error);
            if (error.resCode === 40039) {
                setPlanHistoryRes([]);
            } else {
                handleError(error);
            }
        }
    }

    useEffect(() => {
        fetchReachPlans();
    }, []);

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    let numOfExtraLines = 0;
    if (customerProfile.extraLines > customerProfile.additionalLines) {
        numOfExtraLines = customerProfile.extraLines - customerProfile.additionalLines;
    }
    const lines = customerProfile.groupLines.length + numOfExtraLines;

    const onCancel = async () => {
        try {
            await unwrap(cancelReachPlan(customerId));
            await fetchReachPlans();
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        setShowCancelModal(false);
    };

    if (!planHistoryRes) {
        return (
            <CustomerPageLayout title="Plan details" customerId={customerId} prevUrl={`/profile/${customerId}`}>
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary" />
                </div>
            </CustomerPageLayout>
        );
    }

    return (
        <CustomerPageLayout title="Plan details" customerId={customerId} prevUrl={`/profile/${customerId}`}>
            <div className="d-flex flex-column align-items-center" style={{ fontSize: 16 }}>
                <div className="font-family-semibold">Current Plan</div>
                <div className="font-family-bold mt-2">
                    {customerProfile.reachPlan.name} {customerProfile.reachPlan.planData}
                    {customerProfile.reachPlan.dataUnit} -{" "}
                    <span className="font-family-medium">
                        for {lines} {lines > 1 ? "lines" : "line"}
                    </span>
                </div>
            </div>

            <div className="row font-family-semibold pb-1 divider mt-3 mx-0">
                <div className="col px-0">Activity</div>
                <div className="col px-0">Plan name</div>
                <div className="col px-0">Status</div>
            </div>
            {planHistoryRes.map((item, index) => (
                <Fragment key={item.createdDate}>
                    <div className="row font-family-semibold pb-1 py-2 mx-0">
                        <div className="col px-0">
                            <div>{item.upgraded ? "Plan upgraded" : "Plan downgraded"}</div>
                            <div className="text-grey1 mt-1" style={{ fontSize: 12 }}>
                                {format(new Date(item.createdDate), "MMM dd, yyyy")}
                            </div>
                        </div>
                        <div className="col px-0">{item.newReachPlanId}</div>
                        {item.cancelled ? (
                            <div className="col px-0">Cancelled</div>
                        ) : (
                            <div className="col px-0">
                                {!item.immediateEffect && isFuture(new Date(item.billingDate)) ? (
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <div>In process</div>
                                            <div className="text-grey1 mt-1" style={{ fontSize: 12 }}>
                                                Active on{" "}
                                                {format(
                                                    new Date(
                                                        item.immediateEffect ? item.createdDate : item.billingDate
                                                    ),
                                                    "MMM dd, yyyy"
                                                )}
                                            </div>
                                        </div>
                                        <div className="text-cta" onClick={() => setShowCancelModal(true)}>
                                            Cancel
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div>Activated on</div>
                                        <div className="text-grey1 mt-1" style={{ fontSize: 12 }}>
                                            {format(
                                                new Date(item.immediateEffect ? item.createdDate : item.billingDate),
                                                "MMM dd, yyyy"
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {index === planHistoryRes.length - 1 && (
                        <div className="row font-family-semibold pb-1 py-2 mx-0">
                            <div className="col px-0">
                                <div>Plan purchased</div>
                                {customerProfile.simPurchaseDate && (
                                    <div className="text-grey1 mt-1" style={{ fontSize: 12 }}>
                                        {format(new Date(customerProfile.simPurchaseDate), "MMM dd, yyyy")}
                                    </div>
                                )}
                            </div>
                            <div className="col px-0">{item.currentReachPlanId}</div>
                            <div className="col px-0">
                                <div>Activated on</div>
                                {customerProfile.activationDate && (
                                    <div className="text-grey1 mt-1" style={{ fontSize: 12 }}>
                                        {format(new Date(customerProfile.activationDate), "MMM dd, yyyy")}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </Fragment>
            ))}
            {planHistoryRes.length === 0 && (
                <div className="row font-family-semibold pb-1 py-2 mx-0">
                    <div className="col px-0">
                        <div>Plan purchased</div>
                        {customerProfile.simPurchaseDate && (
                            <div className="text-grey1 mt-1" style={{ fontSize: 12 }}>
                                {format(new Date(customerProfile.simPurchaseDate), "MMM dd, yyyy")}
                            </div>
                        )}
                    </div>
                    <div className="col px-0">{customerProfile.reachPlan.name}</div>
                    <div className="col px-0">
                        <div>Activated on</div>
                        {customerProfile.activationDate && (
                            <div className="text-grey1 mt-1" style={{ fontSize: 12 }}>
                                {format(new Date(customerProfile.activationDate), "MMM dd, yyyy")}
                            </div>
                        )}
                    </div>
                </div>
            )}

            <Modal title="Cancel request?" show={showCancelModal} onHide={() => setShowCancelModal(false)} size="sm">
                <Button color="secondary" onClick={handleSubmit(onCancel)} loading={formState.isSubmitting} fullWidth>
                    Confirm
                </Button>
            </Modal>
        </CustomerPageLayout>
    );
}

export default PlanHistory;
