import React from "react";
import { RouteComponentProps } from "@reach/router";
import Layout from "../../components/layout";
import { navigate } from "gatsby";
import purchaseCompletedImg from "../../images/purchaseCompleted.png";
import { Button } from "../../components/button";
import { useSelector } from "react-redux";
import { customerSelector, purchaseReset } from "../../features/purchase-slice";
import { useAppDispatch } from "../../utils/store";
import CustomerBadge from "../../components/customer-badge";

type PurchaseCompleteProps = RouteComponentProps;

export default function PurchaseComplete(props: PurchaseCompleteProps) {
    const customer = useSelector(customerSelector);
    const dispatch = useAppDispatch();

    return (
        <Layout title={`${customer.firstName} ${customer.lastName} - Purchase Complete`}>
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customer.firstName} {customer.lastName} <CustomerBadge isPrimary={customer.isPrimary} />
                    </div>
                    <div>{customer.emailId}</div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>Complete Sign Up</h1>
                </div>
                <div className="divider" />

                <div className="d-flex flex-column align-items-center mt-5">
                    <img src={purchaseCompletedImg} />
                    <div className="font-family-bold text-primary text-center mt-5" style={{ fontSize: 16 }}>
                        We emailed a payment link to {customer.emailId}
                    </div>
                    <div className="text-center mt-3">Please complete the payment to proceed further</div>
                    <Button
                        fullWidth
                        color="secondary"
                        className="col-12 col-md-6 my-3"
                        onClick={() => {
                            navigate(`/profile/${customer.id}`);
                            dispatch(purchaseReset());
                        }}>
                        Next
                    </Button>
                </div>
            </div>
        </Layout>
    );
}
