import React, { useState } from "react";
import classNames from "classnames";
import { format, getMonth, getYear } from "date-fns";
import range from "lodash.range";
import { useSelector } from "react-redux";
import {
    addCreditCard,
    CardExpiryState,
    fetchProfile,
    generatePaymentPage,
    ParsedCreditCard,
    profilesSelector,
    removeCard,
    setAutopayCard,
    updateCardExpiry,
} from "../features/profile-slice";
import { usePermissions } from "../features/hooks/use-permissions";
import dinersClub from "../images/dinersclub.png";
import amex from "../images/imgFiAmex.png";
import discoverImg from "../images/imgFiDiscover.png";
import visa from "../images/imgFiVisa.png";
import mastercard from "../images/mastercard.png";
import { useAppDispatch, useAppSelector, useUnwrapAsyncThunk } from "../utils/store";
import { Button } from "./button";
import { Card } from "./card";
import Modal from "./modal";
import Select from "./select";
import { useErrorHandler } from "./error-snackbar";
import { useForm } from "react-hook-form";
import { useSuccessModal } from "./success-modal";
import CustomerVerification from "./customer-verification";
import { isBrowser } from "../utils/helpers";
import { reachEndpoint } from "../utils/reach-cs-api";

export interface PaymentCardsProps {
    className?: string;
    cards: ParsedCreditCard[];
    customerId: string;
    isCardHold: boolean;
}

export const cardImages: Record<string, string> = {
    VISA: visa,
    MASTERCARD: mastercard,
    "AMERICAN EXPRESS": amex,
    "DINERS CLUB": dinersClub,
    DISCOVER: discoverImg,
    AMERICANEXPRESS: amex,
    DINERSCLUB: dinersClub,
};

export function getCardImage(cardType?: string) {
    return cardType && cardImages[cardType?.toUpperCase()];
}

interface FormData {
    month: string;
    year: string;
}

function UpdateCardModal(props: { creditCard: ParsedCreditCard; setCreditCard: (card?: ParsedCreditCard) => void }) {
    const { creditCard, setCreditCard } = props;

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();

    const customerProfile = useAppSelector((state) => profilesSelector.selectById(state, creditCard.customerId))!;

    const originalMonth = creditCard.dateOfExpiry.substring(0, 2);
    const originalYear = "20" + creditCard.dateOfExpiry.substring(2);

    const currentMonth = getMonth(new Date()) + 1;
    const currentYear = getYear(new Date());

    const { handleSubmit, register, formState, watch } = useForm<FormData>();

    const [showCustomerVerification, setShowCustomerVerification] = useState(false);

    const onSubmit = async (data: FormData) => {
        try {
            const expiry = data.month + data.year.substring(2);
            await unwrap(updateCardExpiry({ ccId: creditCard.uuid, expiry }));
            await dispatch(fetchProfile(creditCard.customerId));
            showSuccessModal("Credit card updated");
            setCreditCard(undefined);
        } catch (error) {
            handleError(error);
        }
    };

    const year = watch("year", originalYear);

    return (
        <Modal
            show={creditCard != null}
            title="Update Card"
            size="sm"
            onHide={() => setCreditCard(undefined)}
            titleClassName={classNames({ disabled: showCustomerVerification })}>
            <div
                className={classNames("w-100 d-flex flex-column align-items-center", {
                    disabled: showCustomerVerification,
                })}
                style={{ pointerEvents: showCustomerVerification ? "none" : undefined }}>
                <div className="align-self-start">
                    <div>
                        <div>Card Number</div>
                        <div className="font-family-semibold">
                            XXXX-XXXX-XXXX-
                            {creditCard.ccNumberMask}
                        </div>
                    </div>
                    <div className="mt-3">
                        <div>Name on card</div>
                        <div className="font-family-semibold">{creditCard.nameOnCard}</div>
                    </div>
                    <div className="mt-3">
                        <div>Expiry date</div>
                        <div className="d-flex">
                            <Select defaultValue={originalMonth} register={register()} name="month">
                                {range(year === currentYear.toString() ? currentMonth + 1 : 1, 13).map((num) => {
                                    const month = ("0" + num).slice(-2);
                                    return (
                                        <option
                                            key={month}
                                            value={month}
                                            disabled={month === originalMonth && year === originalYear}>
                                            {month}
                                        </option>
                                    );
                                })}
                            </Select>
                            <Select defaultValue={originalYear} className="ml-3" name="year" register={register()}>
                                {range(currentYear, 2100).map((num) => (
                                    <option key={num} value={num}>
                                        {num}
                                    </option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div>CVV</div>
                        <div className="font-family-semibold">***</div>
                    </div>
                </div>
                <Button
                    color="secondary"
                    className="col-10 mt-3"
                    loading={formState.isSubmitting}
                    onClick={() => setShowCustomerVerification(true)}>
                    Update
                </Button>
            </div>

            {showCustomerVerification && (
                <CustomerVerification onSubmit={handleSubmit(onSubmit)} customerProfile={customerProfile} />
            )}
        </Modal>
    );
}

export default function PaymentCards(props: PaymentCardsProps) {
    const { className, cards, customerId, isCardHold } = props;

    const { hasOnePermission } = usePermissions();

    const [selectedAutopayCard, setSelectedAutopayCard] = useState<ParsedCreditCard | undefined>();
    const [selectedUpdateCard, setSelectedUpdateCard] = useState<ParsedCreditCard | undefined>();
    const [selectedRemoveCard, setSelectedRemoveCard] = useState<ParsedCreditCard | undefined>();
    const [loading, setLoading] = useState(false);

    const [showNewCardModal, setShowNewCardModal] = useState(false);

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const { handleSubmit, formState } = useForm({
        mode: "onBlur",
    });

    const customerProfile = useAppSelector((state) => profilesSelector.selectById(state, customerId))!;

    if (!customerProfile.isPrimary) {
        return null;
    }

    const onPayment = async () => {
        try {
            const res = await unwrap(
                generatePaymentPage({
                    emailId: customerProfile.emailId,
                    redirectUrl: `${window.location.origin}/profile/${customerProfile.id}`,
                    type: "card",
                })
            );
            window.location.href = `${reachEndpoint}/account/redirect/page/${res.id}`;
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    return (
        <Card className={className}>
            <div className="w-100 d-flex justify-content-between">
                <div className="font-family-bold align-self-start" style={{ fontSize: 16 }}>
                    Saved Cards
                </div>
                <div
                    className={classNames("text-cta", {
                        disabled: !hasOnePermission("billing.credit_card.add"),
                    })}
                    onClick={() => hasOnePermission("billing.credit_card.add") && setShowNewCardModal(true)}>
                    Add a new card
                </div>
            </div>
            {cards.map((card, index) => (
                <div key={card.uuid} className="row w-100 align-items-center mt-2">
                    {index !== 0 && <div className="divider mb-2" />}
                    {getCardImage(card.type) && (
                        <img src={getCardImage(card.type)} className="align-self-start mt-1" width={45} height={30} />
                    )}
                    <div className="col px-2">
                        <div>XXXX-XXXX-XXXX-{card.ccNumberMask}</div>
                        <div style={{ fontSize: 12 }}>
                            {card.nameOnCard} |{" "}
                            {card.cardExpiryState === CardExpiryState.EXPIRED ? (
                                <span className="text-danger">Expired date {format(card.parsedDate, "MM/yy")}</span>
                            ) : (
                                <span
                                    className={classNames({
                                        "text-tertiary2": card.cardExpiryState === CardExpiryState.ALMOST_EXPIRED,
                                    })}>
                                    Expiration date {format(card.parsedDate, "MM/yy")}
                                </span>
                            )}
                        </div>
                        <div className="d-flex">
                            {card.cardExpiryState !== CardExpiryState.EXPIRED && !card.isAutoPay && (
                                <div
                                    className={classNames("text-cta mr-3", {
                                        disabled: !hasOnePermission("billing.credit_card.update") || isCardHold,
                                    })}
                                    onClick={() =>
                                        hasOnePermission("billing.credit_card.update") &&
                                        !isCardHold &&
                                        setSelectedAutopayCard(card)
                                    }>
                                    Set default
                                </div>
                            )}
                            <div
                                className={classNames("text-cta mr-3", {
                                    disabled: !hasOnePermission("billing.credit_card.update"),
                                })}
                                onClick={() =>
                                    hasOnePermission("billing.credit_card.update") && setSelectedUpdateCard(card)
                                }>
                                Update
                            </div>
                            {!card.isAutoPay && (
                                <div
                                    className={classNames("text-cta", {
                                        disabled: !hasOnePermission("billing.credit_card.delete"),
                                    })}
                                    onClick={() =>
                                        hasOnePermission("billing.credit_card.update") && setSelectedRemoveCard(card)
                                    }>
                                    Remove
                                </div>
                            )}
                        </div>
                    </div>
                    {card.isAutoPay && <span className="reach-cs-tick text-primary align-self-start mt-1" />}
                </div>
            ))}
            {selectedAutopayCard && (
                <Modal
                    show={selectedAutopayCard != null}
                    title="Set as default card?"
                    onHide={() => setSelectedAutopayCard(undefined)}>
                    <div className="d-flex">
                        {getCardImage(selectedAutopayCard.type) && (
                            <img
                                src={getCardImage(selectedAutopayCard.type)}
                                className="align-self-start mt-1"
                                width={45}
                                height={30}
                            />
                        )}
                        <div className="col px-2">
                            <div>
                                XXXX-XXXX-XXXX-
                                {selectedAutopayCard.ccNumberMask}
                            </div>
                            <div style={{ fontSize: 12 }}>
                                {selectedAutopayCard.nameOnCard} |{" "}
                                {selectedAutopayCard.cardExpiryState === CardExpiryState.EXPIRED ? (
                                    <span className="text-danger">
                                        Expired date {format(selectedAutopayCard.parsedDate, "MM/yy")}
                                    </span>
                                ) : (
                                    <span
                                        className={classNames({
                                            "text-tertiary2":
                                                selectedAutopayCard.cardExpiryState === CardExpiryState.ALMOST_EXPIRED,
                                        })}>
                                        Expiration date {format(selectedAutopayCard.parsedDate, "MM/yy")}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <Button
                        color="secondary"
                        className="col-6 mt-3"
                        loading={loading}
                        onClick={async () => {
                            try {
                                setLoading(true);
                                await unwrap(setAutopayCard(selectedAutopayCard.uuid));
                                await dispatch(fetchProfile(customerId));
                                setSelectedAutopayCard(undefined);
                            } catch (error) {
                                handleError(error);
                            }
                            setLoading(false);
                        }}>
                        Confirm
                    </Button>
                </Modal>
            )}
            {selectedUpdateCard && (
                <UpdateCardModal creditCard={selectedUpdateCard} setCreditCard={setSelectedUpdateCard} />
            )}
            {selectedRemoveCard && (
                <Modal
                    show={selectedRemoveCard != null}
                    title="Do you want to remove the card?"
                    onHide={() => setSelectedRemoveCard(undefined)}>
                    <Button
                        color="secondary"
                        className="col-6 mt-3"
                        loading={loading}
                        onClick={async () => {
                            try {
                                setLoading(true);
                                await unwrap(removeCard(selectedRemoveCard.uuid));
                                await dispatch(fetchProfile(customerId));
                                setSelectedRemoveCard(undefined);
                            } catch (error) {
                                handleError(error);
                            }
                            setLoading(false);
                        }}>
                        Confirm
                    </Button>
                </Modal>
            )}
            <Modal
                show={showNewCardModal}
                size="sm"
                title=" Do you want to add a new card?"
                onHide={() => setShowNewCardModal(false)}>
                {hasOnePermission("user_management.payment.send_email") && (
                    <Button
                        color="secondary"
                        className="col-12"
                        loading={loading}
                        onClick={async () => {
                            try {
                                setLoading(true);
                                await unwrap(addCreditCard(customerId));
                                await dispatch(fetchProfile(customerId));
                                window.scrollTo(0, 0);
                                setShowNewCardModal(false);
                            } catch (error) {
                                handleError(error);
                            }
                            setLoading(false);
                        }}>
                        Send email with Link to add card
                    </Button>
                )}
                {hasOnePermission("user_management.payment.collect_direct") && (
                    <Button
                        color="secondary"
                        className="col-12 mt-3"
                        loading={formState.isSubmitting}
                        onClick={handleSubmit(onPayment)}>
                        Collect card details online
                    </Button>
                )}
            </Modal>
        </Card>
    );
}
