import React from "react";
import Layout from "../components/layout";

export default function Help() {
    return (
        <Layout title="Help">
            <iframe
                style={{ width: "100%", height: "100vh", border: 0 }}
                src="/Troubleshooting d43fde58268e4d3eab17601e90002aef.html"></iframe>
        </Layout>
    );
}
