import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import Layout from "../../components/layout";
import { Link } from "gatsby";
import { Button } from "../../components/button";
import { Input } from "../../components/input";
import { Controller, useForm } from "react-hook-form";
import { useAppSelector, useUnwrapAsyncThunk } from "../../utils/store";
import { useErrorHandler } from "../../components/error-snackbar";
import Select from "../../components/select";
import { plansSelector } from "../../features/plans-slice";
import { useSelector } from "react-redux";
import range from "lodash.range";
import { ReachCsAPI } from "../../utils/reach-cs-api";
import { ReachPlan, TaxType, ZipcodeRes } from "../../utils/types";
import { differenceInDays, format, getDate, getDaysInMonth, isSameDay } from "date-fns";
import { topupPulseSelector } from "../../features/config-slice";
import Modal from "../../components/modal";
import { toTitleCase } from "../../utils/helpers";
import Toggle from "../../components/toggle";

type BillCalculatorProps = RouteComponentProps;

interface FormData {
    plan: string;
    lines: number;
    topups?: number;
    zipcode: string;
    activationDate?: Date;
    promoCode: string;
}

function getTotalLineCost(plan: ReachPlan, lines: number) {
    const actualPrice = ((100 - plan.discountPctg) * plan.baseLinePrice) / 100;

    let prices = 0;
    const additionalLines = lines - 1;
    if (plan.addLineSplitPrice) {
        for (let i = 1; i <= additionalLines; i++) {
            prices = prices + plan.addLineSplitPrice[`${i}`];
        }
    } else {
        prices = additionalLines * plan.additionalLinePrice;
    }
    return actualPrice + prices;
}

interface CalculatedTotal {
    totalAmount: number;
    topupCost: number;
    stateAndLocal: number;
    e911: number;
    fusf: number;
    costRecovery: number;
    perLineCost: number;
    perLineCostWithTax: number;
    totalTax: number;
}

interface ModalData extends FormData {
    amount: number;
    planSize: number;
    planDiscount?: number;
    couponName?: string;
    isProrated: boolean;
    zipRes: ZipcodeRes;
    totalWithoutCoupon: CalculatedTotal;
    totalWithCoupon?: CalculatedTotal;
}

export default function BillCalculator(props: BillCalculatorProps) {
    const { handleSubmit, register, watch, errors, formState, setError, reset } = useForm<FormData>({
        mode: "onBlur",
    });
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const topupPulse = useSelector(topupPulseSelector);

    const plans = useSelector(plansSelector.selectAll);

    const [modalData, setModalData] = useState<ModalData>();

    const calculateTotal = async (amount: number, zipRes: ZipcodeRes, data: FormData) => {
        const taxRes = await ReachCsAPI.getTaxByAddress(
            amount.toFixed(2),
            TaxType.PLAN,
            {
                address1: "285 Bilerica Road",
                address2: "suite 203",
                country: "USA",
                city: zipRes.City,
                state: zipRes.State,
                zip: zipRes.Zip,
                type: "billing",
                name: "",
                phone: "",
            },
            data.lines - 1
        );
        let stateAndLocal = 0;
        let e911 = 0;
        let fusf = 0;
        let costRecovery = 0;
        for (let i = 0; i < taxRes.taxes.length; i++) {
            switch (getTaxType(taxRes.taxes[i].name, taxRes.taxes[i].cat)) {
                case "STATE_AND_LOCAL_TAXES": {
                    stateAndLocal += taxRes.taxes[i].tax;
                    break;
                }
                case "FEES_911": {
                    e911 += taxRes.taxes[i].tax;
                    break;
                }
                case "COST_RECOVERY_FEE": {
                    costRecovery += taxRes.taxes[i].tax;
                    break;
                }
                case "FEDERAL_UNIVERSAL_SERVICE_FUND_FEE": {
                    fusf += taxRes.taxes[i].tax;
                    break;
                }
                default: {
                    stateAndLocal += taxRes.taxes[i].tax;
                    break;
                }
            }
            //console.log(allTaxes[i].name + allTaxes[i].cat);
        }
        const topupCost = ((data.topups ?? 0) * topupPulse.pulseCost) / topupPulse.pulse;
        const totalAmount = amount + topupCost + taxRes.totalTax;

        return {
            totalAmount,
            topupCost,
            stateAndLocal,
            e911,
            fusf,
            costRecovery,
            perLineCost: amount / data.lines,
            perLineCostWithTax: (amount + taxRes.totalTax) / data.lines,
            totalTax: taxRes.totalTax,
        };
    };

    const onSubmit = async (data: FormData) => {
        console.log(data);

        let planDiscount: number | undefined;
        let couponName: string | undefined;
        let couponDiscount: number | undefined;
        try {
            const billingStartDate = 7;
            const totalActiveDays = 31;
            const isProrated = data.activationDate?.getDate() !== billingStartDate;
            let amount = getTotalLineCost(selectedPlan!, data.lines);
            let planSize = selectedPlan!.planData;
            if (isProrated && data.activationDate) {
                const daysInTheMonth = getDaysInMonth(data.activationDate);
                const activationDate = data.activationDate.getDate();
                const activeDays =
                    activationDate > billingStartDate
                        ? daysInTheMonth - (activationDate - billingStartDate)
                        : billingStartDate - activationDate;
                amount = (activeDays / totalActiveDays) * amount;
                planSize = Math.max((activeDays / totalActiveDays) * planSize, 0.2);
            }
            if (data.promoCode != "") {
                const coupon = await ReachCsAPI.getCoupon(data.promoCode);
                if (!coupon.expired) {
                    if (coupon.planDiscount) {
                        planDiscount = coupon.planDiscount;
                        couponName = coupon.name;
                        couponDiscount = (planDiscount * selectedPlan!.baseLinePrice) / 100;
                    }
                    if (coupon.discountInDollar) {
                        planDiscount = coupon.discountInDollar;
                        couponName = coupon.name;
                        couponDiscount = planDiscount;
                    }
                } else {
                    throw new Error("Promocode expired");
                }
            }
            const zipRes = await ReachCsAPI.checkZipcode(data.zipcode);
            const totalWithoutCoupon = await calculateTotal(amount, zipRes, data);
            let totalWithCoupon;
            if (couponDiscount) {
                totalWithCoupon = await calculateTotal(amount - couponDiscount, zipRes, data);
            }

            setModalData({
                ...data,
                amount,
                planSize,
                planDiscount,
                couponName,
                isProrated,
                zipRes,
                totalWithoutCoupon,
                totalWithCoupon,
            });
        } catch (error) {
            console.log(error);
            handleError(error.response?.data);
        }
    };

    const watchPlan = watch("plan");

    const selectedPlan = useAppSelector((state) => plansSelector.selectById(state, watchPlan));

    return (
        <Layout title="Tools - Estimate Bill">
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to="/tools">
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>Estimate bill</h1>
                </div>
                <div className="divider" />

                <form className="d-flex flex-column align-items-center mt-3" onSubmit={handleSubmit(onSubmit)}>
                    <Select
                        className="col-12 col-md-8 col-xl-4 mt-3 px-2"
                        register={register({
                            required: {
                                value: true,
                                message: "Plan is required",
                            },
                        })}
                        name="plan"
                        showError={errors.plan != null}
                        errorMessage={errors.plan?.message}>
                        <option value="">Select plan</option>
                        {plans.map((plan) => (
                            <option key={plan.name} value={plan.name}>
                                {plan.name}
                            </option>
                        ))}
                    </Select>
                    <Select
                        className="col-12 col-md-8 col-xl-4 mt-3 px-2"
                        register={register({
                            required: {
                                value: true,
                                message: "Lines is required",
                            },
                            valueAsNumber: true,
                        })}
                        name="lines"
                        disabled={!selectedPlan}
                        showError={errors.lines != null}
                        errorMessage={errors.lines?.message}>
                        <option value="">Select lines</option>
                        {range(1, 1 + (selectedPlan?.maxLines ?? 0)).map((val) => (
                            <option key={val} value={val}>
                                {val}
                            </option>
                        ))}
                    </Select>
                    <Input
                        type="text"
                        inputMode="numeric"
                        placeholder="Zipcode"
                        name="zipcode"
                        maxLength={5}
                        className="col-12 col-md-8 col-xl-4 mt-3 px-2"
                        defaultValue=""
                        register={register({
                            required: {
                                value: true,
                                message: "Zip is required",
                            },
                            pattern: {
                                value: /^\d{5}$/,
                                message: "Zip should be 5 digits long",
                            },
                        })}
                        showError={errors.zipcode != null}
                        errorMessage={errors.zipcode?.message}
                    />
                    <div className="col-12 col-md-8 col-xl-4 mt-3 px-2" style={{ fontSize: 12 }}>
                        Activation Date (Optional)
                    </div>
                    <Input
                        type="date"
                        placeholder="Activation date"
                        className="col-12 col-md-8 col-xl-4 px-2"
                        name="activationDate"
                        min={format(new Date(), "yyyy-MM-dd")}
                        defaultValue=""
                        register={register({
                            valueAsDate: true,
                        })}
                        showError={errors.activationDate != null}
                        errorMessage={errors.activationDate?.message}
                    />
                    <Input
                        type="number"
                        min={0}
                        step={0.5}
                        placeholder="Topups (in GB)"
                        name="topups"
                        className="col-12 col-md-8 col-xl-4 mt-3 px-2"
                        defaultValue=""
                        register={register({
                            valueAsNumber: true,
                        })}
                        showError={errors.topups != null}
                        errorMessage={errors.topups?.message}
                    />
                    <Input
                        type="text"
                        placeholder="Promo code"
                        name="promoCode"
                        className="col-12 col-md-8 col-xl-4 mt-3 px-2"
                        defaultValue=""
                        register={register}
                        showError={errors.promoCode != null}
                        errorMessage={errors.promoCode?.message}
                    />
                    <Button
                        color="secondary"
                        type="submit"
                        loading={formState.isSubmitting}
                        className="col-12 col-md-8 col-xl-4 my-3">
                        Submit
                    </Button>
                </form>
            </div>

            {modalData && <EstimateModal modalData={modalData} setModalData={setModalData} />}
        </Layout>
    );
}

function EstimateModal(props: {
    modalData: ModalData;
    setModalData: React.Dispatch<React.SetStateAction<ModalData | undefined>>;
}) {
    const { modalData, setModalData } = props;
    let shareText = "";

    const [calculateWithPromo, setCalculateWithPromo] = useState(modalData.totalWithCoupon != null);

    const calculatedTotal =
        calculateWithPromo && modalData.totalWithCoupon ? modalData.totalWithCoupon : modalData.totalWithoutCoupon;

    if (modalData.lines === 1) {
        shareText = `Your monthly cost is $${calculatedTotal.perLineCost.toFixed(
            2
        )} and $${calculatedTotal.totalTax.toFixed(2)} in taxes and fees.`;
    } else {
        shareText = `Your monthly cost is $${calculatedTotal.perLineCost.toFixed(2)} per user for ${
            modalData.lines
        } users in a group, and $${calculatedTotal.totalTax.toFixed(
            2
        )} in taxes and fees. When you break it down, it comes to only $${calculatedTotal.perLineCostWithTax.toFixed(
            2
        )} per user per month.`;
    }

    return (
        <Modal
            title=""
            show={modalData != null}
            onHide={() => {
                setModalData(undefined);
            }}>
            {modalData.totalWithCoupon != null && (
                // <Toggle
                //     label={calculateWithPromo ? "Show calculations without promo" : "Show calculations with promo"}
                //     id="promo-toggle"
                //     checked={calculateWithPromo}
                //     onChange={({ target: { checked } }) => setCalculateWithPromo(checked)}
                //     className="align-self-start mb-3"
                // />
                <div className="d-flex mb-2">
                    <Button
                        color={`${calculateWithPromo ? "primary" : "grey1"}`}
                        onClick={() => setCalculateWithPromo(true)}
                        className="with"
                        style={{ borderRadius: 0 }}>
                        Cost with Promo
                    </Button>
                    <Button
                        color={`${!calculateWithPromo ? "primary" : "grey1"}`}
                        onClick={() => setCalculateWithPromo(false)}
                        className="with"
                        style={{ borderRadius: 0 }}>
                        Cost without Promo
                    </Button>
                </div>
            )}
            {modalData.isProrated && modalData.activationDate && (
                <div className="text-center font-family-semibold mb-3">
                    Because the selected the date for activation as {format(modalData.activationDate, "MMM dd, yyyy")},
                    the data is prorated to {modalData.planSize.toFixed(2)}
                    GB and the amount shown below the prorated cost for the first month.
                </div>
            )}
            <div className="text-primary font-family-bold" style={{ fontSize: 16 }}>
                Your estimated bill is <span className="h1">${calculatedTotal.totalAmount.toFixed(2)}</span> per month
            </div>
            <div className="text-center font-family-semibold my-3" style={{ fontSize: 16 }}>
                {shareText}
                <span
                    className="reach-cs-copy ml-1 text-secondary cursor-pointer"
                    onClick={() =>
                        navigator.clipboard.writeText(
                            `Your estimated bill is $${calculatedTotal.totalAmount.toFixed(2)} per month. ` + shareText
                        )
                    }
                />
            </div>
            <div className="font-family-semibold">
                Coverage for {toTitleCase(modalData.zipRes.City)}, {modalData.zipRes.State} is{" "}
                <span className="font-family-bold">{modalData.zipRes.CoveragePercent}%</span>
            </div>
            {modalData.couponName && calculateWithPromo && (
                <div className="font-family-semibold d-flex justify-content-between align-self-start divider py-2">
                    <div>Coupon details</div>
                    <div>{modalData.couponName}</div>
                </div>
            )}

            <div className="font-family-semibold d-flex justify-content-between align-self-start divider py-2">
                <div>Total monthly amount</div>
                <div>${calculatedTotal.totalAmount.toFixed(2)}</div>
            </div>
            <div className="font-family-semibold align-self-start divider py-2">
                <div className="d-flex justify-content-between">
                    <div>Total Taxes</div>
                    <div>${calculatedTotal.totalTax.toFixed(2)}</div>
                </div>
                <div
                    className="font-family-medium d-flex justify-content-between align-self-start"
                    style={{ fontSize: 12 }}>
                    <div>Total FUSF Taxes</div>
                    <div>${calculatedTotal.fusf.toFixed(2)}</div>
                </div>
                <div
                    className="font-family-medium d-flex justify-content-between align-self-start"
                    style={{ fontSize: 12 }}>
                    <div>Total State and Local taxes</div>
                    <div>${calculatedTotal.stateAndLocal.toFixed(2)}</div>
                </div>
                <div
                    className="font-family-medium d-flex justify-content-between align-self-start"
                    style={{ fontSize: 12 }}>
                    <div>Total 911 taxes</div>
                    <div>${calculatedTotal.e911.toFixed(2)}</div>
                </div>
                <div
                    className="font-family-medium d-flex justify-content-between align-self-start"
                    style={{ fontSize: 12 }}>
                    <div>Total cost recovery charges</div>
                    <div>${calculatedTotal.costRecovery.toFixed(2)}</div>
                </div>
            </div>
            <div className="font-family-semibold d-flex justify-content-between align-self-start divider py-2">
                <div>Total plan value</div>
                <div>${modalData.amount.toFixed(2)}</div>
            </div>
            <div className="font-family-semibold d-flex justify-content-between align-self-start divider py-2">
                <div>Total topup cost (charged instantly)</div>
                <div>${calculatedTotal.topupCost.toFixed(2)}</div>
            </div>
            <div className="font-family-semibold d-flex justify-content-between align-self-start divider py-2">
                <div>Per user cost without taxes</div>
                <div>${calculatedTotal.perLineCost.toFixed(2)}</div>
            </div>
            <div className="font-family-semibold d-flex justify-content-between align-self-start w-100 py-2">
                <div>Per user cost with taxes</div>
                <div>${calculatedTotal.perLineCostWithTax.toFixed(2)}</div>
            </div>
        </Modal>
    );
}

const taxes = {
    "Sales TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Business and Occupation TaxBUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "Carrier Gross ReceiptsGROSS RECEIPTS TAXES": "STATE_AND_LOCAL_TAXES",
    "District TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Excise TaxEXCISE TAXES": "STATE_AND_LOCAL_TAXES",
    "Federal Excise TaxEXCISE TAXES": "STATE_AND_LOCAL_TAXES",
    "Fed USF A - SchoolCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "License TaxBUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "P.U.C. FeeREGULATORY CHARGES": "COST_RECOVERY_FEE",
    "E-911E-911 CHARGES": "FEES_911",
    "Service TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Special TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "State Universal Service FundCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Statutory Gross ReceiptsGROSS RECEIPTS TAXES": "STATE_AND_LOCAL_TAXES",
    "SurchargeGROSS RECEIPTS TAXES": "STATE_AND_LOCAL_TAXES",
    "Utility Users TaxUTILITY USER TAXES": "STATE_AND_LOCAL_TAXES",
    "Sales (Web Hosting)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Fed Universal Service FundCONNECTIVITY CHARGES": "FEDERAL_UNIVERSAL_SERVICE_FUND_FEE",
    "State High Cost FundCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "State Deaf and Disabled FundCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "CA Teleconnect FundCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Universal Lifeline Telephone Service ChargeCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Telecom Relay SurchargeCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Telecommunications Infrastructure Maintenance FeeRIGHT OF WAY FEES": "COST_RECOVERY_FEE",
    "Poison Control FundE-911 CHARGES": "FEES_911",
    "Telecommunications Infrastructure FundRIGHT OF WAY FEES": "COST_RECOVERY_FEE",
    "NY MCTD 186cEXCISE TAXES": "STATE_AND_LOCAL_TAXES",
    "NY MCTD 184aBUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "Franchise TaxBUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "Utility Users Tax - BusinessUTILITY USER TAXES": "STATE_AND_LOCAL_TAXES",
    "Fed Telecommunications Relay ServiceCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "District Tax (Residential Only)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Transit TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Telecommunications Assistance Service FundCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "E911 (Business)E-911 CHARGES": "FEES_911",
    "TRS (Business)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Universal Service Fund (Line)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Universal Service Fund (Business Line)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "E911 (PBX/Trunk line)E-911 CHARGES": "FEES_911",
    "License Tax (Business)BUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "Optional TIMFRIGHT OF WAY FEES": "COST_RECOVERY_FEE",
    "Sales Tax (Business)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "E911 (Residential)E-911 CHARGES": "FEES_911",
    "E911 (Wireless)E-911 CHARGES": "FEES_911",
    "NY Franchise 184BUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "NY Franchise 184 UsageBUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "NY MCTD 184a UsageBUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "Universal Service Fund (Wireless)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Sales Tax (Data)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Municipal Right of WayRIGHT OF WAY FEES": "COST_RECOVERY_FEE",
    "Municipal Right of Way (Business)RIGHT OF WAY FEES": "COST_RECOVERY_FEE",
    "Municipal Right of Way (Private Line)RIGHT OF WAY FEES": "COST_RECOVERY_FEE",
    "Utility Users Tax - WirelessUTILITY USER TAXES": "STATE_AND_LOCAL_TAXES",
    "Fed USF CellularCONNECTIVITY CHARGES": "FEDERAL_UNIVERSAL_SERVICE_FUND_FEE",
    "Fed USF PagingCONNECTIVITY CHARGES": "FEDERAL_UNIVERSAL_SERVICE_FUND_FEE",
    "Sales Tax (Interstate)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Utility Users Tax (PBX Trunk)UTILITY USER TAXES": "STATE_AND_LOCAL_TAXES",
    "District Tax (Web Hosting)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "CA High Cost Fund ACONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Telecommunications Education Access FundCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Fed TRS CellularCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Fed TRS PagingCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Communications Service TaxCOMMUNICATIONS SERVICES TAX": "STATE_AND_LOCAL_TAXES",
    "Value Added Tax (VAT)VALUE ADDED TAXES": "STATE_AND_LOCAL_TAXES",
    "Goods and Service Tax (GST)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Harmonized Sales Tax (HST)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Provincial Sales Tax (PST)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Quebec Sales Tax (QST)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "National Contribution Regime (NCR)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Utility Users Tax (Cable Television)UTILITY USER TAXES": "STATE_AND_LOCAL_TAXES",
    "FCC Regulatory FeeCABLE REGULATORY FEES": "COST_RECOVERY_FEE",
    "Franchise Tax (Cable)CABLE REGULATORY FEES": "COST_RECOVERY_FEE",
    "Universal Service Fund (Paging)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Statutory Gross Receipts (Wireless)GROSS RECEIPTS TAXES": "STATE_AND_LOCAL_TAXES",
    "Franchise Tax (Wireless)BUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    ReservedRESERVED: "COST_RECOVERY_FEE",
    "Public Education and Government (PEG) Access FeeCABLE REGULATORY FEES": "COST_RECOVERY_FEE",
    "Communications Service Tax (Satellite)COMMUNICATIONS SERVICES TAX": "STATE_AND_LOCAL_TAXES",
    "Franchise Tax (Satellite)BUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "Federal TRS (Alternate)RESERVED": "COST_RECOVERY_FEE",
    "TRS (Centrex)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Utility Users Tax (Cable Television - Business)UTILITY USER TAXES": "STATE_AND_LOCAL_TAXES",
    "Utility Users Tax (Centrex)UTILITY USER TAXES": "STATE_AND_LOCAL_TAXES",
    "E911 (Centrex)E-911 CHARGES": "FEES_911",
    "Utility Users Tax (Line)UTILITY USER TAXES": "STATE_AND_LOCAL_TAXES",
    "Crime Control District TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Library District TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Hospital District TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Health Services District TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Emergency Services District TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Improvement District TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Development District TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Transit Web Hosting TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Ambulance District TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Fire District TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Police District TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Football District TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Baseball District TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Crime Control District Web Hosting TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Library District Web Hosting TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Hospital District Web Hosting TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Health Services District Web Hosting TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Emergency Services District Web Hosting TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Improvement District Web Hosting TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Development District Web Hosting TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Utility Users Tax (Interstate)UTILITY USER TAXES": "STATE_AND_LOCAL_TAXES",
    "Utility Users Tax (Telegraph)UTILITY USER TAXES": "STATE_AND_LOCAL_TAXES",
    "E911 Network and Database SurchargeE-911 CHARGES": "FEES_911",
    "License Tax EmergencyBUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "License Tax Emergency (Business)BUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "Educational Sales TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Educational Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "E911 Operational Surcharge County CommissionE-911 CHARGES": "FEES_911",
    "E911 Operational Surcharge Voter ApprovedE-911 CHARGES": "FEES_911",
    "Sales Tax Nine HundredSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Convention Center TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "E911 High Capacity TrunkE-911 CHARGES": "FEES_911",
    "School Board Tax ASALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "School Board Tax BSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "School Board Tax CSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "School Board Tax DSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "School Board Tax ESALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "School Board Tax FSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "School District TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Police Jury Tax BSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Police Jury Tax CSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Police Jury Tax ESALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Communications Service Tax (Wireless)COMMUNICATIONS SERVICES TAX": "STATE_AND_LOCAL_TAXES",
    "Service Provider TaxBUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "Telecommunications Sales TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Advanced Transit TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Advanced Transit Web Hosting TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Missouri Universal Service FundCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Business and Occupation Tax (Wholesale)BUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "Telecommunications Education Access Fund (Centrex)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Business and Occupation Tax (Other)BUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "Tribal Sales TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Sales Tax (Data Processing)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Transit Tax (Data Processing)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Crime Control District Tax (Data Processing)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Library District Tax (Data Processing)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Hospital District Tax (Data Processing)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Health Services District Tax (Data Processing)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Emergency Services District Tax (Data Processing)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Improvement District Tax (Data Processing)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Development District Tax (Data Processing)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Advanced Transit Tax (Data Processing)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "CA PSPE SurchargeCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "District Tax (Data Processing)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Eschelon UUTRESERVED": "COST_RECOVERY_FEE",
    "Cable Franchise FeeRESERVED": "COST_RECOVERY_FEE",
    "Statutory Gross Receipts (Business)GROSS RECEIPTS TAXES": "STATE_AND_LOCAL_TAXES",
    "E911 (VoIP)E-911 CHARGES": "FEES_911",
    "FUSF (VoIP)CONNECTIVITY CHARGES": "FEDERAL_UNIVERSAL_SERVICE_FUND_FEE",
    "FUSFCONNECTIVITY CHARGES": "FEDERAL_UNIVERSAL_SERVICE_FUND_FEE",
    "Universal Service Fund (VoIP)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Communications Service Tax (Cable)COMMUNICATIONS SERVICES TAX": "STATE_AND_LOCAL_TAXES",
    "Municipal Right of Way (Cable)RIGHT OF WAY FEES": "COST_RECOVERY_FEE",
    "FCC Regulatory Fee (Wireline)REGULATORY CHARGES": "COST_RECOVERY_FEE",
    "FCC Regulatory Fee (Wireless)REGULATORY CHARGES": "COST_RECOVERY_FEE",
    "Statutory Gross Receipts (Video)GROSS RECEIPTS TAXES": "STATE_AND_LOCAL_TAXES",
    "Utility Users Tax - LifelineUTILITY USER TAXES": "STATE_AND_LOCAL_TAXES",
    "TRS - Long DistanceCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Telecom Relay Surcharge (Wireless)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Sales Tax - Senior CitizenSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Regulatory Cost Charge - LocalREGULATORY CHARGES": "COST_RECOVERY_FEE",
    "Regulatory Cost Charge - IntrastateREGULATORY CHARGES": "COST_RECOVERY_FEE",
    "Regulatory Cost Charge - CableREGULATORY CHARGES": "COST_RECOVERY_FEE",
    "P.U.C. Fee - CableREGULATORY CHARGES": "COST_RECOVERY_FEE",
    "Provincial Sales Tax (TOLL)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "UUTUTILITY USER TAXES": "STATE_AND_LOCAL_TAXES",
    "Sales Tax-ManufacturingSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Use Tax-ManufacturingSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Sales Tax-Motor VehiclesSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Use Tax-Motor VehiclesSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Rental TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Rental Tax-LinenSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Sales Tax-VendingSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Rental Tax-Motor VehiclesSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Sales Tax-WholesaleSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Sales Tax-Food and DrugsSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Sales Tax-FoodSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Fur TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Privilege Tax-ManufacturingBUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "Lead Acid Battery FeeSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Sales Tax-Motor FuelSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Lead Acid Battery Fee-Larger BatterySALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Sales Tax-ParkingSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Privilege Tax-RecreationBUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "Dry Cleaning FeeSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "White Goods TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Sales Tax-Medical EquipmentSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Electronic Waste Recycling Fee-SmallSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Electronic Waste Recycling Fee-MediumSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Electronic Waste Recycling Fee-LargeSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Alcoholic Beverage TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Sales Tax-AlcoholSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Liquor Drink TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "IN Universal Service ChargeCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "TRS (Paging)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "ConnectME FundCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "PA PURTA SurchargeGROSS RECEIPTS TAXES": "STATE_AND_LOCAL_TAXES",
    "ConnectME Fund (VoIP)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "ConnectME Fund (Cable)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "TRS (VoIP)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Consumer Counsel FeeREGULATORY CHARGES": "COST_RECOVERY_FEE",
    "San Diego Underground Conversion SurchargeRIGHT OF WAY FEES": "COST_RECOVERY_FEE",
    "RSPF SurchargeCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "CASFCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "License Tax (Cable)BUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "Relay Missouri SurchargeCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "FCC Regulatory Fee (VoIP)REGULATORY CHARGES": "COST_RECOVERY_FEE",
    "Municipal Right of Way (Extension)RIGHT OF WAY FEES": "COST_RECOVERY_FEE",
    "Carrier Cost Recovery (VoIP)RESERVED": "COST_RECOVERY_FEE",
    "Sales Tax-VideoSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "North Carolina Telecommunications Sales TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Telecommunications Relay Surcharge (Cellular)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "E-911 Prepaid WirelessE-911 CHARGES": "FEES_911",
    "Telecommunications Relay Surcharge (Paging)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Telecommunications Relay Surcharge (VoIP)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "TDAPCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "TAP SurchargeCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Communications Service Tax (Non-Facilities)COMMUNICATIONS SERVICES TAX": "STATE_AND_LOCAL_TAXES",
    "E-911 (VoIP) AlternateE-911 CHARGES": "FEES_911",
    "E-911 (VoIP PBX)E-911 CHARGES": "FEES_911",
    "Utility Users Tax (VoIP)UTILITY USER TAXES": "STATE_AND_LOCAL_TAXES",
    "Utility Users Tax (VoIP-Business)UTILITY USER TAXES": "STATE_AND_LOCAL_TAXES",
    "Solid Waste Collection TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "E-911 (VoIP Business)E-911 CHARGES": "FEES_911",
    "E-911 (VoIP-Nomadic)E-911 CHARGES": "FEES_911",
    "E-911 Prepaid Wireless (Alternate)E-911 CHARGES": "FEES_911",
    "Police and Fire Protection FeeE-911 CHARGES": "FEES_911",
    "San Francisco Access Line TaxE-911 CHARGES": "FEES_911",
    "San Francisco Access Line Tax (PBX/Trunk line)E-911 CHARGES": "FEES_911",
    "San Francisco Access Line Tax (VoIP)E-911 CHARGES": "FEES_911",
    "San Francisco Access Line Tax (Wireless)E-911 CHARGES": "FEES_911",
    "San Francisco Access Line Tax (High Cap Trunk)E-911 CHARGES": "FEES_911",
    "City of San Jose Telephone Line TaxE-911 CHARGES": "FEES_911",
    "City of San Jose Telephone Line Tax-PBX/Trunk LineE-911 CHARGES": "FEES_911",
    "City of San Jose Telephone Line Tax (VoIP)E-911 CHARGES": "FEES_911",
    "City of San Jose Telephone Line Tax (Wireless)E-911 CHARGES": "FEES_911",
    "San Leandro Emerg Com Sys Access TaxE-911 CHARGES": "FEES_911",
    "San Leandro Emerg Com Sys Access Tax (PBX Trunk)E-911 CHARGES": "FEES_911",
    "San Leandro Emerg Com Sys Access Tax (VoIP)E-911 CHARGES": "FEES_911",
    "San Leandro Emerg Com Sys Access Tax (Wireless)E-911 CHARGES": "FEES_911",
    "San Leandro Emerg Com Sys Access Tax-High Cap TrnkE-911 CHARGES": "FEES_911",
    "Police and Fire Protection Fee (Prepaid)E-911 CHARGES": "FEES_911",
    "Public Safety Communications SurchargeE-911 CHARGES": "FEES_911",
    "E-911 Technical ChargeE-911 CHARGES": "FEES_911",
    "Telecom Assistance Svc Fund-High Capacity TrunkCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "CRT LevyREGULATORY CHARGES": "COST_RECOVERY_FEE",
    "Access Line TaxE-911 CHARGES": "FEES_911",
    "Access Line Tax (PBX/Trunk Line)E-911 CHARGES": "FEES_911",
    "Access Line Tax (VoIP)E-911 CHARGES": "FEES_911",
    "Access Line Tax (Wireless)E-911 CHARGES": "FEES_911",
    "WI USFCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Network Access Fee-InterstateRESERVED": "COST_RECOVERY_FEE",
    "Sales Tax - OtherSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "FCC Regulatory Fee (VoIP Alternate)RESERVED": "COST_RECOVERY_FEE",
    "Excise Tax (Wireless)EXCISE TAXES": "STATE_AND_LOCAL_TAXES",
    Reserved_276RESERVED: "COST_RECOVERY_FEE",
    "Federal Universal Service Fund (Non-billable)CONNECTIVITY CHARGES": "FEDERAL_UNIVERSAL_SERVICE_FUND_FEE",
    "Municipal Right of Way-High Capacity TrunkRIGHT OF WAY FEES": "COST_RECOVERY_FEE",
    "Education CessSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Secondary and Higher Education CessSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Utility Users Tax (Video)UTILITY USER TAXES": "STATE_AND_LOCAL_TAXES",
    "State USF (VoIP Alternate)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "TRS (VoIP Business)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "TRS (Trunk)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Deaf and Disabled Fund (Wireless)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Utility Users Tax-Wireless(Business)UTILITY USER TAXES": "STATE_AND_LOCAL_TAXES",
    "Telecommunications Sales Tax-PrepaidSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "CA High Cost Fund A (VoIP Actual)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "State High Cost Fund (VoIP Actual)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Universal Lifeline Telephone Svc Chg (VoIP Actual)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Telecommunications Relay Svc Charge (VoIP Actual)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "CA Teleconnect Fund (VoIP Actual)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "CASF (VoIP Actual)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Oklahoma Sales TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Business and Occupation Tax (Prtg and Publishing)BUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "Premier Resort Area TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "E911 Equalization SurchargeE-911 CHARGES": "FEES_911",
    "Universal Service FeeCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "NE Universal ServiceCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "TAP Surcharge (Wireless)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "GA Universal Access FundCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "CA High Cost Fund A (Wireless)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "CA Teleconnect Fund (Wireless)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "CASF (Wireless)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "State High Cost Fund (Wireless)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "PUC Fee (Wireless)REGULATORY CHARGES": "COST_RECOVERY_FEE",
    "Universal Lifeline Telephone Svc Charge (Wireless)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "NY TAFCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Prepaid Wireless E911 TRS SurchargeE-911 CHARGES": "FEES_911",
    "TRS-Prepaid WirelessCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "FUSF (Multi-line)CONNECTIVITY CHARGES": "FEDERAL_UNIVERSAL_SERVICE_FUND_FEE",
    "ND Gross Receipts TaxGROSS RECEIPTS TAXES": "STATE_AND_LOCAL_TAXES",
    "NY Sales TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "NY Local Transit TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "NY Local District TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Sales Tax-SatelliteSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Sales Tax-Commercial LeaseSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Food and Beverage TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Network Access Fee LD-InterstateRESERVED": "COST_RECOVERY_FEE",
    "Network Access Fee LD-IntrastateRESERVED": "COST_RECOVERY_FEE",
    "Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "District Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Special Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Transit Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Crime Control District Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Library District Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Hospital District Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Health Services District Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Emergency Services District Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Improvement District Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Development District Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Ambulance District Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Fire District Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Football District Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Baseball District Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Educational Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "School District Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Advanced Transit Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Tribal Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Vendor Use Tax-Senior CitizenSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Vendor Use Tax-ManufacturingSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Vendor Use Tax-Motor VehiclesSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Vendor Use Tax-VendingSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Vendor Use Tax-Food and DrugsSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Vendor Use Tax-FoodSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Vendor Use Tax-Motor FuelSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Vendor Use Tax-ParkingSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Vendor Use Tax-Medical EquipmentSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Alcoholic Beverage Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Vendor Use Tax-AlcoholSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Liquor Drink Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Vendor Use Tax-VideoSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Premier Resort Area Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "NY Transit Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "NY District Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Vendor Use Tax-Food and BeverageSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "District Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Special Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Transit Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Crime Control District Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Library District Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Hospital District Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Health Services District Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Emergency Services District Consumer UseTaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Improvement District Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Development District Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Ambulance District Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Fire District Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Football District Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Baseball District Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Educational Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "School District Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Advanced Transit Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Tribal Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Consumer Use Tax-Senior CitizenSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Consumer Use Tax-ManufacturingSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Consumer Use Tax-Motor VehiclesSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Consumer Use Tax-VendingSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Consumer Use Tax-Food and DrugsSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Consumer Use Tax-FoodSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Consumer Use Tax-Motor FuelSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Consumer Use Tax-ParkingSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Consumer Use Tax-Medical EquipmentSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Alcoholic Beverage Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Consumer Use Tax-AlcoholSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Liquor Drink Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Consumer Use Tax-VideoSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Premier Resort Area Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "NY Transit Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "NY District Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Consumer Use Tax-Food and BeverageSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Tasa de ControlREGULATORY CHARGES": "COST_RECOVERY_FEE",
    "Radio Rights FeeCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Business & Occupation Tax-Rent and RoyaltyBUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "Business & Occupation Tax-Other ServicesBUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "Montana Excise TaxEXCISE TAXES": "STATE_AND_LOCAL_TAXES",
    "Rural Transportation Authority District TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "MHA District TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Public Safety Improvements District TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Mass Transit District TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Metropolitan District TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "RTA Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "RTA Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "MHA Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "MHA Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Mass Transit District Consumer Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Mass Transit District Vendor Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "VAT (Reduced Rate)VALUE ADDED TAXES": "STATE_AND_LOCAL_TAXES",
    "Poison Control Fund (Wireless)E-911 CHARGES": "FEES_911",
    "State Inspection and SupervisionREGULATORY CHARGES": "COST_RECOVERY_FEE",
    "Education Sales-VendingSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Education Sales-Motor VehiclesSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Education Use-Motor VehiclesSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Education Consumer Use-Motor VehiclesSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Education Vendor Use-Motor VehiclesSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Education Sales-ManufacturingSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Education Use-ManufacturingSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Education Consumer Use - ManufacturingSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Education Vendor Use - ManufacturingSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Rental Use Tax - Motor VehiclesSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Consumer Use Rental Tax - Motor VehiclesSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Vendor Use Rental Tax - Motor VehiclesSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Revenue StatementRESERVED": "COST_RECOVERY_FEE",
    "NY MCTD 186c (Wireless)EXCISE TAXES": "STATE_AND_LOCAL_TAXES",
    "WY USFCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "WY USF (Paging)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "WY USF (Wireless)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "FCC Regulatory Fee-Toll FreeREGULATORY CHARGES": "COST_RECOVERY_FEE",
    "FCC Regulatory Fee (Satellite)REGULATORY CHARGES": "COST_RECOVERY_FEE",
    "Commerce TaxGROSS RECEIPTS TAXES": "STATE_AND_LOCAL_TAXES",
    "Telecom Assistance Svc Fund - VoIPCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Telecom Assistance Svc Fund - VoIP High Cap TrnkCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "E-911 (VoIP-Nomadic PBX)E-911 CHARGES": "FEES_911",
    "E-911 Service Fee (NL 911 Bureau)E-911 CHARGES": "FEES_911",
    "Copyright Fee (Rated)RESERVED": "COST_RECOVERY_FEE",
    "Copyright Fee (Fixed)RESERVED": "COST_RECOVERY_FEE",
    "Utility TaxBUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "Audio-Video Service TaxGROSS RECEIPTS TAXES": "STATE_AND_LOCAL_TAXES",
    "Swachh Bharat CessEXCISE TAXES": "STATE_AND_LOCAL_TAXES",
    "PISBUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "COFINSBUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "ICMSVALUE ADDED TAXES": "STATE_AND_LOCAL_TAXES",
    "Federal USF (Centrex)REGULATORY CHARGES": "FEDERAL_UNIVERSAL_SERVICE_FUND_FEE",
    "UUT (Prepaid Wireless)UTILITY USER TAXES": "STATE_AND_LOCAL_TAXES",
    "Mobile Telephony Services SurchargeREGULATORY CHARGES": "COST_RECOVERY_FEE",
    "Access Line Tax (Prepaid Wireless)E-911 CHARGES": "FEES_911",
    "San Leandro Emerg Com Sys Acc Tax (Ppd Wireless)E-911 CHARGES": "FEES_911",
    "Rental Tax (Lower Rate)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "CA High Cost Fund A (VoIP)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "State High Cost Fund (VoIP)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "CA Teleconnect Fund (VoIP)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "CASF (VoIP)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Universal Lifeline Telephone Service Charge (VoIP)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "FUNTTELREGULATORY CHARGES": "COST_RECOVERY_FEE",
    "FUSTCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Telecommunications Use TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Krishi Kalyan CessEXCISE TAXES": "STATE_AND_LOCAL_TAXES",
    "School and Library Fund SurchargeCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "State 911 ChargeE-911 CHARGES": "FEES_911",
    "ITAC AssessmentCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "State 911 Charge (Wireless)E-911 CHARGES": "FEES_911",
    "E-911 (Advanced Services)E-911 CHARGES": "FEES_911",
    "VAT (Wireless)VALUE ADDED TAXES": "STATE_AND_LOCAL_TAXES",
    "VAT (Communications)VALUE ADDED TAXES": "STATE_AND_LOCAL_TAXES",
    "CA TRSCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "CA TRS (Wireless)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "CA PUC FeeREGULATORY CHARGES": "COST_RECOVERY_FEE",
    "Use Tax (Rental)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Use Tax (Other)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Consumer Use Tax (Other)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Vendor Use Tax (Other)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "SC USFCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "USF (Prepaid Wireless)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "E-911 (Lifeline)E-911 CHARGES": "FEES_911",
    "Utility Tax NFBUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "Telecommunications Sales Tax (Wholesale)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "E-rate Broadband ProgramCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "E-rate Broadband Program (Business Line)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "E-rate Broadband Program (Line)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "E-rate Broadband Program (Wireless)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "IGST (Communications)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "CGSTSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "CGST (Communications)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "SGSTSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "SGST (Communications)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Universal Service Fund (Other)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "IGSTSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Kentucky Lifeline SurchargeCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Telecommunications Sales Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Public Safety Communications Surcharge (Prepaid)E-911 CHARGES": "FEES_911",
    "Statutory Gross Receipts NFGROSS RECEIPTS TAXES": "STATE_AND_LOCAL_TAXES",
    "PUC Franchise Fee (Video) NFREGULATORY CHARGES": "COST_RECOVERY_FEE",
    "Sales Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "District Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Hospital District Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Improvement District Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Mass Transit District Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Metropolitan District Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "MHA District Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Public Safety Improvement District Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Rural Transportation Authority District Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Transit Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "District Consumer Use Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Hospital District Consumer Use Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Improvement District Consumer Use Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Mass Transit District Consumer Use Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "MHA Consumer Use Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "RTA Consumer Use Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Transit Consumer Use Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "District Vendor Use Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Hospital District Vendor Use Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Improvement District Vendor Use Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Mass Transit District Vendor Use Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "MHA Vendor Use Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "RTA Vendor Use Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Transit Vendor Use Tax NFSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "State USF (VoIP Alternate) NFCONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "E-rate Broadband Program (VoIP Alternate)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "ConnectME Fund (VoIP Alternate)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "P.U.C. Fee (VoIP Alternate)REGULATORY CHARGES": "COST_RECOVERY_FEE",
    "P.U.C. Fee NFREGULATORY CHARGES": "COST_RECOVERY_FEE",
    "Sales Tax (Tiered Rate)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Use Tax (Tiered Rate)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Consumer Use Tax (Tiered Rate)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Vendor Use Tax (Tiered Rate)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "National Sales TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "National Service TaxSALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Communications Service Tax NFCOMMUNICATIONS SERVICES TAX": "STATE_AND_LOCAL_TAXES",
    "Communications Service Tax NF (Cable)COMMUNICATIONS SERVICES TAX": "STATE_AND_LOCAL_TAXES",
    "Communications Service Tax NF (Satellite)COMMUNICATIONS SERVICES TAX": "STATE_AND_LOCAL_TAXES",
    "Communications Service Tax NF (Wireless)COMMUNICATIONS SERVICES TAX": "STATE_AND_LOCAL_TAXES",
    "Excise Tax NFEXCISE TAXES": "STATE_AND_LOCAL_TAXES",
    "Statutory Gross Receipts NF (Video)GROSS RECEIPTS TAXES": "STATE_AND_LOCAL_TAXES",
    "License Tax NFBUSINESS TAXES": "STATE_AND_LOCAL_TAXES",
    "Communications Service Tax NFRCOMMUNICATIONS SERVICES TAX": "STATE_AND_LOCAL_TAXES",
    "Communications Service Tax NFR (Satellite)COMMUNICATIONS SERVICES TAX": "STATE_AND_LOCAL_TAXES",
    "Statutory Gross Receipts NFRGROSS RECEIPTS TAXES": "STATE_AND_LOCAL_TAXES",
    "Statutory Gross Receipts NFR (Video)GROSS RECEIPTS TAXES": "STATE_AND_LOCAL_TAXES",
    "Statutory Gross Receipts NFR (Wireless)GROSS RECEIPTS TAXES": "STATE_AND_LOCAL_TAXES",
    "Statutory Gross Receipts NFR (Business)GROSS RECEIPTS TAXES": "STATE_AND_LOCAL_TAXES",
    "Sales Tax (Prepaid Wireless)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "CA High Cost Fund A (Prepaid Wireless)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "CA Teleconnect Fund (Prepaid Wireless)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "CASF (Prepaid Wireless)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "PUC Fee (Prepaid Wireless)REGULATORY CHARGES": "COST_RECOVERY_FEE",
    "Universal Lifeline Telephone Svc (Prepaid Wireless)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "CA TRS (Prepaid Wireless)CONNECTIVITY CHARGES": "COST_RECOVERY_FEE",
    "Sales Tax-Commercial Lease (Additional)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Telecommunications LevySALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Telecommunications Levy (Internet)SALES AND USE TAXES": "STATE_AND_LOCAL_TAXES",
    "Cost Recovery SurchargeRESERVED": "COST_RECOVERY_FEE",
    "Carrier Cost RecoveryRESERVED": "COST_RECOVERY_FEE",
    "Federal USF (Alternate)RESERVED": "FEDERAL_UNIVERSAL_SERVICE_FUND_FEE",
};

function getTaxType(name: string, category: string) {
    const taxName = `${name}${category}`;
    // console.log(taxName);
    // console.log(taxes[taxName]);
    return taxes[taxName as keyof typeof taxes];
}
