import React, { useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import { format, subMonths } from "date-fns";
import DayPicker, { DateUtils, RangeModifier } from "react-day-picker";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";

import Layout from "../components/layout";
import { Button } from "../components/button";
import { Input } from "../components/input";
import Select from "../components/select";
import Modal from "../components/modal";
import { useViewport, ViewportBreakpoint } from "../utils/viewport-context";
import { useAppSelector } from "../utils/store";
import { CustomerProfile } from "../utils/types";
import { formatPhone } from "../utils/helpers";
import { usePermissions } from "../features/hooks/use-permissions";
import { getAllDepTickets, ticketsSelector, queryUpdated, ticketSearchSelector } from "../features/all-tickets-slice";
import noResultsImage from "../images/icon-noresultsfound.png";
import { useAppDispatch } from "../utils/store";
import { TicketRes, TicketResData } from "../utils/types";
import CreateTicketModal from "../components/create-ticket-modal";
import { selectUserInfo } from "../features/user-role-slice";

enum SearchFilter {
    ALL = "ALL",
    OPEN = "OPEN",
    IN_PROGRESS = "IN-PROGRESS",
    RESOLVED = "RESOLVED",
    ESCALATED = "ESCALATED",
    WAITING_L3_SUPPORT = "WAITING-L3-SUPPORT",
    WAITING_FROM_OPERATOR = "WAITING-FROM-OPERATOR",
    FIRST_RESPONSE_ESCALATION = "FIRST-RESPONSE-ESCALATION",
    WAITING_FOR_L1_FEEDBACK = "WAITING-FOR-L1-FEEDBACK",
    RESOLUTION_ESCALATED = "RESOLUTION-ESCALATED",
}

export interface NewTickets {
    customerProfile: CustomerProfile;
}

export default function AllTickets() {
    const dispatch = useAppDispatch();
    const { width } = useViewport();

    const { hasOnePermission } = usePermissions();

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showCreateHelpdeskModal, setShowCreateHelpdeskModal] = useState(false);
    const [startDate, setStartDate] = useState(subMonths(new Date(), 1));
    const [endDate, setEndDate] = useState(new Date());

    const initialBatchSize = width < ViewportBreakpoint.MD ? 5 : 10;

    const [batchSize, setBatchSize] = useState(initialBatchSize);
    const [ticketsFrom, setTicketsFrom] = useState(0);

    const [filterValue, setFilterValue] = useState<SearchFilter>(SearchFilter.ALL);

    const allDepTickets = useSelector(ticketsSelector.selectAll);
    const { loading, loadingAdditional, query, totalCount, currentCount } = useSelector(ticketSearchSelector);
    const userInfo = useAppSelector(selectUserInfo);
    const updateTickets = (update: boolean) => {
        const searchFilter = getSearchFilterFromValue(filterValue);
        if (update && batchSize < allDepTickets.length) {
            setBatchSize(batchSize + initialBatchSize);
            return;
        }
        if (update) {
            setBatchSize(ticketsFrom + 100);
            setTicketsFrom(ticketsFrom + 100);
        }
        dispatch(
            getAllDepTickets({
                departmentID: userInfo.supportDepartmentId!,
                limit: "100",
                subject: query || "",
                update,
                from: update ? ticketsFrom + 100 : ticketsFrom,
                createdTimeRange: `${format(startDate, "yyyy-MM-dd")}T00:00:00.000Z,${format(
                    endDate,
                    "yyyy-MM-dd"
                )}T23:59:00.000Z`,
                ...searchFilter,
            })
        );
    };

    useEffect(() => {
        updateTickets(false);
    }, [filterValue, startDate, endDate, query]);

    const handleSearch = debounce((query: string) => {
        dispatch(queryUpdated(query));
    }, 1000);

    return (
        <Layout title="All Tickets">
            <div className="container col-9 d-flex flex-column mt-3 px-0">
                <Link className="text-cta mb-3" to="/">
                    <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                    Back
                </Link>
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <h1>All Tickets {!loading && <>{totalCount}</>}</h1>
                    <Button
                        color="secondary"
                        icon="reach-cs-plus"
                        mini={width < ViewportBreakpoint.MD}
                        disabled={!hasOnePermission("user_management.helpdesk.read_ticket")}
                        onClick={() => setShowCreateHelpdeskModal(true)}>
                        Create ticket
                    </Button>
                </div>
                {/* <div className="row mb-2 d-flex align-items-center justify-content-between"> */}
                <div
                    className={`mb-2 d-flex 
                    ${width < ViewportBreakpoint.MD ? "flex-column" : ""} align-items-center justify-content-between`}>
                    <Input
                        type="text"
                        icon="reach-cs-search"
                        placeholder="Search by subject..."
                        className={` ${width < ViewportBreakpoint.MD ? "col-12 mb-2 " : "col-5"} pr-1 pr-sm-3`}
                        defaultValue={query}
                        onChange={({ target: { value } }) => {
                            handleSearch(value);
                        }}
                    />
                    <div
                        className={`d-flex align-items-center pr-0 ${
                            width < ViewportBreakpoint.MD
                                ? "justify-content-between col-12 "
                                : "col-7 justify-content-end"
                        } ${width < ViewportBreakpoint.SM ? "flex-column " : ""}`}>
                        {startDate && endDate && (
                            <>
                                {format(startDate, "MMM dd, yyyy")} - {format(endDate, "MMM dd, yyyy")}{" "}
                            </>
                        )}
                        <span
                            className={`reach-cs-calendar text-secondary cursor-pointer mx-1 ${
                                width < ViewportBreakpoint.SM ? "my-2" : ""
                            }`}
                            style={{ fontSize: 18 }}
                            onClick={() => setShowDatePicker(true)}
                        />
                        <Select
                            className="col-sm-6 col-9 pl-0"
                            value={filterValue}
                            onChange={({ target: { value } }) => setFilterValue(value as SearchFilter)}>
                            {/* <option value={SearchFilter.ALL}>Filter by</option> */}
                            <option value="">Filter by</option>
                            <option value={SearchFilter.OPEN}>Open</option>
                            <option value={SearchFilter.IN_PROGRESS}>In Progress</option>
                            <option value={SearchFilter.RESOLVED}>Resolved</option>
                            <option value={SearchFilter.ESCALATED}>Escalated</option>
                            <option value={SearchFilter.WAITING_L3_SUPPORT}>Waiting L3 Support</option>
                            <option value={SearchFilter.WAITING_FROM_OPERATOR}>WAITING FROM OPERATOR</option>
                            <option value={SearchFilter.FIRST_RESPONSE_ESCALATION}>FIRST RESPONSE ESCALATION</option>
                            <option value={SearchFilter.WAITING_FOR_L1_FEEDBACK}>WAITING FOR L1 FEEDBACK</option>
                            <option value={SearchFilter.RESOLUTION_ESCALATED}>Resolution Escalated</option>
                        </Select>
                    </div>
                </div>
                {width > ViewportBreakpoint.SM && (
                    <div
                        className="d-flex mx-2 mt-3 py-3 font-family-bold"
                        style={{ fontSize: "14px", background: "#fbfbfb" }}>
                        <div className="col-2">Ticket ID</div>
                        <div className="col-3">Customer details</div>
                        <div className="col-2">Subject</div>
                        <div className="col-3">Date</div>
                        <div className="col-2">Status</div>
                    </div>
                )}
                {loading ? (
                    <div className="d-flex justify-content-center mt-5">
                        <div className="spinner-border text-primary" />
                    </div>
                ) : allDepTickets.length === 0 ? (
                    <div
                        className="d-flex align-items-center justify-content-center flex-column"
                        style={{ height: 200 }}>
                        <img src={noResultsImage} />
                        <div className="font-family-semibold" style={{ fontSize: 18 }}>
                            {query === "" ? "No results found. Please start searching" : "No results found"}
                        </div>
                    </div>
                ) : (
                    allDepTickets.slice(0, batchSize).map((ticket: TicketResData) => (
                        <div key={ticket.id} className="d-flex flex-column">
                            <div
                                className="d-flex mx-2 row align-items-center py-3"
                                style={{ fontSize: "14px", wordBreak: "break-all", hyphens: "auto" }}>
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        navigate(`/tickets/${ticket.cf.cf_customer_id}/id/${ticket.id}`);
                                    }}
                                    className="col-sm-2 col-12 font-family-semibold">
                                    #{ticket.ticketNumber}
                                </div>
                                <div className="col-sm-3 col-12 d-flex flex-column">
                                    <div>{ticket.cf.cf_customer_id}</div>
                                    <div className="font-family-semibold">{formatPhone(ticket.cf.cf_mdn)}</div>
                                    {ticket.cf.cf_customer_id && (
                                        <Link to={`/profile/${ticket.cf.cf_customer_id}`}>See Details</Link>
                                    )}
                                </div>
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        navigate(`/tickets/${ticket.cf.cf_customer_id}/id/${ticket.id}`);
                                    }}
                                    className="col-sm-2 col-12">
                                    {ticket.subject}
                                </div>
                                <div className="col-sm-3 col-12">
                                    {format(new Date(ticket.createdTime), "dd MMMM yyyy, k.mm a")}
                                </div>
                                <div className="col-sm-2 col-12">{ticket.status}</div>
                            </div>
                            <div className="divider mb-2 mx-3" style={{ opacity: 0.31 }} />
                        </div>
                    ))
                )}
                {!loading && (batchSize < allDepTickets.length || batchSize % 100 === 0) && (
                    <Button
                        color="secondary"
                        outline
                        loading={loadingAdditional}
                        className="col-8 col-md-4 align-self-center my-3"
                        onClick={() => updateTickets(true)}>
                        Load More
                    </Button>
                )}
            </div>

            <CreateTicketModal
                // customerProfile={}
                title="Create new ticket"
                show={showCreateHelpdeskModal}
                onHide={() => setShowCreateHelpdeskModal(false)}
            />

            <Modal title="Select date range" show={showDatePicker} size="sm" onHide={() => setShowDatePicker(false)}>
                <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onRangeSelect={(from, to) => {
                        setStartDate(from);
                        setEndDate(to);
                        setShowDatePicker(false);
                    }}
                />
            </Modal>
        </Layout>
    );
}

function DateRangePicker(props: { startDate: Date; endDate: Date; onRangeSelect: (from: Date, to: Date) => void }) {
    const [state, setState] = useState<RangeModifier>({
        from: props.startDate,
        to: props.endDate,
    });

    const { from, to } = state;

    function getInitialState() {
        return {
            from: undefined,
            to: undefined,
        };
    }

    function handleDayClick(day: Date) {
        const range = DateUtils.addDayToRange(day, state);
        setState((prev) => ({ ...prev, ...range }));
    }

    function handleResetClick() {
        setState((prev) => ({ ...prev, ...getInitialState() }));
    }

    const modifiers = { start: from, end: to };

    return (
        <div className="d-flex flex-column">
            <DayPicker
                className="Selectable"
                showOutsideDays
                // numberOfMonths={1}
                selectedDays={[from ?? undefined, { from, to }]}
                disabledDays={[{ after: new Date() }]}
                month={from ?? undefined}
                // @ts-ignore
                modifiers={modifiers}
                onDayClick={handleDayClick}
            />
            <Button
                color="secondary"
                className="align-self-center"
                onClick={() => {
                    if (from && to) {
                        props.onRangeSelect(from, to);
                    }
                }}>
                Confirm
            </Button>
            <Helmet>
                <style>{`
    .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      background-color: #f0f8ff !important;
      color: #4a90e2;
    }
    .Selectable .DayPicker-Day {
      border-radius: 0 !important;
    }
    .Selectable .DayPicker-Day--start {
      border-top-left-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
    }
    .Selectable .DayPicker-Day--end {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
  `}</style>
            </Helmet>
        </div>
    );
}

function getSearchFilterFromValue(filterValue: SearchFilter) {
    const searchFilter: { status?: string } = {};
    switch (filterValue) {
        case SearchFilter.ALL:
            // searchFilter.status = SearchFilter.ALL;
            searchFilter.status = "";
            break;
        case SearchFilter.OPEN:
            searchFilter.status = SearchFilter.OPEN;
            break;
        case SearchFilter.IN_PROGRESS:
            searchFilter.status = SearchFilter.IN_PROGRESS;
            break;
        case SearchFilter.RESOLVED:
            searchFilter.status = SearchFilter.RESOLVED;
            break;
        case SearchFilter.ESCALATED:
            searchFilter.status = SearchFilter.ESCALATED;
            break;
        case SearchFilter.WAITING_L3_SUPPORT:
            searchFilter.status = SearchFilter.WAITING_L3_SUPPORT;
            break;
        case SearchFilter.WAITING_FROM_OPERATOR:
            searchFilter.status = SearchFilter.WAITING_FROM_OPERATOR;
            break;
        case SearchFilter.FIRST_RESPONSE_ESCALATION:
            searchFilter.status = SearchFilter.FIRST_RESPONSE_ESCALATION;
            break;
        case SearchFilter.WAITING_FOR_L1_FEEDBACK:
            searchFilter.status = SearchFilter.WAITING_FOR_L1_FEEDBACK;
            break;
        case SearchFilter.RESOLUTION_ESCALATED:
            searchFilter.status = SearchFilter.RESOLUTION_ESCALATED;
            break;
    }
    return searchFilter;
}
