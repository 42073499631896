import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { topupPulseSelector } from "../features/config-slice";
import { getCDRDataUsage } from "../features/data-usage-slice";
import { addTopupInfo, fetchProfile, getTaxByCustomer } from "../features/profile-slice";
import { formatPhone } from "../utils/helpers";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile, TaxRes, TaxType } from "../utils/types";
import { CustomerStatus } from "../utils/types/customer";
import { Button } from "./button";
import Counter from "./counter";
import { useErrorHandler } from "./error-snackbar";
import Modal, { ModalProps } from "./modal";
import { cardImages, getCardImage } from "./payment-cards";
import Select from "./select";
import { useSuccessModal } from "./success-modal";
import Toggle from "./toggle";

export interface AddTopupModalProps extends ModalProps {
    customerProfile: CustomerProfile;
}

interface FormData {
    customerId?: string;
    isChargable: boolean;
}

enum UIState {
    ADD_TOPUP,
    TOPUP_SUMMARY,
}

export default function AddTopupModal(props: AddTopupModalProps) {
    const { customerProfile, ...rest } = props;

    const { register, handleSubmit, formState, errors } = useForm<FormData>({ mode: "onBlur" });

    const topupPulse = useSelector(topupPulseSelector);

    const [value, setValue] = useState(topupPulse.pulse);
    const [uiState, setUIState] = useState(UIState.ADD_TOPUP);
    const [topupTax, setTopupTax] = useState<TaxRes>();
    const [selectedCustomer, setSelectedCustomer] = useState(customerProfile.id);

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();

    const addTopup = async (customerId: string, isChargable: boolean) => {
        try {
            await unwrap(
                addTopupInfo({
                    primaryCustomerId: customerProfile.id,
                    customerId,
                    ccUUID: isChargable ? customerProfile.creditCards?.[0].uuid : undefined,
                    isChargable,
                    totalData: value.toFixed(2),
                    taxAmount: topupTax?.totalTax.toFixed(2),
                    taxId: topupTax?.taxId,
                    taxInfo: topupTax,
                })
            );
            await dispatch(fetchProfile(customerProfile.id));
            const id = customerProfile.groupLines.find((cust) => cust.isPrimary)!.id;
            await unwrap(getCDRDataUsage(id));
            showSuccessModal(`Additional ${value}GB data added`);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        rest.onHide();
    };

    const onSubmit = async (data: FormData) => {
        setSelectedCustomer(data.customerId ?? customerProfile.id);
        if (!data.isChargable) {
            await addTopup(data.customerId ?? customerProfile.id, data.isChargable);
        } else {
            setUIState(UIState.TOPUP_SUMMARY);
        }
    };

    useEffect(() => {
        async function getTopupTax() {
            if (uiState === UIState.TOPUP_SUMMARY) {
                try {
                    const res = await unwrap(
                        getTaxByCustomer({
                            customerId: customerProfile.id,
                            type: TaxType.TOPUP,
                            amount: ((value * topupPulse.pulseCost) / topupPulse.pulse).toFixed(2),
                            additionalLines: customerProfile.additionalLines,
                        })
                    );
                    setTopupTax(res);
                } catch (error) {
                    console.log(error);
                    handleError(error);
                }
            }
        }
        getTopupTax();
    }, [uiState]);

    if (uiState === UIState.TOPUP_SUMMARY) {
        return (
            <Modal {...rest} size="sm">
                <div className="w-100 d-flex justify-content-between my-2">
                    <div className="font-family-semibold">
                        {value}GB ($
                        {topupPulse.pulseCost}/{topupPulse.pulse}GB)
                    </div>
                    <div className="font-family-semibold">
                        ${((value * topupPulse.pulseCost) / topupPulse.pulse).toFixed(2)}
                    </div>
                </div>
                <div className="divider mb-2" />
                <div className="w-100 d-flex justify-content-between my-2">
                    <div className="font-family-semibold">Taxes &amp; fees</div>
                    <div className="font-family-semibold">${topupTax?.totalTax.toFixed(2) ?? "-"}</div>
                </div>
                <div className="divider mb-2" />
                <div className="w-100 d-flex justify-content-between my-2">
                    <div className="font-family-semibold">Total due today</div>
                    <div className="font-family-semibold">
                        ${topupTax ? (topupTax.amount + topupTax.totalTax).toFixed(2) : "-"}
                    </div>
                </div>
                <div className="font-family-semibold mt-2">We will charge the credit card on file</div>
                {customerProfile?.creditCards?.[0] && (
                    <div className="d-flex align-items-center my-3">
                        {getCardImage(customerProfile?.creditCards[0].type) && (
                            <img
                                src={getCardImage(customerProfile.creditCards[0].type)}
                                className="mb-2"
                                width={45}
                                height={30}
                            />
                        )}
                        <div className="font-family-semibold ml-2">
                            XXXX-XXXX-XXXX-
                            {customerProfile.creditCards[0].ccNumberMask}
                        </div>
                    </div>
                )}
                <Button
                    color="secondary"
                    fullWidth
                    disabled={topupTax == null}
                    loading={formState.isSubmitting}
                    onClick={handleSubmit(() => addTopup(selectedCustomer, true))}>
                    Confirm
                </Button>
            </Modal>
        );
    }

    return (
        <Modal {...rest} size="sm">
            {customerProfile.reachPlan.isUnlimited && (
                <Select
                    className="mb-3 w-100"
                    register={register({
                        required: {
                            value: true,
                            message: "Customer is required",
                        },
                    })}
                    name="customerId"
                    showError={errors.customerId != null}
                    errorMessage={errors.customerId?.message}>
                    <option value="">Select customer</option>
                    {customerProfile.groupLines
                        .filter((customer) => customer.status === CustomerStatus.ACTIVE)
                        .map((customer) => (
                            <option key={customer.id} value={customer.id}>
                                {customer.firstName} {customer.lastName} {formatPhone(customer.reachNumber)}
                            </option>
                        ))}
                </Select>
            )}
            <Counter
                defaultValue={topupPulse.pulse}
                minValue={topupPulse.pulse}
                maxValue={topupPulse.limit}
                step={topupPulse.pulse}
                render={(value) => <span className="h1">{value.toFixed(1)}GB</span>}
                onChange={(value) => setValue(value)}
                className="ml-2"
            />
            <div className="font-family-semibold my-2">
                Data cost: ${((value * topupPulse.pulseCost) / topupPulse.pulse).toFixed(1)} (${topupPulse.pulseCost}/
                {topupPulse.pulse}GB)
            </div>
            <Toggle
                className="my-3 align-self-start"
                id="isChargable"
                name="isChargable"
                label="Is Top-Up Chargeable?"
                register={register}
                defaultChecked={true}
            />
            <Button color="secondary" fullWidth loading={formState.isSubmitting} onClick={handleSubmit(onSubmit)}>
                Confirm
            </Button>
        </Modal>
    );
}
