import React, { forwardRef, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Link, navigate } from "gatsby";
import { useSelector } from "react-redux";
import { useErrorHandler } from "../../components/error-snackbar";
import Layout from "../../components/layout";
import { useUnwrapAsyncThunk } from "../../utils/store";
import { Input } from "../../components/input";
import roamingIcon from "../../images/roaming.png";
import intlCallIcon from "../../images/intlcall.png";
import { IntlMaxCountry } from "../../utils/types";
import { Dropdown } from "react-bootstrap";
import classNames from "classnames";
import { provConfig } from "../../features/config-slice";
import { ReachCsAPI } from "../../utils/reach-cs-api";

export type IntlRatesProps = RouteComponentProps;

const ForwardedInput = forwardRef((props, ref) => (
    <Input
        {...props}
        type="text"
        icon="reach-cs-search"
        placeholder="Enter country name"
        className="w-100"
        register={ref}
    />
));
ForwardedInput.displayName = "ForwardedInput";

export default function IntlRates(props: IntlRatesProps) {
    const config = useSelector(provConfig);

    const handleError = useErrorHandler();

    const [countries, setCountries] = useState<IntlMaxCountry[]>();
    const [selectedCountry, setSelectedCountry] = useState<IntlMaxCountry>();
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        async function fetchCountries() {
            try {
                const res = await ReachCsAPI.getInternationalRoamingCountries();
                setCountries(res.countriesList.sort((a, b) => a.name.localeCompare(b.name)));
            } catch (error) {
                console.log(error);
                handleError(error.response?.data);
            }
        }
        fetchCountries();
    }, []);

    if (!countries) {
        return (
            <Layout title="Tools - International rates">
                <div className="container col-10 d-flex flex-column my-3 px-0">
                    <div className="d-flex justify-content-between">
                        <Link className="text-cta mb-3" to="/tools">
                            <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                            Back
                        </Link>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <h1>International rates</h1>
                    </div>
                    <div className="divider" />
                    <div className="align-self-center mt-3 spinner-border text-primary" />
                </div>
            </Layout>
        );
    }

    return (
        <Layout title="Tools - International rates">
            <div className="container col-10 d-flex flex-column my-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to="/tools">
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>International rates</h1>
                </div>
                <div className="divider" />

                <Dropdown className="mt-3 w-100">
                    <Dropdown.Toggle
                        id="search"
                        as={ForwardedInput}
                        // @ts-ignore
                        value={searchValue}
                        // @ts-ignore
                        onChange={({ target: { value } }) => {
                            setSearchValue(value);
                        }}
                    />
                    <Dropdown.Menu className="w-100">
                        {countries
                            .filter((country) => {
                                return (
                                    country.name.toLowerCase().includes(searchValue.trim().toLowerCase()) &&
                                    (country.irEnabled || country.ildEnabled)
                                );
                            })
                            .slice(0, 5)
                            .map((country) => (
                                <Dropdown.Item
                                    key={country.code}
                                    eventKey={country.code}
                                    onClick={() => setSelectedCountry(country)}>
                                    {country.name}
                                </Dropdown.Item>
                            ))}
                    </Dropdown.Menu>
                </Dropdown>

                {selectedCountry && (
                    <div className="w-100 d-flex flex-column align-items-center mt-3">
                        <div className="font-family-bold" style={{ fontSize: 16 }}>
                            {selectedCountry.name}
                        </div>
                        {selectedCountry.ildEnabled && (
                            <>
                                <div className="font-family-semibold mt-2">
                                    Calling from the U.S. - premium quality and flexible pricing.
                                </div>
                                <div className="divider my-2" />
                                <div
                                    className="w-100 d-flex font-family-semibold justify-content-around"
                                    style={{ fontSize: 12 }}>
                                    <div>
                                        <div>Voice</div>
                                        <div>
                                            ${selectedCountry.maxIldCostForVoiceOut}/
                                            {config.ILD_PULSE_SIZE === 1 ? "" : `${config.ILD_PULSE_SIZE} `}
                                            Min
                                        </div>
                                    </div>
                                    <div>
                                        <div>SMS</div>
                                        <div>${selectedCountry.maxIldCostForSmsOut}</div>
                                    </div>
                                </div>
                            </>
                        )}
                        {selectedCountry.irEnabled && (
                            <>
                                <div className="font-family-semibold mt-3">
                                    Travelling outside the U.S. - stay connected while traveling the world.
                                </div>
                                <div className="divider my-2" />
                                <div
                                    className={classNames("w-100 d-flex font-family-semibold justify-content-between", {
                                        disabled: selectedCountry.zone === "PAYGO",
                                    })}
                                    style={{ fontSize: 12 }}>
                                    <div>
                                        <div>Data</div>
                                        <div>
                                            ${selectedCountry.maxCostForData.toFixed(2)}/{config.DATA_PULSE}
                                            {"GB"}
                                        </div>
                                    </div>
                                    <div>
                                        <div>Voice</div>
                                        <div>${selectedCountry.maxCostForVoiceIn.toFixed(2)}/Min</div>
                                    </div>
                                    <div>
                                        <div>SMS In</div>
                                        <div>${selectedCountry.maxCostForSmsIn.toFixed(2)}</div>
                                    </div>
                                    <div>
                                        <div>SMS Out</div>
                                        <div>${selectedCountry.maxCostForSmsOut.toFixed(2)}</div>
                                    </div>
                                </div>
                            </>
                        )}

                        {selectedCountry.zone === "PAYGO" && (
                            <div className="text-error text-center mt-3">
                                We do not currently offer discounted data packs for {selectedCountry.name}. Here&apos;s
                                a summary of the roaming charges applicable.
                            </div>
                        )}
                    </div>
                )}

                <div className="font-family-semibold align-self-start mt-3">
                    <img src={roamingIcon} className="mr-2" width={16} />
                    <span className="font-family-bold">Roaming: </span>
                    stay connected while traveling the world.
                </div>
                <div className="font-family-semibold align-self-start mt-3">
                    <img src={intlCallIcon} className="mr-2" width={16} />
                    <span className="font-family-bold">Call and text from the U.S.: </span>
                    premium quality and flexible pricing.
                </div>
            </div>
        </Layout>
    );
}
