import { Link } from "gatsby";
import React, { ReactNode } from "react";
import Layout from "./layout";

export interface PageLayoutProps {
    prevUrl: string;
    title: string;
    children: ReactNode;
}

function PageLayout(props: PageLayoutProps) {
    const { prevUrl, title, children } = props;

    return (
        <Layout title={title}>
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to={prevUrl}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>{title}</h1>
                </div>
                <div className="divider mb-3" />
                {children}
            </div>
        </Layout>
    );
}

export default PageLayout;
