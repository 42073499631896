import classNames from "classnames";
import { compareDesc, format, max, subMonths, compareAsc, addDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { getTopupHistory } from "../features/profile-slice";
import { getUTCDate } from "../utils/helpers";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile, TopupHistoryRes } from "../utils/types";
import { useErrorHandler } from "./error-snackbar";
import Modal, { ModalProps } from "./modal";

export interface TopupHistoryModalProps extends ModalProps {
    customerProfile: CustomerProfile;
    billMonth?: string;
    selectedCustomerId?: string;
}

export default function TopupHistoryModal(props: TopupHistoryModalProps) {
    const { customerProfile, selectedCustomerId, ...rest } = props;

    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const [topupHistory, setTopupHistory] = useState<Record<string, TopupHistoryRes>>();
    const [selectedIndex, setSelectedIndex] = useState(topupHistory ? Object.keys(topupHistory).length - 1 : 0);

    useEffect(() => {
        async function fetchTopup() {
            try {
                let res = await unwrap(getTopupHistory(customerProfile.groupId));
                res = res
                    .sort((a, b) => compareAsc(new Date(a.topupDate), new Date(b.topupDate)))
                    .filter((item) => (selectedCustomerId ? item.customerId === selectedCustomerId : true));

                const groupedHistory: Record<string, TopupHistoryRes> = {};
                for (const item of res) {
                    if (groupedHistory[item.billMonth]) {
                        groupedHistory[item.billMonth].push(item);
                    } else {
                        groupedHistory[item.billMonth] = [item];
                    }
                }
                setTopupHistory(groupedHistory);
            } catch (error) {
                console.log(error);
                if (error.resCode === 40137) {
                    setTopupHistory({});
                } else {
                    handleError(error);
                }
            }
        }
        fetchTopup();
    }, []);

    useEffect(() => {
        if (topupHistory && props.billMonth) {
            const keys = Object.keys(topupHistory);
            console.log(keys);
            for (let i = 0; i < keys.length; i++) {
                const billMonth = keys[i];
                if (billMonth === props.billMonth) {
                    setSelectedIndex(i);
                }
            }
        } else {
            setSelectedIndex(topupHistory ? Object.keys(topupHistory).length - 1 : 0);
        }
    }, [topupHistory, props.billMonth]);

    if (!topupHistory) {
        return (
            <Modal {...rest} size="sm">
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary" />
                </div>
            </Modal>
        );
    }

    if (Object.keys(topupHistory).length === 0) {
        return (
            <Modal {...rest} size="sm">
                <div className="d-flex justify-content-center">No topup info available</div>
            </Modal>
        );
    }

    const billMonths = Object.keys(topupHistory);
    const endDate = billMonths[selectedIndex];

    const leftDisabled = selectedIndex === 0;
    const rightDisabled = selectedIndex === Object.keys(topupHistory).length - 1;

    let startDate = addDays(subMonths(new Date(endDate), 1), 1);

    if (selectedIndex === 0 && customerProfile.activationDate) {
        startDate = max([startDate, getUTCDate(new Date(customerProfile.activationDate))]);
    }

    return (
        <Modal {...rest} size="sm">
            <div className="d-flex align-items-center">
                <span
                    style={{ fontSize: 10 }}
                    className={classNames("reach-cs-arrow-left cursor-pointer", { disabled: leftDisabled })}
                    onClick={() => !leftDisabled && setSelectedIndex(selectedIndex - 1)}
                />
                <div className="font-family-semibold mx-2">
                    {format(new Date(startDate), "MMM dd, yyyy")} - {format(new Date(endDate), "MMM dd, yyyy")}
                </div>
                <span
                    style={{ fontSize: 10 }}
                    className={classNames("reach-cs-arrow-right cursor-pointer", {
                        disabled: rightDisabled,
                    })}
                    onClick={() => !rightDisabled && setSelectedIndex(selectedIndex + 1)}
                />
            </div>
            {topupHistory[endDate].map((item, index) => {
                const customer = customerProfile.groupLines.find((cust) => cust.id === item.customerId);
                return (
                    <div className="w-100" key={index}>
                        {index !== 0 && <div className="divider" />}
                        <div className="w-100 d-flex justify-content-between my-2">
                            <div className="font-family-semibold">
                                {customerProfile.reachPlan.isUnlimited && (
                                    <div>
                                        {customer?.firstName} {customer?.lastName}
                                    </div>
                                )}
                                <div style={{ fontSize: 12 }}>
                                    {format(new Date(item.topupDate), "hh:mm a, MMM dd, yyyy")}
                                </div>
                                <div style={{ fontSize: 12 }}>
                                    <span>{item.isAutoTopUp ? "Recurring topup" : "Instant topup"}</span>,{" "}
                                    {item.status === "REFUNDED" ? (
                                        <span className="text-error">Refunded</span>
                                    ) : item.status === "CANCELLED" ? (
                                        <span className="text-error">Cancelled</span>
                                    ) : (
                                        <span style={{ color: "#777777" }}>
                                            {item.status === "GIFTED" ? "Gifted" : "Purchased"}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="font-family-bold">{item.totalData}GB</div>
                        </div>
                    </div>
                );
            })}
        </Modal>
    );
}
