import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Controller, useForm } from "react-hook-form";
import { Button } from "../components/button";
import PageLayout from "../components/page-layout";
import Select from "../components/select";
import { TextArea } from "../components/text-area";
import DropdownInput, { DropdownItem } from "../components/dropdown-input";
import { debounce, range } from "lodash";
import classNames from "classnames";
import { createAccount, getQRCCategories, searchCustomers } from "../features/events-slice";
import { useOpsEvents } from "../features/hooks/use-ops-events";
import { useAppSelector, useUnwrapAsyncThunk } from "../utils/store";
import { useErrorHandler } from "../components/error-snackbar";
import { EventName, QRCCategoriesRes, SearchableCustomer } from "../utils/types";
import { EMAIL_PATTERN, formatPhone, NAME_PATTERN } from "../utils/helpers";
import { Input } from "../components/input";
import { selectUserInfo } from "../features/user-role-slice";
import { useSuccessModal } from "../components/success-modal";
import FileInput from "../components/file-input";
import firebase from "firebase/app";
import { v4 as uuidv4 } from "uuid";
import QrcPage from "./qrc-page";

export type QrcProps = RouteComponentProps;

interface FormData {
    event_type: "query" | "action" | "complaint";
    description: string;
    event_category: string;
    event_subcategory: string;
    firstName: string;
    lastName: string;
    emailId: string;
    primaryNumber: string;
    imageFile: FileList;
}

interface QrcPropsData {
    customerEmail: string;
    customerIdProp?: string;
}

export default function QrcPages(props: QrcPropsData) {
    const { register, handleSubmit, errors, control, formState, watch } = useForm<FormData>({
        mode: "onBlur",
    });

    const { customerEmail, customerIdProp } = props;

    const [query, setQuery] = useState("");
    const [searchResults, setSearchResults] = useState<SearchableCustomer[]>();
    const [selectedCustomer, setSelectedCustomer] = useState<SearchableCustomer>();
    const [addNew, setAddNew] = useState(false);

    const userInfo = useAppSelector(selectUserInfo);

    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();

    const addEvent = useOpsEvents();

    const batchSize = 3;

    const updateCustomers = async () => {
        try {
            if (categoryRes) {
                const res = await unwrap(
                    searchCustomers({
                        limit: 100,
                        query,
                        limitedFieldSearch: true,
                        partialMatch: true,
                    })
                );
                setSearchResults(res.results);
            }
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const [categoryRes, setCategoryRes] = useState<QRCCategoriesRes>();

    useEffect(() => {
        async function fetchQRCCategories() {
            try {
                const res = await unwrap(getQRCCategories(undefined));
                setCategoryRes(res);
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
        fetchQRCCategories();
    }, []);

    useEffect(() => {
        updateCustomers();
        handleSearch(customerEmail);
        if (searchResults) {
            setSelectedCustomer(searchResults[0]);
        }
    }, [query, customerEmail, searchResults]);

    const handleSearch = (query: string) => {
        setQuery(query);
    };

    const onSubmit = async (data: FormData) => {
        let customerId = selectedCustomer?.id;

        try {
            if (addNew) {
                const customer = await unwrap(
                    createAccount({
                        firstName: data.firstName,
                        lastName: data.lastName,
                        emailId: data.emailId,
                        primaryNumber: data.primaryNumber,
                        leadSource: "Others",
                    })
                );
                customerId = customer.id;
            }

            let imagePath: string | undefined;

            if (data.imageFile && data.imageFile[0]) {
                const file = data.imageFile[0];
                const storageRef = firebase.storage().ref("/qrc_images");
                const fileRef = storageRef.child(uuidv4());
                await fileRef.put(file);
                imagePath = fileRef.fullPath;
            }

            const event = mapEventType(data.event_type);

            await addEvent({
                event,
                status: "SUCCESS",
                description: data.description,
                event_type: data.event_type,
                event_category: data.event_category,
                event_subcategory: data.event_subcategory,
                customer_id: customerIdProp ? customerIdProp : customerId,
                customer_email: customerEmail ? customerEmail : data.emailId,
                user: userInfo.emailId,
                user_type: "cs",
                image_path: imagePath,
            });
            const message =
                event === EventName.Request
                    ? "Request registered"
                    : event === EventName.Complaint
                    ? "Complaint registered"
                    : "Query registered";

            showSuccessModal(message);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    if (!categoryRes) {
        return (
            <div className="mt-3 d-flex justify-content-center">
                <div className="spinner-border text-primary" />
            </div>
        );
    }

    const watchType = watch("event_type");
    const watchCategory = watch("event_category");
    return (
        <form className="d-flex flex-column align-items-center" onSubmit={handleSubmit(onSubmit)}>
            <Select
                className="col-12 mt-3 px-0"
                register={register({
                    required: {
                        value: true,
                        message: "Type is required",
                    },
                })}
                name="event_type"
                showError={errors.event_type != null}
                errorMessage={errors.event_type?.message}>
                <option value="">Select type</option>
                <option value="query">Query</option>
                <option value="action">Request</option>
                <option value="complaint">Complaint</option>
            </Select>
            <DropdownInput
                style={{ display: "none" }}
                type="text"
                placeholder="Phone number / email address"
                icon="reach-cs-search"
                iconPosition="right"
                dropdownClassName="col-12 mt-3 px-0"
                defaultValue={customerEmail}
                disabled
                onChange={({ target: { value } }) => handleSearch(value)}>
                {searchResults == null ? (
                    <div className="d-flex justify-content-center my-3">
                        <span className="spinner-border spinner-border-sm text-primary" />
                    </div>
                ) : searchResults.length === 0 ? (
                    <DropdownItem className="p-0" onClick={() => setAddNew(true)}>
                        <div className="mx-3 py-2 text-cta">Add new</div>
                    </DropdownItem>
                ) : (
                    searchResults.slice(0, batchSize).map((customer, index) => (
                        <DropdownItem
                            key={customer.id}
                            className="p-0"
                            onClick={() => {
                                setSelectedCustomer(customer);
                            }}>
                            <div
                                className={classNames("mx-3 py-2 font-family-semibold", {
                                    "border-top": index !== 0,
                                })}
                                style={{ fontSize: 12 }}>
                                <div>
                                    {customer.firstName} {customer.lastName}
                                </div>
                                <div>{formatPhone(customer.reachNumber)}</div>
                                <div>{customer.emailId}</div>
                            </div>
                        </DropdownItem>
                    ))
                )}
            </DropdownInput>
            {(addNew || selectedCustomer != null) && (
                <>
                    <Input
                        type="text"
                        placeholder="First Name"
                        className="col-12 mt-3 px-0"
                        name="firstName"
                        defaultValue={selectedCustomer?.firstName ?? ""}
                        readOnly={!addNew}
                        register={register({
                            required: {
                                value: true,
                                message: "First name is required",
                            },
                            pattern: {
                                value: NAME_PATTERN,
                                message: "Not a valid first name",
                            },
                        })}
                        showError={errors.firstName != null}
                        errorMessage={errors.firstName?.message}
                    />
                    <Input
                        type="text"
                        placeholder="Last Name"
                        className="col-12 mt-3 px-0"
                        name="lastName"
                        defaultValue={selectedCustomer?.lastName ?? ""}
                        readOnly={!addNew}
                        register={register({
                            required: {
                                value: true,
                                message: "Last name is required",
                            },
                            pattern: {
                                value: NAME_PATTERN,
                                message: "Not a valid last name",
                            },
                        })}
                        showError={errors.lastName != null}
                        errorMessage={errors.lastName?.message}
                    />
                    <Controller
                        control={control}
                        name="primaryNumber"
                        defaultValue={selectedCustomer?.reachNumber ?? selectedCustomer?.primaryNumber ?? ""}
                        rules={{
                            required: {
                                value: true,
                                message: "Phone number is required",
                            },
                            pattern: {
                                value: /^\d{10}$/,
                                message: "Phone number should be 10 digits long",
                            },
                        }}
                        render={({ onChange, onBlur, value, name }) => (
                            <Input
                                type="text"
                                inputMode="numeric"
                                placeholder="Billing phone number"
                                className="col-12 mt-3 px-0"
                                maxLength={10}
                                mask="phone"
                                readOnly={!addNew}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                name={name}
                                showError={errors.primaryNumber != null}
                                errorMessage={errors.primaryNumber?.message}
                            />
                        )}
                    />
                    <Input
                        type="text"
                        placeholder="Email address"
                        className="col-12 mt-3 px-0"
                        name="emailId"
                        defaultValue={selectedCustomer?.emailId ?? ""}
                        readOnly={!addNew}
                        register={register({
                            required: {
                                value: true,
                                message: "Email ID is required",
                            },
                            pattern: {
                                value: EMAIL_PATTERN,
                                message: "Not a valid email id",
                            },
                        })}
                        showError={errors.emailId != null}
                        errorMessage={errors.emailId?.message}
                    />
                </>
            )}
            <Select
                className="col-12 mt-3 px-0"
                register={register({
                    required: {
                        value: true,
                        message: "Category is required",
                    },
                })}
                name="event_category"
                showError={errors.event_category != null}
                errorMessage={errors.event_category?.message}>
                <option value="">Select category</option>
                {Object.keys(categoryRes[mapEventType(watchType)]).map((item) => (
                    <option value={item} key={item}>
                        {item}
                    </option>
                ))}
            </Select>
            <Select
                className="col-12 mt-3 px-0"
                register={register({
                    required: {
                        value: true,
                        message: "Sub-category is required",
                    },
                })}
                name="event_subcategory"
                showError={errors.event_subcategory != null}
                errorMessage={errors.event_subcategory?.message}>
                <option value="">Select sub-category</option>
                {categoryRes[mapEventType(watchType)]?.[watchCategory]?.map((item) => (
                    <option value={item} key={item}>
                        {item}
                    </option>
                ))}
            </Select>
            <TextArea
                placeholder="Description"
                name="description"
                className="col-12 mt-3 px-0"
                rows={5}
                register={register({
                    required: {
                        value: true,
                        message: "Description required",
                    },
                })}
                showError={errors.description != null}
                errorMessage={errors.description?.message}
            />
            <FileInput
                label="Choose file"
                className="col-12 mt-3 px-0"
                accept="image/*"
                register={register}
                name="imageFile"
            />
            <Button color="secondary" className="col-8 my-3" loading={formState.isSubmitting}>
                Create
            </Button>
        </form>
    );
}

function mapEventType(watchType: "action" | "complaint" | "query") {
    return watchType === "action"
        ? EventName.Request
        : watchType === "complaint"
        ? EventName.Complaint
        : EventName.Query;
}
