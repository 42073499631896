import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import Layout from "../../components/layout";
import CustomerBadge from "../../components/customer-badge";
import { useAppDispatch, useAppSelector, useUnwrapAsyncThunk } from "../../utils/store";
import { Link, navigate } from "gatsby";
import { getInternationalCountryList, profilesSelector } from "../../features/profile-slice";
import { Input } from "../../components/input";
import { Button } from "../../components/button";
import { selectAllowedCustomers } from "../../features/intl-roaming-slice";
import Modal from "../../components/modal";
import { Checkbox } from "../../components/checkbox";
import { formatPhone } from "../../utils/helpers";
import { useForm } from "react-hook-form";
import {
    irCountryAdded,
    irCountryRemoved,
    selectIrPurchase,
    tentativeDateUpdated,
    travelersUpdated,
} from "../../features/ir-purchase-slice";
import { IntlCountry } from "../../utils/types";
import { useErrorHandler } from "../../components/error-snackbar";
import { configSelector } from "../../features/config-slice";
import { format } from "date-fns";
import { intlCountriesSelector } from "../../features/intl-countries-slice";

export interface IRPurchaseProps extends RouteComponentProps {
    customerId: string;
}

interface FormData {
    destinations: string;
    travelers: string;
    tentativeStartDate: Date;
}

export default function IRPurchase(props: IRPurchaseProps) {
    const { customerId } = props;

    const customerProfile = useAppSelector((state) => profilesSelector.selectById(state, customerId));
    const { travelers, destinations, tentativeStartDate } = useAppSelector(selectIrPurchase);

    const allowedCustomers = useAppSelector(
        selectAllowedCustomers(customerProfile?.groupLines ?? [], customerProfile?.groupId ?? "")
    );

    const { intlPopularDestinations } = useAppSelector(configSelector);
    const { countryRes } =
        useAppSelector((state) => intlCountriesSelector.selectById(state, customerProfile?.source ?? "")) ?? {};

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const [showTravelerModal, setShowTravelerModal] = useState(false);
    const [showDestinationModal, setShowDestinationModal] = useState(false);
    const [countrySearch, setCountrySearch] = useState("");

    const travelerFormMethods = useForm<Record<string, boolean>>({
        mode: "onBlur",
    });
    const purchaseForm = useForm<FormData>({ mode: "onBlur" });

    useEffect(() => {
        async function fetchCountries() {
            try {
                const res = await unwrap(getInternationalCountryList(customerProfile!.source));
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
        fetchCountries();
    }, []);

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    const countries = countryRes?.countriesList;

    const onTravelerSubmit = (data: Record<string, boolean>) => {
        console.log(data);
        dispatch(
            travelersUpdated(
                Object.entries(data)
                    .filter(([_, value]) => value)
                    .map(([key]) => key)
            )
        );
        purchaseForm.clearErrors("travelers");
        setShowTravelerModal(false);
    };

    const onSubmit = (data: FormData) => {
        if (destinations.length === 0) {
            return purchaseForm.setError("destinations", { message: "Destination is required" });
        }
        if (travelers.length === 0) {
            return purchaseForm.setError("travelers", { message: "Travelers are required" });
        }

        dispatch(tentativeDateUpdated(data.tentativeStartDate.getTime()));

        navigate(`/ir/purchase/confirm/${customerId}`);
    };

    const selectedCustomers = travelers.map((id) => customerProfile.groupLines.find((customer) => customer.id === id)!);

    return (
        <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - International roaming`}>
            <div className="container col-10 d-flex flex-column my-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to={`/ir/manage/${customerProfile.id}`}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>Enter travel details</h1>
                </div>
                <div className="divider" />

                <form className="row justify-content-center" onSubmit={purchaseForm.handleSubmit(onSubmit)}>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <Input
                            type="text"
                            readOnly
                            placeholder="Destination(s)"
                            className="mt-3"
                            inputClassName="bg-white"
                            name="destinations"
                            icon="reach-cs-search"
                            iconPosition="right"
                            value={destinations.map((country) => country.countryName).join("; ")}
                            onClick={() => setShowDestinationModal(true)}
                            register={purchaseForm.register({
                                required: "Destination is required",
                            })}
                            showError={purchaseForm.errors.destinations != null}
                            errorMessage={purchaseForm.errors.destinations?.message}
                        />
                        <Input
                            type="date"
                            placeholder="Departure date"
                            className="mt-3"
                            name="tentativeStartDate"
                            min={format(new Date(), "yyyy-MM-dd")}
                            register={purchaseForm.register({
                                required: "Departure date is required",
                                valueAsDate: true,
                            })}
                            defaultValue={tentativeStartDate ? format(tentativeStartDate, "yyyy-MM-dd") : ""}
                            showError={purchaseForm.errors.tentativeStartDate != null}
                            errorMessage={purchaseForm.errors.tentativeStartDate?.message}
                        />
                        <Input
                            type="text"
                            readOnly
                            placeholder="Traveler details"
                            className="mt-3"
                            inputClassName="bg-white"
                            name="travelers"
                            value={selectedCustomers.map((customer) => customer?.firstName).join("; ")}
                            onClick={() => setShowTravelerModal(true)}
                            showError={purchaseForm.errors.travelers != null}
                            register={purchaseForm.register({
                                required: "Travelers are required",
                            })}
                            errorMessage={purchaseForm.errors.travelers?.message}
                        />

                        <Button color="secondary" fullWidth className="my-3">
                            Proceed
                        </Button>
                    </div>
                </form>
            </div>

            <Modal
                title="Select the travelers"
                size="sm"
                show={showTravelerModal}
                onHide={() => setShowTravelerModal(false)}>
                <form className="w-100 align-items-start" onSubmit={travelerFormMethods.handleSubmit(onTravelerSubmit)}>
                    {allowedCustomers.map((customer) => (
                        <Checkbox
                            key={customer.id}
                            id={customer.id}
                            name={customer.id}
                            small
                            className="mb-3"
                            register={travelerFormMethods.register}
                            defaultChecked={travelers.find((id) => id === customer.id) != null}
                            label={`${customer.firstName} ${customer.lastName} (${formatPhone(customer.reachNumber)})`}
                        />
                    ))}
                    <Button color="secondary" fullWidth>
                        Done
                    </Button>
                </form>
            </Modal>

            <Modal
                title="Select destinations"
                size="sm"
                show={showDestinationModal}
                onHide={() => setShowDestinationModal(false)}>
                <div className="w-100 align-items-start">
                    <Input
                        type="text"
                        placeholder="Search destination(s)"
                        value={countrySearch}
                        onChange={({ target: { value } }) => setCountrySearch(value)}
                        icon="reach-cs-search"
                        iconPosition="right"
                    />
                    {countrySearch === "" ? (
                        destinations.length > 0 ? (
                            <>
                                <div className="font-family-bold mt-3">Destinations</div>
                                {destinations
                                    .map((destCountry) =>
                                        countries?.find(
                                            (country) =>
                                                country.countryCode === destCountry.countryCode &&
                                                country.status === 1 &&
                                                country.zone !== "PAYGO"
                                        )
                                    )
                                    .filter((country) => !!country)
                                    .map((country) => (
                                        <div
                                            key={country!.countryCode}
                                            className="font-family-semibold mt-1 d-flex justify-content-between">
                                            <div>{country!.countryName}</div>
                                            <div
                                                className="reach-cs-cross cursor-pointer"
                                                style={{ fontSize: 12 }}
                                                onClick={() => dispatch(irCountryRemoved(country!.countryCode))}
                                            />
                                        </div>
                                    ))}
                            </>
                        ) : (
                            <>
                                <div className="font-family-bold mt-3">Popular destinations</div>
                                {intlPopularDestinations
                                    .map((code) =>
                                        countries?.find(
                                            (country) =>
                                                country.countryCode === code &&
                                                country.status === 1 &&
                                                country.zone !== "PAYGO"
                                        )
                                    )
                                    .filter((country) => !!country)
                                    .map((country) => (
                                        <div
                                            key={country!.countryCode}
                                            className="font-family-semibold mt-1 cursor-pointer"
                                            onClick={() => {
                                                dispatch(irCountryAdded(country!));
                                                purchaseForm.clearErrors("destinations");
                                                setCountrySearch("");
                                            }}>
                                            {country!.countryName}
                                        </div>
                                    ))}
                            </>
                        )
                    ) : (
                        <div className="overflow-auto mt-3" style={{ maxHeight: 200 }}>
                            {countries
                                ?.filter((country) => {
                                    return (
                                        country.countryName
                                            .toLowerCase()
                                            .includes(countrySearch.trim().toLowerCase()) &&
                                        (country.status === 1 || country.ildStatus === 1) &&
                                        country.zone !== "PAYGO"
                                    );
                                })
                                .map((country) => (
                                    <div
                                        key={country!.countryCode}
                                        className="font-family-semibold mt-1 cursor-pointer"
                                        onClick={() => {
                                            dispatch(irCountryAdded(country!));
                                            purchaseForm.clearErrors("destinations");
                                            setCountrySearch("");
                                        }}>
                                        {country!.countryName}
                                    </div>
                                ))}
                        </div>
                    )}
                    <Button color="secondary" className="mt-3" fullWidth onClick={() => setShowDestinationModal(false)}>
                        Done
                    </Button>
                </div>
            </Modal>
        </Layout>
    );
}
