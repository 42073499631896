import React from "react";
import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";
import Accordion, { AccordionBody } from "../../components/accordion";
import PageLayout from "../../components/page-layout";
import { QuickActionCard } from "../../components/quick-action-card";
import image2 from "../../images/callDashboard@2x.png";
import image1 from "../../images/chatDashboard@2x.png";
import image3 from "../../images/ticketDashboard@2x.png";

export type ManagerDashboardsProps = RouteComponentProps;

export default function ManagerDashboards(props: ManagerDashboardsProps) {
    return (
        <PageLayout title="Manager Dashboards" prevUrl="/cs-dashboards">
            <Accordion>
                <AccordionBody title="">
                    <div className="row row-cols-2 row-cols-md-4 row-cols-lg-5 mx-n2">
                        <div className="p-2 col">
                            <QuickActionCard
                                icon={image1}
                                title="Chats"
                                onClick={() => {
                                    navigate("/chats-dashboard");
                                }}
                            />
                        </div>
                        <div className="p-2 col">
                            <QuickActionCard
                                icon={image2}
                                title="Calls"
                                onClick={() => {
                                    window.open("https://justcall.io/app/index");
                                }}
                            />
                        </div>
                        <div className="p-2 col">
                            <QuickActionCard
                                icon={image3}
                                title="Tickets"
                                onClick={() => {
                                    navigate("/tickets-dashboard");
                                }}
                            />
                        </div>
                    </div>
                </AccordionBody>
            </Accordion>
        </PageLayout>
    );
}
