import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { fetchProfile, profilesSelector, updateProfile } from "../features/profile-slice";
import Layout from "../components/layout";
import { Link, navigate } from "gatsby";
import { Card } from "../components/card";
import { Button } from "../components/button";
import { Input } from "../components/input";
import { useForm } from "react-hook-form";
import { useErrorHandler } from "../components/error-snackbar";
import { EMAIL_PATTERN, formatPhone, NAME_PATTERN } from "../utils/helpers";
import Modal from "../components/modal";
import { AddressComponent } from "./purchase/checkout-page";
import { Address } from "../utils/types/customer";

export interface EditProfileProps extends RouteComponentProps {
    customerId: string;
}

interface FormData {
    firstName: string;
    lastName: string;
    emailId: string;
}

export default function EditProfile(props: EditProfileProps) {
    const { customerId } = props;

    const customerProfile = useSelector((state: RootState) => profilesSelector.selectById(state, customerId));

    const { register, formState, handleSubmit, errors } = useForm<FormData>({
        mode: "onBlur",
    });

    const [showAddressModal, setShowAddressModal] = useState(false);

    const unwrap = useUnwrapAsyncThunk();
    const dispatch = useAppDispatch();
    const handleError = useErrorHandler();

    const modalFormMethods = useForm({
        mode: "onBlur",
    });

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    const onSubmit = async (data: FormData) => {
        try {
            await unwrap(
                updateProfile({
                    id: customerId,
                    customer: data,
                })
            );
            await dispatch(fetchProfile(customerId));
            navigate(`/profile/${customerId}`);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const onAddressSubmit = async (data: { addresses: Address[] }) => {
        try {
            const addresses = [...customerProfile.addresses];
            addresses[0] = data.addresses[0];
            addresses[0].name = `${customerProfile.firstName} ${customerProfile.lastName}`;
            addresses[0].phone = customerProfile.primaryNumber!;
            addresses[0].country = "USA";
            addresses[0].type = "billing";
            await unwrap(
                updateProfile({
                    id: customerId,
                    customer: { addresses },
                })
            );
            await dispatch(fetchProfile(customerId));
            navigate(`/profile/${customerId}`);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    return (
        <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - Edit Profile`}>
            <form className="container col-10 d-flex flex-column my-3 px-0" onSubmit={handleSubmit(onSubmit)}>
                <Link className="text-cta mb-3" to={`/profile/${customerId}`}>
                    <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                    Back
                </Link>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>Edit Profile</h1>
                </div>
                <div className="divider" />

                <Card className="mt-3">
                    <div className="font-family-bold align-self-start" style={{ fontSize: 16 }}>
                        Personal details
                    </div>
                    <div className="row w-100 row-cols-1 row-cols-md-2 mx-n2">
                        <Input
                            type="text"
                            placeholder="First name"
                            className="col mt-3 px-2"
                            defaultValue={customerProfile.firstName}
                            name="firstName"
                            register={register({
                                required: {
                                    value: true,
                                    message: "First name is required",
                                },
                                pattern: {
                                    value: NAME_PATTERN,
                                    message: "Not a valid first name",
                                },
                            })}
                            showError={errors.firstName != null}
                            errorMessage={errors.firstName?.message}
                        />
                        <Input
                            type="text"
                            placeholder="Last name"
                            className="col mt-3 px-2"
                            defaultValue={customerProfile.lastName}
                            name="lastName"
                            register={register({
                                required: {
                                    value: true,
                                    message: "Last name is required",
                                },
                                pattern: {
                                    value: NAME_PATTERN,
                                    message: "Not a valid last name",
                                },
                            })}
                            showError={errors.lastName != null}
                            errorMessage={errors.lastName?.message}
                        />
                        <Input
                            type="text"
                            placeholder="Phone"
                            className="col mt-3 px-2"
                            defaultValue={formatPhone(customerProfile.reachNumber)}
                            disabled
                        />
                        <Input
                            type="text"
                            placeholder="Email"
                            className="col mt-3 px-2"
                            defaultValue={customerProfile.emailId}
                            name="emailId"
                            register={register({
                                required: {
                                    value: true,
                                    message: "Email ID is required",
                                },
                                pattern: {
                                    value: EMAIL_PATTERN,
                                    message: "Not a valid email id",
                                },
                            })}
                            showError={errors.emailId != null}
                            errorMessage={errors.emailId?.message}
                        />
                    </div>
                    <div className="col-12 col-md-6 align-self-start px-2 mt-3">
                        <div>Billing address</div>
                        <div className="font-family-semibold">
                            {Object.entries(customerProfile.addresses[0])
                                .map(([key, value]) => key !== "name" && key !== "type" && key !== "phone" && value)
                                .filter(Boolean)
                                .join(", ")}
                        </div>
                        <div className="text-cta" onClick={() => setShowAddressModal(true)}>
                            Edit
                        </div>
                    </div>
                </Card>

                <Button
                    color="secondary"
                    className="col-12 col-md-6 mt-3 align-self-center"
                    loading={formState.isSubmitting}>
                    Save
                </Button>
            </form>

            <Modal
                title="Update billing address"
                size="sm"
                show={showAddressModal}
                onHide={() => setShowAddressModal(false)}>
                <AddressComponent
                    index={0}
                    address={customerProfile.addresses[0]}
                    onSubmit={(address) => console.log(address)}
                    className="row-cols-1"
                    {...modalFormMethods}
                />
                <Button
                    color="secondary"
                    fullWidth
                    loading={modalFormMethods.formState.isSubmitting}
                    onClick={modalFormMethods.handleSubmit(onAddressSubmit)}>
                    Save
                </Button>
            </Modal>
        </Layout>
    );
}
