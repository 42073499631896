import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Link, navigate } from "gatsby";
import Layout from "../components/layout";
import { useSelector } from "react-redux";
import { activateIld, deactivateIld, getIldCreditStatus, profilesSelector } from "../features/profile-slice";
import { RootState, useUnwrapAsyncThunk } from "../utils/store";
import { useErrorHandler } from "../components/error-snackbar";
import { CreditPurchaseRes, CreditPurchaseStatus } from "../utils/types";
import { Card } from "../components/card";
import { Customer, CustomerStatus } from "../utils/types/customer";
import { formatPhone } from "../utils/helpers";
import Toggle from "../components/toggle";
import { Button } from "../components/button";
import Modal from "../components/modal";
import { useForm } from "react-hook-form";
import CustomerBadge from "../components/customer-badge";
import { usePermissions } from "../features/hooks/use-permissions";

export interface ManageILDProps extends RouteComponentProps {
    customerId: string;
}

export default function ManageILD(props: ManageILDProps) {
    const { customerId } = props;

    const customerProfile = useSelector((state: RootState) => profilesSelector.selectById(state, customerId));

    const handleError = useErrorHandler();
    const unwrap = useUnwrapAsyncThunk();

    const { hasOnePermission } = usePermissions();

    const [ildCreditStatus, setILDCreditStatus] = useState<CreditPurchaseRes>();

    async function fetchILDStatus() {
        if (customerProfile) {
            try {
                const res = await unwrap(getIldCreditStatus(customerProfile.groupId));
                setILDCreditStatus(res);
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
    }

    useEffect(() => {
        fetchILDStatus();
    }, []);

    const [selectedCustomer, setSelectedCustomer] = useState<Customer>();

    const { formState, handleSubmit } = useForm({
        mode: "onBlur",
    });

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    if (!ildCreditStatus) {
        return (
            <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - International calling`}>
                <div className="container col-10 d-flex flex-column my-3 px-0">
                    <div className="d-flex justify-content-between">
                        <Link className="text-cta mb-3" to={`/profile/${customerId}`}>
                            <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                            Back
                        </Link>
                    </div>
                    <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                        <div>
                            {customerProfile.firstName} {customerProfile.lastName}{" "}
                            <CustomerBadge isPrimary={customerProfile.isPrimary} />
                        </div>
                        <div>{customerProfile.emailId}</div>
                    </div>
                    <Card>
                        <div className="spinner-border text-primary" />
                    </Card>
                </div>
            </Layout>
        );
    }

    if (Object.keys(ildCreditStatus).length === 0) {
        navigate(`/ild/purchase/${customerId}?noCredit=true`);
        return null;
    }

    const activeLines = customerProfile.groupLines
        .filter((customer) => customer.status === CustomerStatus.ACTIVE)
        .sort((c1, c2) => {
            if (c1.isPrimary) {
                return -1;
            }
            if (c2.isPrimary) {
                return 1;
            }
            return 0;
        });

    const onDisable = async () => {
        try {
            await unwrap(
                deactivateIld({
                    primaryId: customerProfile.id,
                    customerId: selectedCustomer!.id,
                    adjustCreditInBill: true,
                })
            );
            const res = await unwrap(getIldCreditStatus(customerProfile.groupId));
            setILDCreditStatus(res);
            setSelectedCustomer(undefined);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const onActivateIld = async (id: string) => {
        try {
            await unwrap(
                activateIld({
                    primaryId: customerId,
                    customerId: id,
                })
            );
            await fetchILDStatus();
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    return (
        <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - International calling`}>
            <div className="container col-10 d-flex flex-column my-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to={`/profile/${customerId}`}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>

                <Card>
                    <div className="font-family-bold align-self-start mb-3" style={{ fontSize: 16 }}>
                        International calling
                    </div>

                    {activeLines.map((customer) => {
                        const creditStatus = ildCreditStatus[customer.id] ?? {
                            availableCredit: 0,
                            status: CreditPurchaseStatus.DEACTIVATED,
                        };

                        return (
                            <div className="w-100" key={customer.id}>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="col-7 font-family-semibold">
                                        <div>{customer.firstName}</div>
                                        <div>{formatPhone(customer.reachNumber)}</div>
                                    </div>
                                    <div className="col font-family-semibold">
                                        {creditStatus.availableCredit >= 0
                                            ? `$${creditStatus.availableCredit}`
                                            : `-$${-creditStatus.availableCredit}`}
                                    </div>
                                    {creditStatus.status === CreditPurchaseStatus.ACTIVATION_IN_PROGRESS && (
                                        <div className="text-grey2">In progress</div>
                                    )}
                                    {(creditStatus.status === CreditPurchaseStatus.ACTIVATED ||
                                        creditStatus.status === CreditPurchaseStatus.DEACTIVATED ||
                                        creditStatus.status == null) && (
                                        <Toggle
                                            id={customer.id}
                                            label=""
                                            disabled={!hasOnePermission("user_management.intl_calling.purchase")}
                                            checked={creditStatus.status === CreditPurchaseStatus.ACTIVATED}
                                            onChange={({ target: { checked } }) => {
                                                console.log("onChange:", checked);
                                                if (!checked) {
                                                    setSelectedCustomer(customer);
                                                } else {
                                                    if (creditStatus.availableCredit <= 0) {
                                                        navigate(`/ild/purchase/${customerId}`);
                                                    } else {
                                                        setILDCreditStatus((state) => ({
                                                            ...state,
                                                            [customer.id]: {
                                                                ...creditStatus,
                                                                status: CreditPurchaseStatus.ACTIVATION_IN_PROGRESS,
                                                            },
                                                        }));
                                                        onActivateIld(customer.id);
                                                    }
                                                }
                                            }}
                                        />
                                    )}
                                </div>
                                <div className="divider my-2" />
                            </div>
                        );
                    })}

                    <Button
                        color="secondary"
                        className="col-12 col-md-4 mt-2"
                        disabled={!hasOnePermission("user_management.intl_calling.purchase")}
                        onClick={() => navigate(`/ild/purchase/${customerId}`)}>
                        Buy credit
                    </Button>
                    <Link to={`/ild/usage/${customerId}`} className="text-cta mt-3">
                        Usage details
                    </Link>
                </Card>
            </div>

            {selectedCustomer && (
                <Modal
                    title="Disable service?"
                    size="sm"
                    show={selectedCustomer != null}
                    onHide={() => setSelectedCustomer(undefined)}>
                    <div className="d-flex w-100 justify-content-between font-family-semibold">
                        <div className="d-flex">
                            {selectedCustomer.firstName} {formatPhone(selectedCustomer.reachNumber)}
                        </div>
                        <div>
                            {ildCreditStatus[selectedCustomer.id]!.availableCredit >= 0
                                ? `$${ildCreditStatus[selectedCustomer.id]!.availableCredit}`
                                : `-$${-ildCreditStatus[selectedCustomer.id]!.availableCredit}`}
                        </div>
                    </div>
                    <div className="font-family-semibold text-center my-2">
                        Note: Remaining available credit will be settled on your next invoice(s).
                    </div>
                    <Button
                        className="col-12"
                        color="secondary"
                        loading={formState.isSubmitting}
                        onClick={handleSubmit(onDisable)}>
                        Confirm
                    </Button>
                </Modal>
            )}
        </Layout>
    );
}
