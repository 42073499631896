import React from "react";
import { RouteComponentProps } from "@reach/router";
import Layout from "../components/layout";
import { Link, navigate } from "gatsby";
import { Card } from "../components/card";
import { Button } from "../components/button";
import { Input } from "../components/input";
import { useForm } from "react-hook-form";
import { EMAIL_PATTERN } from "../utils/helpers";
import firebase from "firebase/app";
import { useSuccessModal } from "../components/success-modal";
import { getTenantId } from "../features/auth-slice";
import { useUnwrapAsyncThunk } from "../utils/store";
import { Duration } from "../components/snackbar";

export type ForgotPasswordProps = RouteComponentProps;

interface FormData {
    accountId: string;
    emailId: string;
}

export default function ForgotPassword(props: ForgotPasswordProps) {
    const { errors, handleSubmit, register, formState, setError } = useForm<FormData>({
        mode: "onBlur",
    });

    const showSuccessModal = useSuccessModal();
    const unwrap = useUnwrapAsyncThunk();

    const onSubmit = async (data: FormData) => {
        try {
            const res = await unwrap(getTenantId(data.accountId));
            firebase.auth().tenantId = res.tenantId;
            await firebase.auth().sendPasswordResetEmail(data.emailId);
            showSuccessModal(`Reset password email sent to ${data.emailId}`, Duration.SHORT);
            setTimeout(() => {
                navigate("/login");
            }, Duration.SHORT);
        } catch (error) {
            console.log(error);
            if (error.code === "auth/user-not-found") {
                setError("emailId", { message: "Email id not found" });
            }
        }
    };

    return (
        <Layout title="Reset password">
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <Link className="text-cta mb-3" to="/login">
                    <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                    Back
                </Link>

                <div className="h2">Reset password</div>

                <Card className="mt-3">
                    <Input
                        type="text"
                        placeholder="Account ID"
                        className="col-12 mb-3"
                        name="accountId"
                        register={register({
                            required: { value: true, message: "Account ID is required" },
                        })}
                        showError={errors.accountId != null}
                        errorMessage={errors.accountId?.message}
                    />
                    <Input
                        type="text"
                        placeholder="Email address"
                        className="col-12 mb-3"
                        name="emailId"
                        register={register({
                            required: {
                                value: true,
                                message: "Email ID is required",
                            },
                            pattern: {
                                value: EMAIL_PATTERN,
                                message: "Not a valid email id",
                            },
                        })}
                        showError={errors.emailId != null}
                        errorMessage={errors.emailId?.message}
                    />
                    <Button
                        color="secondary"
                        className="col-12 col-md-6"
                        loading={formState.isSubmitting}
                        onClick={handleSubmit(onSubmit)}>
                        Submit
                    </Button>
                </Card>
            </div>
        </Layout>
    );
}
