import React from "react";
import Layout from "../components/layout";

export default function HubspotTickets() {
    return (
        <Layout title="Hubspot Tickets">
            <iframe
                style={{ width: "100%", height: "100vh", border: 0 }}
                src="https://app.hubspot.com/contacts/4888946/tickets/board/view/all/"></iframe>
        </Layout>
    );
}
