import React, { useState } from "react";
import DropdownInput, { DropdownInputProps, DropdownItem } from "./dropdown-input";
import classNames from "classnames";

interface ItemInput {
    label: string;
    value: string;
}

export interface MultiDropdownInputProps extends Omit<DropdownInputProps, "children"> {
    items: ItemInput[];
    defaultItems?: string[];
    defaultValue?: string;
    onSelectionChange?: (items: string[]) => void;
    containerClassName?: string;
}

export default function MultiDropdownInput(props: MultiDropdownInputProps) {
    const { items, containerClassName, onSelectionChange, defaultValue = "", defaultItems = [], ...rest } = props;

    const [label, setLabel] = useState(defaultValue);
    const [selectedItems, setSelectedItems] = useState(defaultItems);

    return (
        <div className={containerClassName}>
            {selectedItems.length > 0 && (
                <div
                    className={classNames("h-auto form-control d-flex flex-wrap pt-1 px-1", {
                        disabled: rest.disabled,
                    })}>
                    {selectedItems.map((item, index) => (
                        <div key={index} className="multi-select-badge mx-1 mt-2 d-flex align-items-center">
                            {items.find((x) => x.value === item)?.label}
                            <span
                                className="reach-cs-cross ml-2 cursor-pointer"
                                style={{ fontSize: 8 }}
                                onClick={() => {
                                    if (rest.disabled) {
                                        return;
                                    }
                                    const itemsAfterRemoval = selectedItems.filter((_, i) => index !== i);
                                    setSelectedItems(itemsAfterRemoval);
                                    onSelectionChange?.(itemsAfterRemoval);
                                }}
                            />
                        </div>
                    ))}
                </div>
            )}
            <DropdownInput {...rest} value={label} onChange={({ target: { value } }) => setLabel(value)}>
                {items
                    .filter(
                        (item) =>
                            item.label.toLowerCase().includes(label.toLowerCase()) &&
                            !selectedItems.includes(item.value)
                    )
                    .map((item, index) => (
                        <DropdownItem
                            key={item.value}
                            onClick={() => {
                                setLabel("");
                                const items = [...selectedItems, item.value];
                                setSelectedItems(items);
                                onSelectionChange?.(items);
                            }}
                            className="p-0">
                            <div className={classNames("mx-3 py-2", { "border-top": index !== 0 })}>{item.label}</div>
                        </DropdownItem>
                    ))}
            </DropdownInput>
        </div>
    );
}
