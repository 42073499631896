import React, { useState } from "react";
import { format } from "date-fns";
import { CardExpiryState, fetchProfile } from "../features/profile-slice";
import { CustomerProfile } from "../utils/types";
import Modal from "./modal";
import { cardImages, getCardImage } from "./payment-cards";
import classNames from "classnames";
import { Button } from "./button";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { createCardHold, customerSelectedForActivation } from "../features/activation-slice";
import { navigate } from "gatsby";
import { ModalProps as BSModalProps } from "react-bootstrap/esm/Modal";
import tickIcon from "../images/tick-icon.png";
import { useErrorHandler } from "./error-snackbar";

export interface CardHoldModalProps extends BSModalProps {
    customerProfile: CustomerProfile;
}

enum UIState {
    INITIAL,
    ERROR,
    SUCCESS,
}

export default function CardHoldModal(props: CardHoldModalProps) {
    const { customerProfile, ...rest } = props;

    const [uiState, setUIState] = useState(UIState.INITIAL);

    const [loading, setLoading] = useState(false);
    const [manageCardModal, setManageCardModal] = useState(false);
    let title: string;

    const dispatch = useAppDispatch();
    const handleError = useErrorHandler();
    const unwrap = useUnwrapAsyncThunk();

    if (uiState === UIState.INITIAL || uiState === UIState.SUCCESS) {
        title = "First month payment hold";
    } else {
        title = "Payment failed";
    }

    const onClick = async () => {
        if (uiState === UIState.SUCCESS) {
            dispatch(
                customerSelectedForActivation({
                    customer: customerProfile.groupLines[0],
                    skipHold: false,
                })
            );
            navigate("/activation/scan-sim");
            return;
        }

        setLoading(true);
        try {
            const res = await unwrap(createCardHold(customerProfile.id));
            if (res.holdStatus) {
                await dispatch(fetchProfile(customerProfile.id));
                dispatch(
                    customerSelectedForActivation({
                        customer: customerProfile.groupLines[0],
                        skipHold: false,
                    })
                );
                setUIState(UIState.SUCCESS);
            } else {
                setUIState(UIState.ERROR);
            }
        } catch (error) {
            if (error.resCode === 40224) {
                await dispatch(fetchProfile(customerProfile.id));
                setUIState(UIState.SUCCESS);
            } else {
                handleError(error);
            }
        }
        setLoading(false);
    };

    if (manageCardModal) {
        return (
            <Modal title="Manage cards" size="sm" show={manageCardModal} onHide={() => setManageCardModal(false)}>
                <div className="font-family-semibold text-center">
                    Cards can be managed from the saved cards section in the customer profile.
                </div>
            </Modal>
        );
    }

    return (
        <Modal {...rest} title={title}>
            <div className="thick-divider bg-primary mb-3" />
            {uiState === UIState.INITIAL && (
                <span className="text-center">
                    At the time of activation, a temporary hold will be placed on{" "}
                    <span className="font-family-semibold">
                        {customerProfile.firstName} {customerProfile.lastName}
                        's
                    </span>{" "}
                    card for the total value of the plan purchased.
                </span>
            )}
            {uiState === UIState.SUCCESS && (
                <span className="text-center">A temporary hold has been placed on the card successfully.</span>
            )}
            {uiState !== UIState.SUCCESS && customerProfile.creditCards?.[0] && (
                <div className="d-flex flex-column align-items-center mt-3">
                    {getCardImage(customerProfile?.creditCards[0].type) && (
                        <img
                            src={getCardImage(customerProfile.creditCards[0].type)}
                            className="mb-2"
                            width={45}
                            height={30}
                        />
                    )}
                    <div className={classNames("font-family-semibold", { "text-danger": uiState === UIState.ERROR })}>
                        XXXX-XXXX-XXXX-
                        {customerProfile.creditCards[0].ccNumberMask}
                    </div>
                    <div>
                        {customerProfile.creditCards[0].nameOnCard} |{" "}
                        {customerProfile.creditCards[0].cardExpiryState === CardExpiryState.EXPIRED ? (
                            <span className="text-danger">
                                Expired date {format(customerProfile.creditCards[0].parsedDate, "MM/yy")}
                            </span>
                        ) : (
                            <span
                                className={classNames({
                                    "text-tertiary2":
                                        customerProfile.creditCards[0].cardExpiryState ===
                                        CardExpiryState.ALMOST_EXPIRED,
                                })}>
                                Expiration date {format(customerProfile.creditCards[0].parsedDate, "MM/yy")}
                            </span>
                        )}
                    </div>
                </div>
            )}
            {uiState === UIState.SUCCESS && (
                <img src={tickIcon} className="d-flex flex-column align-items-center mt-3" />
            )}
            {customerProfile?.creditCards?.[0] && (
                <Button color="secondary" className="mt-3 col-10" loading={loading} onClick={onClick}>
                    Continue
                </Button>
            )}
            {uiState !== UIState.SUCCESS && (
                <div
                    className="text-cta mt-3 align-self-center cursor-pointer"
                    onClick={() => {
                        setManageCardModal(true);
                    }}>
                    Manage Cards
                </div>
            )}
            {uiState !== UIState.SUCCESS && (
                <div
                    className="text-cta mt-3 align-self-center cursor-pointer"
                    onClick={() => {
                        dispatch(
                            customerSelectedForActivation({
                                customer: customerProfile.groupLines[0],
                                skipHold: true,
                            })
                        );
                        navigate("/activation/scan-sim");
                    }}>
                    Skip card hold
                </div>
            )}
        </Modal>
    );
}
