import React, { useState } from "react";
import classNames from "classnames";
import { ModalProps as BSModalProps } from "react-bootstrap/esm/Modal";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { provConfig } from "../features/config-slice";
import { fetchProfile, updateAutoTopup } from "../features/profile-slice";
import { formatPhone } from "../utils/helpers";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile } from "../utils/types";
import { AutoTopUpStatus, CustomerStatus } from "../utils/types/customer";
import { Button } from "./button";
import { useErrorHandler } from "./error-snackbar";
import Modal from "./modal";
import { useSuccessModal } from "./success-modal";
import Toggle from "./toggle";
import CustomerVerification from "./customer-verification";

export interface RecurringTopupModalProps extends BSModalProps {
    customerProfile: CustomerProfile;
}

export default function RecurringTopupModal(props: RecurringTopupModalProps) {
    const { customerProfile, ...rest } = props;

    const config = useSelector(provConfig);

    const defaultMap: Record<string, boolean> = {};

    customerProfile.groupLines
        .filter((customer) => customer.status === CustomerStatus.ACTIVE)
        .forEach((cust) => (defaultMap[cust.id] = cust.autoTopUpStatus === AutoTopUpStatus.ENABLED));

    const { handleSubmit, register, formState } = useForm({
        defaultValues: {
            customerAutoTopUpMap: defaultMap,
        },
    });
    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();

    const [showCustomerVerification, setShowCustomerVerification] = useState(false);

    let title = "Turn on recurring topup";
    let subtitle = "Topup gets added automatically when you run out of data";

    if (!customerProfile.reachPlan.isUnlimited && customerProfile.autoTopUpStatus === AutoTopUpStatus.ENABLED) {
        title = "Disable recurring topup";
        subtitle = "Any remaining topup-data may be used before the billing cycle ends";
    } else if (customerProfile.groupLines.some((customer) => customer.autoTopUpStatus === AutoTopUpStatus.ENABLED)) {
        title = "Manage recurring topup";
        subtitle =
            "Data packs are valid for the current billing cycle and will be charged automatically when the user meets their data limit";
    }

    const onSubmit = async (data: { customerAutoTopUpMap?: Record<string, boolean> }) => {
        let customerAutoTopUpMap: Record<string, boolean>;
        if (!data.customerAutoTopUpMap) {
            customerAutoTopUpMap = {};
            customerProfile.groupLines
                .filter((customer) => customer.status === CustomerStatus.ACTIVE)
                .forEach(
                    (customer) =>
                        (customerAutoTopUpMap[customer.id] =
                            customerProfile.autoTopUpStatus !== AutoTopUpStatus.ENABLED)
                );
        } else {
            customerAutoTopUpMap = data.customerAutoTopUpMap;
        }
        try {
            await unwrap(
                updateAutoTopup({
                    primaryId: customerProfile.id,
                    customerAutoTopUpMap,
                })
            );
            const names = customerProfile.groupLines
                .filter((customer) => customerAutoTopUpMap[customer.id] != null)
                .map((customer) => customer.firstName)
                .join(", ");

            await dispatch(fetchProfile(customerProfile.id));
            showSuccessModal(`Recurring topup settings changed for ${names}`);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        rest.onHide();
    };

    return (
        <Modal {...rest} title={title} titleClassName={classNames({ disabled: showCustomerVerification })}>
            <div
                className={classNames("w-100 d-flex flex-column align-items-center", {
                    disabled: showCustomerVerification,
                })}
                style={{ pointerEvents: showCustomerVerification ? "none" : undefined }}>
                <div className="font-family-semibold" style={{ fontSize: 18 }}>
                    {config.AUTO_TOP_UP_SIZE}GB (${config.AUTO_TOP_UP_AMOUNT.toFixed(2)})
                </div>
                <div className="font-family-semibold text-center my-3">{subtitle}</div>
                {customerProfile.reachPlan.isUnlimited && (
                    <>
                        {customerProfile.groupLines
                            .filter((customer) => customer.status === CustomerStatus.ACTIVE)
                            .map((customer, index) => (
                                <div key={customer.id} className="w-100">
                                    {index !== 0 && <div className="divider mb-2" />}
                                    <div className="d-flex justify-content-between align-items-center mb-2">
                                        <div>
                                            <div className="font-family-semibold">
                                                {customer.firstName}'s line{" "}
                                                {customer.isPrimary && (
                                                    <span
                                                        className="font-family-medium"
                                                        style={{
                                                            fontSize: 10,
                                                        }}>
                                                        (Primary account)
                                                    </span>
                                                )}
                                            </div>
                                            {customer.reachNumber && (
                                                <div
                                                    style={{
                                                        fontSize: 12,
                                                    }}>
                                                    {formatPhone(customer.reachNumber)}
                                                </div>
                                            )}
                                        </div>
                                        <Toggle
                                            className="pt-2"
                                            id={`customerAutoTopUpMap[${customer.id}]`}
                                            name={`customerAutoTopUpMap[${customer.id}]`}
                                            label=""
                                            register={register}
                                            defaultChecked={defaultMap[customer.id]}
                                        />
                                    </div>
                                </div>
                            ))}
                    </>
                )}
                <Button
                    color="secondary"
                    loading={formState.isSubmitting}
                    fullWidth
                    onClick={() => setShowCustomerVerification(true)}>
                    Confirm
                </Button>
            </div>

            {showCustomerVerification && (
                <CustomerVerification onSubmit={handleSubmit(onSubmit)} customerProfile={customerProfile} />
            )}
        </Modal>
    );
}
