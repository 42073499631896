import { createReachApiThunk, ReachCsAPI } from "../utils/reach-cs-api";
import { OpsEventsPayload, SearchCustomerReq } from "../utils/types";
import { Customer } from "../utils/types/customer";

export const getQRCCategories = createReachApiThunk("events/getQRCCategories", () => ReachCsAPI.getQRCCategories());

export const searchCustomers = createReachApiThunk("events/searchCustomers", async (payload: SearchCustomerReq) => {
    const res = await ReachCsAPI.searchCustomers(payload);
    return res;
});

export const addOpsEvents = createReachApiThunk(
    "events/addOpsEvents",
    async ({ payload, accountSrc }: { payload: OpsEventsPayload; accountSrc: string }) => {
        const res = await ReachCsAPI.addOpsEvents(payload, accountSrc);
        return res;
    }
);

export const createAccount = createReachApiThunk("purchase/createAccount", async (customer: Partial<Customer>) => {
    const res = await ReachCsAPI.createAccount(customer, process.env.MOCK_ACTIVATION ? "DATAMI" : "TELISPIRE");
    return res;
});
