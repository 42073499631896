import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import Layout from "../../components/layout";
import { useSelector } from "react-redux";
import { Link, navigate } from "gatsby";
import { additionalLineAdded, selectAdditionalLinesPurchase } from "../../features/additional-lines-purchase-slice";
import { Input } from "../../components/input";
import { Button } from "../../components/button";
import { useForm } from "react-hook-form";
import { RootState, useAppDispatch } from "../../utils/store";
import { Customer } from "../../utils/types/customer";
import { formatPhone, NAME_PATTERN } from "../../utils/helpers";
import { plansSelector } from "../../features/plans-slice";
import AddLineSummaryModal from "../../components/add-line-summary-modal";
import CustomerBadge from "../../components/customer-badge";

export type AddLinesProps = RouteComponentProps;

type FormData = Pick<Customer, "firstName" | "lastName">;

export default function AddLines(props: AddLinesProps) {
    const { customerProfile, groupLines, isSimInHand, reachPlan: selectedPlan, additionalCustomers } = useSelector(
        selectAdditionalLinesPurchase
    );

    const { handleSubmit, register, setValue, errors } = useForm<FormData>({
        mode: "onBlur",
    });

    const [showSummaryModal, setShowSummaryModal] = useState(false);

    console.log(customerProfile);

    const dispatch = useAppDispatch();

    if (!customerProfile) {
        navigate("/customers");
        return null;
    }

    const onSubmit = (data: FormData) => {
        dispatch(additionalLineAdded({ ...data, isPrimary: false, isMNP: false }));
        setValue("firstName", "");
        setValue("lastName", "");
    };

    let price = 0;
    if (selectedPlan!.addLineSplitPrice) {
        for (
            let i = customerProfile.additionalLines + 1;
            i <= customerProfile.additionalLines + additionalCustomers.length;
            i++
        ) {
            price = price + selectedPlan!.addLineSplitPrice[`${i}`];
        }
    } else {
        price = additionalCustomers.length * selectedPlan!.additionalLinePrice;
    }

    return (
        <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - Add lines`}>
            <div className="container col-10 d-flex flex-column my-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to={`/profile/${customerProfile.id}`}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>Add lines</h1>
                </div>
                <div className="divider" />

                <div className="mt-3 align-self-center" style={{ fontSize: 18 }}>
                    <div className="font-family-semibold">Additional lines: {additionalCustomers.length}</div>
                    <div className="font-family-bold text-center">${price}</div>
                </div>

                {groupLines.map((customer, index) => (
                    <div key={index} className="mt-3">
                        <div className="font-family-semibold">
                            {customer.firstName} {customer.lastName} <CustomerBadge isPrimary={customer.isPrimary} />
                        </div>
                        {customer.reachNumber && (
                            <div style={{ fontSize: 12 }}>{formatPhone(customer.reachNumber)}</div>
                        )}
                    </div>
                ))}

                {additionalCustomers.map((customer, index) => (
                    <div key={index} className="mt-3 font-family-semibold">
                        {customer.firstName} {customer.lastName} <CustomerBadge isPrimary={customer.isPrimary} />
                    </div>
                ))}

                {((isSimInHand && additionalCustomers.length === 0) ||
                    (!isSimInHand &&
                        customerProfile.additionalLines + additionalCustomers.length < selectedPlan!.maxLines - 1)) && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mt-3 mx-n2 align-items-start">
                            <Input
                                className="col px-2"
                                name="firstName"
                                register={register({
                                    required: {
                                        value: true,
                                        message: "First name is required",
                                    },
                                    pattern: {
                                        value: NAME_PATTERN,
                                        message: "Not a valid first name",
                                    },
                                })}
                                placeholder="First name"
                                showError={errors.firstName != null}
                                errorMessage={errors.firstName?.message}
                            />
                            <Input
                                className="col px-2"
                                name="lastName"
                                register={register({
                                    required: {
                                        value: true,
                                        message: "Last name is required",
                                    },
                                    pattern: {
                                        value: NAME_PATTERN,
                                        message: "Not a valid last name",
                                    },
                                })}
                                placeholder="Last name"
                                showError={errors.lastName != null}
                                errorMessage={errors.lastName?.message}
                            />
                            <Button color="secondary" mini icon="reach-cs-plus" className="mt-2" type="submit" />
                        </div>
                    </form>
                )}

                <Button
                    color="secondary"
                    disabled={additionalCustomers.length === 0}
                    className="align-self-center mt-3 col-12 col-md-6"
                    onClick={() => {
                        if (isSimInHand) {
                            setShowSummaryModal(true);
                        } else {
                            navigate(`/add-lines/number-preference/${0}`);
                        }
                    }}>
                    Proceed
                </Button>
            </div>

            <AddLineSummaryModal
                title="Review new montly statement"
                show={showSummaryModal}
                onHide={() => setShowSummaryModal(false)}
            />
        </Layout>
    );
}
