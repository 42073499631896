import React from "react";
import { Router } from "@reach/router";
import Login from "../screens/login-page";
import PrivateRoute from "../components/private-route";
import Dashboard from "../screens/dashboard-page";
import Dashboards from "../screens/dashboards/dashboards-page";
import CustomerList from "../screens/customer-list-page";
import CreateCustomer from "../screens/purchase/create-customer-page";

import SelectPlan from "../screens/purchase/select-plan-page";
import NumberPreference from "../screens/purchase/number-preference-page";
import DeviceCompatibility from "../screens/purchase/device-compatibility-page";
import Checkout from "../screens/purchase/checkout-page";
import CustomerProfile from "../screens/customer-profile-page";
import PurchaseComplete from "../screens/purchase/purchase-complete-page";
import ScanSIM from "../screens/activation/scan-sim-page";
import ActivateService from "../screens/activation/activate-service-page";
import Discounts from "../screens/discounts-page";
import CDRReports from "../screens/cdr-reports-page";
import AccountSwap, { AccountSwapFlow } from "../screens/account-swap-page";
import AddCredit from "../screens/add-credit-page";
import EditProfile from "../screens/edit-profile-page";
import Support from "../screens/support-page";
import Help from "../screens/help-page";
import IFrameLayout from "../screens/iframe-layout";
import ManageILD from "../screens/manage-ild-page";
import ILDUsage from "../screens/ild-usage-page";
import BuyILDCredits from "../screens/buy-ild-credits-page";
import IntlRates from "../screens/intl-rates-page";
import branding from "../branding/branding.json";
import { Helmet } from "react-helmet";
import BusinessDashboard from "../screens/dashboards/business-dashboard-page";
import CSDashboards from "../screens/dashboards/cs-dashboards-page";
import ManagerDashboards from "../screens/dashboards/manager-dashboards-page";
import MarketingDashboard from "../screens/dashboards/marketing-dashboard-page";
import CoverageCheck from "../screens/purchase/coverage-check-page";
import ToolsCoverageCheck from "../screens/coverage-check-page";
import ToolsDeviceCompatibility from "../screens/tools/device-compatibility-page";
import ToolsIntlRates from "../screens/tools/intl-rates-page";
import ToolsPortNumber from "../screens/tools/check-portability-page";
import ToolsScanSIM from "../screens/tools/scan-sim-page";
import Tools from "../screens/tools-page";
import AddLines from "../screens/secondary-purchase/add-lines-page";
import ShippingAddress from "../screens/secondary-purchase/shipping-address-page";
import AddLinesCheckout from "../screens/secondary-purchase/add-lines-checkout-page";
import AddLinesComplete from "../screens/secondary-purchase/add-lines-complete-page";
import ForgotPassword from "../screens/forgot-password-page";
import ResetPassword from "../screens/reset-password-page";
import BillCalculator from "../screens/tools/bill-calculator-page";
import LeavingReach from "../screens/leave-reach-page";
import LeavingReachSummary from "../screens/leave-reach-summary-page";
import ReconnectPort from "../screens/reconnect-port-page";
import PortOutDetails from "../screens/port-out-details-page";
import QrcPage from "../screens/qrc-page";
import PermissionDetails from "../screens/permission-details-page";
import UserList from "../screens/user-list-page";
import CreateUser from "../screens/create-user-page";
import ManageIR from "../screens/intl-roaming/manage-ir-page";
import IRPurchase from "../screens/intl-roaming/ir-purchase-page";
import IRPurchaseConfirm from "../screens/intl-roaming/ir-purchase-confirm-page";
import ManageIrTrip from "../screens/intl-roaming/manage-ir-trip-page";
import PlanHistory from "../screens/plan-history-page";
import AlertsHistory from "../screens/alerts-history-page";
import AccountHistory from "../screens/account-history-page";
import PromiseToPayHistory from "../screens/promise-to-pay-history-page";
import TicketSummary from "../screens/helpdesk/tickets-summary-page";
import TicketsHistory from "../screens/tickets-history";
import RolesList from "../screens/roles-list-page";
import RolePermissionDetails from "../screens/role-permission-details-page";
import CreateRole from "../screens/create-role-page";
import AllTickets from "../screens/all-tickets";
import HubspotTickets from "../screens/hubspot-tickets-page";
import HubspotTasks from "../screens/hubspot-tasks-page";
import HubspotConversations from "../screens/hubspot-coversations-page";
import { isBrowser, isReachSSOEnabled, isSSOEnabled } from "../utils/helpers";
import SsoLogin from "../screens/sso-login-page";

const App = () => {
    if (!isBrowser) {
        // Prevent incorrect rehydration
        return null;
    }

    return (
        <>
            <Helmet title={`${branding.shortName} Operations`} />
            <Router className="d-flex flex-column h-100">
                <PrivateRoute component={Dashboard} path="/" default />
                <PrivateRoute component={CustomerList} path="/customers" />
                <PrivateRoute component={UserList} path="/users" />
                <PrivateRoute component={CreateUser} path="/users/new" />
                <PrivateRoute component={RolesList} path="/roles" />
                <PrivateRoute component={CreateRole} path="/roles/new" />
                <PrivateRoute component={CreateCustomer} path="/purchase/new" />
                <PrivateRoute component={SelectPlan} path="/purchase/plans" />
                <PrivateRoute component={CoverageCheck} path="/purchase/coverage" />
                <PrivateRoute component={NumberPreference} path="/purchase/number-preference" />
                <PrivateRoute component={DeviceCompatibility} path="/purchase/imei" />
                <PrivateRoute component={Checkout} path="/purchase/checkout" />
                <PrivateRoute component={PurchaseComplete} path="/purchase/complete" />
                <PrivateRoute component={CustomerProfile} path="/profile/:customerId" />
                {/* <PrivateRoute component={ManageFeature} path="/manage-feature/:customerId" /> */}
                <PrivateRoute component={Discounts} path="/discounts/:customerId" />
                <PrivateRoute component={AddCredit} path="/add-credit/:customerId" />
                <PrivateRoute component={EditProfile} path="/edit-profile/:customerId" />
                <PrivateRoute component={AccountSwap} path="/account-swap/:customerId" />
                <PrivateRoute component={ReconnectPort} path="/reconnect-port/:customerId" />
                <PrivateRoute component={CDRReports} path="/cdr-reports/:customerId" />
                <PrivateRoute component={LeavingReach} path="/disconnections/:customerId" />
                <PrivateRoute component={LeavingReachSummary} path="/disconnections-summary/:customerId" />
                <PrivateRoute component={PortOutDetails} path="/port-out-details/:customerId" />
                <PrivateRoute
                    component={AccountSwap}
                    path="/disconnections/account-swap/:customerId"
                    flow={AccountSwapFlow.LEAVING_REACH}
                />
                <PrivateRoute component={ManageILD} path="/ild/manage/:customerId" />
                <PrivateRoute component={BuyILDCredits} path="/ild/purchase/:customerId" />
                <PrivateRoute component={ILDUsage} path="/ild/usage/:customerId" />
                <PrivateRoute component={IntlRates} path="/intl/rates/:customerId" />
                <PrivateRoute component={ScanSIM} path="/activation/scan-sim" />
                <PrivateRoute component={ActivateService} path="/activation" />
                <PrivateRoute component={Support} path="/support" />
                <PrivateRoute component={HubspotTickets} path="/hubspot-tickets" />
                <PrivateRoute component={HubspotTasks} path="/hubspot-tasks" />
                <PrivateRoute component={HubspotConversations} path="/hubspot-conversations" />
                <PrivateRoute component={Help} path="/help" />
                <PrivateRoute component={Dashboards} path="/dashboards" />
                <PrivateRoute component={BusinessDashboard} path="/business-dashboard" />
                <PrivateRoute component={MarketingDashboard} path="/marketing-dashboard" />
                <PrivateRoute component={CSDashboards} path="/cs-dashboards" />
                <PrivateRoute component={ManagerDashboards} path="/manager-dashboards" />
                <PrivateRoute component={IFrameLayout} path="/performance-dashboard" />
                <PrivateRoute component={Tools} path="/tools" />
                <PrivateRoute component={ToolsCoverageCheck} path="/tools/coverage" />
                <PrivateRoute component={ToolsDeviceCompatibility} path="/tools/imei" />
                <PrivateRoute component={ToolsIntlRates} path="/tools/intl-rates" />
                <PrivateRoute component={ToolsPortNumber} path="/tools/check-portability" />
                <PrivateRoute component={ToolsScanSIM} path="/tools/scan-sim" />
                <PrivateRoute component={BillCalculator} path="/tools/bill-calculator" />
                <PrivateRoute component={QrcPage} path="/customer-support/qrc" />
                <PrivateRoute
                    component={IFrameLayout}
                    url="https://app.hubspot.com/reports-dashboard/4888946/view/4466781"
                    path="/my-dashboard"
                />
                {/* <PrivateRoute
                    component={IFrameLayout}
                    url="https://app.hubspot.com/reports-dashboard/4888946/view/4858754"
                    path="/acquisitions"
                /> */}
                <PrivateRoute
                    component={IFrameLayout}
                    url="https://app.hubspot.com/email/4888946/analyze"
                    path="/email-analytics"
                />
                <PrivateRoute
                    component={IFrameLayout}
                    url="https://app.hubspot.com/ads/4888946/dashboard"
                    path="/digital-spends"
                />
                <PrivateRoute
                    component={IFrameLayout}
                    url="https://app.hubspot.com/reports-dashboard/4888946/view/4858801"
                    path="/chats-dashboard"
                />
                <PrivateRoute
                    component={IFrameLayout}
                    url="https://app.hubspot.com/reports-dashboard/4888946/view/4858828"
                    path="/tickets-dashboard"
                />
                <PrivateRoute
                    component={IFrameLayout}
                    url="https://app.hubspot.com/reports-dashboard/4888946/view/4858727"
                    path="/business-dashboard-2"
                />
                <PrivateRoute
                    component={IFrameLayout}
                    url="https://dev-es.reachmobile.com:5601/app/dashboards?auth_provider_hint=reachany#/view/20dceb60-91fb-11eb-a7ef-cdfb01fef1c2?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-1y%2Cto%3Anow))&show-time-filter=true"
                    path="/acquisition-dashboard"
                />
                <PrivateRoute
                    component={IFrameLayout}
                    url="https://dev-es.reachmobile.com:5601/app/dashboards?auth_provider_hint=reachany#/view/2ce81a40-9211-11eb-8f73-5926e452a0fa?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-1y%2Cto%3Anow))&show-time-filter=true"
                    path="/churn-dashboard"
                />
                <PrivateRoute
                    component={IFrameLayout}
                    url="https://dev-es.reachmobile.com:5601/app/dashboards?auth_provider_hint=reachany#/view/d87a6c50-92c0-11eb-987e-0785dfed3ea0?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-1y%2Cto%3Anow))&show-time-filter=true"
                    path="/collections-dashboard"
                />

                <PrivateRoute component={AddLines} path="/add-lines" />
                <PrivateRoute component={NumberPreference} path="/add-lines/number-preference/:index" />
                <PrivateRoute component={DeviceCompatibility} path="/add-lines/imei/:index" />
                <PrivateRoute component={ShippingAddress} path="/add-lines/shipping" />
                <PrivateRoute component={AddLinesCheckout} path="/add-lines/checkout" />
                <PrivateRoute component={AddLinesComplete} path="/add-lines/complete" />
                <PrivateRoute component={PermissionDetails} path="/permissions/:emailId" />
                <PrivateRoute component={RolePermissionDetails} path="/role/permissions" />
                <PrivateRoute component={RolePermissionDetails} path="/role/permissions/:roleId" />
                <PrivateRoute component={ManageIR} path="/ir/manage/:customerId" />
                <PrivateRoute component={ManageIrTrip} path="/ir/manage/:customerId/trip/:tripId" />
                <PrivateRoute component={IRPurchase} path="/ir/purchase/:customerId" />
                <PrivateRoute component={IRPurchaseConfirm} path="/ir/purchase/confirm/:customerId" />
                <PrivateRoute component={PlanHistory} path="/plan/history/:customerId" />
                <PrivateRoute component={AlertsHistory} path="/alerts/history/:customerId" />
                <PrivateRoute component={AccountHistory} path="/account/history/:customerId" />
                <PrivateRoute component={PromiseToPayHistory} path="/promisetopay/history/:customerId" />
                <PrivateRoute component={AllTickets} path="/all-tickets" />
                <PrivateRoute component={TicketsHistory} path="/tickets/:customerId" />
                <PrivateRoute component={TicketSummary} path="/tickets/:customerId/id/:ticketId" />

                {isSSOEnabled() || isReachSSOEnabled() ? <SsoLogin path="/login" /> : <Login path="/login" />}
                <ForgotPassword path="/forgot-password" />
                <ResetPassword path="/recover" />
            </Router>
        </>
    );
};

export default App;
