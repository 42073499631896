import React from "react";
import { Link, navigate } from "gatsby";
import Layout from "../../components/layout";
import { RouteComponentProps } from "@reach/router";
import { QuickActionCard } from "../../components/quick-action-card";
import image2 from "../../images/emailAnalytics@2x.png";
import image3 from "../../images/digitalSpends@2x.png";

export default function MarketingDashboard(props: RouteComponentProps) {
    return (
        <Layout title="Marketing Dashboard">
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <Link className="text-cta mb-3" to="/dashboards">
                    <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                    Back
                </Link>
                <div className="d-flex align-items-center justify-content-between">
                    <h1>Marketing Dashboard</h1>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="font-family-semibold my-3">Funnel Chart</div>
                        <div>
                            <img src="/funnelChart.svg" style={{ width: "100%", height: "100%" }} />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="font-family-semibold my-3">CPA</div>
                        <div>
                            <img src="/cpaChart.svg" style={{ width: "100%", height: "100%" }} />
                        </div>
                    </div>
                </div>

                <div className="row my-4 mx-3">
                    <div className="p-2 col-6 col-md-4">
                        <QuickActionCard
                            icon={image2}
                            title="Email Analytics"
                            onClick={() => {
                                navigate("/email-analytics");
                            }}
                        />
                    </div>
                    <div className="p-2 col-6 col-md-4">
                        <QuickActionCard
                            icon={image3}
                            title="Digital Spends"
                            onClick={() => {
                                navigate("/digital-spends");
                            }}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
}
