import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Link, navigate } from "gatsby";
import { Button } from "../../components/button";
import { Input } from "../../components/input";
import Layout from "../../components/layout";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useUnwrapAsyncThunk } from "../../utils/store";
import { checkZipCoverage, customerSelector, updateAccount } from "../../features/purchase-slice";
import { useSelector } from "react-redux";
import deviceCompatibleImg from "../../images/device-compatible.png";
import deviceInompatibleImg from "../../images/device-incompatible.png";
import branding from "../../branding/branding.json";
import { useErrorHandler } from "../../components/error-snackbar";
import { ZipcodeRes } from "../../utils/types";
import { toTitleCase } from "../../utils/helpers";
import CustomerBadge from "../../components/customer-badge";

export type CoverageCheckProps = RouteComponentProps;

interface FormData {
    zipcode: string;
}

export default function CoverageCheck(props: CoverageCheckProps) {
    const { errors, handleSubmit, control, formState, setError } = useForm<FormData>({
        mode: "onBlur",
    });
    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const customer = useSelector(customerSelector);

    const [zipRes, setZipRes] = useState<ZipcodeRes>();

    const onSubmit = async (data: FormData) => {
        try {
            const res = await unwrap(checkZipCoverage(data.zipcode));
            if (res.isValid) {
                await unwrap(
                    updateAccount({
                        id: customer.id!,
                        customer: {
                            zipcode: data.zipcode,
                        },
                    })
                );
                setZipRes(res);
            }
        } catch (error) {
            console.log(error);
            if (error.resCode === 40037) {
                setError("zipcode", { message: "Enter a valid zip code" });
            } else {
                handleError(error);
            }
        }
    };

    return (
        <Layout title={`${customer.firstName} ${customer.lastName} - Network Coverage`}>
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <Link className="text-cta mb-3" to="/purchase/plans">
                    <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                    Back
                </Link>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customer.firstName} {customer.lastName} <CustomerBadge isPrimary={customer.isPrimary} />
                    </div>
                    <div>{customer.emailId}</div>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>Network Coverage</h1>
                </div>
                <div className="divider" />

                {zipRes && (
                    <div className="row flex-column col-md-8 mx-auto mt-4 d-flex align-items-center">
                        <img src={deviceCompatibleImg} />
                        <div className="font-family-semibold text-center mt-2 mx-n3" style={{ fontSize: 15 }}>
                            {branding.shortName} covers {zipRes.CoveragePercent}% of {toTitleCase(zipRes.City)},{" "}
                            {zipRes.State}.
                        </div>
                        <Button
                            color="secondary"
                            fullWidth
                            className="mt-4 col-12 col-md-6"
                            onClick={() => navigate("/purchase/number-preference")}>
                            Next
                        </Button>
                    </div>
                )}

                {!zipRes && (
                    <form className="row d-flex flex-column align-items-center" onSubmit={handleSubmit(onSubmit)}>
                        <div className="font-family-semibold text-center mt-5">
                            Let's make sure you’re in our service area.
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <Controller
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Zipcode is required",
                                    },
                                    pattern: {
                                        value: /^\d{5}$/,
                                        message: "Zipcode must be 5 digits.",
                                    },
                                }}
                                name="zipcode"
                                defaultValue={customer.zipcode ?? ""}
                                render={({ onChange, onBlur, value, name }) => (
                                    <Input
                                        type="text"
                                        inputMode="numeric"
                                        placeholder="Enter zip code"
                                        className="my-3"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        name={name}
                                        maxLength={5}
                                        characterRestriction={5}
                                        showError={errors.zipcode != null}
                                        errorMessage={errors.zipcode?.message}
                                    />
                                )}
                            />

                            <Button color="secondary" fullWidth loading={formState.isSubmitting}>
                                Check zip code
                            </Button>
                        </div>
                        <a className="text-cta mt-3" onClick={() => navigate("/purchase/number-preference")}>
                            Skip for now
                        </a>
                    </form>
                )}
            </div>
        </Layout>
    );
}
