import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { billsSelector, currentBillSelector, getBillingHistory, paidBillsSelector } from "../features/billing-slice";
import { ReachCsAPI } from "../utils/reach-cs-api";
import { RootState, useUnwrapAsyncThunk } from "../utils/store";
import { BillingForecastItemType, CustomizedBill } from "../utils/types";
import { Bill } from "../utils/types/bill";
import { Button } from "./button";
import { Card } from "./card";
import { useErrorHandler } from "./error-snackbar";
import Modal from "./modal";
import classNames from "classnames";

export interface PaymentHistoryCardProps {
    customerId: string;
}

export function PaymentReceipt({ bill, setSelectedBill }: { bill: Bill; setSelectedBill: (bill?: Bill) => void }) {
    const [invoice, setInvoice] = useState<CustomizedBill>();
    const [invoiceSent, setInvoiceSent] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchBill() {
            try {
                const res = await ReachCsAPI.getCustomizedBills(bill.id);
                setInvoice(res);
            } catch (error) {
                console.log(error);
            }
        }
        fetchBill();
    }, []);

    if (!invoice) {
        return (
            <Modal
                title={bill.billPaid ? "Payment receipt" : "Invoice details"}
                size="sm"
                show={true}
                onHide={() => setSelectedBill(undefined)}>
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary" />
                </div>
            </Modal>
        );
    }

    const resendInvoice = async () => {
        const id = bill.id;

        setLoading(true);

        try {
            await ReachCsAPI.resendBill(id, false);

            setInvoiceSent(true);

            setTimeout(() => {
                setInvoiceSent(false);
            }, 5000);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    return (
        <Modal
            title={bill.billPaid ? "Payment receipt" : "Invoice details"}
            size="sm"
            show={true}
            onHide={() => setSelectedBill(undefined)}>
            <h1>${invoice.amountToPay.toFixed(2)}</h1>
            {invoice.billing.map((lineItem, index) => {
                const isNegative =
                    lineItem.type === BillingForecastItemType.CREDIT ||
                    lineItem.type === BillingForecastItemType.DISCOUNT ||
                    lineItem.type === BillingForecastItemType.REFERRAL;

                return (
                    <div key={index} className="w-100">
                        <div className="w-100 d-flex justify-content-between">
                            <div className="w-100">
                                <div className={classNames(isNegative ? "text-primary" : "font-family-semibold")}>
                                    {lineItem.title}{" "}
                                    {lineItem.prorated && (
                                        <span className="font-family-medium text-tertiary2">(Prorated)</span>
                                    )}
                                </div>
                                {lineItem.subtitle && (
                                    <div className="font-family-medium" style={{ fontSize: 12 }}>
                                        {lineItem.subtitle}
                                    </div>
                                )}
                                {lineItem.bestfit && (
                                    <div className="font-family-semibold text-primary" style={{ fontSize: 12 }}>
                                        Best Fit savings applied
                                    </div>
                                )}
                                {lineItem.details?.map((item, index) => {
                                    const isNegative =
                                        item.type === BillingForecastItemType.CREDIT ||
                                        item.type === BillingForecastItemType.DISCOUNT ||
                                        item.type === BillingForecastItemType.REFERRAL;

                                    return (
                                        <div className="w-100 d-flex justify-content-between" key={index}>
                                            <div key={index} className="font-family-medium" style={{ fontSize: 12 }}>
                                                {item.subtitle}
                                            </div>
                                            {item.amount && (
                                                <div
                                                    className={classNames("font-family-medium", {
                                                        "text-primary": isNegative,
                                                    })}
                                                    style={{ fontSize: 12 }}>
                                                    {isNegative && "-"}${item.amount.toFixed(2)}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                            {lineItem.amount && (
                                <div
                                    className={classNames("font-family-semibold", {
                                        "text-primary": isNegative,
                                    })}
                                    style={{ whiteSpace: "nowrap" }}>
                                    {lineItem.bestfit && lineItem.actualAmount && (
                                        <span style={{ textDecorationLine: "line-through" }}>
                                            ${lineItem.actualAmount.toFixed(2)}
                                        </span>
                                    )}
                                    {lineItem.bestfit && lineItem.actualAmount && " "}
                                    {isNegative && "-"}${lineItem.amount.toFixed(2)}
                                </div>
                            )}
                            {lineItem.rightText && (
                                <div className="font-family-semibold" style={{ whiteSpace: "nowrap" }}>
                                    {lineItem.rightText}
                                </div>
                            )}
                        </div>
                        <div className="divider my-2" />
                    </div>
                );
            })}
            {invoiceSent ? (
                <div className="font-family-semibold text-primary text-center">Invoice sent to {bill.primaryEmail}</div>
            ) : loading ? (
                <div className="spinner-border spinner-border-sm text-primary" />
            ) : (
                <div className="w-100 d-flex justify-content-around mt-2">
                    <div className="text-cta" onClick={() => window.open(invoice.invoiceUrl)}>
                        View invoice
                    </div>
                    <div className="text-cta" onClick={resendInvoice}>
                        Email statement
                    </div>
                </div>
            )}
        </Modal>
    );
}

function BillItem({
    bill,
    index,
    setSelectedBill,
}: {
    bill: Bill;
    index: number;
    setSelectedBill: (bill?: Bill) => void;
}) {
    return (
        <div key={bill.id} className="w-100">
            {index !== 0 && <div className="divider" />}
            <div className="w-100 d-flex justify-content-between my-2 align-items-center">
                <div className="font-family-semibold">
                    <div>{format(new Date(bill.billPaidDate), "MMMM d")}</div>
                    <div style={{ fontSize: 12 }}>{format(new Date(bill.billPaidDate), "hh:mm a")}</div>
                </div>

                <div className="font-family-semibold text-right">
                    {bill.bestFitSaveInDollar > 0 && (
                        <div className="font-family-medium text-tertiary5" style={{ fontSize: 12 }}>
                            ${bill.bestFitSaveInDollar} Best Fit savings
                        </div>
                    )}
                    <div>
                        {bill.bestFitSaveInDollar > 0 && (
                            <span style={{ textDecorationLine: "line-through", opacity: 0.5 }}>
                                ${(bill.amountToPay + bill.bestFitSaveInDollar).toFixed(2)}
                            </span>
                        )}{" "}
                        ${bill.billPaidAmount}
                    </div>
                    <div className="text-cta" onClick={() => setSelectedBill(bill)}>
                        View Invoice
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function PaymentHistoryCard(props: PaymentHistoryCardProps) {
    const { customerId } = props;

    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const paidBills = useSelector(paidBillsSelector(customerId));

    const [showModal, setShowModal] = useState(false);
    const [selectedBill, setSelectedBill] = useState<Bill>();

    useEffect(() => {
        async function fetchBills() {
            try {
                await unwrap(getBillingHistory(customerId));
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
        fetchBills();
    }, []);

    if (!paidBills || paidBills.length === 0) {
        return null;
    }

    const firstPageBills = paidBills.slice(0, 3);

    return (
        <Card className="mt-3">
            <div className="font-family-bold align-self-start" style={{ fontSize: 16 }}>
                Payment history
            </div>
            {firstPageBills.map((bill, index) => (
                <BillItem key={bill.id} bill={bill} index={index} setSelectedBill={setSelectedBill} />
            ))}
            <Button color="secondary" className="col-10" onClick={() => setShowModal(true)}>
                View more
            </Button>
            <Modal title="Payment history" show={showModal} onHide={() => setShowModal(false)}>
                {paidBills.map((bill, index) => (
                    <BillItem
                        key={bill.id}
                        bill={bill}
                        index={index}
                        setSelectedBill={(bill) => {
                            setShowModal(false);
                            setSelectedBill(bill);
                        }}
                    />
                ))}
            </Modal>
            {selectedBill && <PaymentReceipt bill={selectedBill} setSelectedBill={setSelectedBill} />}
        </Card>
    );
}
