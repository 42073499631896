import React, { ReactNode, useEffect, useState } from "react";
import classNames from "classnames";

export interface CounterProps {
    minValue: number;
    maxValue: number;
    defaultValue: number;
    className?: string;
    onChange?: (value: number) => void;
    render?: (value: number) => ReactNode;
    step?: number;
    disabledProps?: boolean;
}

export default function Counter(props: CounterProps) {
    const { minValue, maxValue, defaultValue, className, disabledProps, onChange, render, step = 1 } = props;
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    return (
        <div className={className}>
            <button
                type="button"
                className={classNames("counter-btn", {
                    disabled: value === minValue,
                })}
                disabled={value === minValue || disabledProps}
                onClick={() => {
                    setValue(value - step);
                    onChange?.(value - step);
                }}>
                {"-"}
            </button>
            <span className="mx-3">{render ? render(value) : value}</span>
            <button
                type="button"
                className={classNames("counter-btn", {
                    disabled: value === maxValue,
                })}
                disabled={value === maxValue || disabledProps}
                onClick={() => {
                    setValue(value + step);
                    onChange?.(value + step);
                }}>
                {"+"}
            </button>
        </div>
    );
}
