import React, { useState } from "react";
import { BillingForecastItemType, CouponType, CustomerProfile } from "../utils/types";
import { CustomerStatus } from "../utils/types/customer";
import { Button } from "./button";
import { Card } from "./card";
import Modal from "./modal";
import classNames from "classnames";
import CouponInput from "./coupon-input";
import { ReachCsAPI } from "../utils/reach-cs-api";
import { fetchProfile } from "../features/profile-slice";
import { useAppDispatch } from "../utils/store";

export type EstimateBillCardProps = CustomerProfile;

export default function EstimateBillCard(props: EstimateBillCardProps) {
    const { ...customerProfile } = props;
    const [showEstimateBill, setShowEstimateBill] = useState(false);
    const dispatch = useAppDispatch();

    if (!customerProfile.estimatedBill) {
        return null;
    }

    return (
        <Card className="mt-3">
            <div className="font-family-bold align-self-start" style={{ fontSize: 16 }}>
                Estimate charges
            </div>
            {customerProfile.estimatedBill.billing?.[0]?.subtitle && (
                <div className="font-family-semibold align-self-start" style={{ fontSize: 12 }}>
                    {customerProfile.estimatedBill.billing[0].subtitle}
                </div>
            )}
            <h5 className="mt-3">${customerProfile.estimatedBill.amountToPay.toFixed(2)}</h5>
            <Button color="secondary" className="col-10" onClick={() => setShowEstimateBill(true)}>
                View Details
            </Button>
            {customerProfile && (
                <Modal
                    title={customerProfile.status === CustomerStatus.INACTIVE ? "Summary" : "Estimated monthly bill"}
                    show={showEstimateBill}
                    onHide={() => setShowEstimateBill(false)}>
                    {customerProfile.estimatedBill.billing.map((lineItem, index) => {
                        const isNegative =
                            lineItem.type === BillingForecastItemType.CREDIT ||
                            lineItem.type === BillingForecastItemType.DISCOUNT ||
                            lineItem.type === BillingForecastItemType.REFERRAL;

                        return (
                            <div key={index} className="w-100 d-flex justify-content-between mb-3">
                                <div className="w-100">
                                    <div className={classNames(isNegative ? "text-primary" : "font-family-bold")}>
                                        {lineItem.title}{" "}
                                        {lineItem.prorated && (
                                            <span className="font-family-medium text-tertiary2">(Prorated)</span>
                                        )}
                                    </div>
                                    {lineItem.subtitle && (
                                        <div className="font-family-semibold" style={{ fontSize: 12 }}>
                                            {lineItem.subtitle}
                                        </div>
                                    )}
                                    {lineItem.details?.map((item, index) => {
                                        const isNegative =
                                            item.type === BillingForecastItemType.CREDIT ||
                                            item.type === BillingForecastItemType.DISCOUNT ||
                                            item.type === BillingForecastItemType.REFERRAL;

                                        return (
                                            <div className="w-100 d-flex justify-content-between" key={index}>
                                                <div
                                                    key={index}
                                                    className="font-family-semibold"
                                                    style={{ fontSize: 12 }}>
                                                    {item.subtitle}
                                                </div>
                                                {item.amount && (
                                                    <div
                                                        className={classNames("font-family-semibold", {
                                                            "text-primary": isNegative,
                                                        })}
                                                        style={{ fontSize: 12 }}>
                                                        {isNegative && "-"}${item.amount.toFixed(2)}
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                                {lineItem.amount && (
                                    <div className={classNames("font-family-bold", { "text-primary": isNegative })}>
                                        {isNegative && "-"}${lineItem.amount.toFixed(2)}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    <div className="w-100 d-flex justify-content-between">
                        <div className="font-family-bold">Total</div>
                        <div className="font-family-bold">${customerProfile.estimatedBill?.amountToPay.toFixed(2)}</div>
                    </div>

                    <div className="w-100 mt-3">
                        <CouponInput
                            coupon={customerProfile.estimatedBill?.coupon}
                            onCouponChange={async (couponCode) => {
                                const coupon = await ReachCsAPI.getCoupon(couponCode);
                                if (coupon.expired) {
                                    return undefined;
                                }
                                if (coupon.type === CouponType.PROMO) {
                                    await ReachCsAPI.applyCoupon(customerProfile.id, coupon.coupon);
                                } else if (coupon.type === CouponType.REFERRAL) {
                                    if (process.env.ENABLE_IR === "true") {
                                        await ReachCsAPI.trackIR(
                                            customerProfile.id,
                                            customerProfile.firstName,
                                            customerProfile.emailId,
                                            coupon.coupon,
                                            process.env.INVITE_CAMPAIGN_ID
                                        );
                                    }
                                }
                                await dispatch(fetchProfile(customerProfile.id));
                                return coupon;
                            }}
                            removable={
                                customerProfile.status === CustomerStatus.INACTIVE &&
                                (!customerProfile.estimatedBill?.coupon ||
                                    customerProfile.estimatedBill.coupon.type === CouponType.PROMO)
                            }
                        />
                    </div>
                </Modal>
            )}
        </Card>
    );
}
