import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { useAppDispatch, useAppSelector, useUnwrapAsyncThunk } from "../../utils/store";
import { fetchProfile, profilesSelector } from "../../features/profile-slice";
import {
    deactivateTrip,
    getIntlAvailableCredits,
    getIntlOverallDataUsage,
    getIntlTrips,
    selectTripById,
    updateTrip,
} from "../../features/intl-roaming-slice";
import { Link, navigate } from "gatsby";
import Layout from "../../components/layout";
import CustomerBadge from "../../components/customer-badge";
import { format } from "date-fns";
import Modal from "../../components/modal";
import { formatPhone, getUTCDate } from "../../utils/helpers";
import Counter from "../../components/counter";
import { Button } from "../../components/button";
import { intlCountriesSelector } from "../../features/intl-countries-slice";
import { Input } from "../../components/input";
import { configSelector } from "../../features/config-slice";
import { AutoDeactivationStatusReq, IRDataUsageRes, TripDeactivationReason, TripStatus } from "../../utils/types";
import { useErrorHandler } from "../../components/error-snackbar";
import { Controller, useForm } from "react-hook-form";
import {
    activateTrip,
    cancelTrip,
    getTroubleshootIRStatus,
    purchaseCredit,
    troubleshootIR,
    updateAutoDeactivationStatus,
} from "../../features/ir-purchase-slice";
import { useSuccessModal } from "../../components/success-modal";
import { getClosestSize } from "../../utils/data-conversion";
import Select from "../../components/select";
import { OperationsInProcess } from "../../utils/types/customer";
import { usePermissions } from "../../features/hooks/use-permissions";
import { TripFilter } from "./manage-ir-page";
import { Checkbox } from "../../components/checkbox";
import { Card } from "../../components/card";
import { useSelector } from "react-redux";

interface ManageIrTripProps extends RouteComponentProps {
    customerId: string;
    tripId: string;
}

interface FormData {
    tentativeStartDate?: Date;
    skipInfo?: AutoDeactivationStatusReq;
    userCancelReason?: string;
    userCancelReasonText?: string;
}

const deactivationMsgMap: Record<TripDeactivationReason, string> = {
    TRIGGERED_BY_USER: "Triggered by user",
    LOCAL_CDR: "Local CDR received",
    PAYGO_CDR: "Paygo CDR received",
    DATA_CAPPING: "Data cap reached",
    NO_IR_CDR: "No IR CDR",
    IR_PACK_UPGRADE: "IR pack upgrade",
    BILL_DUE: "Bill due",
    ROAMING_ALREADY_ENABLED: "Roaming already enabled",
    INACTIVE_CUSTOMER: "Inactive customer",
    FAILED_BY_MVNE: "Failed by mvne",
    NO_PACK_INFO: "No pack info",
    BACKEND_ERROR: "Backend error",
    INVALID_TRIP_STATE: "Invalid trip state",
    TRIGGERED_BY_CS: "Triggered by CS",
    REMOVE_THROTTLE_FAILED: "Remove throttle failed",
    DISCONNECT_CUSTOMER: "Disconnect customer",
    PORTED_OUT: "Ported out",
    AVAILABLE_CREDIT_IS_LESS_THAN_MIN_CREDIT: "Available credit is less than min credit",
};

export default function ManageIrTrip(props: ManageIrTripProps) {
    const { customerId, tripId } = props;

    const customerProfile = useAppSelector((state) => profilesSelector.selectById(state, customerId));

    const trip = useAppSelector(selectTripById(customerProfile?.groupId ?? "", tripId));
    const { countryRes } =
        useAppSelector((state) => intlCountriesSelector.selectById(state, customerProfile?.source ?? "")) ?? {};
    const { intlPopularDestinations } = useAppSelector(configSelector);

    const [dataCap, setDataCap] = useState(trip?.dataCap ?? 0);
    const [countriesMap, setCountriesMap] = useState(trip?.countriesMap ?? {});
    const [showDataCapModal, setShowDataCapModal] = useState(false);
    const [showDestinationModal, setShowDestinationModal] = useState(false);
    const [showTroubleshootingModal, setShowTroubleshootingModal] = useState(false);
    const [showCancellationModal, setShowCancellationModal] = useState(false);
    const [countrySearch, setCountrySearch] = useState("");
    const [isSaveVisible, setIsSaveVisible] = useState(false);
    const [availableCredits, setAvailableCredits] = useState(0);
    const [showReasonField, setReasonField] = useState(false);
    const [overallUsage, setOverallUsage] = useState<IRDataUsageRes>();
    const [loading, setLoading] = useState(false);

    const { handleSubmit, register, formState, watch, control, errors, reset } = useForm<FormData>();

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();

    const { getLimitations, hasOnePermission } = usePermissions();

    const limitations = getLimitations("user_management.intl_roaming.cancel");

    useEffect(() => {
        async function fetchIntlUsage() {
            try {
                const credits = await unwrap(getIntlAvailableCredits(trip!.customerId!));
                const res = await unwrap(getIntlOverallDataUsage(tripId));
                setOverallUsage(res);
                setAvailableCredits(credits?.availableCredit ?? 0);
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
        if (trip?.status !== TripStatus.INACTIVE) {
            fetchIntlUsage();
        }
        fetchIRTrips();
    }, [trip?.status]);

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    if (!trip) {
        navigate(`/ir/manage/${customerId}`);
        return null;
    }

    const pricePerPulse = Math.max(...Object.values(countriesMap).map((country) => country?.pricePerPulse ?? 0));

    const dataPulse = countryRes?.dataPulse ?? 0;
    const maxNoOfPulses = countryRes?.maxNoOfPulses ?? 0;
    const countries = countryRes?.countriesList;
    const { provConfig } = useSelector(configSelector);

    const customer = customerProfile.groupLines.find((customer) => customer.id === trip.customerId)!;

    // console.log("DD", trip);

    const title =
        trip.status === TripStatus.COMPLETED || trip.status === TripStatus.CANCELLED
            ? "Estimated roaming bill"
            : `Manage ${customer.firstName}'s trip`;

    const destinations = Object.values(countriesMap);

    const startDate = watch("tentativeStartDate", new Date(trip.startDate ?? trip.tentativeStartDate!));

    async function fetchIRTrips() {
        if (customerProfile) {
            try {
                await unwrap(getIntlTrips(customerProfile.groupId));
            } catch (error) {
                console.log(error);
                if (error.resCode !== 40193) {
                    handleError(error);
                }
            }
        }
    }

    const onSave = async (data: FormData) => {
        try {
            if (data.skipInfo) {
                await unwrap(
                    updateAutoDeactivationStatus({
                        tripId,
                        payload: data.skipInfo,
                    })
                );
            }
            await unwrap(
                updateTrip({
                    tripId,
                    trip: {
                        countriesList: Object.keys(countriesMap),
                        dataCap: dataCap !== trip.dataCap ? dataCap : undefined,
                        tentativeStartDate: data.tentativeStartDate
                            ? format(getUTCDate(data.tentativeStartDate), "yyyy-MM-dd")
                            : undefined,
                        customerId: trip.customerId,
                        primaryCustomerId: trip.primaryCustomerId,
                    },
                })
            );
            await fetchIRTrips();
            showSuccessModal("Trip updated successfully");
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        setIsSaveVisible(false);
    };

    const onDeactivateTrip = async (data: FormData) => {
        try {
            await unwrap(deactivateTrip(tripId));
            await fetchIRTrips();
            showSuccessModal("Trip deactivated successfully");
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const onActivateTrip = async (data: FormData) => {
        try {
            if (availableCredits <= provConfig.IR_MIN_CREDIT_AMOUNT) {
                const customerCreditMap: Record<string, number> = {};
                const minCredit = trip.minimumCreditsToBePurchased || 0;
                console.log("Min Credits", minCredit);
                customerCreditMap[trip.customerId!] = minCredit - availableCredits;
                await unwrap(
                    purchaseCredit({
                        primaryId: customerProfile.id,
                        customerCreditMap,
                        ccUUID: customerProfile.creditCards![0].uuid,
                        creditType: "IR",
                        totalAmount: minCredit - availableCredits,
                        enableFeature: false,
                    })
                );
            }
            await unwrap(activateTrip(tripId));
            await fetchIRTrips();
            showSuccessModal("Trip activated successfully");
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const onTroubleshooting = async () => {
        try {
            await unwrap(troubleshootIR(customerId));
            await dispatch(fetchProfile(customerId));
            await fetchIRTrips();
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        setShowTroubleshootingModal(false);
    };

    const onCancel = async (data: FormData) => {
        try {
            await unwrap(cancelTrip({ customerId, tripId, userCancelReason: data.userCancelReason! }));
            await dispatch(fetchProfile(customerId));
            await fetchIRTrips();
            showSuccessModal(
                trip.status === TripStatus.INACTIVE ? "Trip cancelled successfully" : "Trip ended successfully"
            );
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        setShowCancellationModal(false);
    };

    const refreshIRTroubleshootStatus = async () => {
        setLoading(true);
        try {
            const res = await unwrap(getTroubleshootIRStatus(customerProfile.id));
            if (res.status === "SUCCEEDED") {
                await dispatch(fetchProfile(customerProfile.id));
                showSuccessModal("International roaming reapplied successful");
            }
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        setLoading(false);
    };

    const data = getClosestSize(overallUsage?.overall?.zoneUsage?.data?.usage ?? 0);
    const dataUsage = `${data.size}${data.unit}`;

    const voiceUsage =
        (overallUsage?.overall?.zoneUsage?.voiceIn?.usage ?? 0) +
        (overallUsage?.overall?.zoneUsage?.voiceOut?.usage ?? 0);

    const isTroubleshootInProgress =
        customerProfile.operationsInProcess?.some((operation) => operation === OperationsInProcess.IR_TROUBLESHOOT) ??
        false;

    const tripStatus =
        trip.status === TripStatus.ACTIVATED ||
        trip.status === TripStatus.DEACTIVATED ||
        trip.status === TripStatus.ACTIVATION_FAILED
            ? TripFilter.Current
            : trip.status === TripStatus.INACTIVE
            ? TripFilter.Upcoming
            : TripFilter.Past;

    return (
        <Layout title={title}>
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to={`/ir/manage/${customerId}?status=${tripStatus}`}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>{title}</h1>
                    <div className="font-family-semibold">
                        {trip.activationInProgress && (
                            <div>
                                <div className="spinner-border spinner-border-sm text-primary mr-2" />
                                <span className="text-primary">In Progress</span>
                            </div>
                        )}
                        {!trip.activationInProgress && trip.status === TripStatus.ACTIVATED && (
                            <div className="text-primary">Active</div>
                        )}
                        {!trip.activationInProgress && trip.status === TripStatus.COMPLETED && (
                            <div className="text-primary">Trip completed</div>
                        )}
                        {!trip.activationInProgress && trip.status === TripStatus.CANCELLED && (
                            <div className="text-primary">Trip cancelled</div>
                        )}
                        {!trip.activationInProgress && trip.status === TripStatus.INACTIVE && (
                            <div>
                                {!trip.prepaid || (trip.availableCredits && trip.availableCredits > 0)
                                    ? "Trip scheduled"
                                    : "Draft"}
                            </div>
                        )}
                        {!trip.activationInProgress &&
                            trip.status === TripStatus.DEACTIVATED &&
                            trip.deactivationReason &&
                            !trip.endedReason && <div className="text-error">Temporarily deactivated</div>}
                        {!trip.activationInProgress &&
                            trip.status === TripStatus.ACTIVATION_FAILED &&
                            trip.activationFailureReason && <div className="text-error">Temporarily deactivated</div>}
                    </div>
                </div>
                <div className="divider mb-3" />

                {trip.status === TripStatus.DEACTIVATED && (
                    <Card className="mb-3">
                        <div className="font-family-bold">International Roaming temporarily deactivated</div>
                        {trip.irDisabledDates && (
                            <div className="font-family-semibold mt-1 text-center">
                                International Roaming has been temporarily deactivated (on{" "}
                                {format(
                                    new Date(trip.irDisabledDates[trip.irDisabledDates.length - 1]),
                                    "MMMM dd, yyyy, hh:mm a"
                                )}
                                )
                            </div>
                        )}
                        {trip.deactivationReason && (
                            <div className="font-family-semibold mt-1 text-center">
                                Reason: {deactivationMsgMap[trip.deactivationReason]}
                            </div>
                        )}
                    </Card>
                )}

                <div className="d-flex align-items-center">
                    <div className="reach-cs-pin mr-3" style={{ fontSize: 24 }} />
                    <div>
                        <div className="font-family-bold">
                            Destinations ({trip.countriesList?.length}){" "}
                            {!isTroubleshootInProgress &&
                                !trip.activationInProgress &&
                                hasOnePermission("user_management.intl_roaming.update") &&
                                trip.status === TripStatus.INACTIVE && (
                                    <span className="text-cta ml-3" onClick={() => setShowDestinationModal(true)}>
                                        Edit
                                    </span>
                                )}
                        </div>
                        <div className="font-family-semibold">
                            {Object.values(countriesMap)
                                .map((country) => country.countryName)
                                .join(";  ")}
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                    <div className="reach-cs-calendar mr-3" />
                    <div>
                        <div className="font-family-bold position-relative">
                            Departure date
                            {!isTroubleshootInProgress &&
                                !trip.activationInProgress &&
                                hasOnePermission("user_management.intl_roaming.update") &&
                                trip.status === TripStatus.INACTIVE && (
                                    <span className="ml-3">
                                        <Controller
                                            control={control}
                                            name="tentativeStartDate"
                                            defaultValue={startDate}
                                            render={({ name, onChange, ref, value, onBlur }) => (
                                                <input
                                                    type="date"
                                                    min={format(new Date(), "yyyy-MM-dd")}
                                                    className="datepicker-input"
                                                    name={name}
                                                    onBlur={onBlur}
                                                    onChange={(event) => {
                                                        setIsSaveVisible(true);
                                                        onChange(event.target.valueAsDate);
                                                    }}
                                                    ref={ref}
                                                    value={format(value, "yyyy-MM-dd")}
                                                />
                                            )}
                                        />
                                        <span className="text-cta">Edit</span>
                                    </span>
                                )}
                        </div>
                        {startDate && <div className="font-family-semibold">{format(startDate, "MMMM dd, yyyy")}</div>}
                    </div>
                </div>
                <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex align-items-center">
                        <div className="reach-cs-user mr-3" style={{ fontSize: 18 }} />
                        <div className="font-family-bold">Travelers</div>
                    </div>
                    <div className="font-family-bold">Data usage limit</div>
                </div>
                <div className="d-flex justify-content-between mt-3">
                    <div className="font-family-bold">
                        {customer.firstName} {customer.lastName}
                    </div>
                    <div className="font-family-bold">{dataCap.toFixed(2)}GB</div>
                </div>
                {hasOnePermission("user_management.intl_roaming.update") && (
                    <>
                        {trip.status !== TripStatus.COMPLETED && trip.status !== TripStatus.CANCELLED && (
                            <div className="mt-2 text-cta" onClick={() => setShowDataCapModal(true)}>
                                Manage data usage limit
                            </div>
                        )}
                    </>
                )}

                {trip.status !== TripStatus.INACTIVE &&
                    (overallUsage == null ? (
                        <div className="spinner-border text-primary mt-3 align-self-center" />
                    ) : (
                        <div className="d-flex flex-column">
                            <div className="divider mt-3" />
                            <div className="d-flex mt-3">
                                <div className="col px-0 font-family-bold">Usage details</div>
                                <div className="col px-0 font-family-bold">Usage</div>
                                <div className="col-1 px-0 text-right font-family-bold">Credit used</div>
                            </div>

                            <div className="d-flex mt-2">
                                <div className="col px-0 font-family-semibold">
                                    <span className="reach-cs-globe mr-1" />
                                    Data
                                </div>
                                <div className="col px-0 font-family-semibold">{dataUsage}</div>
                                <div className="col-1 px-0 text-right font-family-semibold">
                                    ${(overallUsage.overall?.zoneUsage?.data?.cost || 0).toFixed(2)}
                                </div>
                            </div>
                            <div className="d-flex mt-2">
                                <div className="col px-0 font-family-semibold">
                                    <span className="reach-cs-phone mr-1" />
                                    Voice
                                </div>
                                <div className="col px-0 font-family-semibold">
                                    {voiceUsage > 0 ? `${voiceUsage.toFixed(2)} Min` : 0}
                                </div>
                                <div className="col-1 px-0 text-right font-family-semibold">
                                    $
                                    {(
                                        (overallUsage.overall?.zoneUsage?.voiceIn?.cost ?? 0) +
                                        (overallUsage.overall?.zoneUsage?.voiceOut?.cost ?? 0)
                                    ).toFixed(2)}
                                </div>
                            </div>
                            <div className="d-flex mt-2">
                                <div className="col px-0 font-family-semibold">
                                    <span className="reach-cs-sms-in mr-1" />
                                    SMS In
                                </div>
                                <div className="col px-0 font-family-semibold">
                                    {overallUsage.overall?.zoneUsage?.smsIn?.usage?.toFixed(2) || 0}
                                </div>
                                <div className="col-1 px-0 text-right font-family-semibold">
                                    ${(overallUsage.overall?.zoneUsage?.smsIn?.cost || 0).toFixed(2)}
                                </div>
                            </div>
                            <div className="d-flex mt-2">
                                <div className="col px-0 font-family-semibold">
                                    <span className="reach-cs-sms-out mr-1" />
                                    SMS Out
                                </div>
                                <div className="col px-0 font-family-semibold">
                                    {overallUsage.overall?.zoneUsage?.smsOut?.usage?.toFixed(2) || 0}
                                </div>
                                <div className="col-1 px-0 text-right font-family-semibold">
                                    ${(overallUsage.overall?.zoneUsage?.smsOut?.cost || 0).toFixed(2)}
                                </div>
                            </div>
                            <div className="divider mt-3" />

                            {Object.entries(overallUsage.overall?.paygoUsage ?? {}).map(([countryName, paygoUsage]) => {
                                const data = getClosestSize(paygoUsage?.data?.usage ?? 0);
                                const dataUsage = `${data.size}${data.unit}`;

                                const voiceUsage =
                                    (paygoUsage?.voiceIn?.usage ?? 0) + (paygoUsage?.voiceOut?.usage ?? 0);
                                return (
                                    <div key={countryName} className="divider pb-3 mt-3">
                                        <div className="font-family-semibold">
                                            {countryName}{" "}
                                            <span className="text-tertiary2">* Pay as you go charges</span>
                                        </div>
                                        <div className="d-flex mt-2">
                                            <div className="col px-0 font-family-semibold">
                                                <span className="reach-cs-globe mr-1" />
                                                Data
                                            </div>
                                            <div className="col px-0 font-family-semibold">{dataUsage}</div>
                                            <div className="col-1 px-0 text-right font-family-semibold">
                                                ${paygoUsage?.data?.cost?.toFixed(2) || 0}
                                            </div>
                                        </div>
                                        <div className="d-flex mt-2">
                                            <div className="col px-0 font-family-semibold">
                                                <span className="reach-cs-phone mr-1" />
                                                Voice
                                            </div>
                                            <div className="col px-0 font-family-semibold">
                                                {voiceUsage > 0 ? `${voiceUsage.toFixed(2)} Min` : 0}
                                            </div>
                                            <div className="col-1 px-0 text-right font-family-semibold">
                                                $
                                                {(
                                                    (paygoUsage?.voiceIn?.cost ?? 0) + (paygoUsage?.voiceOut?.cost ?? 0)
                                                ).toFixed(2)}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ))}

                <div className="d-flex justify-content-between mt-2">
                    <div className="font-family-semibold col-6 px-0">
                        {trip.status === TripStatus.COMPLETED || trip.status === TripStatus.CANCELLED
                            ? "Note: This is only an estimate. It does not include taxes or fees. Actual voice and text costs will be applied to your bill once final usage details are received from carrier(s)."
                            : "Note: This is only an estimate, please refer invoice for the details."}
                    </div>
                    <div className="text-right">
                        {trip.carryForwardedCredit != null && trip.carryForwardedCredit > 0 && (
                            <div className="font-family-bold">
                                Credit brought forward: ${trip.carryForwardedCredit.toFixed(2)}
                            </div>
                        )}
                        {trip.totalPurchasedCredit != null && trip.totalPurchasedCredit > 0 && (
                            <div className="font-family-bold">
                                Credit purchased: ${trip.totalPurchasedCredit.toFixed(2)}
                            </div>
                        )}
                        {overallUsage && (
                            <div className="font-family-bold">
                                Used credit: ${(overallUsage.overall?.totalCost ?? 0).toFixed(2)}
                            </div>
                        )}
                        {availableCredits >= 0 && (
                            <div className="font-family-bold">Available credit: ${availableCredits.toFixed(2)}</div>
                        )}
                        {availableCredits < 0 && (
                            <div className="font-family-bold">
                                Outstanding Balance: ${(availableCredits * -1).toFixed(2)}
                            </div>
                        )}
                        {trip.adjustedCredits != null && trip.adjustedCredits > 0 && (
                            <div className="font-family-bold">Adjusted credits: ${trip.adjustedCredits.toFixed(2)}</div>
                        )}
                    </div>
                </div>

                {!isTroubleshootInProgress &&
                    trip.status !== TripStatus.COMPLETED &&
                    trip.status !== TripStatus.CANCELLED &&
                    !trip.activationInProgress && (
                        <>
                            <div className="font-family-bold mt-3">Skip auto-deactivation</div>
                            <Checkbox
                                id="skipInfo.deactivationByLocalCDR"
                                name="skipInfo.deactivationByLocalCDR"
                                label="Do not deactivate the trip for local country"
                                className="mt-2"
                                defaultChecked={trip.skipInfo?.deactivationByLocalCDR ?? false}
                                register={register}
                                small
                                onClick={() => setIsSaveVisible(true)}
                            />
                            <Checkbox
                                id="skipInfo.deactivationByPaygoCDR"
                                name="skipInfo.deactivationByPaygoCDR"
                                label="Do not deactivate the trip for Paygo country"
                                className="mt-2"
                                defaultChecked={trip.skipInfo?.deactivationByPaygoCDR ?? false}
                                register={register}
                                small
                                onClick={() => setIsSaveVisible(true)}
                            />
                            <Checkbox
                                id="skipInfo.deactivationByNoCDR"
                                name="skipInfo.deactivationByNoCDR"
                                label="Do not deactivate the trip if IR CDR is not received"
                                className="mt-2"
                                defaultChecked={trip.skipInfo?.deactivationByNoCDR ?? false}
                                register={register}
                                small
                                onClick={() => setIsSaveVisible(true)}
                            />
                            <Checkbox
                                id="skipInfo.cancellationByNoCDR"
                                name="skipInfo.cancellationByNoCDR"
                                label="Do not cancel the trip if IR CDR is not received"
                                className="mt-2"
                                defaultChecked={trip.skipInfo?.cancellationByNoCDR ?? false}
                                register={register}
                                small
                                onClick={() => setIsSaveVisible(true)}
                            />
                        </>
                    )}

                {isSaveVisible && (
                    <Button
                        color="secondary"
                        onClick={handleSubmit(onSave)}
                        loading={formState.isSubmitting}
                        className="col-12 col-md-4 col-xl-2 align-self-center mt-3">
                        Save
                    </Button>
                )}

                {isSaveVisible && (
                    <div
                        onClick={() => {
                            reset({
                                skipInfo: {
                                    deactivationByLocalCDR: trip.skipInfo?.deactivationByLocalCDR ?? false,
                                    deactivationByPaygoCDR: trip.skipInfo?.deactivationByPaygoCDR ?? false,
                                    deactivationByNoCDR: trip.skipInfo?.deactivationByNoCDR ?? false,
                                    cancellationByNoCDR: trip.skipInfo?.cancellationByNoCDR ?? false,
                                },
                                tentativeStartDate: new Date(trip.startDate ?? trip.tentativeStartDate!),
                            });
                            setIsSaveVisible(false);
                            setDataCap(trip?.dataCap ?? 0);
                            setCountriesMap(trip?.countriesMap ?? {});
                        }}
                        className="text-cta align-self-center mt-3">
                        Cancel
                    </div>
                )}

                {!isTroubleshootInProgress &&
                    !isSaveVisible &&
                    trip.status === TripStatus.ACTIVATED &&
                    hasOnePermission("user_management.intl_roaming.cancel") &&
                    !trip.activationInProgress && (
                        <Button
                            color="secondary"
                            loading={formState.isSubmitting}
                            // disabled={!hasOnePermission("user_management.intl_roaming.cancel")}
                            onClick={handleSubmit(onDeactivateTrip)}
                            className="col-12 col-md-4 col-xl-2 align-self-center mt-3">
                            Deactivate trip
                        </Button>
                    )}

                {!isTroubleshootInProgress &&
                    !isSaveVisible &&
                    trip.status !== TripStatus.ACTIVATED &&
                    hasOnePermission("user_management.intl_roaming.activate") &&
                    !trip.activationInProgress && (
                        <Button
                            color="secondary"
                            loading={formState.isSubmitting}
                            onClick={handleSubmit(onActivateTrip)}
                            className="col-12 col-md-4 col-xl-2 align-self-center mt-3">
                            {trip.status === TripStatus.INACTIVE
                                ? "Activate trip"
                                : availableCredits <= provConfig.IR_MIN_CREDIT_AMOUNT
                                ? "Purchase Credits & Re-activate trip"
                                : "Re-activate trip"}
                        </Button>
                    )}

                {!isTroubleshootInProgress &&
                    !isSaveVisible &&
                    trip.status === TripStatus.ACTIVATED &&
                    !trip.activationInProgress && (
                        <div
                            className="text-cta align-self-center mt-3"
                            onClick={() => setShowTroubleshootingModal(true)}>
                            Reset international roaming
                        </div>
                    )}

                {!isTroubleshootInProgress &&
                    !isSaveVisible &&
                    trip.status !== TripStatus.COMPLETED &&
                    trip.status !== TripStatus.CANCELLED &&
                    hasOnePermission("user_management.intl_roaming.cancel") &&
                    !trip.activationInProgress && (
                        <div className="text-cta align-self-center mt-3" onClick={() => setShowCancellationModal(true)}>
                            {trip.status === TripStatus.INACTIVE ? "Cancel trip" : "End trip"}
                        </div>
                    )}

                {isTroubleshootInProgress && (
                    <div className="align-self-center mt-3 font-family-semibold text-tertiary5">
                        Reapplying international roaming{" "}
                        {loading ? (
                            <span className="spinner-border spinner-border-sm ml-2 mb-1 text-primary" />
                        ) : (
                            <span
                                className="reach-cs-refresh cursor-pointer text-secondary ml-2"
                                onClick={refreshIRTroubleshootStatus}
                            />
                        )}
                    </div>
                )}
            </div>

            <Modal title="Set data usage limit" show={showDataCapModal} onHide={() => setShowDataCapModal(false)}>
                <div className="mt-2 divider d-flex justify-content-between pb-2">
                    <div className="font-family-bold">Travelers</div>
                    <div className="font-family-bold">Data usage limit</div>
                </div>
                <div className="w-100 mt-3 d-flex align-items-center justify-content-between">
                    <div className="font-family-semibold">
                        <span className="font-family-bold">
                            {customer.firstName} {customer.lastName}
                        </span>{" "}
                        {formatPhone(customer.reachNumber)}
                    </div>
                    <Counter
                        defaultValue={dataCap}
                        minValue={dataPulse}
                        maxValue={dataPulse * maxNoOfPulses}
                        step={dataPulse}
                        render={(value) => <span>{value.toFixed(2)}GB</span>}
                        onChange={(value) => setDataCap(value)}
                    />
                </div>
                <div className="align-self-end mt-3 font-family-semibold">
                    Estimated data cost:{" "}
                    <span className="font-family-bold">${((dataCap * pricePerPulse) / dataPulse).toFixed(2)}</span> (
                    {dataPulse}GB/ ${pricePerPulse.toFixed(2)})
                </div>

                <div className="my-3 text-center">
                    The service (data, voice, text) is automatically paused once you reach the data usage limit.
                </div>

                <Button
                    color="secondary"
                    fullWidth
                    onClick={() => {
                        setIsSaveVisible(true);
                        setShowDataCapModal(false);
                    }}>
                    Save
                </Button>
            </Modal>

            <Modal
                title="Select destinations"
                size="sm"
                show={showDestinationModal}
                onHide={() => setShowDestinationModal(false)}>
                <div className="w-100 align-items-start">
                    <Input
                        type="text"
                        placeholder="Search destination(s)"
                        value={countrySearch}
                        onChange={({ target: { value } }) => setCountrySearch(value)}
                        icon="reach-cs-search"
                        iconPosition="right"
                    />
                    {countrySearch === "" ? (
                        destinations && destinations.length > 0 ? (
                            <>
                                <div className="font-family-bold mt-3">Destinations</div>
                                {destinations
                                    .map((destCountry) =>
                                        countries?.find(
                                            (country) =>
                                                country.countryCode === destCountry.countryCode &&
                                                country.status === 1 &&
                                                country.zone !== "PAYGO"
                                        )
                                    )
                                    .filter((country) => !!country)
                                    .map((country) => (
                                        <div
                                            key={country!.countryCode}
                                            className="font-family-semibold mt-1 d-flex justify-content-between">
                                            <div>{country!.countryName}</div>
                                            <div
                                                className="reach-cs-cross cursor-pointer"
                                                style={{ fontSize: 12 }}
                                                onClick={() => {
                                                    setCountriesMap((countryMap) => {
                                                        const clone = { ...countryMap };
                                                        delete clone[country!.countryCode];
                                                        return clone;
                                                    });
                                                }}
                                            />
                                        </div>
                                    ))}
                            </>
                        ) : (
                            <>
                                <div className="font-family-bold mt-3">Popular destinations</div>
                                {intlPopularDestinations
                                    .map((code) =>
                                        countries?.find(
                                            (country) =>
                                                country.countryCode === code &&
                                                country.status === 1 &&
                                                country.zone !== "PAYGO"
                                        )
                                    )
                                    .filter((country) => !!country)
                                    .map((country) => (
                                        <div
                                            key={country!.countryCode}
                                            className="font-family-semibold mt-1 cursor-pointer"
                                            onClick={() => {
                                                setCountriesMap({ ...countriesMap, [country!.countryCode]: country! });
                                                setCountrySearch("");
                                            }}>
                                            {country!.countryName}
                                        </div>
                                    ))}
                            </>
                        )
                    ) : (
                        <div className="overflow-auto mt-3" style={{ maxHeight: 200 }}>
                            {countries
                                ?.filter((country) => {
                                    return (
                                        country.countryName
                                            .toLowerCase()
                                            .includes(countrySearch.trim().toLowerCase()) &&
                                        (country.status === 1 || country.ildStatus === 1) &&
                                        country.zone !== "PAYGO"
                                    );
                                })
                                .map((country) => (
                                    <div
                                        key={country!.countryCode}
                                        className="font-family-semibold mt-1 cursor-pointer"
                                        onClick={() => {
                                            setCountriesMap({ ...countriesMap, [country!.countryCode]: country! });
                                            setCountrySearch("");
                                        }}>
                                        {country!.countryName}
                                    </div>
                                ))}
                        </div>
                    )}
                    <Button
                        color="secondary"
                        className="mt-3"
                        fullWidth
                        onClick={() => {
                            setIsSaveVisible(true);
                            setShowDestinationModal(false);
                        }}>
                        Done
                    </Button>
                </div>
            </Modal>

            <Modal
                title="Reapply international roaming"
                size="sm"
                show={showTroubleshootingModal}
                onHide={() => setShowTroubleshootingModal(false)}>
                <div className="font-family-semibold text-center">
                    Once you click confirm, the International Roaming feature will be removed and added again to the
                    phone number.
                </div>
                <Button
                    color="secondary"
                    className="mt-3"
                    onClick={handleSubmit(onTroubleshooting)}
                    loading={formState.isSubmitting}
                    fullWidth>
                    Confirm
                </Button>
            </Modal>

            <Modal
                title={trip.status === TripStatus.INACTIVE ? "Cancel trip" : "End trip"}
                size="sm"
                show={showCancellationModal}
                onHide={() => setShowCancellationModal(false)}>
                <form className="w-100" onSubmit={handleSubmit(onCancel)}>
                    <Controller
                        defaultValue=""
                        name="userCancelReason"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Reason must be selected",
                            },
                        }}
                        render={({ onChange, onBlur, value, name }) => (
                            <Select
                                onChange={(event) => {
                                    onChange(event.target.value);
                                    setReasonField(event.target.value === "Other");
                                }}
                                onBlur={onBlur}
                                value={value}
                                name={name}
                                className="col my-2 px-0"
                                showError={errors.userCancelReason != null}
                                errorMessage={errors.userCancelReason?.message}>
                                <option value="">Select carrier</option>
                                {limitations?.descriptions.value.map((value) => (
                                    <option value={value} key={value}>
                                        {value}
                                    </option>
                                ))}
                            </Select>
                        )}
                    />
                    {showReasonField && (
                        <Input
                            type="text"
                            placeholder="Enter reason"
                            className="col my-2 px-0"
                            name="userCancelReasonText"
                            defaultValue=""
                            register={register({
                                required: {
                                    value: true,
                                    message: "Reason is required",
                                },
                            })}
                            showError={errors.userCancelReasonText != null}
                            errorMessage={errors.userCancelReasonText?.message}
                        />
                    )}
                    <Button color="secondary" className="mt-3" loading={formState.isSubmitting} fullWidth>
                        Confirm
                    </Button>
                </form>
            </Modal>
        </Layout>
    );
}
