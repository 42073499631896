import { compareDesc, format } from "date-fns";
import React, { useEffect, useState } from "react";
import { getGroupReferralCredits } from "../features/profile-slice";
import { useUnwrapAsyncThunk } from "../utils/store";
import {
    CustomerProfile,
    ReferralAnalytics,
    ReferralCustomers,
    ReferralDetail,
    ActivatedReferralDetail,
} from "../utils/types";
import { useErrorHandler } from "./error-snackbar";
import Modal, { ModalProps } from "./modal";
import branding from "../branding/branding.json";

export interface ReferralCreditModalProps extends ModalProps {
    customerProfile: CustomerProfile;
}

function mergeReferralData(customers: Record<string, ReferralCustomers>) {
    const inviteAccepted = [];
    const signedUp = [];
    const activated = [];

    for (const cId in customers) {
        if (customers.hasOwnProperty(cId)) {
            const customerReferralData = customers[cId];

            if (customerReferralData.inviteAccepted) {
                inviteAccepted.push(...customerReferralData.inviteAccepted);
            }

            if (customerReferralData.signedUp) {
                signedUp.push(...customerReferralData.signedUp);
            }

            if (customerReferralData.activated) {
                activated.push(...customerReferralData.activated);
            }
        }
    }

    return {
        inviteAccepted,
        signedUp,
        activated,
    };
}

function getPendingReferrals(
    inviteAccepted: ReferralDetail[],
    signedUp: ReferralDetail[],
    activated: ReferralDetail[]
) {
    const filteredAccepted = inviteAccepted
        .filter((user) => !signedUp.some((u) => user.emailId === u.emailId))
        .map((user) => ({
            ...user,
            type: "inviteAccepted",
        }));

    const filteredSignedUp = signedUp
        .filter((user) => !activated.some((u) => user.emailId === u.emailId))
        .map((user) => ({
            ...user,
            type: "signedUp",
        }));

    return [...filteredAccepted, ...filteredSignedUp].sort((a, b) => compareDesc(new Date(a.date), new Date(b.date)));
}

export default function ReferralCreditModal(props: ReferralCreditModalProps) {
    const { customerProfile, ...rest } = props;

    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const [res, setReferralRes] = useState<ReferralAnalytics>();

    useEffect(() => {
        async function getReferrals() {
            try {
                const res = await unwrap(getGroupReferralCredits(customerProfile.groupId));
                setReferralRes(res);
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
        getReferrals();
    }, []);

    if (!res) {
        return (
            <Modal {...rest} size="sm">
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary" />
                </div>
            </Modal>
        );
    }

    if (Object.keys(res).length === 0) {
        return (
            <Modal {...rest} size="sm">
                No referral details available
            </Modal>
        );
    }

    const totalCredits = res.discountInDollar.total || 0;
    const availableCredits = res.discountInDollar.remaining || 0;

    const { inviteAccepted, signedUp, activated } = mergeReferralData(res.customers);

    let activatedReferrals: ActivatedReferralDetail[] = [];
    if (activated) {
        activatedReferrals = activated.sort((a, b) => compareDesc(new Date(a.date), new Date(b.date)));
    }

    const maxDiscInDollar = res.referralMaxDiscInDollar;
    let referrerDiscInDollar = Number.MAX_VALUE;
    let refereeDiscInDollar = Number.MAX_VALUE;
    if (res.referrerDiscInDollar) {
        referrerDiscInDollar = res.referrerDiscInDollar;
    }
    if (res.refereeDiscInDollar) {
        refereeDiscInDollar = res.refereeDiscInDollar;
    }

    const pendingReferrals = getPendingReferrals(inviteAccepted, signedUp, activated);

    let pendingCost = 0;
    if (referrerDiscInDollar !== Number.MAX_VALUE) {
        pendingCost = referrerDiscInDollar;
    } else {
        const { baseLinePrice, discountPctg } = customerProfile.reachPlan;
        const primaryPrice = ((100 - discountPctg) * baseLinePrice) / 100;
        pendingCost = Math.min(primaryPrice, maxDiscInDollar);
    }

    return (
        <Modal {...rest} size="sm">
            {totalCredits > 0 && (
                <>
                    <div className="font-family-bold">Available credit</div>
                    <div className="font-family-semibold text-center">Here's what's left of your credits</div>
                    <div className="font-family-bold" style={{ fontSize: 21 }}>
                        ${availableCredits}
                    </div>
                    <div className="divider my-3"></div>
                    <div className="font-family-bold">Earned credit</div>
                    <div className="font-family-semibold text-center">
                        All the credits you've earned from sharing {branding.shortName}
                    </div>
                    <div className="font-family-bold" style={{ fontSize: 21 }}>
                        ${totalCredits}
                    </div>
                    <div className="divider my-3"></div>
                </>
            )}
            {activatedReferrals.length > 0 && (
                <>
                    <div className="font-family-bold">Activated referrals</div>
                    {activatedReferrals.map((item) => (
                        <div key={item.emailId} className="w-100 d-flex mt-2" style={{ fontSize: 12 }}>
                            <div className="font-family-bold flex-fill">{item.name}</div>
                            <div>{format(new Date(item.date), "MMM dd, yyyy")}</div>
                            <div className="font-family-bold ml-2">${item.maxDiscInDollar}</div>
                        </div>
                    ))}
                    {pendingReferrals.length > 0 && <div className="divider my-3"></div>}
                </>
            )}
            {pendingReferrals.length > 0 && (
                <>
                    <div className="font-family-bold">Pending credit</div>
                    {pendingReferrals.map((item, index) => (
                        <div key={index} className="w-100 d-flex mt-3" style={{ fontSize: 12 }}>
                            <div>
                                <div className="font-family-semibold flex-fill">
                                    {item.name}{" "}
                                    {item.type === "inviteAccepted"
                                        ? "accepted your invite but hasn’t picked a plan yet"
                                        : "accepted your invite but hasn’t activated service yet"}
                                </div>
                                {/* <div className="text-cta">Send a reminder</div> */}
                            </div>
                            <div className="text-right">
                                <div className="font-family-bold ml-2">${pendingCost}</div>
                                <div>Pending</div>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </Modal>
    );
}
