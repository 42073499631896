import { useAppDispatch, useAppSelector, useUnwrapAsyncThunk } from "../../utils/store";
import { ImeiRes, MNPValidateRes } from "../../utils/types";
import { Customer, MnpInfo } from "../../utils/types/customer";
import {
    additionalLineUpdated,
    selectAdditionalLinesPurchase,
    submitMNPValidate as submitAddLinesMNPValidate,
    validateMNP as validateAddLinesMNP,
} from "../additional-lines-purchase-slice";
import {
    customerSelector,
    submitMNPValidate as submitPurchaseMNPValidate,
    updateAccount,
    updateHubspot,
    validateMNP as validatePurchaseMNP,
} from "../purchase-slice";

interface NumberPreferenceMethods {
    customer: Partial<Customer>;
    isNextLine?: boolean;
    submitMNPValidate: typeof submitPurchaseMNPValidate | typeof submitAddLinesMNPValidate;
    validateMNP: typeof validatePurchaseMNP | typeof validateAddLinesMNP;
    onPortValid: (mnpInfo: MnpInfo) => Promise<void>;
    onNewNumber: () => Promise<void>;
    onPortInvalid?: (mnpInfo: MnpInfo, res: MNPValidateRes) => Promise<void>;
}

function usePurchaseNumberPreference(): NumberPreferenceMethods {
    const customer = useAppSelector(customerSelector);
    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();

    const onPortValid = async (mnpInfo: MnpInfo) => {
        await unwrap(
            updateAccount({
                id: customer.id!,
                customer: {
                    isMNP: true,
                    mnpInfo,
                },
            })
        );
    };

    const onPortInvalid = async (mnpInfo: MnpInfo, res: MNPValidateRes) => {
        await dispatch(
            updateHubspot({
                email: customer.emailId!,
                old_carrier: mnpInfo.operator,
                legacy_carrier: mnpInfo.operator,
                phone: mnpInfo.oldNumber,
                app_number_portable_failure: res.message,
            })
        );
    };

    const onNewNumber = async () => {
        await unwrap(
            updateAccount({
                id: customer.id!,
                customer: {
                    isMNP: false,
                },
            })
        );
    };

    return {
        customer,
        onPortValid,
        onPortInvalid,
        onNewNumber,
        submitMNPValidate: submitPurchaseMNPValidate,
        validateMNP: validatePurchaseMNP,
    };
}

function useAddLinesNumberPreference(index = 0): NumberPreferenceMethods {
    const { additionalCustomers } = useAppSelector(selectAdditionalLinesPurchase);
    const dispatch = useAppDispatch();
    const customer = additionalCustomers[index];
    const isNextLine = index < additionalCustomers.length - 1;

    const onPortValid = async (mnpInfo: MnpInfo) => {
        dispatch(
            additionalLineUpdated({
                index,
                customer: {
                    isMNP: true,
                    mnpInfo,
                },
            })
        );
    };

    const onNewNumber = async () => {
        await dispatch(
            additionalLineUpdated({
                index,
                customer: {
                    isMNP: false,
                },
            })
        );
    };

    return {
        customer,
        isNextLine,
        onPortValid,
        onNewNumber,
        submitMNPValidate: submitAddLinesMNPValidate,
        validateMNP: validateAddLinesMNP,
    };
}

export function useNumberPreference(index?: number) {
    const purchaseMethods = usePurchaseNumberPreference();
    const addLinesMethods = useAddLinesNumberPreference(index);

    // if index is not present, it is the initial purchase flow
    if (index == null) {
        return purchaseMethods;
    }

    return addLinesMethods;
}
