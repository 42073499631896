import React from "react";
import { Card, CardProps } from "./card";
import classNames from "classnames";

export interface QuickActionCardProps extends CardProps {
    icon: string;
    title: string;
    disabled?: boolean;
    className?: string;
}

export const QuickActionCard = (props: QuickActionCardProps) => {
    const { icon, title, className, ...rest } = props;
    return (
        <Card {...rest} className={classNames("quick-action-card p-2", className)} style={{ height: 145 }}>
            <img src={icon} className="mt-2" style={{ height: 66, width: 64, objectFit: "contain" }} />
            <h3 className="mt-2 mb-0 text-center">{title}</h3>
        </Card>
    );
};
