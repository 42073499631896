import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import Layout from "../../components/layout";
import { Link } from "gatsby";
import { Button } from "../../components/button";
import { Input } from "../../components/input";
import { Controller, useForm } from "react-hook-form";
import { useUnwrapAsyncThunk } from "../../utils/store";
import { useErrorHandler } from "../../components/error-snackbar";
import { validateIccIdByMvne } from "../../features/profile-slice";
import Modal from "../../components/modal";
import tickIcon from "../../images/tick-icon.png";
import cancelIcon from "../../images/cancel-icon.png";

type ScanSIMProps = RouteComponentProps;

interface FormData {
    simId: string;
}

export default function ScanSIM(props: ScanSIMProps) {
    const { handleSubmit, control, errors, formState, setError, reset } = useForm<FormData>({
        mode: "onBlur",
    });
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const [isValid, setValid] = useState<boolean>();

    const onSubmit = async (data: { simId: string }) => {
        try {
            const res = await unwrap(
                validateIccIdByMvne({ iccId: data.simId, mvne: process.env.MOCK_ACTIVATION ? "DATAMI" : "TELISPIRE" })
            );
            if (res.isValid) {
                setValid(true);
            } else {
                setValid(false);
                setError("simId", { message: "Invalid Sim Id" });
            }
        } catch (error) {
            console.log(error);
            if (error.resCode === 40155) {
                setValid(false);
                setError("simId", { message: "Invalid Sim Id" });
            } else {
                handleError(error);
            }
        }
        reset({
            simId: "",
        });
    };

    return (
        <Layout title="Tools - SIM checker">
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to="/tools">
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>SIM checker</h1>
                </div>
                <div className="divider" />

                <form className="d-flex flex-column align-items-center mt-3" onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "ICCID is required",
                            },
                            pattern: {
                                value: /^\d{20}$/,
                                message: "ICCID must be 20 digits.",
                            },
                        }}
                        name="simId"
                        defaultValue=""
                        render={({ onChange, onBlur, value, name }) => (
                            <Input
                                type="text"
                                inputMode="numeric"
                                placeholder="Enter ICCID"
                                className="col-12 col-md-8 col-xl-4 px-0"
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                name={name}
                                maxLength={20}
                                characterRestriction={20}
                                showError={errors.simId != null}
                                errorMessage={errors.simId?.message}
                            />
                        )}
                    />
                    <Button
                        color="secondary"
                        type="submit"
                        loading={formState.isSubmitting}
                        className="col-12 col-md-8 col-xl-4 my-3">
                        Check
                    </Button>
                </form>
            </div>
            <Modal
                title={isValid ? "ICCID is valid" : "ICCID is not valid"}
                show={isValid != null}
                size="sm"
                onHide={() => setValid(undefined)}>
                {isValid ? (
                    <img src={tickIcon} className="d-flex flex-column align-items-center mb-3" />
                ) : (
                    <img src={cancelIcon} className="d-flex flex-column align-items-center mb-3" />
                )}
                <Button
                    color="secondary"
                    fullWidth
                    onClick={() => {
                        setValid(undefined);
                    }}>
                    Try another SIM ID
                </Button>
            </Modal>
        </Layout>
    );
}
