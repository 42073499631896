import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import Layout from "../../components/layout";
import { Link, navigate } from "gatsby";
import TabGroup, { Tab } from "../../components/tab";
import simCardImage from "../../images/sim-card-image.png";
import { Button } from "../../components/button";
import { Input } from "../../components/input";
import Select from "../../components/select";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useAppDispatch, useUnwrapAsyncThunk } from "../../utils/store";
import { configSelector } from "../../features/config-slice";
import { useErrorHandler } from "../../components/error-snackbar";
import CustomerBadge from "../../components/customer-badge";
import { useNumberPreference } from "../../features/hooks/use-number-preference";

export interface NumberPreferenceProps extends RouteComponentProps {
    index?: string;
}
interface FormData {
    oldNumber: string;
    operator: string;
    operatorText: string;
}

function PortNumber({ index }: { index?: number }) {
    const { register, handleSubmit, errors, formState, setError, control } = useForm({
        mode: "onBlur",
    });
    const { customer, submitMNPValidate, validateMNP, onPortValid, onPortInvalid } = useNumberPreference(index);
    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const { operators } = useSelector(configSelector);
    const [showOperatorField, setOperatorField] = useState(false);

    const onSubmit = async (data: FormData) => {
        const mnpInfo = {
            oldNumber: data.oldNumber,
            operator: data.operator === "Other" ? data.operatorText : data.operator,
        };
        try {
            const refNumber = await unwrap(submitMNPValidate(mnpInfo.oldNumber));
            const res = await unwrap(validateMNP(refNumber));

            if (res.res.isPortValidate) {
                await onPortValid(mnpInfo);
                navigate(index == null ? "/purchase/imei" : `/add-lines/imei/${index}`);
            } else {
                await onPortInvalid?.(mnpInfo, res.res);
                setError("oldNumber", { message: "Cannot port given number" });
            }
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    return (
        <form className="col-12" onSubmit={handleSubmit(onSubmit)}>
            <Controller
                control={control}
                name="oldNumber"
                rules={{
                    required: {
                        value: true,
                        message: "Phone number is required",
                    },
                    pattern: {
                        value: /^\d{10}$/,
                        message: "Phone number should be 10 digits long",
                    },
                }}
                defaultValue={customer.mnpInfo?.oldNumber || customer.primaryNumber || ""}
                render={({ onChange, onBlur, value, name }) => (
                    <Input
                        type="text"
                        inputMode="numeric"
                        placeholder="Current phone number"
                        className="mt-4"
                        maxLength={10}
                        mask="phone"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                        showError={errors.oldNumber}
                        errorMessage={errors.oldNumber?.message}
                    />
                )}
            />
            <Controller
                defaultValue={customer.mnpInfo?.operator || ""}
                name="operator"
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: "Operator must be selected",
                    },
                }}
                render={({ onChange, onBlur, value, name }) => (
                    <Select
                        onChange={(event) => {
                            console.log(event.target.value);
                            onChange(event.target.value);
                            setOperatorField(event.target.value === "Other");
                        }}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                        className="my-4"
                        showError={errors.operator}
                        errorMessage={errors.operator?.message}>
                        <option value="">Select carrier</option>
                        {operators.map((operator) => (
                            <option key={operator.value} value={operator.value}>
                                {operator.displayName}
                            </option>
                        ))}
                    </Select>
                )}
            />
            {showOperatorField && (
                <Input
                    type="text"
                    placeholder="Enter operator name"
                    className="my-4"
                    name="operatorText"
                    defaultValue=""
                    register={register({
                        required: {
                            value: true,
                            message: "Operator is required",
                        },
                        pattern: {
                            value: /^[a-zA-Z0-9 &]+$/,
                            message: "Invalid carrier name",
                        },
                    })}
                    showError={errors.operatorText}
                    errorMessage={errors.operatorText?.message}
                />
            )}

            <Button color="secondary" fullWidth loading={formState.isSubmitting}>
                Next
            </Button>
        </form>
    );
}

export default function NumberPreference(props: NumberPreferenceProps) {
    const handleError = useErrorHandler();

    const [activeIndex, setActiveIndex] = useState(0);
    const [loading, setLoading] = useState(false);

    const index = props.index ? parseInt(props.index, 10) : undefined;

    const { customer, onNewNumber } = useNumberPreference(index);

    if (!customer.firstName) {
        navigate("/customers");
        return null;
    }

    return (
        <Layout title={`${customer.firstName} ${customer.lastName} - Number Preference`}>
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <Link className="text-cta mb-3" to={index == null ? "/purchase/coverage" : "/add-lines"}>
                    <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                    Back
                </Link>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customer.firstName} {customer.lastName} <CustomerBadge isPrimary={customer.isPrimary} />
                    </div>
                    <div>{customer.emailId}</div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>Number Preference</h1>
                </div>
                <div className="divider" />

                <TabGroup
                    className="row mx-auto mt-4 col-12 col-md-8 col-xl-6 px-0"
                    defaultValue={0}
                    onChange={setActiveIndex}>
                    <Tab>Use current number</Tab>
                    <Tab>Get a new number</Tab>
                </TabGroup>

                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-xl-4">
                        {activeIndex === 0 ? (
                            <PortNumber index={index} />
                        ) : (
                            <div className="d-flex flex-column align-items-center">
                                <img src={simCardImage} className="mt-4" />
                                <div className="font-family-semibold text-center mb-5">
                                    Change is good thing!
                                    <br /> We’ll reserve a new number for you
                                </div>
                                <Button
                                    color="secondary"
                                    fullWidth
                                    loading={loading}
                                    onClick={async () => {
                                        setLoading(true);
                                        try {
                                            await onNewNumber();
                                        } catch (error) {
                                            console.log(error);
                                            handleError(error);
                                        }
                                        navigate(index == null ? "/purchase/imei" : `/add-lines/imei/${index}`);
                                        setLoading(false);
                                    }}>
                                    Next
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
}
