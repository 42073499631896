import React from "react";
import { useForm } from "react-hook-form";
import { fetchProfile, resetNetwork } from "../features/profile-slice";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile } from "../utils/types";
import { Button } from "./button";
import { useErrorHandler } from "./error-snackbar";
import { Input } from "./input";
import Modal, { ModalProps } from "./modal";
import { useSuccessModal } from "./success-modal";

export interface NetworkResetModalProps extends ModalProps {
    customerProfile: CustomerProfile;
}

export default function NetworkResetModal(props: NetworkResetModalProps) {
    const { customerProfile, ...rest } = props;

    const { control, handleSubmit, formState, errors } = useForm({ mode: "onBlur" });

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();

    const onSubmit = async () => {
        try {
            await unwrap(resetNetwork(customerProfile.id));
            await dispatch(fetchProfile(customerProfile.id));
            rest.onHide();
            window.scrollTo(0, 0);
            showSuccessModal("Network reset request received. Please click on refresh button to get the latest status");
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    return (
        <Modal {...rest} size="sm">
            <Button color="secondary" fullWidth loading={formState.isSubmitting} onClick={handleSubmit(onSubmit)}>
                Confirm
            </Button>
        </Modal>
    );
}
