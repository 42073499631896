import React, { ReactNode, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { Button } from "../components/button";
import { Checkbox } from "../components/checkbox";
import { useErrorHandler } from "../components/error-snackbar";
import { Input } from "../components/input";
import MultiDropdownInput from "../components/multi-dropdown-input";
import PageLayout from "../components/page-layout";
import Toggle from "../components/toggle";
import { useRolePermissions } from "../features/hooks/use-role-permissions";
import { createRole, getRoleByRoleId, rolesSelector, updateRole } from "../features/operation-roles-slice";
import { getRolesByEmailId, selectUserInfo } from "../features/user-role-slice";
import { ReachCsAPI } from "../utils/reach-cs-api";
import { useAppDispatch, useAppSelector, useUnwrapAsyncThunk } from "../utils/store";
import { PermissionsRes } from "../utils/types";
import { RolesReq, RolesRes, UserRolesRes } from "../utils/types/user-roles";
import { useViewport, ViewportBreakpoint } from "../utils/viewport-context";
import CreateRole from "./create-role-page";
import { navigate } from "gatsby";

export interface RolePermissionDetailsProps extends RouteComponentProps {
    roleId?: string;
}

export default function RolePermissionDetails(props: RolePermissionDetailsProps) {
    const [allPermissions, setAllPermissions] = useState<PermissionsRes>();
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const { emailId, roleMap } = useAppSelector(selectUserInfo);
    const { width } = useViewport();

    const searchParams = new URLSearchParams(location.search);
    const roleName = searchParams.get("roleName");
    const inheritFrom = searchParams.get("inheritFrom");

    const role = useAppSelector((state) => rolesSelector.selectById(state, inheritFrom ?? props.roleId ?? ""));
    const { hasOnePermission, getLimitations, permissions: rolePermissions } = useRolePermissions(
        inheritFrom ?? props.roleId ?? ""
    );

    const { handleSubmit, register, watch, formState, control } = useForm<RolesRes>({
        shouldUnregister: false,
    });

    useEffect(() => {
        async function getPermissions() {
            try {
                const res = await ReachCsAPI.getAllPermissions();
                setAllPermissions(res);
                if (inheritFrom || props.roleId) {
                    await unwrap(getRoleByRoleId(inheritFrom ?? props.roleId ?? ""));
                }
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
        getPermissions();
    }, []);

    if (!allPermissions || !rolePermissions) {
        return (
            <PageLayout title="Role details" prevUrl="/roles">
                <div className="mt-3 d-flex justify-content-center">
                    <div className="spinner-border text-primary" />
                </div>
            </PageLayout>
        );
    }

    const onSubmit = async (data: UserRolesRes) => {
        console.log(data);
        Object.keys(data.permissions).forEach((key) => {
            // @ts-ignore
            data.permissions[key.replace(/[*]{2}/g, ".")] = data.permissions[key];
            // @ts-ignore
            delete data.permissions[key];
        });
        Object.keys(data.limitations).forEach((key) => {
            // @ts-ignore
            data.limitations[key.replace(/[*]{2}/g, ".")] = data.limitations[key];
            if (key === "user_role_management**manage_role**assign") {
                // @ts-ignore
                data.limitations[key.replace(/[*]{2}/g, ".")]["assignableRoles"]["type"] = 5;
            }
            // @ts-ignore
            delete data.limitations[key];
        });

        const updatedRole: RolesReq = {
            ...role,
            permissions: { ...role?.permissions, ...data.permissions },
            limitations: { ...role?.limitations, ...data.limitations },
            name: roleName ?? role?.name ?? "",
            id: roleName ?? role?.id ?? "",
        };
        try {
            console.log(updatedRole);
            if (roleName) {
                await unwrap(createRole(updatedRole));
            } else {
                await unwrap(updateRole(updatedRole));
            }
            await dispatch(getRolesByEmailId(emailId ?? ""));
            navigate("/roles");
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const categories = [...new Set(allPermissions.map((permission) => permission.categoryName))];

    const subCategories = [
        ...new Set(
            allPermissions
                .filter((permission) => permission.categoryName === categories[selectedCategoryIndex])
                .map((permission) => permission.subCategoryName)
        ),
    ];

    const availableIds = allPermissions
        .filter((permission) => permission.categoryName === categories[selectedCategoryIndex])
        .map((permission) => permission.id);

    let categoryBeforeEl: ReactNode;
    let categoryAfterEl: ReactNode;
    const categoryEl = categories.map((category, index) => (
        <div
            key={category}
            onClick={() => setSelectedCategoryIndex(index)}
            className={classNames(
                "d-flex justify-content-between align-items-center font-family-semibold p-3 cursor-pointer",
                selectedCategoryIndex === index ? "btn-secondary" : "btn-grey3"
            )}
            style={{
                marginBottom: 1,
            }}>
            {category}
            <span className="reach-cs-arrow-right" />
        </div>
    ));

    if (width < ViewportBreakpoint.LG) {
        categoryBeforeEl = <div className="col-12 col-lg-3 mb-3">{categoryEl.slice(0, selectedCategoryIndex + 1)}</div>;
        categoryAfterEl = <div className="col-12 col-lg-3 mb-3">{categoryEl.slice(selectedCategoryIndex + 1)}</div>;
    } else {
        categoryBeforeEl = <div className="col-12 col-lg-3 mb-3">{categoryEl}</div>;
        categoryAfterEl = null;
    }

    return (
        <PageLayout title="Role details" prevUrl="/roles">
            <div className="mb-3 pb-3">
                <span>Role name:</span> {roleName ?? role?.name}
            </div>
            <div className="row">
                {categoryBeforeEl}
                <div className="col-12 col-lg-9">
                    <div className="d-flex justify-content-between">
                        <div className="h1">{categories[selectedCategoryIndex]}</div>
                        <Toggle id="" name="" label="" checked={hasOnePermission(...availableIds)} />
                    </div>
                    {subCategories.map((subCategory, index) => {
                        const ops = allPermissions.filter(
                            (permission) =>
                                permission.categoryName === categories[selectedCategoryIndex] &&
                                permission.subCategoryName === subCategory
                        );
                        return (
                            <div key={subCategory} className="row mt-3">
                                {index !== 0 && <div className="mb-3 divider mx-3" />}
                                <div className="col-4">{subCategory}</div>
                                <div className="col-8 row">
                                    {ops.map((operation) => (
                                        <Checkbox
                                            key={operation.operationId}
                                            style={{ minWidth: "15%" }}
                                            label={operation.operationName}
                                            id={`permissions[${operation.id.replace(/[.]/g, "**")}]`}
                                            name={`permissions[${operation.id.replace(/[.]/g, "**")}]`}
                                            register={register}
                                            small
                                            inline
                                            defaultChecked={hasOnePermission(operation.id)}
                                        />
                                    ))}
                                    {ops.map((operation) => {
                                        if (!("supportedLimitations" in operation) || !operation.supportedLimitations) {
                                            return null;
                                        }

                                        const limitations = getLimitations(operation.id);
                                        const isChecked = watch(
                                            `permissions[${operation.id.replace(/[.]/g, "**")}]`,
                                            hasOnePermission(operation.id)
                                        );

                                        return (
                                            <div key={operation.id} className="col-12 px-0 mt-2">
                                                {"max_value" in operation.supportedLimitations &&
                                                    (!limitations || "max_value" in limitations) &&
                                                    operation.supportedLimitations.max_value != null && (
                                                        <div className="d-flex align-items-center font-family-semibold">
                                                            Max amount:
                                                            <Input
                                                                className="ml-2"
                                                                type="number"
                                                                register={register({
                                                                    valueAsNumber: true,
                                                                })}
                                                                name={`limitations[${operation.id.replace(
                                                                    /[.]/g,
                                                                    "**"
                                                                )}].max_value.value`}
                                                                disabled={!isChecked}
                                                                style={{ width: 75 }}
                                                                defaultValue={
                                                                    limitations?.max_value?.value ??
                                                                    operation.supportedLimitations.max_value.value
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                {"descriptions" in operation.supportedLimitations && (
                                                    <div>
                                                        <Controller
                                                            control={control}
                                                            name={`limitations[${operation.id.replace(
                                                                /[.]/g,
                                                                "**"
                                                            )}].descriptions.value`}
                                                            defaultValue={
                                                                limitations && "descriptions" in limitations
                                                                    ? limitations.descriptions.value
                                                                    : []
                                                            }
                                                            render={({ name, onChange, value, ref }) => (
                                                                <MultiDropdownInput
                                                                    placeholder="Descriptions"
                                                                    inputClassName="custom-select"
                                                                    containerClassName="mt-3"
                                                                    name={name}
                                                                    ref={ref}
                                                                    disabled={!isChecked}
                                                                    defaultItems={value}
                                                                    items={
                                                                        operation.supportedLimitations &&
                                                                        "descriptions" in operation.supportedLimitations
                                                                            ? operation.supportedLimitations.descriptions.value.map(
                                                                                  (item) => ({
                                                                                      label: item,
                                                                                      value: item,
                                                                                  })
                                                                              )
                                                                            : []
                                                                    }
                                                                    onSelectionChange={(items) => onChange(items)}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                )}
                                                {"assignableRoles" in operation.supportedLimitations && roleMap && (
                                                    <div>
                                                        <Controller
                                                            control={control}
                                                            name={`limitations[${operation.id.replace(
                                                                /[.]/g,
                                                                "**"
                                                            )}].assignableRoles.value`}
                                                            defaultValue={
                                                                limitations && "assignableRoles" in limitations
                                                                    ? limitations.assignableRoles.value
                                                                    : []
                                                            }
                                                            render={({ name, onChange, value, ref }) => (
                                                                <MultiDropdownInput
                                                                    placeholder="Select roles"
                                                                    inputClassName="custom-select"
                                                                    containerClassName="mt-3"
                                                                    name={name}
                                                                    disabled={!isChecked}
                                                                    defaultItems={value}
                                                                    items={Object.keys(roleMap).map((item) => ({
                                                                        label: roleMap[item],
                                                                        value: item,
                                                                    }))}
                                                                    onSelectionChange={(items) => onChange(items)}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}

                    <div className="d-flex align-items-center justify-content-center flex-column">
                        {selectedCategoryIndex < categories.length - 1 ? (
                            <Button
                                color="secondary"
                                className="my-3 col-12 col-md-4 col-lg-3"
                                onClick={() => setSelectedCategoryIndex(selectedCategoryIndex + 1)}>
                                Next
                            </Button>
                        ) : (
                            <Button
                                color="secondary"
                                loading={formState.isSubmitting}
                                className="my-3 col-3"
                                onClick={handleSubmit(onSubmit)}>
                                Proceed
                            </Button>
                        )}
                        {selectedCategoryIndex < categories.length - 1 && (
                            <div
                                className="text-cta mb-3"
                                onClick={() => setSelectedCategoryIndex(categories.length - 1)}>
                                Skip
                            </div>
                        )}
                    </div>
                </div>
                {categoryAfterEl}
            </div>
        </PageLayout>
    );
}
