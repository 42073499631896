import React from "react";
import { useForm } from "react-hook-form";
import { fetchProfile, setCallerIdStatus } from "../features/profile-slice";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile } from "../utils/types";
import { Button } from "./button";
import { useErrorHandler } from "./error-snackbar";
import Modal, { ModalProps } from "./modal";
import { useSuccessModal } from "./success-modal";
import Toggle from "./toggle";

export interface CallerIDModalProps extends ModalProps {
    customerProfile: CustomerProfile;
}

export default function CallerIDModal(props: CallerIDModalProps) {
    const { customerProfile, ...rest } = props;

    const { handleSubmit, formState, watch } = useForm<FormData>({ mode: "onBlur" });

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();

    const onSubmit = async (data: FormData) => {
        try {
            await unwrap(
                setCallerIdStatus({
                    customerId: customerProfile.id,
                    isBlocked: !customerProfile.isCIDBEnabled,
                })
            );
            await dispatch(fetchProfile(customerProfile.id));
            showSuccessModal(`Caller ID ${!customerProfile.isCIDBEnabled ? "blocked successfully" : "unlocked"}`);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        rest.onHide();
    };

    const isCIDBEnabled = watch("isBlocked", customerProfile.isCIDBEnabled);

    return (
        <Modal {...rest} size="sm">
            <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
                <Button color="secondary" fullWidth loading={formState.isSubmitting}>
                    Confirm
                </Button>
            </form>
        </Modal>
    );
}
