import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";
import React from "react";
import PageLayout from "../../components/page-layout";
import { QuickActionCard } from "../../components/quick-action-card";
import image1 from "../../images/businessDashboard@2x.png";
import image2 from "../../images/csDashboard@2x.png";
import image3 from "../../images/marketingDashboard@2x.png";
import image4 from "../../images/collectionDashboard@2x.png";
import image5 from "../../images/acquisitions@2x.png";
import { usePermissions } from "../../features/hooks/use-permissions";
import Accordion, { AccordionBody } from "../../components/accordion";

export type DashboardsProps = RouteComponentProps;

export default function Dashboards(props: DashboardsProps) {
    const { hasOnePermission } = usePermissions();

    return (
        <PageLayout title="Dashboards" prevUrl="/">
            <Accordion>
                <AccordionBody title="">
                    <div className="row row-cols-2 row-cols-md-4 row-cols-lg-5 mx-n2">
                        {hasOnePermission("reports.operation_dashboards.view") && (
                            <div className="p-2 col">
                                <QuickActionCard
                                    icon={image1}
                                    title="Churn Dashboard"
                                    onClick={() => {
                                        navigate("/churn-dashboard");
                                    }}
                                />
                            </div>
                        )}
                        {hasOnePermission("reports.cs_dashboards.view") && (
                            <div className="p-2 col">
                                <QuickActionCard
                                    icon={image2}
                                    title="CS Dashboard"
                                    onClick={() => {
                                        navigate("/cs-dashboards");
                                    }}
                                />
                            </div>
                        )}
                        {hasOnePermission("reports.marketing_dashboards.view") && (
                            <div className="p-2 col">
                                <QuickActionCard
                                    icon={image3}
                                    title="Marketing Dashboard"
                                    onClick={() => {
                                        navigate("/marketing-dashboard");
                                    }}
                                />
                            </div>
                        )}
                        {hasOnePermission("reports.finance_dashboard.view") && (
                            <div className="p-2 col">
                                <QuickActionCard
                                    icon={image4}
                                    title="Payments Dashboard"
                                    onClick={() => {
                                        navigate("/collections-dashboard");
                                    }}
                                />
                            </div>
                        )}
                        {hasOnePermission("reports.marketing_dashboards.view") && (
                            <div className="p-2 col">
                                <QuickActionCard
                                    icon={image5}
                                    title="Acquisition Dashboard"
                                    onClick={() => {
                                        navigate("/acquisition-dashboard");
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </AccordionBody>
            </Accordion>
        </PageLayout>
    );
}
