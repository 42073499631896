import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { Controller, useForm } from "react-hook-form";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import { debounce } from "lodash";
import classNames from "classnames";

import { createTicket, getTicketsByCustomerId } from "../features/tickets-slice";
import { searchCustomers } from "../features/events-slice";
import { selectUserInfo } from "../features/user-role-slice";
import { useAppDispatch, useAppSelector, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile, TicketReq } from "../utils/types";
import { formatPhone } from "../utils/helpers";
import DropdownInput, { DropdownItem } from "./dropdown-input";
import { Button } from "./button";
import { useErrorHandler } from "./error-snackbar";
import { Input } from "./input";
import Modal, { ModalProps } from "./modal";
import Select from "./select";
import { TextArea } from "./text-area";
import { ReachCsAPI } from "../utils/reach-cs-api";
import { SearchableCustomer } from "../utils/types";

export interface CreateTicketModalProps extends ModalProps {
    customerProfile?: CustomerProfile;
}

type FormData = Omit<TicketReq, "channel" | "contact" | "email" | "departmentId">;

function CreateTicketModal(props: CreateTicketModalProps) {
    const { customerProfile, ...rest } = props;

    const { handleSubmit, register, errors, control, formState, watch } = useForm<FormData>({
        mode: "onBlur",
    });

    const [files, setFiles] = useState<FileList>();
    const [secondaryEmails, setSecondaryEmails] = useState<string[]>([]);
    const [query, setQuery] = useState("");
    const [searchResults, setSearchResults] = useState<SearchableCustomer[]>();
    const [selectedCustomer, setSelectedCustomer] = useState<SearchableCustomer>();
    const [addNew, setAddNew] = useState(false);
    const [createdTicket, setCreatedTicket] = useState(false);

    const batchSize = 3;

    const userInfo = useAppSelector(selectUserInfo);

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const categories: any = {
        Account: [
            "Update name",
            "Update phone number",
            "Merge account",
            "Update secondary email",
            "Swap primary and secondary",
            "Update billing address",
            "Update email",
            "Other",
        ],
        "Plan Management": [
            "Add recurring topup",
            "Disable IR",
            "Remove data pause",
            "Downgrade plan",
            "Instant upgrade to All-in",
            "Upgrade plan",
            "Instant downgrade",
            "Enable IR",
            "Enable ILD",
            "Add topups",
            "Other",
            "Disable ILD",
            "Add data pause",
            "Remove recurring topup",
        ],
        SIM: ["SIM Swap", "Other", "Replacement shipment"],
        Billing: [
            "Late payment",
            "Bill amount",
            "Promo amount",
            "Other",
            "Taxes",
            "Referral amount",
            "Card hold",
            "Incorrect ILD charges",
            "Incorrect credit card used",
            "Change bill date",
            "Incorrect IR Charges",
        ],
        Network: [
            "Low coverage",
            "ILD",
            "Hotspot",
            "No coverage",
            "Wifi calling",
            "Other",
            "Data",
            "Call drops",
            "Slow network",
            "IR",
            "Locked phone",
            "Voice",
            "MMS",
            "SMS",
        ],
        Cancellation: [
            "Other",
            "Cancel order",
            "Port out request single line",
            "Disconnect line",
            "Port out request group",
        ],
        Activation: ["Activation failed", "Incompatible IMEI", "5G IMEI", "Port failed", "Other"],
        "Re-activation": [
            "Re-activate with same number",
            "Port back the same number",
            "Re-activate with new number",
            "Other",
        ],
        "Feature Request": [
            "Selling Reach approved devices",
            "esim",
            "5G",
            "Reach interactive coverage map",
            "Number parking",
            "Domestic Roaming",
            "Data-only plan",
            "Individual user throttling",
            "Increased web functionality",
            "Other",
            "Suspend service for extended period",
            "Int'l calling",
            "Concierge Service (iPhone Support)",
            "Int'l Roaming",
            "Split Bill",
            "Minimalist Plan",
            "Student/Military/Senior Discount",
            "Apple Watches and Tablets",
        ],
        Other: ["Other"],
        "App/Web issues": [
            "Signup issue",
            "App crash",
            "Other",
            "Secondary line activation issue",
            "App not able to download",
        ],
    };
    const watchCat: string = watch("category");

    useEffect(() => {
        updateCustomers();
    }, [query]);

    const updateCustomers = async () => {
        try {
            const res = await unwrap(
                searchCustomers({
                    limit: 100,
                    query,
                    limitedFieldSearch: true,
                    partialMatch: true,
                })
            );
            setSearchResults(res.results);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const handleSearch = debounce((query: string) => {
        setQuery(query);
    }, 1000);

    const onSubmit = async (data: FormData) => {
        try {
            const res = await unwrap(
                createTicket({
                    ...data,
                    channel: "Ops portal",
                    contact: {
                        email: userInfo.emailId!,
                        firstName: userInfo.firstName!,
                        lastName: userInfo.lastName!,
                    },
                    departmentId: userInfo.supportDepartmentId!,
                    email: userInfo.emailId!,
                    secondaryContacts: secondaryEmails,
                })
            );
            if (files) {
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    const formData = new FormData();
                    formData.append("file", file);
                    await ReachCsAPI.uploadTicketAttachment(res.id, formData);
                }
            }
            setCreatedTicket(true);
            if (customerProfile) {
                await unwrap(getTicketsByCustomerId(customerProfile.id));
                navigate(`/tickets/${customerProfile.id}/id/${res.id}`);
            } else {
                setSelectedCustomer(undefined);
                navigate("/all-tickets");
            }
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        rest.onHide();
    };
    return (
        <>
            <Modal size="lg" {...rest}>
                <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
                    {!customerProfile && (
                        <DropdownInput
                            type="text"
                            placeholder="Phone number / email address"
                            icon="reach-cs-search"
                            iconPosition="right"
                            dropdownClassName="col px-0"
                            onChange={({ target: { value } }) => handleSearch(value)}>
                            {searchResults == null ? (
                                <div className="d-flex justify-content-center my-3">
                                    <span className="spinner-border spinner-border-sm text-primary" />
                                </div>
                            ) : searchResults.length === 0 ? (
                                <DropdownItem className="p-0" onClick={() => setAddNew(true)}>
                                    <div className="mx-3 py-2 text-cta">Add new</div>
                                </DropdownItem>
                            ) : (
                                searchResults.slice(0, batchSize).map((customer, index) => (
                                    <DropdownItem
                                        key={customer.id}
                                        className="p-0"
                                        onClick={() => {
                                            setSelectedCustomer(customer);
                                        }}>
                                        <div
                                            className={classNames("mx-3 py-2 font-family-semibold", {
                                                "border-top": index !== 0,
                                            })}
                                            style={{ fontSize: 12 }}>
                                            <div>
                                                {customer.firstName} {customer.lastName}
                                            </div>
                                            <div>{formatPhone(customer.reachNumber)}</div>
                                            <div>{customer.emailId}</div>
                                        </div>
                                    </DropdownItem>
                                ))
                            )}
                        </DropdownInput>
                    )}
                    {!customerProfile && (
                        // (addNew ||
                        //     (selectedCustomer != null && (
                        <>
                            {/* <Controller
                                control={control}
                                name="cf.cf_mdn"
                                defaultValue={selectedCustomer?.primaryNumber ?? ""}
                                rules={{
                                    required: {
                                        value: true,
                                        message:
                                            "Phone number is required. Please select a customer from the dropdown above.",
                                    },
                                    pattern: {
                                        value: /^\d{10}$/,
                                        message:
                                            "Phone number should be 10 digits long. Please select a customer from the dropdown above.",
                                    },
                                }}
                                render={({ onChange, onBlur, value, name }) => (
                                    <Input
                                        type="text"
                                        disabled
                                        inputMode="numeric"
                                        placeholder="Billing phone number"
                                        className="col my-3 px-0"
                                        maxLength={10}
                                        mask="phone"
                                        value={selectedCustomer?.primaryNumber ?? ""}
                                        name={name}
                                        showError={errors.cf?.cf_mdn != null}
                                        errorMessage={errors.cf?.cf_mdn?.message}
                                    />
                                )}
                            /> */}
                            <Input
                                type="text"
                                placeholder="Billing phone number"
                                className="col my-3 px-0"
                                name="cf.cf_mdn"
                                value={selectedCustomer?.primaryNumber ?? ""}
                                register={register({
                                    required: {
                                        value: true,
                                        message:
                                            "Phone number is required. Please select a customer from the dropdown above.",
                                    },
                                })}
                                showError={errors.cf?.cf_mdn != null}
                                errorMessage={errors.cf?.cf_mdn?.message}
                            />
                            <Input
                                type="text"
                                placeholder="Customer ID"
                                className="col my-3 px-0"
                                name="cf.cf_customer_id"
                                value={selectedCustomer?.id ?? ""}
                                register={register({
                                    required: {
                                        value: true,
                                        message:
                                            "Customer ID is required. Please select a customer from the dropdown above.",
                                    },
                                })}
                                showError={errors.cf?.cf_customer_id != null}
                                errorMessage={errors.cf?.cf_customer_id?.message}
                            />
                            {/* <Controller
                                control={control}
                                name="cf.cf_customer_id"
                                defaultValue={selectedCustomer?.id ?? ""}
                                rules={{
                                    required: {
                                        value: true,
                                        message:
                                            "Customer ID is required. Please select a customer from the dropdown above.",
                                    },
                                }}
                                render={({ name }) => (
                                    <Input
                                        type="text"
                                        placeholder="Customer ID"
                                        className="col my-3 px-0"
                                        disabled
                                        value={selectedCustomer?.id ?? ""}
                                        name={name}
                                        showError={errors.cf?.cf_customer_id != null}
                                        errorMessage={errors.cf?.cf_customer_id?.message}
                                    />
                                )}
                            /> */}
                        </>
                    )}
                    {customerProfile && (
                        <>
                            <Controller
                                control={control}
                                name="cf.cf_mdn"
                                defaultValue={customerProfile?.reachNumber ?? ""}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Phone number is required",
                                    },
                                    pattern: {
                                        value: /^\d{10}$/,
                                        message: "Phone number should be 10 digits long",
                                    },
                                }}
                                render={({ onChange, onBlur, value, name }) => (
                                    <Input
                                        type="text"
                                        inputMode="numeric"
                                        placeholder="Billing phone number"
                                        className="col px-0"
                                        maxLength={10}
                                        mask="phone"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        name={name}
                                        showError={errors.cf?.cf_mdn != null}
                                        errorMessage={errors.cf?.cf_mdn?.message}
                                    />
                                )}
                            />
                            <Input
                                type="text"
                                placeholder="Customer ID"
                                className="col my-3 px-0"
                                name="cf.cf_customer_id"
                                defaultValue={customerProfile?.id ?? ""}
                                register={register({
                                    required: {
                                        value: true,
                                        message: "Customer ID is required",
                                    },
                                })}
                                showError={errors.cf?.cf_customer_id != null}
                                errorMessage={errors.cf?.cf_customer_id?.message}
                            />
                        </>
                    )}
                    <Select
                        className="col my-3 px-0"
                        register={register({
                            required: {
                                value: true,
                                message: "Category is required",
                            },
                        })}
                        name="category"
                        showError={errors.category != null}
                        errorMessage={errors.category?.message}>
                        <option value="">Select category</option>
                        {Object.keys(categories).map((item) => (
                            <option value={item} key={item}>
                                {item}
                            </option>
                        ))}
                    </Select>
                    <Select
                        className="col my-3 px-0"
                        register={register({
                            required: {
                                value: true,
                                message: "Sub Category is required",
                            },
                        })}
                        name="subCategory"
                        showError={errors.subCategory != null}
                        errorMessage={errors.subCategory?.message}>
                        <option value="">Select sub category</option>
                        {categories[watchCat]?.map((item: string) => (
                            <option value={item} key={item}>
                                {item}
                            </option>
                        ))}
                    </Select>
                    <Input
                        type="hidden"
                        className="col my-3 px-0"
                        name="cf.cf_account_id"
                        defaultValue={userInfo.accountId}
                        register={register({
                            required: {
                                value: true,
                                message: "AccountId is required",
                            },
                        })}
                        showError={errors.cf?.cf_account_id != null}
                        errorMessage={errors.cf?.cf_account_id?.message}
                    />
                    <Controller
                        control={control}
                        name="cf.secondaryContacts"
                        defaultValue={secondaryEmails}
                        render={() => (
                            <ReactMultiEmail
                                placeholder="CC Emails"
                                emails={secondaryEmails}
                                onChange={(_emails: string[]) => {
                                    setSecondaryEmails(_emails);
                                }}
                                getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
                                    return (
                                        <div data-tag key={index}>
                                            {email}
                                            <span data-tag-handle onClick={() => removeEmail(index)}>
                                                ×
                                            </span>
                                        </div>
                                    );
                                }}
                            />
                        )}
                    />
                    <Input
                        type="text"
                        placeholder="Subject"
                        className="col my-3 px-0"
                        name="subject"
                        defaultValue=""
                        register={register({
                            required: {
                                value: true,
                                message: "Subject is required",
                            },
                        })}
                        showError={errors.subject != null}
                        errorMessage={errors.subject?.message}
                    />
                    <TextArea
                        placeholder="Description"
                        name="description"
                        className="col my-3 px-0"
                        rows={5}
                        register={register({
                            required: {
                                value: true,
                                message: "Description required",
                            },
                        })}
                        showError={errors.description != null}
                        errorMessage={errors.description?.message}
                    />
                    <Input
                        type="text"
                        placeholder="Issue transaction ID (if available)"
                        className="col my-3 px-0"
                        name="cf.cf_issue_transaction_id"
                        defaultValue=""
                        register={register}
                        showError={errors.cf?.cf_issue_transaction_id != null}
                        errorMessage={errors.cf?.cf_issue_transaction_id?.message}
                    />
                    <TextArea
                        placeholder="List of troubleshooting steps already performed"
                        name="cf.cf_list_of_troubleshooting_steps_already_performed"
                        className="col my-3 px-0"
                        rows={5}
                        register={register}
                        showError={errors.cf?.cf_list_of_troubleshooting_steps_already_performed != null}
                        errorMessage={errors.cf?.cf_list_of_troubleshooting_steps_already_performed?.message}
                    />
                    <Select
                        defaultValue=""
                        name="priority"
                        className="col-12 px-0 mb-3"
                        register={register({
                            required: {
                                value: true,
                                message: "Priority must be selected",
                            },
                        })}
                        showError={errors.priority != null}
                        errorMessage={errors.priority?.message}>
                        <option value="">Priority</option>
                        <option value="Critical">Critical</option>
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Low">Low</option>
                    </Select>
                    <div className="font-family-semibold my-2">Attachments</div>
                    {files && Array.from(files).map((file, index) => <div key={index}>{file.name}</div>)}
                    <input
                        id="file-upload"
                        className="d-none"
                        type="file"
                        multiple
                        onChange={(event) => {
                            setFiles(event.target.files ?? undefined);
                        }}
                    />
                    <label htmlFor="file-upload" className="text-cta">
                        Attach file
                    </label>

                    <Button
                        color="secondary"
                        loading={formState.isSubmitting}
                        onClick={() => handleSubmit(onSubmit)}
                        type="submit"
                        fullWidth>
                        Create ticket
                    </Button>
                </form>
            </Modal>
            <Modal
                title="Ticket Creation Successful"
                size="sm"
                show={createdTicket}
                onHide={() => setCreatedTicket(false)}>
                <div className="text-center">Your ticket has been created successfully.</div>
            </Modal>
        </>
    );
}

export default CreateTicketModal;
