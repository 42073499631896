import { RouteComponentProps } from "@reach/router";
import "firebase/auth";
import { navigate } from "gatsby";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AuthState, authStateSelector, authStateUpdated, reset, signOut, userUpdated } from "../features/auth-slice";
import {
    configSelector,
    fetchOperatorList,
    fetchPopularDestinations,
    fetchShippingRates,
    fetchSimCost,
    getProvConfig,
    getTopupPulse,
} from "../features/config-slice";
import { updateUser } from "../features/operation-users-slice";
import { fetchReachPlans } from "../features/plans-slice";
import { accountIdUpdated, getRolesByEmailId, userRolesSelector } from "../features/user-role-slice";
import UserSessionTimeOutModal from "./user-session-timeout-modal";

import { usePermissions } from "../features/hooks/use-permissions";
import { isFirebaseDBAllowed, isReachSSOEnabled, isSSOEnabled } from "../utils/helpers";
import { persistor, useAppDispatch, useAppSelector, useUnwrapAsyncThunk } from "../utils/store";
import ErrorBoundaryFull from "./error-boundary-full";
//import ShouldRenderWithPermission from "../screens/profile/renderWithPermission";
//import Modal from "./modal";

interface Props extends RouteComponentProps<any> {
    component: any;
    isIdleTimeOut: boolean;
    setIsIdleTimeOut: any;
}

const PrivateRoute = ({ component: Component, ...rest }: Props) => {
    const { authState } = useSelector(authStateSelector);
    const { userRolesLoading } = useSelector(userRolesSelector);
    const auth = useAppSelector(authStateSelector);
    const { operators, shippingRates } = useSelector(configSelector);
    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const query = rest.location?.search;
    const { getLimitations } = usePermissions();
    const { hasOnePermission } = usePermissions();
    const sessionTimeoutLimitations = getLimitations("user_role_management.session_timeout.allow");

    const updateUserSignIn = async (user: any) => {
        dispatch(authStateUpdated(AuthState.LOGGED_IN));
        let res: any;
        if (user?.email) {
            dispatch(userUpdated({ emailId: user?.email?.toLowerCase() }));
            res = await dispatch(getRolesByEmailId(user.email));
            dispatch(getProvConfig());
            if (user?.realm_access && res?.payload?.roleMap && isSSOEnabled()) {
                const currentRoleArr = user?.realm_access?.split(",")?.map((el: string) => el?.trim());
                let currentRole;
                currentRoleArr?.forEach((el: string) => {
                    if (Object.values(res?.payload?.roleMap).includes(el)) {
                        currentRole = el;
                    }
                });
                if (currentRole) {
                    const payload = {
                        firstName: res?.payload?.firstName,
                        lastName: res?.payload?.lastName,
                        emailId: res?.payload?.emailId,
                        accountId: res?.payload?.accountId,
                        managerId: res?.payload?.managerId,
                        phoneNumber: res?.payload?.phoneNumber,
                        countryCode: res?.payload?.countryCode,
                        roleIds: [currentRole],
                    };
                    await unwrap(updateUser(payload));
                    await dispatch(getRolesByEmailId(user.email));
                }
            }
        }
        if (user) {
            const promise = Promise.all([
                dispatch(fetchOperatorList()),
                dispatch(fetchShippingRates()),
                dispatch(fetchPopularDestinations()),
                dispatch(fetchReachPlans()),
                dispatch(fetchSimCost()),
                dispatch(getTopupPulse()),
            ]);
            if (operators.length === 0 || shippingRates.length === 0) {
                await promise;
            }
        }

        if (isSSOEnabled() || isReachSSOEnabled()) {
            dispatch(accountIdUpdated(process.env.BRAND));
        }

        dispatch(userUpdated({ emailId: user?.email, displayName: user?.displayName }));

        dispatch(authStateUpdated(AuthState.LOGGED_IN));
    };
    let id_token: string | undefined = undefined;
    id_token = query ? query?.split("id_token=")[1] : undefined;
    if (id_token === undefined) {
        id_token = localStorage.getItem("id_token") ?? undefined;
    }

    // session timeout
    const [isIdleTimeOut, setIsIdleTimeOut] = useState(true);
    const logoutValue: any = sessionTimeoutLimitations?.max_value?.value;
    const logoutTimeout: any = logoutValue * 60 * 1000;
    const alertTimeout = logoutTimeout - 120000; //2mins

    let inactivityAlertTimer: number;
    let inactivityLogoutTimer: number;
    // Function to reset the inactivity timer
    const resetInactivityAlertTimer = () => {
        clearTimeout(inactivityAlertTimer);
        clearTimeout(inactivityLogoutTimer);
        if (hasOnePermission("user_role_management.session_timeout.allow") && logoutTimeout > 0) {
            inactivityAlertTimer = setTimeout(alert, alertTimeout);
            inactivityLogoutTimer = setTimeout(logout, logoutTimeout);
        }
    };

    // Function to handle the user alert
    const alert = () => {
        setIsIdleTimeOut(false);
    };

    const logout = async () => {
        const ssoToken = localStorage.getItem("id_token");
        const isReachLogin = localStorage.getItem("isReachLogin");
        if (isFirebaseDBAllowed()) {
            await dispatch(signOut());
        }
        dispatch(reset());
        persistor.purge();
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem("session_timeout", "true");
        if (ssoToken) {
            if (isReachLogin) {
                window.location.href = process.env.SSO_REACH_LOGOUT_URL;
            } else {
                window.location.href = process.env.SSO_LOGOUT_URL;
            }
        } else {
            window.location.href = "/login";
        }
    };

    useEffect(() => {
        if (id_token !== undefined) {
            localStorage.setItem("id_token", id_token);
        }
        if (id_token !== undefined && authState !== AuthState.LOGGED_IN) {
            const decoded: any = jwt_decode(id_token);
            if (decoded.sub && !decoded.email) {
                decoded.email = decoded.sub;
            }
            updateUserSignIn(decoded);
        } else if (authState === AuthState.LOGGED_OUT) {
            navigate("/login");
        }

        // session time out
        resetInactivityAlertTimer();

        window.addEventListener("mousemove", resetInactivityAlertTimer);
        window.addEventListener("keydown", resetInactivityAlertTimer);

        return () => {
            window.removeEventListener("mousemove", resetInactivityAlertTimer);
            window.removeEventListener("keydown", resetInactivityAlertTimer);
            clearTimeout(inactivityAlertTimer);
            clearTimeout(inactivityLogoutTimer);
        };
        // session time out end
    }, []);

    if (authState === AuthState.LOGGED_OUT && id_token === undefined) {
        localStorage.setItem("redirect_url", window.location.pathname + (window.location.search ?? ""));
        navigate("/login");
    }
    if (authState === AuthState.INITIAL || userRolesLoading) {
        return (
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border text-primary" />
            </div>
        );
    }

    return (
        <>
            <ErrorBoundaryFull>
                <Component {...rest} />
            </ErrorBoundaryFull>

            {!isIdleTimeOut ? (
                <div>
                    <UserSessionTimeOutModal
                        isIdleTimeOut={!isIdleTimeOut}
                        setIsIdleTimeOut={setIsIdleTimeOut}
                        isLogout={true}
                    />
                </div>
            ) : null}
        </>
    );
};
export default PrivateRoute;
