import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Link, navigate } from "gatsby";
import { Controller, useForm } from "react-hook-form";
import { Button } from "../components/button";
import { Input } from "../components/input";
import Layout from "../components/layout";
import Select from "../components/select";
import { selectUserInfo } from "../features/user-role-slice";
import { formatPhone } from "../utils/helpers";
import { useAppSelector } from "../utils/store";
import { CustomerStatus } from "../utils/types/customer";
import noResultsImage from "../images/icon-noresultsfound.png";

interface FormData {
    roleName: string;
    inheritRole: string;
}

export type CreateRoleProps = RouteComponentProps;

export default function CreateRole(props: CreateRoleProps) {
    const { handleSubmit, control, register, errors } = useForm<FormData>({
        mode: "onBlur",
    });

    const { roleMap } = useAppSelector(selectUserInfo);

    const onSubmit = (data: FormData) => {
        navigate(`/role/permissions?roleName=${data.roleName}&inheritFrom=${data.inheritRole}`);
    };
    return (
        <Layout title="Create role">
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <Link className="text-cta mb-3" to="/roles">
                    <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                    Back
                </Link>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>Create role</h1>
                </div>
                <div className="divider" />
                {roleMap && Object.entries(roleMap).length > 0 ? (
                    <form className="row w-100 justify-content-center" onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <Controller
                                control={control}
                                name="roleName"
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Role name is required.",
                                    },
                                }}
                                render={() => (
                                    <Input
                                        type="text"
                                        placeholder="Role name"
                                        className="mt-4"
                                        name="roleName"
                                        defaultValue=""
                                        register={register}
                                        showError={errors.roleName != null}
                                        errorMessage={errors.roleName?.message}
                                    />
                                )}
                            />
                            {/* <Input
                            type="text"
                            placeholder="Role name"
                            className="mt-4"
                            name="roleName"
                            defaultValue=""
                            register={register}
                            showError={errors.roleName != null}
                            errorMessage={errors.roleName?.message}
                        /> */}
                            <Controller
                                control={control}
                                name="inheritRole"
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Base role is required.",
                                    },
                                }}
                                render={() => (
                                    <Select
                                        className="mt-4"
                                        register={register}
                                        name="inheritRole"
                                        showError={errors.inheritRole != null}
                                        errorMessage={errors.inheritRole?.message}>
                                        <option value="">Select base role</option>
                                        {Object.entries(roleMap).map(([key, value]) => (
                                            <option key={key} value={key}>
                                                {value}
                                            </option>
                                        ))}
                                    </Select>
                                )}
                            />
                            {/* {roleMap && (
                            <Select
                                className="mt-4"
                                register={register}
                                name="inheritRole"
                                showError={errors.inheritRole != null}
                                errorMessage={errors.inheritRole?.message}>
                                <option value="">Select base role</option>
                                {Object.entries(roleMap).map(([key, value]) => (
                                    <option key={key} value={key}>
                                        {value}
                                    </option>
                                ))}
                            </Select>
                        )} */}
                            <Button color="secondary" fullWidth className="mt-4">
                                Next
                            </Button>
                        </div>
                    </form>
                ) : (
                    <div
                        className="d-flex align-items-center justify-content-center flex-column"
                        style={{ height: 200 }}>
                        <img src={noResultsImage} />
                        <div className="font-family-semibold" style={{ fontSize: 18 }}>
                            Unfortunately, you can create a role but you are not assigned any assignable base role.
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
}
