import { format } from "date-fns";
import { navigate } from "gatsby";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { fetchProfile, rectifyMDNAlert } from "../features/profile-slice";
import { AlertActivityType } from "../screens/alerts-history-page";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { MDNAlert } from "../utils/types";
import { Button } from "./button";
import { useErrorHandler } from "./error-snackbar";
import Modal from "./modal";
import { useSuccessModal } from "./success-modal";

interface MDNAlertSnackbarProps {
    latestMDNAlert?: MDNAlert;
}

function MDNAlertSnackbar(props: MDNAlertSnackbarProps) {
    const { latestMDNAlert } = props;

    const [showDetails, setShowDetails] = useState(false);

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();
    const { handleSubmit, formState } = useForm();

    if (!latestMDNAlert) {
        return null;
    }

    const onRectify = async () => {
        try {
            await unwrap(
                rectifyMDNAlert({
                    customerId: latestMDNAlert.customerId,
                    payload: {
                        operation: latestMDNAlert.activity,
                        mode: "manual",
                    },
                })
            );
            await dispatch(fetchProfile(latestMDNAlert.customerId));
            showSuccessModal("Alert marked as rectified");
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        setShowDetails(false);
    };

    return (
        <div className="sticky-top alert bg-tertiary2 text-white text-center" style={{ borderRadius: 0 }} role="alert">
            <span>{latestMDNAlert.reason}</span>{" "}
            <Button color="white" className="py-1 ml-3" onClick={() => setShowDetails(true)}>
                View more
            </Button>
            <Modal
                title={AlertActivityType[latestMDNAlert.activity]}
                size="sm"
                show={showDetails}
                onHide={() => setShowDetails(false)}>
                <div className="font-family-bold align-self-start">
                    Date: {format(new Date(latestMDNAlert.createdDate), "MMM dd, yyyy")}{" "}
                    <span className="font-family-semibold">
                        {format(new Date(latestMDNAlert.createdDate), "hh:mma")}
                    </span>
                </div>
                <div className="font-family-semibold align-self-start mt-2">Not rectified yet</div>

                <div className="font-family-semibold align-self-start my-2">{latestMDNAlert.reason}</div>
                <Button color="secondary" loading={formState.isSubmitting} fullWidth onClick={handleSubmit(onRectify)}>
                    Mark as resolved
                </Button>
                <div className="text-cta mt-2" onClick={() => navigate(`/alerts/history/${latestMDNAlert.customerId}`)}>
                    Alert history
                </div>
            </Modal>
        </div>
    );
}

export default MDNAlertSnackbar;
