import React, { useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { format } from "date-fns";
import { navigate } from "gatsby";
import CustomerPageLayout from "../components/customer-page-layout";
import { useErrorHandler } from "../components/error-snackbar";
import { profilesSelector } from "../features/profile-slice";
import { getTicketsByCustomerId, ticketsSelector } from "../features/tickets-slice";
import { useAppSelector, useUnwrapAsyncThunk } from "../utils/store";

export interface TicketsHistoryProps extends RouteComponentProps {
    customerId: string;
}

function TicketsHistory(props: TicketsHistoryProps) {
    const { customerId } = props;

    const customerProfile = useAppSelector((state) => profilesSelector.selectById(state, customerId ?? ""));

    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const { tickets } = useAppSelector((state) => ticketsSelector.selectById(state, customerId)) ?? {};

    useEffect(() => {
        async function fetchTickets() {
            try {
                await unwrap(getTicketsByCustomerId(customerId));
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
        fetchTickets();
    }, []);

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    return (
        <CustomerPageLayout title="All tickets" customerId={customerId} prevUrl={`/profile/${customerId}`}>
            <div className="row font-family-bold py-2 mx-0" style={{ backgroundColor: "#fbfbfb" }}>
                <div className="col-2">Ticket ID</div>
                <div className="col-5">Subject</div>
                <div className="col">Date</div>
                <div className="col-2">Status</div>
            </div>
            {tickets == null ? (
                <div className="d-flex justify-content-center mt-3">
                    <div className="spinner-border text-primary" />
                </div>
            ) : tickets.length === 0 ? (
                <div className="d-flex justify-content-center font-family-semibold mt-3">
                    No tickets for this customer
                </div>
            ) : (
                tickets?.map((ticket) => (
                    <div
                        className="row font-family-semibold py-2 mx-0 divider cursor-pointer"
                        key={ticket.id}
                        onClick={() => navigate(`/tickets/${customerProfile.id}/id/${ticket.id}`)}>
                        <div className="col-2 font-family-bold">#{ticket.ticketNumber}</div>
                        <div className="col-5">{ticket.subject}</div>
                        <div className="col">{format(new Date(ticket.createdTime), "MMM dd, yyyy")}</div>
                        <div className="col-2">{ticket.status}</div>
                    </div>
                ))
            )}
        </CustomerPageLayout>
    );
}

export default TicketsHistory;
