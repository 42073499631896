import classNames from "classnames";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { usePermissions } from "../features/hooks/use-permissions";
import { sendEngagementNotes, updateHubspot } from "../features/profile-slice";
import callIcon from "../images/call@2x.png";
import smsIcon from "../images/sms@2x.png";
import QrcPages from "../screens/qrc-pages";
import { useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile } from "../utils/types";
import { Button } from "./button";
import { Card } from "./card";
import { useErrorHandler } from "./error-snackbar";
import Modal from "./modal";
import { useSuccessModal } from "./success-modal";
import { TextArea } from "./text-area";

export interface CustomerActivityCardProps extends CustomerProfile {
    className?: string;
}

enum ModalType {
    ADD_NOTE,
    SEND_SMS,
    VIEW_TIMELINE,
    HUBSPOT_TICKETS,
    HUBSPORT_TASKS,
}

interface FormData {
    text: string;
}

export default function CustomerActivityCard(props: CustomerActivityCardProps) {
    const { className, ...customerProfile } = props;

    const [modalType, setModalType] = useState<ModalType>();

    const { register, handleSubmit, errors, formState } = useForm<FormData>();

    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();
    const { hasOnePermission } = usePermissions();

    const onSubmit = async (data: FormData) => {
        try {
            if (modalType === ModalType.ADD_NOTE) {
                await unwrap(
                    sendEngagementNotes({
                        contactIds: [customerProfile.hubSpotId],
                        message: data.text,
                    })
                );
                showSuccessModal("Note added successfully");
            } else if (modalType === ModalType.SEND_SMS) {
                await unwrap(
                    updateHubspot({
                        email: customerProfile.emailId,
                        cs_portal_send_sms: data.text,
                        cs_portal_send_sms_number: `+1${customerProfile.reachNumber ?? customerProfile.primaryNumber}`,
                    })
                );
                showSuccessModal("SMS sent successfully");
            }
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        setModalType(undefined);
    };

    return (
        <Card className={className}>
            <div className="font-family-bold align-self-start" style={{ fontSize: 16 }}>
                Customer activity
            </div>
            <Button
                color="secondary"
                className="col-12 col-md-10 mt-3"
                disabled={!hasOnePermission("customer_support.notes.add")}
                onClick={() => setModalType(ModalType.ADD_NOTE)}>
                Add notes
            </Button>
            <div className="mt-3">
                <img
                    src={callIcon}
                    style={{ width: 36 }}
                    className={classNames("cursor-pointer", {
                        disabled: !hasOnePermission("customer_support.call.make_call"),
                    })}
                    onClick={() => {
                        hasOnePermission("customer_support.call.make_call") &&
                            window.open(
                                `https://justcall.io/dialer?numbers=+1${
                                    customerProfile.reachNumber ?? customerProfile.primaryNumber
                                }`
                            );
                    }}
                />
                <img
                    src={smsIcon}
                    className={classNames("cursor-pointer ml-3", {
                        disabled: !hasOnePermission("customer_support.sms.send"),
                    })}
                    style={{ width: 36 }}
                    onClick={() => hasOnePermission("customer_support.sms.send") && setModalType(ModalType.SEND_SMS)}
                />
            </div>
            <div className="text-cta mt-3" onClick={() => setModalType(ModalType.VIEW_TIMELINE)}>
                View timeline
            </div>
            <div className="text-cta mt-3" onClick={() => setModalType(ModalType.HUBSPOT_TICKETS)}>
                View tickets
            </div>
            {/* <div className="text-cta mt-3" onClick={() => setModalType(ModalType.HUBSPORT_TASKS)}>
                View tasks
            </div> */}
            <a
                rel="noreferrer"
                style={{ cursor: "pointer" }}
                className="mt-3 text-cta"
                href={"https://app.hubspot.com/tasks/4888946/view/all"}
                target="_blank">
                View tasks
            </a>

            {modalType === ModalType.ADD_NOTE && (
                <Modal
                    title="Add note"
                    size="sm"
                    show={modalType === ModalType.ADD_NOTE}
                    onHide={() => setModalType(undefined)}>
                    {/* <TextArea
                        placeholder="Type note"
                        name="text"
                        className="col-12 px-0"
                        rows={5}
                        register={register({
                            required: {
                                value: true,
                                message: "Note message required",
                            },
                        })}
                        showError={errors.text != null}
                        errorMessage={errors.text?.message}
                    />
                    <Button
                        color="secondary"
                        className="col-12 mt-3"
                        loading={formState.isSubmitting}
                        onClick={handleSubmit(onSubmit)}>
                        Submit
                    </Button> */}
                    <QrcPages customerEmail={customerProfile.emailId} customerIdProp={customerProfile.id} />
                </Modal>
            )}

            {modalType === ModalType.SEND_SMS && (
                <Modal
                    title="Send SMS"
                    size="sm"
                    show={modalType === ModalType.SEND_SMS}
                    onHide={() => setModalType(undefined)}>
                    <TextArea
                        placeholder="Type SMS"
                        name="text"
                        className="col-12 px-0"
                        rows={5}
                        register={register({
                            required: {
                                value: true,
                                message: "SMS message required",
                            },
                        })}
                        showError={errors.text != null}
                        errorMessage={errors.text?.message}
                    />
                    <Button
                        color="secondary"
                        className="col-12 mt-3"
                        loading={formState.isSubmitting}
                        onClick={handleSubmit(onSubmit)}>
                        Send
                    </Button>
                </Modal>
            )}

            <Modal
                title="Hubspot Tickets"
                size="xl"
                show={modalType === ModalType.HUBSPOT_TICKETS}
                onHide={() => setModalType(undefined)}>
                <iframe
                    style={{ width: "100%", height: "80vh", border: 0 }}
                    src={"https://app.hubspot.com/contacts/4888946/tickets/board/view/all/"}
                />
            </Modal>

            <Modal
                title="Customer timeline"
                size="xl"
                show={modalType === ModalType.VIEW_TIMELINE}
                onHide={() => setModalType(undefined)}>
                <iframe
                    style={{ width: "100%", height: "80vh", border: 0 }}
                    src={`https://app.hubspot.com/contacts/4888946/contact/${customerProfile.hubSpotId}`}
                />
            </Modal>
            <Modal
                title="Hubspot Tasks"
                size="xl"
                show={modalType === ModalType.HUBSPORT_TASKS}
                onHide={() => setModalType(undefined)}>
                <iframe
                    style={{ width: "100%", height: "80vh", border: 0 }}
                    src={"https://app.hubspot.com/tasks/4888946/view/all"}
                />
            </Modal>
        </Card>
    );
}
