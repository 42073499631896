import React, { useState } from "react";
import { formatPhone, getNumberWithOrdinal, isPostActivationState } from "../utils/helpers";
import { CustomerProfile, DisconnectionReason, ManageFeatureOperation, ManageFeatureReq } from "../utils/types";
import { Customer, CustomerStatus, FlowVersion, OperationsInProcess } from "../utils/types/customer";
import { Card } from "./card";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { usePermissions } from "../features/hooks/use-permissions";
import Modal from "./modal";
import { Button } from "./button";
import branding from "../branding/branding.json";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import {
    additionalLineGroupUpdated,
    additionalLinePurchaseTypeUpdated,
    additionalLinesPurchaseReset,
} from "../features/additional-lines-purchase-slice";
import { navigate } from "@reach/router";
import CustomerBadge from "./customer-badge";
import Select from "./select";
import { useForm } from "react-hook-form";
import { useSuccessModal } from "./success-modal";
import { manageFeature, fetchProfile } from "../features/profile-slice";
import { useErrorHandler } from "./error-snackbar";
import { Checkbox } from "./checkbox";
import { Feature, ManageFeaturesMap, reasonMap } from "./customer-account-info";

export interface ManageLinesProps {
    customerProfile: CustomerProfile;
    onClick?: (customer: Customer) => void;
    onClickActivate?: (customer: Customer | Pick<Customer, "status">) => void;
}

export function isCustomer(customer: Customer | Pick<Customer, "status">): customer is Customer {
    return "id" in customer;
}

export default function ManageLines(props: ManageLinesProps) {
    const { customerProfile, onClick, onClickActivate } = props;

    const { hasOnePermission } = usePermissions();
    const dispatch = useAppDispatch();

    const [showSIMPreferenceModal, setShowSIMPreferenceModal] = useState(false);
    const [showChangeStatus, setShowChangeStatus] = useState(false);
    const [isConfirmed, setConfirmed] = useState(false);

    const { handleSubmit, register, errors, formState, watch } = useForm<ManageFeatureReq>({ mode: "onBlur" });

    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();

    const operation = watch("operation");
    let selectedFeature: Feature | undefined = undefined;
    if (operation === ManageFeatureOperation.SUSPEND) {
        selectedFeature = ManageFeaturesMap.Suspend;
    } else if (operation === ManageFeatureOperation.RESTORE) {
        selectedFeature = ManageFeaturesMap.Restore;
    } else if (operation === ManageFeatureOperation.HOTLINE) {
        selectedFeature = ManageFeaturesMap.Hotline;
    }

    const onSubmit = async (data: Omit<ManageFeatureReq, "operation">) => {
        console.log(data);
        const customerId = customerProfile.id;
        try {
            const res = await unwrap(
                manageFeature({ customerId, payload: { ...data, operation: selectedFeature!.type } })
            );
            await dispatch(fetchProfile(customerId));
            if (res.failureReasonMap) {
                handleError({
                    message: Object.entries(res.failureReasonMap).map(([key, value]) => {
                        const customer = customerProfile.groupLines.find((customer) => customer.id === key);
                        if (!customer) {
                            return `${key}: ${value}`;
                        }
                        return `${customer.firstName} ${customer.lastName}: ${value}`;
                    }),
                });
            } else if (selectedFeature?.successMessage) {
                showSuccessModal(selectedFeature.successMessage);
            }
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        setShowChangeStatus(false);
    };

    const getRightElement = (customer: Customer | Pick<Customer, "status">) => {
        let rightElement: React.ReactNode;

        const disabled =
            !primaryCustomer.simPaid ||
            ((!isCustomer(customer) || !customer.isPrimary) && !isPostActivationState(primaryCustomer.status));

        if (
            isCustomer(customer) &&
            isPostActivationState(customer.status) &&
            customer.operationsInProcess?.includes(OperationsInProcess.PORT_OUT)
        ) {
            return <span className="ml-1">Port out pending</span>;
        }

        if (isCustomer(customer) && isPostActivationState(customer.status) && customer.laterDisconnectionDate) {
            return <span className="ml-1">Disconnection pending</span>;
        }

        switch (customer.status) {
            case CustomerStatus.INACTIVE:
            case CustomerStatus.PORT_CANCELLED:
                rightElement = (
                    <span
                        className="ml-1 cursor-pointer"
                        onClick={
                            !disabled
                                ? (event) => {
                                      event.stopPropagation();
                                      onClickActivate?.(customer);
                                  }
                                : undefined
                        }>
                        Activate Now
                    </span>
                );
                break;
            case CustomerStatus.ACTIVE:
                rightElement = (
                    <span className="ml-1">
                        Active{" "}
                        <span
                            className="reach-cs-edit ml-2 text-secondary cursor-pointer"
                            style={{ fontSize: 10 }}
                            onClick={(event) => {
                                event.stopPropagation();
                                setShowChangeStatus(true);
                            }}
                        />
                    </span>
                );
                break;
            case CustomerStatus.ACTIVATION_IN_PROGRESS:
                rightElement = <span className="ml-1">Activation in progress</span>;
                break;
            case CustomerStatus.SUSPENDED:
                rightElement = (
                    <span className="ml-1">
                        Suspended{" "}
                        <span
                            className="reach-cs-edit ml-2 text-secondary cursor-pointer"
                            style={{ fontSize: 10 }}
                            onClick={(event) => {
                                event.stopPropagation();
                                setShowChangeStatus(true);
                            }}
                        />
                    </span>
                );
                break;
            case CustomerStatus.DISCONNECTED:
                rightElement = <span className="ml-1">Disconnected</span>;
                if (isCustomer(customer) && customer.lastDisconnectedReason === DisconnectionReason.PORTED_OUT) {
                    rightElement = <span className="ml-1">Ported out</span>;
                } else {
                    rightElement = <span className="ml-1">Disconnected</span>;
                }
                break;
            case CustomerStatus.HOTLINED:
                rightElement = (
                    <span className="ml-1">
                        Hotlined{" "}
                        <span
                            className="reach-cs-edit ml-2 text-secondary cursor-pointer"
                            style={{ fontSize: 10 }}
                            onClick={(event) => {
                                event.stopPropagation();
                                setShowChangeStatus(true);
                            }}
                        />
                    </span>
                );
                break;
            case CustomerStatus.REJECTED:
                rightElement = <span className="ml-1">Rejected</span>;
                break;
            case CustomerStatus.DISABLED:
                rightElement = <span className="ml-1">Disabled</span>;
                break;
            case CustomerStatus.ACTIVATION_FAILED:
                rightElement = <span className="ml-1">Activation failed</span>;
                break;
            case CustomerStatus.PORTING_IN_PROGRESS:
                rightElement = <span className="ml-1">Porting in progress</span>;
                break;
            case CustomerStatus.PORTING_FAILED:
                rightElement = <span className="ml-1">Porting failed</span>;
                break;
        }
        return rightElement;
    };

    const lines: (Customer | Pick<Customer, "status">)[] = [...customerProfile.groupLines];

    if (customerProfile.extraLines > customerProfile.additionalLines) {
        const numOfExtraLines = customerProfile.extraLines - customerProfile.additionalLines;

        for (let i = 0; i < numOfExtraLines; i++) {
            lines.push({ status: CustomerStatus.INACTIVE });
        }
    }

    const primaryCustomer = customerProfile.groupLines.find((customer) => customer.isPrimary)!;

    const enableAddLines =
        isPostActivationState(primaryCustomer.status) &&
        (primaryCustomer.flowVersion !== FlowVersion.FLOW_1 ||
            primaryCustomer.extraLines < customerProfile.reachPlan.maxLines - 1) &&
        primaryCustomer.additionalLines < customerProfile.reachPlan.maxLines - 1 &&
        hasOnePermission("user_management.customer.create");

    return (
        <Card>
            <div className="font-family-bold justify-content-between w-100 d-flex align-self-start align-items-center">
                <div style={{ fontSize: 16 }}>Manage Lines</div>
                {enableAddLines && customerProfile.isPrimary && (
                    <div className="text-cta" onClick={() => setShowSIMPreferenceModal(true)}>
                        Add lines
                    </div>
                )}
            </div>
            {lines.map((customer, index) => (
                <div
                    key={index}
                    onClick={() => {
                        if (isCustomer(customer)) {
                            onClick?.(customer);
                        }
                    }}
                    className={classNames("row w-100 align-items-center mt-2", {
                        "cursor-pointer": isCustomer(customer) && customer.id !== customerProfile.id,
                        disabled:
                            !hasOnePermission("user_management.activation.create") ||
                            !primaryCustomer.simPaid ||
                            ((!isCustomer(customer) || !customer.isPrimary) &&
                                !isPostActivationState(primaryCustomer.status)),
                    })}>
                    {index !== 0 && <div className="divider mb-2" />}
                    <div className="font-family-semibold">{index + 1}</div>
                    <div className="col">
                        <div className="font-family-semibold">
                            {isCustomer(customer) ? `${customer.firstName}'s` : getNumberWithOrdinal(index + 1)} line{" "}
                            <CustomerBadge isPrimary={isCustomer(customer) ? customer.isPrimary : false} />
                        </div>
                        {isCustomer(customer) && customer.reachNumber && (
                            <div
                                style={{
                                    fontSize: 12,
                                }}>
                                {formatPhone(customer.reachNumber)}
                            </div>
                        )}
                    </div>
                    <div className="text-secondary font-family-semibold align-self-center">
                        {customer.status === CustomerStatus.ACTIVE && <span className="reach-cs-tick text-primary" />}
                        {getRightElement(customer)}
                    </div>
                </div>
            ))}

            <Modal
                title="SIM preference"
                size="sm"
                show={showSIMPreferenceModal}
                onHide={() => setShowSIMPreferenceModal(false)}>
                <Button
                    color="secondary"
                    fullWidth
                    onClick={() => {
                        dispatch(additionalLinesPurchaseReset());
                        dispatch(additionalLinePurchaseTypeUpdated(false));
                        dispatch(additionalLineGroupUpdated(customerProfile));
                        navigate("/add-lines");
                    }}>
                    Buy a new {branding.shortName} SIM
                </Button>
                <Button
                    color="secondary"
                    fullWidth
                    className="mt-3"
                    onClick={() => {
                        dispatch(additionalLinesPurchaseReset());
                        dispatch(additionalLinePurchaseTypeUpdated(true));
                        dispatch(additionalLineGroupUpdated(customerProfile));
                        navigate("/add-lines");
                    }}>
                    Already have a {branding.shortName} SIM
                </Button>
            </Modal>

            <Modal title="Change status?" show={showChangeStatus} onHide={() => setShowChangeStatus(false)} size="sm">
                <Select
                    className="mb-3 w-100"
                    register={register({
                        required: {
                            value: true,
                            message: "Operation is required",
                        },
                    })}
                    name="operation"
                    showError={errors.operation != null}
                    errorMessage={errors.operation?.message}>
                    <option value="">Select the operation</option>
                    {customerProfile.status === CustomerStatus.ACTIVE && (
                        <option value={ManageFeatureOperation.HOTLINE}>Hotline</option>
                    )}
                    {customerProfile.status === CustomerStatus.ACTIVE && (
                        <option value={ManageFeatureOperation.SUSPEND}>Suspend</option>
                    )}
                    {customerProfile.status !== CustomerStatus.ACTIVE && (
                        <option value={ManageFeatureOperation.RESTORE}>Restore</option>
                    )}
                </Select>
                <Select
                    className="mb-3 w-100"
                    register={register({
                        required: {
                            value: true,
                            message: "Reason is required",
                        },
                    })}
                    name="reason"
                    showError={errors.reason != null}
                    errorMessage={errors.reason?.message}>
                    <option value="">Select the reason</option>
                    {selectedFeature?.reasons.map((reason) => (
                        <option key={reason} value={reason}>
                            {reasonMap[reason]}
                        </option>
                    ))}
                </Select>

                <Checkbox
                    label="Are you sure you want to proceed?"
                    id="isConfirmed"
                    name="isConfirmed"
                    small
                    className="text-center mb-3"
                    checked={isConfirmed}
                    onChange={({ target: { checked } }) => setConfirmed(checked)}
                />

                <Button
                    color="secondary"
                    fullWidth
                    loading={formState.isSubmitting}
                    disabled={!isConfirmed}
                    onClick={handleSubmit(onSubmit)}>
                    Confirm
                </Button>
            </Modal>
        </Card>
    );
}
