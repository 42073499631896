import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { fetchProfile, updateVMPassword } from "../features/profile-slice";
import { formatPhone } from "../utils/helpers";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile } from "../utils/types";
import { Button } from "./button";
import { useErrorHandler } from "./error-snackbar";
import Modal, { ModalProps } from "./modal";
import tickIcon from "../images/tick-icon.png";
import { useSuccessModal } from "./success-modal";

export interface VoicemailResetModalProps extends ModalProps {
    customerProfile: CustomerProfile;
}

interface FormData {
    password: string;
}

export default function VoicemailResetModal(props: VoicemailResetModalProps) {
    const { customerProfile, ...rest } = props;

    const { control, handleSubmit, formState, errors } = useForm<FormData>({ mode: "onBlur" });

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();

    const onSubmit = async (data: FormData) => {
        console.log(data);
        try {
            await unwrap(
                updateVMPassword({
                    customerId: customerProfile.id,
                    password: data.password,
                })
            );
            await dispatch(fetchProfile(customerProfile.id));
            showSuccessModal("You have successfully reset the password");
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        rest.onHide();
    };

    return (
        <Modal {...rest} size="sm">
            <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
                <div className="font-family-semibold text-center">Please enter the new password</div>
                <Controller
                    render={({ onChange, value }) => (
                        <OtpInput
                            numInputs={4}
                            onChange={onChange}
                            value={value}
                            className="my-3"
                            isInputNum={true}
                            separator={<span />}
                            inputStyle="form-control otp-input"
                            containerStyle="justify-content-around"
                        />
                    )}
                    control={control}
                    name="password"
                    rules={{
                        required: "Password required",
                        validate: (value) => value.length === 4 || "4 digits required",
                    }}
                />
                {errors.password && <div className="mb-2 text-center text-error">{errors.password?.message}</div>}
                <Button color="secondary" fullWidth loading={formState.isSubmitting}>
                    Confirm
                </Button>
            </form>
        </Modal>
    );
}
