import React from "react";
import Layout from "../components/layout";
import { RouteComponentProps } from "@reach/router";

interface Props extends RouteComponentProps {
    url: string;
}

export default function IFrameLayout(props: Props) {
    return (
        <Layout>
            <iframe style={{ width: "100%", height: "100vh", border: 0 }} src={props.url}></iframe>
        </Layout>
    );
}
