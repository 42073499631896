import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { getBillingHistory, waiveOffLatePayment } from "../features/billing-slice";
import { fetchProfile } from "../features/profile-slice";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile } from "../utils/types";
import { Button } from "./button";
import { useErrorHandler } from "./error-snackbar";
import { Input } from "./input";
import Modal, { ModalProps } from "./modal";
import Select from "./select";
import { useSuccessModal } from "./success-modal";

export interface WaiveOffPaymentModalProps extends ModalProps {
    customerProfile: CustomerProfile;
}

interface FormData {
    reason: string;
    reasonText?: string;
}

export default function WaiveOffPaymentModal(props: WaiveOffPaymentModalProps) {
    const { customerProfile, ...rest } = props;

    const { control, register, handleSubmit, formState, errors } = useForm<FormData>({ mode: "onBlur" });

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();

    const onSubmit = async (data: FormData) => {
        try {
            await unwrap(waiveOffLatePayment(customerProfile.id));
            await unwrap(getBillingHistory(customerProfile.id));
            await dispatch(fetchProfile(customerProfile.id));
            showSuccessModal("Late-payment fees waived off successfully");
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        rest.onHide();
    };

    const [showCustomReason, setCustomReason] = useState(false);

    return (
        <Modal {...rest} size="sm">
            <Controller
                defaultValue=""
                name="reason"
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: "Reason must be selected",
                    },
                }}
                render={({ onChange, onBlur, value, name }) => (
                    <Select
                        onChange={(event) => {
                            console.log(event.target.value);
                            onChange(event.target.value);
                            setCustomReason(event.target.value === "Other");
                        }}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                        className="col-12 px-0 mb-2"
                        showError={errors.reason != null}
                        errorMessage={errors.reason?.message}>
                        <option value="">Select Reason</option>
                        <option value="Expired credit card">Expired credit card</option>
                        <option value="Class A customer">Class A customer</option>
                        <option value="CEO approved">CEO approved</option>
                        <option value="Other">Other</option>
                    </Select>
                )}
            />
            {showCustomReason && (
                <Input
                    type="text"
                    placeholder="Enter reason"
                    name="reasonText"
                    defaultValue=""
                    className="col-12 px-0 mb-2"
                    register={register({
                        required: {
                            value: true,
                            message: "Reason is required",
                        },
                    })}
                    showError={errors.reasonText != null}
                    errorMessage={errors.reasonText?.message}
                />
            )}
            <Button color="secondary" fullWidth loading={formState.isSubmitting} onClick={handleSubmit(onSubmit)}>
                Confirm
            </Button>
        </Modal>
    );
}
