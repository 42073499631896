import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import Layout from "../components/layout";
import { Link, navigate } from "gatsby";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch, useAppSelector, useUnwrapAsyncThunk } from "../utils/store";
import {
    fetchProfile,
    profilesSelector,
    ProfileState,
    profileStateSelector,
    swapAccount,
} from "../features/profile-slice";
import { Card } from "../components/card";
import { EMAIL_PATTERN, formatPhone } from "../utils/helpers";
import { CustomerStatus } from "../utils/types/customer";
import { Button } from "../components/button";
import Modal from "../components/modal";
import { useForm } from "react-hook-form";
import { Input } from "../components/input";
import { useErrorHandler } from "../components/error-snackbar";
import CustomerBadge from "../components/customer-badge";
import { Radio } from "../components/radio";
import {
    leaveReach,
    selectNonLeavingCustomers,
    selectLeavingCustomers,
    leavingReachReasonSelector,
} from "../features/leaving-reach-slice";
import { LeaveReachReq } from "../utils/types";
import CustomerVerification from "../components/customer-verification";
import classNames from "classnames";

export interface AccountSwapProps extends RouteComponentProps {
    customerId: string;
    flow?: AccountSwapFlow;
}

export enum AccountSwapFlow {
    DEFAULT,
    LEAVING_REACH,
}

interface FormData {
    newPrimaryPreferredEmailId?: string;
}

export default function AccountSwap(props: AccountSwapProps) {
    const { customerId, flow = AccountSwapFlow.DEFAULT } = props;

    const customerProfile = useSelector((state: RootState) => profilesSelector.selectById(state, customerId));

    const reason = useAppSelector(leavingReachReasonSelector);
    const leavingCustomers = useAppSelector(selectLeavingCustomers(customerId));
    const nonLeavingCustomers = useAppSelector(selectNonLeavingCustomers(customerId));

    const secondaryLines =
        flow === AccountSwapFlow.DEFAULT
            ? customerProfile?.groupLines.slice(1).filter((customer) => customer.status === CustomerStatus.ACTIVE)
            : nonLeavingCustomers.filter((customer) => customer.status === CustomerStatus.ACTIVE);

    const [selectedLine, setSelectedLine] = useState(secondaryLines?.[0]?.id);
    const [showModal, setShowModal] = useState(false);
    const [showCustomerVerification, setShowCustomerVerification] = useState(false);

    const selectedCustomer = secondaryLines?.find((customer) => customer.id === selectedLine);

    const { handleSubmit, register, errors, formState } = useForm<FormData>({
        mode: "onBlur",
    });

    const radioFormMethods = useForm<{ secondaryCustomerId: string }>({
        mode: "onBlur",
    });

    const unwrap = useUnwrapAsyncThunk();
    const dispatch = useAppDispatch();
    const handleError = useErrorHandler();

    const { profileState } = useSelector(profileStateSelector);

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    const onSubmit = async (data: FormData) => {
        try {
            await unwrap(
                swapAccount({
                    groupId: customerProfile.groupId,
                    primaryCustomerId: customerId,
                    secondaryCustomerId: selectedCustomer!.id,
                    newPrimaryPreferredEmailId: data.newPrimaryPreferredEmailId ?? selectedCustomer!.userName,
                })
            );
            if (flow === AccountSwapFlow.LEAVING_REACH) {
                const payload: LeaveReachReq = { reason, customers: {} };
                for (const entry of leavingCustomers) {
                    payload.customers[entry.customerId] = {
                        type: entry.type,
                        laterDisconnectionDate: entry.laterDisconnectionDate,
                    };
                }
                await unwrap(
                    leaveReach({
                        customerId: selectedCustomer!.id,
                        payload,
                    })
                );
            }
            await dispatch(fetchProfile(customerId));
            navigate(`/profile/${customerId}`);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        setShowModal(false);
    };

    return (
        <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - Account Swap`}>
            <div className="container col-10 d-flex flex-column my-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link
                        className="text-cta mb-3"
                        to={
                            flow === AccountSwapFlow.DEFAULT
                                ? `/profile/${customerId}`
                                : `/disconnections-summary/${customerId}`
                        }>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                    {profileState === ProfileState.LOADING && <div className="spinner-border text-primary" />}
                </div>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>

                <Card>
                    <div className="align-self-start w-100">
                        <h1 className="">Account Swap</h1>
                        <div className="font-family-semibold">
                            {customerProfile.firstName}{" "}
                            <span
                                className="font-family-medium"
                                style={{
                                    fontSize: 10,
                                }}>
                                <CustomerBadge isPrimary={customerProfile.isPrimary} />
                            </span>
                            <div
                                className="font-family-medium"
                                style={{
                                    fontSize: 12,
                                }}>
                                {formatPhone(customerProfile.reachNumber)}
                            </div>
                        </div>

                        <div className="font-family-bold my-3" style={{ fontSize: 16 }}>
                            Secondary account
                        </div>

                        {secondaryLines?.map((customer, index) => (
                            <div key={index}>
                                {index !== 0 && <div className="divider my-2" />}
                                <div className="d-flex justify-content-between align-items-center">
                                    <Radio
                                        inline
                                        small
                                        id={customer.id}
                                        register={radioFormMethods.register}
                                        name="secondaryCustomerId"
                                        value={customer.id}
                                        defaultChecked={index === 0}
                                        label={
                                            <div>
                                                <div className="font-family-semibold">{customer.firstName}</div>
                                                <div
                                                    className="font-family-medium"
                                                    style={{
                                                        fontSize: 12,
                                                    }}>
                                                    {formatPhone(customer.reachNumber)}
                                                </div>
                                            </div>
                                        }
                                    />
                                    <CustomerBadge isPrimary={customer.isPrimary} />
                                </div>
                            </div>
                        ))}

                        <Button
                            color="secondary"
                            className="col-12 col-md-6 offset-md-3 mt-3"
                            onClick={radioFormMethods.handleSubmit((data) => {
                                setSelectedLine(data.secondaryCustomerId);
                                setShowModal(true);
                            })}>
                            Next
                        </Button>
                    </div>
                </Card>
            </div>
            {selectedCustomer && (
                <Modal
                    title="Account swap"
                    size="sm"
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    titleClassName={classNames({ disabled: showCustomerVerification })}>
                    <div
                        className={classNames("w-100 d-flex flex-column align-items-center", {
                            disabled: showCustomerVerification,
                        })}
                        style={{ pointerEvents: showCustomerVerification ? "none" : undefined }}>
                        <div className="font-family-bold text-center" style={{ fontSize: 16 }}>
                            Once you confirm, the below line will be the primary account holder.
                        </div>
                        <div className="font-family-bold mt-3">Current account</div>
                        <div className="border-bottom" style={{ width: 35 }} />
                        <div className="font-family-semibold mt-1">
                            {customerProfile.firstName} {customerProfile.lastName}
                        </div>
                        <div
                            className="font-family-medium"
                            style={{
                                fontSize: 12,
                            }}>
                            {formatPhone(customerProfile.reachNumber)}
                        </div>
                        <div className="font-family-bold mt-3">New account</div>
                        <div className="border-bottom" style={{ width: 35 }} />
                        <div className="font-family-semibold mt-1">
                            {selectedCustomer.firstName} {selectedCustomer.lastName}
                        </div>
                        <div
                            className="font-family-medium"
                            style={{
                                fontSize: 12,
                            }}>
                            {formatPhone(selectedCustomer.reachNumber)}
                        </div>
                        {(!selectedCustomer.userName || selectedCustomer.userName == "") && (
                            <Input
                                type="text"
                                placeholder="New Email address"
                                className="w-100 mt-3"
                                name="newPrimaryPreferredEmailId"
                                defaultValue={selectedCustomer.userName}
                                register={register({
                                    required: {
                                        value: true,
                                        message: "Email ID is required",
                                    },
                                    pattern: {
                                        value: EMAIL_PATTERN,
                                        message: "Not a valid email id",
                                    },
                                })}
                                showError={errors.newPrimaryPreferredEmailId != null}
                                errorMessage={errors.newPrimaryPreferredEmailId?.message}
                            />
                        )}
                        <Button
                            color="secondary"
                            fullWidth
                            className="mt-3"
                            loading={formState.isSubmitting}
                            onClick={() => setShowCustomerVerification(true)}>
                            Next
                        </Button>
                    </div>

                    {showCustomerVerification && (
                        <CustomerVerification onSubmit={handleSubmit(onSubmit)} customerProfile={customerProfile} />
                    )}
                </Modal>
            )}
        </Layout>
    );
}
