import React from "react";
import BSModal, { ModalProps as BSModalProps } from "react-bootstrap/Modal";

export type BottomSheetProps = BSModalProps;

export default function BottomSheet(props: BottomSheetProps) {
    const { children, title, onHide, ...rest } = props;

    return (
        <BSModal
            dialogClassName="modal-dialog-bottom"
            contentClassName="modal-content-bottom"
            onHide={onHide}
            {...rest}>
            <div className="modal-body d-flex flex-column align-items-center">{children}</div>
        </BSModal>
    );
}
