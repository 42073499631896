import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { createSecondaryCustomer, selectAdditionalLinesPurchase } from "../features/additional-lines-purchase-slice";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { ReachPlan } from "../utils/types";
import { Customer } from "../utils/types/customer";
import { Button } from "./button";
import { useErrorHandler } from "./error-snackbar";
import Modal, { ModalProps } from "./modal";
import { navigate } from "gatsby";
import { fetchProfile } from "../features/profile-slice";

function getTotalLineCost(plan: ReachPlan, lines: number) {
    const actualPrice = ((100 - plan.discountPctg) * plan.baseLinePrice) / 100;

    let prices = 0;
    const additionalLines = lines - 1;
    if (plan.addLineSplitPrice) {
        for (let i = 1; i <= additionalLines; i++) {
            prices = prices + plan.addLineSplitPrice[`${i}`];
        }
    } else {
        prices = additionalLines * plan.additionalLinePrice;
    }
    return actualPrice + prices;
}

export default function AddLineSummaryModal(props: ModalProps) {
    const { customerProfile, isSimInHand, reachPlan, additionalCustomers, groupLines } = useSelector(
        selectAdditionalLinesPurchase
    );

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const { handleSubmit, formState } = useForm();

    const currentPlanCost = getTotalLineCost(reachPlan!, groupLines.length);
    const newPlanCost = getTotalLineCost(reachPlan!, groupLines.length + additionalCustomers.length);

    const onSubmit = async () => {
        try {
            if (isSimInHand) {
                const customer = {
                    ...additionalCustomers[0],
                    addresses: customerProfile?.addresses,
                    reachPlanId: customerProfile?.reachPlan.name,
                    parentId: customerProfile!.id,
                    simPaid: true,
                    isSimInHand: true,
                };

                if (process.env.MOCK_ACTIVATION) {
                    customer.source = "DATAMI";
                }

                await unwrap(createSecondaryCustomer(customer as Customer));
                await dispatch(fetchProfile(customerProfile!.id));
                navigate(`/profile/${customerProfile!.id}`);
            } else {
                navigate("/add-lines/checkout");
            }
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    return (
        <Modal size="sm" {...props}>
            <div className="w-100 d-flex justify-content-between align-items-end">
                <div>
                    <div className="font-family-bold" style={{ fontSize: 16 }}>
                        Current pricing
                    </div>
                    <div className="font-family-semibold" style={{ fontSize: 12 }}>
                        {reachPlan!.name} {reachPlan!.planData}
                        {reachPlan!.dataUnit} for {groupLines.length} + Tax
                    </div>
                </div>
                <div className="font-family-semibold" style={{ fontSize: 12 }}>
                    ${currentPlanCost.toFixed(2)}
                </div>
            </div>
            <div className="divider my-3" />
            <div className="w-100 d-flex justify-content-between align-items-end mb-3">
                <div>
                    <div className="font-family-bold" style={{ fontSize: 16 }}>
                        New pricing
                    </div>
                    <div className="font-family-semibold" style={{ fontSize: 12 }}>
                        {reachPlan!.name} {reachPlan!.planData} {reachPlan!.dataUnit} for{" "}
                        {groupLines.length + additionalCustomers.length} + Tax
                    </div>
                </div>
                <div className="font-family-semibold" style={{ fontSize: 12 }}>
                    ${newPlanCost.toFixed(2)}
                </div>
            </div>
            <Button color="secondary" fullWidth onClick={handleSubmit(onSubmit)} loading={formState.isSubmitting}>
                Confirm
            </Button>
        </Modal>
    );
}
