import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import Layout from "../../components/layout";
import { Link, navigate } from "gatsby";
import scanSimImg from "../../images/scanSim.png";
import branding from "../../branding/branding.json";
import { Button } from "../../components/button";
import { Input } from "../../components/input";
import { Controller, useForm } from "react-hook-form";
import { activationCustomerSelector, validateIccId } from "../../features/activation-slice";
import { useSelector } from "react-redux";
import { useUnwrapAsyncThunk } from "../../utils/store";
import { useErrorHandler } from "../../components/error-snackbar";
import CustomerBadge from "../../components/customer-badge";

type ScanSIMProps = RouteComponentProps;

enum PageState {
    // INFO,
    // SCAN_SIM,
    ENTER_MANUALLY,
}

export default function ScanSIM(props: ScanSIMProps) {
    const customer = useSelector(activationCustomerSelector);

    const [pageState, setPageState] = useState(PageState.ENTER_MANUALLY);

    const { handleSubmit, control, errors, formState, setError } = useForm({
        mode: "onBlur",
    });
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    if (!customer) {
        navigate("/customers");
        return null;
    }

    const onSubmit = async (data: { simId: string }) => {
        try {
            const res = await unwrap(validateIccId({ id: customer.id, simId: data.simId }));
            if (res.isValid) {
                navigate("/activation");
            } else {
                setError("simId", { message: "Invalid Sim Id" });
            }
        } catch (error) {
            console.log(error);
            if (error.resCode === 40155) {
                setError("simId", { message: "Invalid Sim Id" });
            } else {
                handleError(error);
            }
        }
    };

    if (pageState === PageState.ENTER_MANUALLY) {
        return (
            <Layout title={`${customer.firstName} ${customer.lastName} - Enter Welcome Kit ID`}>
                <div className="container col-10 d-flex flex-column mt-3 px-0">
                    <Link className="text-cta mb-3" to={`/profile/${customer.id}`}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                    <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                        <div>
                            {customer.firstName} {customer.lastName} <CustomerBadge isPrimary={customer.isPrimary} />
                        </div>
                        <div>{customer.emailId}</div>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <h1>Enter Welcome Kit ID</h1>
                    </div>
                    <div className="divider" />

                    <form className="d-flex flex-column align-items-center mt-3" onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "ICCID is required",
                                },
                                pattern: {
                                    value: /^\d{19,20}$/,
                                    message: "ICCID must be 19 or 20 digits.",
                                },
                            }}
                            name="simId"
                            defaultValue={""}
                            render={({ onChange, onBlur, value, name }) => (
                                <Input
                                    type="text"
                                    inputMode="numeric"
                                    placeholder="Enter ICCID"
                                    className="col-12 col-md-8 col-xl-4 px-0"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value.replace(/\s/g, "")}
                                    name={name}
                                    maxLength={20}
                                    characterRestriction={20}
                                    showError={errors.simId != null}
                                    errorMessage={errors.simId?.message}
                                />
                            )}
                        />
                        <Button
                            color="secondary"
                            type="submit"
                            loading={formState.isSubmitting}
                            className="col-12 col-md-8 col-xl-4 my-3">
                            Next
                        </Button>
                    </form>
                </div>
            </Layout>
        );
    }

    return (
        <Layout title={`${customer.firstName} ${customer.lastName} - Activate Service`}>
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <Link className="text-cta mb-3" to={`/profile/${customer.id}`}>
                    <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                    Back
                </Link>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customer.firstName} {customer.lastName} <CustomerBadge isPrimary={customer.isPrimary} />
                    </div>
                    <div>{customer.emailId}</div>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>Activate Service</h1>
                </div>
                <div className="divider" />

                <div className="d-flex flex-column align-items-center mt-5">
                    <img src={scanSimImg} />
                    <div className="font-family-bold text-center mt-3" style={{ fontSize: 16 }}>
                        Scan the barcode on your SIM
                    </div>
                    <div className="text-center col-12 col-md-8 mt-2">
                        Your {branding.shortName} Welcome Kit includes a SIM card with a barcode on the back.
                    </div>
                    <Button
                        fullWidth
                        color="secondary"
                        onClick={() => setPageState(PageState.ENTER_MANUALLY)}
                        className="col-12 col-md-6 col-xl-4 my-3">
                        Next
                    </Button>
                    <div
                        className="text-cta align-self-center mb-3"
                        onClick={() => setPageState(PageState.ENTER_MANUALLY)}>
                        Enter Manually
                    </div>
                </div>
            </div>
        </Layout>
    );
}
