import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Link } from "gatsby";
import { Button } from "../../components/button";
import { Input } from "../../components/input";
import Layout from "../../components/layout";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useUnwrapAsyncThunk } from "../../utils/store";
import deviceCompatibleImg from "../../images/device-compatible.png";
import deviceInompatibleImg from "../../images/device-incompatible.png";
import branding from "../../branding/branding.json";
import { useErrorHandler } from "../../components/error-snackbar";
import { checkIMEIInfo } from "../../features/profile-slice";
import { ImeiInfoRes } from "../../utils/types";

export interface DeviceCompatibilityProps extends RouteComponentProps {
    defaultState?: UIState;
}

interface FormData {
    imei: string;
}

enum UIState {
    CHECK_IMEI,
    IMEI_VALID,
    IMEI_INVALID,
    IMEI_INVALID_5G,
}

export default function DeviceCompatibility(props: DeviceCompatibilityProps) {
    const { errors, handleSubmit, control, formState } = useForm({
        mode: "onBlur",
    });
    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const [uiState, setUIState] = useState(props.defaultState ?? UIState.CHECK_IMEI);
    const [imeiRes, setImeiRes] = useState<ImeiInfoRes>();

    const onSubmit = async (data: FormData) => {
        try {
            const res = await unwrap(
                checkIMEIInfo({
                    imei: data.imei,
                    reachMvne: process.env.MOCK_ACTIVATION ? "DATAMI" : "TELISPIRE",
                    // reachMvne: "TELISPIRE",
                })
            );
            if (res.isValid) {
                setUIState(UIState.IMEI_VALID);
                setImeiRes(res);
            } else if (!res.isValid && res.mode === "5GDevice") {
                setUIState(UIState.IMEI_INVALID_5G);
                setImeiRes(res);
            } else {
                setUIState(UIState.IMEI_INVALID);
            }
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    return (
        <Layout title="Tools - Device Compatibility">
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <Link className="text-cta mb-3" to="/tools">
                    <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                    Back
                </Link>

                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>Device Compatibility</h1>
                </div>
                <div className="divider" />

                {uiState === UIState.IMEI_VALID && (
                    <>
                        <div className="row flex-column col-md-8 col-xl-4 mx-auto mt-4 d-flex align-items-center">
                            <img src={deviceCompatibleImg} />
                            <h2 className="my-3">Yay! This phone's a keeper</h2>
                        </div>

                        <div className="divider py-3 d-flex justify-content-between">
                            <div>Device Make and Model</div>
                            <div>
                                {imeiRes?.make} {imeiRes?.model}
                            </div>
                        </div>
                        <div className="divider py-3 d-flex justify-content-between">
                            <div>Mode</div>
                            <div>{imeiRes?.mode}</div>
                        </div>
                        <div className="divider py-3 d-flex justify-content-between">
                            <div>WiFi Calling Support</div>
                            <div>{imeiRes?.wifiCalling}</div>
                        </div>
                        <div className="divider py-3 d-flex justify-content-between">
                            <div>Is reported lost or stolen</div>
                            <div>{imeiRes?.isLostOrStolen}</div>
                        </div>
                        <div className="divider py-3 d-flex justify-content-between">
                            <div>Is phone in box locked</div>
                            <div>{imeiRes?.isPhoneInBoxLocked ?? "No"}</div>
                        </div>
                        <div className="divider py-3 d-flex justify-content-between">
                            <div>HD Voice</div>
                            <div>{imeiRes?.hdVoice ?? "No"}</div>
                        </div>
                        {/* <div className="divider py-3 d-flex justify-content-between">
                            <div>Discount Level</div>
                            <div>{imeiRes?.discountLevel}</div>
                        </div> */}
                        <div className="divider py-3 d-flex justify-content-between">
                            <div>CDMAless</div>
                            <div>{imeiRes?.cdmaLess}</div>
                        </div>
                        <div className="py-3 d-flex justify-content-between">
                            <div>Device is in use from reach on verizon</div>
                            <div>{imeiRes?.deviceIsInUseFromReachOnVerizon ?? "No"}</div>
                        </div>

                        <div className="row flex-column col-md-8 col-xl-4 mx-auto mt-4 d-flex align-items-center">
                            <div className="font-family-semibold text-center mx-n3" style={{ fontSize: 15 }}>
                                Good news - your phone will work on our network. Before you activate, please make sure
                                your phone is unlocked. Contact the carrier or retailer you purchased from to confirm.
                            </div>
                            <Button
                                color="secondary"
                                fullWidth
                                className="my-4"
                                onClick={() => setUIState(UIState.CHECK_IMEI)}>
                                Check another
                            </Button>
                        </div>
                    </>
                )}

                {uiState === UIState.IMEI_INVALID_5G && (
                    <>
                        <div className="row flex-column col-md-8 col-xl-6 mx-auto mt-4 d-flex align-items-center">
                            <img src={deviceCompatibleImg} />
                            <h2 className="my-3 text-center">Bummer! This phone isn't compatible.</h2>
                        </div>

                        <div className="divider py-3 d-flex justify-content-between">
                            <div>Device Make and Model</div>
                            <div>
                                {imeiRes?.make} {imeiRes?.model}
                            </div>
                        </div>
                        <div className="divider py-3 d-flex justify-content-between align-items-center">
                            <div>Mode</div>
                            <div className="bg-danger p-2 text-white">{imeiRes?.mode}</div>
                        </div>

                        <div className="row flex-column col-md-8 col-xl-4 mx-auto mt-4 d-flex align-items-center">
                            <div className="font-family-semibold text-center mx-n3" style={{ fontSize: 15 }}>
                                Sorry! We are currently unable to support this 5G device. But we will be able to support
                                5G in the fall. Would the customer like to be notified once we support 5G?
                            </div>
                            {/* <Button
                                color="secondary"
                                fullWidth
                                className="mt-4"
                                onClick={() => setUIState(UIState.CHECK_IMEI)}>
                                Notify me when 5G is supported
                            </Button> */}
                            <Button
                                color="secondary"
                                fullWidth
                                className="my-2"
                                onClick={() => setUIState(UIState.CHECK_IMEI)}>
                                Try another IMEI
                            </Button>
                        </div>
                    </>
                )}

                {uiState === UIState.IMEI_INVALID && (
                    <div className="row flex-column col-md-8 col-xl-4 mx-auto mt-4 d-flex align-items-center">
                        <img src={deviceInompatibleImg} />
                        <h2 className="text-danger my-3">Bummer! This phone isn't compatible.</h2>
                        <div className="font-family-semibold text-center mx-n3" style={{ fontSize: 15 }}>
                            It may be locked to a different carrier or could be a legacy model we don't support. You can
                            try another device or purchase a new unlocked phone from {branding.shortName}, no contract
                            required.
                        </div>
                        <Button
                            color="secondary"
                            fullWidth
                            className="mt-4"
                            onClick={() => setUIState(UIState.CHECK_IMEI)}>
                            Try again
                        </Button>
                    </div>
                )}

                {uiState === UIState.CHECK_IMEI && (
                    <>
                        <form className="row d-flex flex-column align-items-center" onSubmit={handleSubmit(onSubmit)}>
                            <div className="font-family-semibold text-center mt-5">
                                Let’s make sure your device will work on the {branding.shortName} network:
                            </div>
                            <div className="col-12 col-md-6 col-xl-4">
                                <Controller
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "IMEI is required",
                                        },
                                        pattern: {
                                            value: /^\d{14,15}$/,
                                            message: "IMEI must be 14 or 15 digits.",
                                        },
                                    }}
                                    name="imei"
                                    defaultValue={""}
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Input
                                            type="text"
                                            inputMode="numeric"
                                            placeholder="Enter your device IMEI or MEID Number"
                                            className="my-3"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value.replace(/\s/g, "")}
                                            // value={value.split(" ").join("")}
                                            name={name}
                                            maxLength={value.replace(/\s/g, "").length >= 15 ? 15 : 22}
                                            size={value.replace(/\s/g, "").length >= 15 ? 15 : 22}
                                            characterRestriction={15}
                                            showError={errors.imei}
                                            errorMessage={errors.imei?.message}
                                        />
                                    )}
                                />

                                <Button color="secondary" fullWidth loading={formState.isSubmitting}>
                                    Check
                                </Button>
                                <div className="font-family-semibold text-center mt-2">
                                    * Make sure your device is unlocked (paid in full)
                                </div>
                            </div>
                        </form>

                        <div>
                            <div className="font-family-semibold mt-5">How to find your IMEI:</div>
                            <div>On most phones, dial *#06#</div>

                            <div>
                                <div className="d-flex mt-4">
                                    <span
                                        className="reach-cs-ios mr-3"
                                        style={{
                                            fontSize: 24,
                                            color: "#494949",
                                        }}
                                    />
                                    <div>
                                        iOS
                                        <br />
                                        Go to Setting - General - About - IMEI
                                    </div>
                                </div>
                                <div className="d-flex mt-3">
                                    <span
                                        className="reach-cs-android mr-3"
                                        style={{
                                            fontSize: 24,
                                            color: "#494949",
                                        }}
                                    />
                                    <div>
                                        Android
                                        <br />
                                        Go to Settings - About phone - Status - IMEI
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Layout>
    );
}
