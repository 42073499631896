import React from "react";
import { Controller, useForm } from "react-hook-form";
import { fetchProfile, manageCDMAless, sendTemporaryPassword } from "../features/profile-slice";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile } from "../utils/types";
import { Button } from "./button";
import { useErrorHandler } from "./error-snackbar";
import { Input } from "./input";
import Modal, { ModalProps } from "./modal";
import { useSuccessModal } from "./success-modal";
import Toggle from "./toggle";

export interface TemporaryPasswordModalProps extends ModalProps {
    customerProfile: CustomerProfile;
}

export default function TemporaryPasswordModal(props: TemporaryPasswordModalProps) {
    const { customerProfile, ...rest } = props;

    const { handleSubmit, formState } = useForm({ mode: "onBlur" });

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();

    const onSubmit = async (data: FormData) => {
        try {
            await unwrap(sendTemporaryPassword(customerProfile.emailId));
            await dispatch(fetchProfile(customerProfile.id));
            showSuccessModal(`Temporary password sent successfully`);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        rest.onHide();
    };

    return (
        <Modal {...rest} size="sm">
            <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
                <Button color="secondary" fullWidth loading={formState.isSubmitting}>
                    Confirm
                </Button>
            </form>
        </Modal>
    );
}
