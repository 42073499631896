import React from "react";
import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";
import Layout from "../../components/layout";
import { QuickActionCard } from "../../components/quick-action-card";
import image1 from "../../images/csDashboard@2x.png";
import image2 from "../../images/icon-dashboard@2x.png";
import PageLayout from "../../components/page-layout";
import Accordion, { AccordionBody } from "../../components/accordion";

export type DashboardsProps = RouteComponentProps;

export default function CSDashboards(props: DashboardsProps) {
    return (
        <PageLayout title="CS Dashboards" prevUrl="/dashboards">
            <Accordion>
                <AccordionBody title="">
                    <div className="row row-cols-2 row-cols-md-4 row-cols-lg-5 mx-n2">
                        <div className="p-2 col">
                            <QuickActionCard
                                icon={image1}
                                title="Manager Dashboard"
                                onClick={() => {
                                    navigate("/manager-dashboards");
                                }}
                            />
                        </div>
                        <div className="p-2 col">
                            <QuickActionCard
                                icon={image2}
                                title="My Dashboard"
                                onClick={() => {
                                    navigate("/my-dashboard");
                                }}
                            />
                        </div>
                    </div>
                </AccordionBody>
            </Accordion>
        </PageLayout>
    );
}
