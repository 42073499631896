import { RouteComponentProps } from "@reach/router";
import firebase from "firebase/app";
import { Link, navigate } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { useSelector } from "react-redux";
import { Button } from "../components/button";
import { Input } from "../components/input";
import Modal from "../components/modal";
import { AuthState, authStateSelector, authStateUpdated, getTenantId, userUpdated } from "../features/auth-slice";
import { accountIdUpdated } from "../features/user-role-slice";
import errorIcon from "../images/cancel-icon.png";
import { isSSOEnabled } from "../utils/helpers";
import brandLogo from "../images/brandLogo.png";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import SessionTimeoutModal from "./session-timeout-modal";

export interface LoginProps extends RouteComponentProps {
    changeUiState?: any;
}

interface FormData {
    accountId: string;
    emailId: string;
    password: string;
    otp?: string;
}

let resolver: firebase.auth.MultiFactorResolver;
let recaptchaVerifier: firebase.auth.RecaptchaVerifier;
let verificationId: string;

export default function Login(props: LoginProps) {
    const { changeUiState } = props;
    const { authState } = useSelector(authStateSelector);
    const recaptchaContainer = useRef<HTMLDivElement>(null);
    const [isError, setIsError] = useState<boolean>(false);
    const [isAuthError, setIsAuthError] = useState<boolean>(false);
    const [loginError, setLoginError] = useState<any>();
    const [resendLoading, setResendLoading] = useState<boolean>(false);
    const [showSessionLogoutModal, setSessionLogoutModal] = useState(false);

    const { register, handleSubmit, errors, formState, setError, control } = useForm<FormData>({
        mode: "onBlur",
    });

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();

    let session_timeout_message: any;
    useEffect(() => {
        session_timeout_message = localStorage.getItem("session_timeout");
        if (session_timeout_message) {
            setSessionLogoutModal(true);
        }
    }, []);

    // need to fix for redirection and clearing is_timeout from local
    useEffect(() => {
        const local_redirect_url: any = localStorage.getItem("redirect_url");
        if (authState === AuthState.LOGGED_IN) {
            if (local_redirect_url) {
                navigate(local_redirect_url);
            } else {
                navigate("/");
            }
            localStorage.removeItem("is_timeout");
        }
    }, [authState]);

    // using this for redirecting the users to logout page (Astound-SSO specific handling)
    useEffect(() => {
        if (props && props.location && props.location.search) {
            const redirect_url = props.location.search?.split("?redirect_url=");
            if (redirect_url && redirect_url.length > 0) {
                window.location.href = redirect_url[1];
            }
        }
    }, []);

    useEffect(() => {
        // check this condition so that stories don't fail
        // firebase.auth().settings.appVerificationDisabledForTesting = true;
        if (props.location) {
            recaptchaVerifier = new firebase.auth.RecaptchaVerifier(recaptchaContainer.current, {
                size: "invisible",
            });
        }
    }, []);

    useEffect(() => {
        const isTimedOut = localStorage.getItem("is_timeout") === "true" ? true : false;
        if (isTimedOut && props?.path === "/login") {
            setIsAuthError(true);
        }

        return () => {
            if (props?.path === "/login") {
                localStorage.removeItem("is_timeout");
            }
        };
    }, [props]);

    const verifyPhoneNumber = async () => {
        setResendLoading(true);
        try {
            if (resolver.hints[0].factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
                const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
                verificationId = await phoneAuthProvider.verifyPhoneNumber(
                    {
                        session: resolver.session,
                        multiFactorHint: resolver.hints[0],
                    },
                    recaptchaVerifier
                );

                dispatch(authStateUpdated(AuthState.MFA));
            }
        } catch (error) {
            console.log(error);
            setResendLoading(false);
        } finally {
            setResendLoading(false);
        }
    };

    const verifyOTP = async (verificationCode: string) => {
        try {
            const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
            const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
            // Complete sign-in.
            const userCredentials = await resolver.resolveSignIn(multiFactorAssertion);
            dispatch(
                userUpdated({
                    emailId: userCredentials.user?.email,
                    displayName: userCredentials.user?.displayName,
                })
            );
        } catch (error: any) {
            console.log(error);
            if (error.code === "auth/invalid-verification-code" || error.code === "auth/code-expired") {
                // TODO: Handle error
                setIsError(true);
                setLoginError({
                    message: "You seem to have entered an invalid otp or entered otp is expired. Please try again.",
                });
            }
        }
    };

    const signIn = async (data: FormData) => {
        try {
            const res: any = await unwrap(getTenantId(data.accountId));
            dispatch(accountIdUpdated(data.accountId));
            firebase.auth().tenantId = res.tenantId;
            const userCredentials = await firebase.auth().signInWithEmailAndPassword(data.emailId, data.password);
            dispatch(
                userUpdated({
                    emailId: userCredentials.user?.email,
                    displayName: userCredentials.user?.displayName,
                })
            );
        } catch (error: any) {
            const standardError = {
                message:
                    "You seem to have entered invalid credentials. Please enter the correct login details and try again.",
            };
            if (error && error.code !== "auth/multi-factor-auth-required") {
                setIsError(true);
                setLoginError(standardError);
            }
            if (error.resCode === 40020) {
                setError("accountId", { message: "Invalid account ID" });
            } else if (error.code === "auth/invalid-email" || error.code === "auth/user-not-found") {
                setError("emailId", { message: "Invalid email id" });
            } else if (error.code === "auth/wrong-password") {
                setError("password", { message: "Wrong password" });
            } else if (error.code === "auth/multi-factor-auth-required") {
                console.log("Handle MFA");
                resolver = error.resolver;
                await verifyPhoneNumber(); // await required for formState.isSubmitting
            }
        }
    };

    const onSubmit = async (data: FormData) => {
        // await required for formState.isSubmitting
        if (data.otp) {
            await verifyOTP(data.otp);
        } else {
            await signIn(data);
        }
        localStorage.removeItem("is_timeout");
    };

    const sessionLogoutSubmit = () => {
        localStorage.removeItem("session_timeout");
        setSessionLogoutModal(false);
    };

    return (
        <>
            <form
                className="container col-lg-4 col-md-6 col-sm-12 d-flex flex-column mt-5"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off">
                <img className="align-self-center mt-4" src={brandLogo} height={45} />
                {authState === AuthState.MFA && "phoneNumber" in resolver.hints[0] ? (
                    // @ts-ignore
                    <h1 className="mt-5">OTP sent to {resolver.hints[0].phoneNumber}</h1>
                ) : (
                    <>
                        {isSSOEnabled() ? (
                            <div className="mt-3 flex-center-row pt-4">
                                <span
                                    className="reach-cs-back-arrow-thin ml-n5 mr-4 text-cta"
                                    // style={{
                                    //   transform: "rotate(180deg)",
                                    // }}
                                    onClick={() => {
                                        if (changeUiState && !formState.isSubmitting) {
                                            changeUiState();
                                        }
                                    }}
                                />
                                <b className="fs-20 ml-1">Login - Reach team only</b>
                            </div>
                        ) : (
                            <h1 className="mt-5">Login</h1>
                        )}
                    </>
                )}
                <div className="divider w-100 mt-1 mb-3" />
                {authState === AuthState.MFA ? (
                    <Controller
                        render={({ onChange, value }) => (
                            <OtpInput
                                numInputs={6}
                                onChange={onChange}
                                value={value}
                                className="mt-4"
                                isInputNum={true}
                                separator={<span />}
                                inputStyle="form-control otp-input"
                                containerStyle="justify-content-between"
                            />
                        )}
                        control={control}
                        name="otp"
                    />
                ) : (
                    <>
                        <Input
                            type="text"
                            placeholder="Account ID"
                            register={register({
                                required: { value: true, message: "Account ID is required" },
                            })}
                            disabled={resendLoading || formState.isSubmitting}
                            // showError={errors.accountId != null}
                            errorMessage={errors.accountId?.message}
                            id="accountId"
                            name="accountId"
                        />
                        <Input
                            type="text"
                            placeholder="Email address"
                            className="mt-4"
                            register={register({
                                required: { value: true, message: "Email address is required" },
                            })}
                            disabled={resendLoading || formState.isSubmitting}
                            // showError={errors.emailId != null}
                            errorMessage={errors.emailId?.message}
                            id="emailId"
                            name="emailId"
                        />
                        <Input
                            type="password"
                            placeholder="Password"
                            className="mt-4"
                            register={register({
                                required: { value: true, message: "Password is required" },
                            })}
                            disabled={resendLoading || formState.isSubmitting}
                            // showError={errors.password != null}
                            errorMessage={errors.password?.message}
                            id="password"
                            name="password"
                        />
                    </>
                )}

                {authState === AuthState.MFA ? (
                    <>
                        {resendLoading ? (
                            <div className="d-flex justify-content-center mt-3">
                                <div className="spinner-border text-primary" />
                            </div>
                        ) : (
                            <div className="font-family-semibold mt-3 " style={{ fontSize: 15 }}>
                                Didn't receive your code?{" "}
                                <span
                                    className="align-self-center text-cta mb-3"
                                    style={{ fontSize: 14 }}
                                    onClick={verifyPhoneNumber}>
                                    Resend code
                                </span>
                            </div>
                        )}
                    </>
                ) : null}

                <div ref={recaptchaContainer} className="mt-3" />

                <Button
                    style={{ width: "45%", padding: "10px 0px" }}
                    color="secondary"
                    className="mt-2 mb-3 fs-15 font-family-regular mx-auto"
                    fullWidth
                    disabled={resendLoading}
                    loading={formState.isSubmitting}>
                    {authState === AuthState.MFA ? "Verify" : "Log In"}
                </Button>

                <Link className="text-cta align-self-center fs-15" to="/forgot-password">
                    Forgot Password?
                </Link>

                {loginError && isError && (
                    <Modal
                        title=""
                        show={isError}
                        onHide={() => {
                            setIsError(false);
                            setLoginError(null);
                        }}>
                        <img src={errorIcon} className="mt-n3 mb-3" />
                        <div className="font-family-semibold text-center">
                            <span className="font-family-bold">Error:</span>{" "}
                            {typeof loginError.message === "object"
                                ? loginError.message.map((message: any) => <div key={message}>{message}</div>)
                                : loginError.message}
                            <br />
                            {"txnId" in loginError && `(txnId: ${loginError.txnId})`}
                        </div>
                    </Modal>
                )}

                {isAuthError && <SessionTimeoutModal isAuthError={isAuthError} setIsAuthError={setIsAuthError} />}
            </form>
            <Modal
                title="You've been logged out"
                size="md"
                show={showSessionLogoutModal}
                onHide={() => {
                    setSessionLogoutModal(false);
                    sessionLogoutSubmit();
                }}>
                <div className="font-family-semibold fs-14 text-center">
                    We noticed that you were inactive during the session, so we have logged you out for your safety.
                    Please click the button to login again.
                </div>
                <Button
                    className="mt-3 col-5 mx-auto btn-secondary"
                    type="submit"
                    onHide={() => {
                        setSessionLogoutModal(false);
                    }}
                    onClick={() => {
                        sessionLogoutSubmit();
                    }}>
                    Log In Again
                </Button>
            </Modal>
        </>
    );
}
