import React from "react";
import { RouteComponentProps } from "@reach/router";
import Layout from "../components/layout";
import { Link, navigate } from "gatsby";
import { Card } from "../components/card";
import { Button } from "../components/button";
import { Input } from "../components/input";
import { useForm } from "react-hook-form";
import { EMAIL_PATTERN } from "../utils/helpers";
import firebase from "firebase/app";
import { useSuccessModal } from "../components/success-modal";
import { useErrorHandler } from "../components/error-snackbar";
import { Duration } from "../components/snackbar";

export type ResetPasswordProps = RouteComponentProps;

interface FormData {
    password: string;
    confirmPassword: string;
}

export default function ResetPassword(props: ResetPasswordProps) {
    const { errors, handleSubmit, register, formState, watch } = useForm<FormData>({
        mode: "onBlur",
    });

    const searchParams = new URLSearchParams(props.location?.search);

    const actionCode = searchParams.get("oobCode");
    const tenantId = searchParams.get("tenantId");

    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();

    if (!actionCode) {
        navigate("/login");
        return null;
    }

    const onSubmit = async (data: FormData) => {
        try {
            firebase.auth().tenantId = tenantId;
            const emailId = await firebase.auth().verifyPasswordResetCode(actionCode);
            await firebase.auth().confirmPasswordReset(actionCode, data.password);
            showSuccessModal(`Password reset for user ${emailId}`, Duration.SHORT);
            setTimeout(() => {
                navigate("/login");
            }, Duration.SHORT);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    return (
        <Layout title="Reset password">
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <Link className="text-cta mb-3" to="/login">
                    <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                    Back
                </Link>

                <div className="h2">Reset password</div>

                <Card className="mt-3">
                    <Input
                        type="password"
                        placeholder="Password"
                        className="col-12 mb-3"
                        name="password"
                        register={register({
                            required: {
                                value: true,
                                message: "Password is required",
                            },
                            validate: (value) => {
                                if (value.length < 8) {
                                    return "Password must be 8 characters long";
                                }

                                if (!/[\W_]/.test(value)) {
                                    return "Must contain atleast one special character";
                                }

                                if (!/[\d]/.test(value)) {
                                    return "Must contain atleast one number";
                                }

                                return true;
                            },
                        })}
                        showError={errors.password != null}
                        errorMessage={errors.password?.message}
                    />
                    <Input
                        type="password"
                        placeholder="Confirm password"
                        className="col-12 mb-3"
                        name="confirmPassword"
                        register={register({
                            required: {
                                value: true,
                                message: "Password is required",
                            },
                            validate: (value) => value === watch("password") || "Password does not match",
                        })}
                        showError={errors.confirmPassword != null}
                        errorMessage={errors.confirmPassword?.message}
                    />
                    <Button
                        color="secondary"
                        className="col-12 col-md-6"
                        loading={formState.isSubmitting}
                        onClick={handleSubmit(onSubmit)}>
                        Submit
                    </Button>
                </Card>
            </div>
        </Layout>
    );
}
