import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Link, navigate } from "gatsby";
import { useSelector } from "react-redux";
import { Card } from "../components/card";
import { useErrorHandler } from "../components/error-snackbar";
import Layout from "../components/layout";
import { getIldOverallUsage, profilesSelector } from "../features/profile-slice";
import { RootState, useUnwrapAsyncThunk } from "../utils/store";
import { compareAsc, format, parse } from "date-fns";
import classNames from "classnames";
import { formatPhone } from "../utils/helpers";
import CustomerBadge from "../components/customer-badge";

export interface ILDUsageProps extends RouteComponentProps {
    customerId: string;
}

export interface IldCountryUsage {
    voiceUsage: number;
    voiceCost: number;
    totalCost: number;
    ratePerMin: number;
    ratePerPulse: number;
    countryCode: string;
    countryName: string;
}

export interface IldCustomerUsage {
    customerId: string;
    voicePulseSize: number;
    totalUsage: {
        voiceUsage?: number;
        smsUsage?: number;
        voiceCost?: number;
        smsCost?: number;
        totalCost?: number;
        serviceCharge: number;
    };
    countryUsage?: IldCountryUsage[];
}

export interface IldMonthlyUsage {
    startDate: Date;
    endDate: Date;
    customerUsages: IldCustomerUsage[];
}

export default function ILDUsage(props: ILDUsageProps) {
    const { customerId } = props;

    const customerProfile = useSelector((state: RootState) => profilesSelector.selectById(state, customerId));

    const handleError = useErrorHandler();
    const unwrap = useUnwrapAsyncThunk();

    const [ildUsage, setILDUsage] = useState<IldMonthlyUsage[]>();
    const [usageIndex, setUsageIndex] = useState(0);

    const [isExpandedMap, setIsExpandedMap] = useState<Record<string, boolean | undefined>>({});

    useEffect(() => {
        async function fetchILDUsage() {
            if (customerProfile) {
                try {
                    const res = await unwrap(getIldOverallUsage(customerProfile.groupId));

                    const overallUsage: IldMonthlyUsage[] = Object.entries(res)
                        .map(([_, value]) => ({
                            startDate: new Date(value.billStartDate),
                            endDate: new Date(value.billEndDate),
                            customerUsages: Object.entries(value.usageDetail)
                                .map(([customerId, v]) => ({
                                    customerId: customerId,
                                    voicePulseSize: v.voicePulseSize ?? 1,
                                    countryUsage: v.countryUsageMap
                                        ? Object.entries(v.countryUsageMap).map(([countryCode, data]) => ({
                                              countryCode,
                                              countryName: countryCode,
                                              ...data,
                                          }))
                                        : undefined,
                                    totalUsage: v.totalUsage,
                                }))
                                .sort((usage1, usage2) => {
                                    const primaryCustomer = customerProfile.groupLines.find((cust) => cust.isPrimary)!;

                                    if (usage1.customerId === primaryCustomer.id) {
                                        return -1;
                                    }
                                    if (usage2.customerId === customerProfile.id) {
                                        return 1;
                                    }

                                    return 0;
                                }),
                        }))
                        .sort((usage1, usage2) => compareAsc(usage1.endDate, usage2.endDate));
                    setILDUsage(overallUsage);
                    setUsageIndex(overallUsage.length - 1);
                } catch (error) {
                    console.log(error);
                    handleError(error);
                }
            }
        }
        fetchILDUsage();
    }, []);

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    if (!ildUsage) {
        return (
            <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - Usage details`}>
                <div className="container col-10 d-flex flex-column my-3 px-0">
                    <div className="d-flex justify-content-between">
                        <Link className="text-cta mb-3" to={`/ild/manage/${customerId}`}>
                            <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                            Back
                        </Link>
                    </div>
                    <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                        <div>
                            {customerProfile.firstName} {customerProfile.lastName}{" "}
                            <CustomerBadge isPrimary={customerProfile.isPrimary} />
                        </div>
                        <div>{customerProfile.emailId}</div>
                    </div>
                    <Card>
                        <div className="spinner-border text-primary" />
                    </Card>
                </div>
            </Layout>
        );
    }

    const selectedMonthUsage = ildUsage[usageIndex];

    const leftDisabled = usageIndex === 0;
    const rightDisabled = usageIndex === ildUsage.length - 1;

    return (
        <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - Usage details`}>
            <div className="container col-10 d-flex flex-column my-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to={`/ild/manage/${customerId}`}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>

                <Card>
                    <div className="font-family-bold align-self-start mb-3" style={{ fontSize: 16 }}>
                        Usage details
                    </div>
                    <div className="d-flex align-items-center mb-3">
                        <span
                            style={{ fontSize: 10 }}
                            className={classNames("reach-cs-arrow-left", { disabled: leftDisabled })}
                            onClick={() => !leftDisabled && setUsageIndex(usageIndex - 1)}
                        />
                        <div className="font-family-semibold mx-2">
                            {format(new Date(selectedMonthUsage.startDate), "MMM dd, yyyy")} -{" "}
                            {format(new Date(selectedMonthUsage.endDate), "MMM dd, yyyy")}
                        </div>
                        <span
                            style={{ fontSize: 10 }}
                            className={classNames("reach-cs-arrow-right", {
                                disabled: rightDisabled,
                            })}
                            onClick={() => !rightDisabled && setUsageIndex(usageIndex + 1)}
                        />
                    </div>

                    {selectedMonthUsage.customerUsages.map((item, index) => {
                        const customer = customerProfile.groupLines.find(
                            (customer) => customer.id === item.customerId
                        )!;
                        const isExpanded = isExpandedMap[item.customerId] ?? false;
                        console.log(item);
                        return (
                            <div className="w-100 font-family-semibold" key={item.customerId}>
                                <div
                                    className="d-flex justify-content-between align-items-center"
                                    onClick={() =>
                                        setIsExpandedMap((map) => ({ ...map, [item.customerId]: !isExpanded }))
                                    }>
                                    <div>
                                        <div>{customer.firstName}</div>
                                        <div>{formatPhone(customer.reachNumber)}</div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        $
                                        {item.totalUsage.totalCost?.toFixed(2) ||
                                            item.totalUsage.serviceCharge.toFixed(2)}
                                        <span
                                            className={classNames(
                                                "ml-3",
                                                isExpanded ? "reach-cs-arrow-up" : "reach-cs-arrow-down"
                                            )}
                                            style={{ fontSize: 12 }}
                                        />
                                    </div>
                                </div>
                                {isExpanded && (
                                    <div
                                        className="my-2 mx-n2 mx-md-n3 px-3 py-2"
                                        style={{ backgroundColor: "#f5f5f5", fontSize: 12 }}>
                                        <div>Voice details</div>
                                        <div className="d-flex mt-2">
                                            <div className="col px-0">Country</div>
                                            <div className="col">Usage (Min)</div>
                                            <div className="col">
                                                Rate ($/
                                                {item.voicePulseSize === 1 ? "" : `${item.voicePulseSize} `}Min)
                                            </div>
                                            <div className="">Charges</div>
                                        </div>
                                        <div className="divider mt-1" />
                                        {item.countryUsage ? (
                                            item.countryUsage.map((country) => (
                                                <div className="d-flex mt-1" key={country.countryCode}>
                                                    <div className="col px-0">{country.countryName}</div>
                                                    <div className="col">{country.voiceUsage}</div>
                                                    <div className="col">${country.ratePerPulse.toFixed(2)}</div>
                                                    <div className="">${country.totalCost.toFixed(2)}</div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="d-flex mt-1">
                                                <div className="col px-0">-</div>
                                                <div className="col">-</div>
                                                <div className="col">-</div>
                                                <div className="" style={{ width: 47 }}>
                                                    -
                                                </div>
                                            </div>
                                        )}
                                        <div className="mt-2">Service charge</div>
                                        <div className="divider my-1" />
                                        <div>${item.totalUsage.serviceCharge?.toFixed(2) || 0}</div>
                                        <div className="mt-2">
                                            <span className="font-family-bold">Note:</span> Please refer invoice for the
                                            cost details.
                                        </div>
                                    </div>
                                )}
                                {!isExpanded && <div className="divider my-2" />}
                            </div>
                        );
                    })}
                </Card>
            </div>
        </Layout>
    );
}
