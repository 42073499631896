import React, { useContext } from "react";
import { AccordionCollapse, Accordion as BSAccordion, AccordionContext, useAccordionToggle } from "react-bootstrap";

export interface AccordionProps {
    children: any | any[];
}

export interface AccordionBodyProps {
    title: string;
    index?: number;
    showArrow?: boolean;
    children: any;
}

interface CustomToggleProps {
    title: string;
    eventKey: string;
    showArrow?: boolean;
}

function CustomToggle({ title, eventKey, showArrow = true }: CustomToggleProps) {
    const currentEventKey = useContext(AccordionContext);

    const onClick = useAccordionToggle(eventKey);

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <div
            className="p-3 cursor-pointer font-family-semibold d-flex align-items-center justify-content-between"
            style={{ fontSize: 16 }}
            onClick={onClick}>
            {title}
            {showArrow && (
                <span
                    className={isCurrentEventKey ? "reach-cs-arrow-up-solid" : "reach-cs-arrow-down-solid"}
                    style={{ fontSize: 10 }}
                />
            )}
        </div>
    );
}

export function AccordionBody(props: AccordionBodyProps) {
    const { index = 0, children, title, showArrow = true } = props;

    return (
        <div className="mb-3" style={{ borderRadius: 4, backgroundColor: "#fbfbfb" }}>
            <CustomToggle eventKey={index.toString()} title={title} showArrow={showArrow} />
            {showArrow ? (
                <AccordionCollapse eventKey={index.toString()} className="px-3 pb-3">
                    {children}
                </AccordionCollapse>
            ) : (
                <div className="px-3 pb-3">{children}</div>
            )}
        </div>
    );
}

export default function Accordion(props: AccordionProps) {
    const { children } = props;

    if (!Array.isArray(children)) {
        return <AccordionBody {...children.props} index={0} showArrow={false} />;
    }

    if (children.length === 1) {
        return <AccordionBody {...children[0].props} index={0} showArrow={false} />;
    }

    return (
        <BSAccordion defaultActiveKey="0">
            {children.map((item, index) => (
                <AccordionBody {...item.props} key={index} index={index} />
            ))}
        </BSAccordion>
    );
}
