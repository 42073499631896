import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import Layout from "../../components/layout";
import { Link, navigate } from "gatsby";
import { useSelector } from "react-redux";
import {
    activateLine,
    activationCustomerSelector,
    checkActivationIMEI,
    skipHoldSelector,
    validateIccId,
    checkZipcode,
    updateActivationCustomer,
} from "../../features/activation-slice";
import { Card } from "../../components/card";
import { useViewport, ViewportBreakpoint } from "../../utils/viewport-context";
import profilePlaceholder from "../../images/profilePlaceholder.png";
import { formatPhone } from "../../utils/helpers";
import { Input } from "../../components/input";
import { Controller, useForm } from "react-hook-form";
import { Radio } from "../../components/radio";
import { Customer, MnpInfo } from "../../utils/types/customer";
import Select from "../../components/select";
import Modal from "../../components/modal";
import { configSelector } from "../../features/config-slice";
import { Button } from "../../components/button";
import { useUnwrapAsyncThunk } from "../../utils/store";
import { useErrorHandler } from "../../components/error-snackbar";
import branding from "../../branding/branding.json";
import { updateHubspot } from "../../features/purchase-slice";
export type ActivateServiceProps = RouteComponentProps;

type FormData = Required<Pick<Customer, "imei" | "simId">> &
    Partial<MnpInfo> & {
        operatorText?: string;
        zipcode?: string;
    };

export default function ActivateService(props: ActivateServiceProps) {
    const customer = useSelector(activationCustomerSelector);

    const { width } = useViewport();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const [isDeviceEditing, setDeviceEditing] = useState(customer?.imei == null);
    const [isMNP, setMNP] = useState(customer?.isMNP);
    const [showModal, setShowModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const { control, handleSubmit, errors, register, formState, setError } = useForm({
        mode: "onBlur",
    });

    const { operators } = useSelector(configSelector);
    const [showOperatorField, setOperatorField] = useState(false);
    const skipHold = useSelector(skipHoldSelector);

    if (!customer) {
        navigate("/customers");
        return null;
    }

    const onSubmit = async (data: FormData) => {
        if (isDeviceEditing) {
            try {
                const { isValid, mode } = await unwrap(
                    checkActivationIMEI({
                        imei: data.imei,
                        reachMvne: customer.source,
                        // reachMvne: "TELISPIRE",
                    })
                );
                if (isValid) {
                    const res = await unwrap(validateIccId({ id: customer.id, simId: data.simId }));
                    if (res.isValid) {
                        setDeviceEditing(false);
                    } else if (!isValid && mode === "5GDEVICE") {
                        setShowModal(true);
                        setError("simId", { message: "5G device is not supported currently." });

                    } else {
                        setError("simId", { message: "Invalid Sim Id" });
                    }
                } else {
                    setError("imei", { message: "Invalid IMEI" });
                }
            } catch (error) {
                // console.log(error);
                if (error.resCode === 40155) {
                    setError("simId", { message: "Invalid Sim Id" });
                } else if (error.resCode === 40267) {
                    setShowModal(true);
                    setError("imei", { message: "5G device is not supported currently." });
                } else {
                    handleError(error);
                }
            }
        } else {
            // activation flow
            try {
                if (data.zipcode) {
                    const res = await unwrap(checkZipcode(data.zipcode));
                    await unwrap(
                        updateActivationCustomer({
                            id: customer.id!,
                            customer: {
                                isMNP: false,
                                zipcode: data.zipcode,
                            },
                        })
                    );
                }
                await unwrap(
                    activateLine({
                        customerId: customer.id,
                        iccId: customer.simId!,
                        imei: customer.imei!,
                        make: customer.make,
                        model: customer.model,
                        reachPlanId: customer.reachPlanId,
                        skipHold,
                        isPort: isMNP ?? false,
                        numberToPort: data.oldNumber,
                        oldCarrier: data.operator === "Other" ? data.operatorText : data.operator,
                        oldCarrierAccountNumber: data.acctNum,
                        oldZip: data.oldZip,
                        password: data.pin,
                    })
                );
                navigate(`/profile/${customer.id}`);
            } catch (error) {
                console.log(error);
                if (error.resCode === 40037) {
                    setError("zipcode", { message: "Invalid zipcode" });
                } else {
                    handleError(error);
                }
            }
        }
    };

    const handle5GNotification = async () => {
        await unwrap(
            updateHubspot({
                email: customer.emailId,
                customerId: customer.id,
                primaryNumber: customer.primaryNumber,
            })
        );
        setShowModal(false);
        setShowSuccessModal(true);
    };

    return (
        <Layout title={`${customer.firstName} ${customer.lastName} - Activate Service`}>
            <form className="container col-10 d-flex flex-column mt-3 px-0" onSubmit={handleSubmit(onSubmit)}>
                <Link className="text-cta mb-3" to="/activation/scan-sim">
                    <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                    Back
                </Link>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>Activate Service</h1>
                </div>
                <div className="divider" />

                <div className="d-flex flex-column align-items-center mt-3">
                    <div className="font-family-bold text-primary" style={{ fontSize: 18 }}>
                        Last step! Confirm details
                    </div>
                    <div className="font-family-semibold">Verify the info and tap to edit, if needed</div>
                </div>
                <Card>
                    <div className="row align-self-start mx-n2">
                        {width >= ViewportBreakpoint.MD && (
                            <div className="col-auto px-2">
                                <img src={profilePlaceholder} />
                            </div>
                        )}
                        <div className="col-12 col-md-4 px-2" style={{ overflowWrap: "break-word" }}>
                            <div>
                                <div>First Name</div>
                                <div className="font-family-semibold">{customer.firstName}</div>
                            </div>
                            <div className="mt-2">
                                <div>Last Name</div>
                                <div className="font-family-semibold">{customer.lastName}</div>
                            </div>
                            <div className="mt-2">
                                <div>Phone</div>
                                <div className="font-family-semibold">
                                    {customer.reachNumber ? formatPhone(customer.reachNumber) : "-"}
                                </div>
                            </div>
                            <div className="mt-2">
                                <div>Email ID</div>
                                <div className="font-family-semibold">{customer.emailId}</div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mt-2 mt-md-0 px-2">
                            <div>
                                <div>Billing zip code</div>
                                <div className="font-family-semibold">{customer.zipcode}</div>
                            </div>
                            <div className="mt-2">
                                <div>Billing address</div>
                                <div className="font-family-semibold">
                                    {Object.entries(customer.addresses[0])
                                        .map(
                                            ([key, value]) =>
                                                key !== "name" &&
                                                key !== "type" &&
                                                key !== "zip" &&
                                                key !== "phone" &&
                                                value
                                        )
                                        .filter(Boolean)
                                        .join(", ")}
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>

                <Card className="mt-3 px-0">
                    <div className="d-flex w-100 px-3 align-items-center justify-content-between">
                        <div className="font-family-bold" style={{ fontSize: 16 }}>
                            Device Details
                        </div>
                        {!isDeviceEditing && (
                            <span className="reach-cs-edit align-self-start" onClick={() => setDeviceEditing(true)} />
                        )}
                    </div>
                    {isDeviceEditing ? (
                        <div className="row w-100">
                            <Controller
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "IMEI is required",
                                    },
                                    pattern: {
                                        value: /^\d{14,15}$/,
                                        message: "IMEI must be 14 or 15 digits.",
                                    },
                                }}
                                name="imei"
                                defaultValue={customer.imei ?? ""}
                                render={({ onChange, onBlur, value, name }) => (
                                    <Input
                                        type="text"
                                        inputMode="numeric"
                                        placeholder="Enter IMEI"
                                        className="col-12 my-2 my-md-0 col-md-6"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        name={name}
                                        maxLength={15}
                                        characterRestriction={15}
                                        showError={errors.imei}
                                        errorMessage={errors.imei?.message}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "ICCID is required",
                                    },
                                    pattern: {
                                        value: /^\d{20}$/,
                                        message: "ICCID must be 20 digits.",
                                    },
                                }}
                                name="simId"
                                defaultValue={customer.simId ?? ""}
                                render={({ onChange, onBlur, value, name }) => (
                                    <Input
                                        type="text"
                                        inputMode="numeric"
                                        placeholder="Enter ICCID"
                                        className="col-12 my-2 my-md-0 col-md-6"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        name={name}
                                        maxLength={20}
                                        characterRestriction={20}
                                        showError={errors.simId}
                                        errorMessage={errors.simId?.message}
                                    />
                                )}
                            />
                        </div>
                    ) : (
                        <div className="row w-100">
                            <div className="col-12 col-md-4">
                                <div>Device</div>
                                <div className="font-family-semibold">{customer.model}</div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div>Device ID (IMEI)</div>{" "}
                                <div className="font-family-semibold">{customer.imei ?? "-"}</div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div>SIM ID (ICCID)</div>{" "}
                                <div className="font-family-semibold">{customer.simId ?? "-"}</div>
                            </div>
                        </div>
                    )}
                </Card>
                {!isDeviceEditing && (
                    <Card className="mt-3">
                        <div className="font-family-bold align-self-start" style={{ fontSize: 16 }}>
                            Number Preference
                        </div>
                        <div className="row mt-3 w-100">
                            <Radio
                                className="col-12 col-md"
                                inline
                                id="newNumber"
                                checked={!isMNP}
                                onChange={() => setMNP(false)}
                                label="Get a new number"
                                name="numberPreferenceGroup"
                            />
                            <Radio
                                className="col-12 col-md"
                                inline
                                id="portNumber"
                                checked={isMNP ?? false}
                                onChange={() => setMNP(true)}
                                label="Use the current number"
                                name="numberPreferenceGroup"
                            />
                        </div>
                    </Card>
                )}
                {!isDeviceEditing && isMNP && (
                    <Card className="mt-3">
                        <div className="font-family-bold" style={{ fontSize: 16 }}>
                            Current carrier information
                        </div>
                        <div className="text-center mx-5 mt-2">
                            We’ll use this to contact your carrier about bringing over {customer.firstName}{" "}
                            {customer.lastName}'s number and get you live on {branding.shortName}!
                        </div>
                        <div className="row row-cols-1 row-cols-md-2 w-100 mx-n2">
                            <Controller
                                control={control}
                                name="oldNumber"
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Phone number is required",
                                    },
                                    pattern: {
                                        value: /^\d{10}$/,
                                        message: "Phone number should be 10 digits long",
                                    },
                                }}
                                defaultValue={customer.primaryNumber ?? ""}
                                render={({ onChange, onBlur, value, name }) => (
                                    <Input
                                        type="text"
                                        inputMode="numeric"
                                        placeholder="Current number"
                                        maxLength={10}
                                        className="col my-2 px-2"
                                        mask="phone"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        name={name}
                                        showError={errors.oldNumber}
                                        errorMessage={errors.oldNumber?.message}
                                    />
                                )}
                            />
                            <Input
                                type="text"
                                placeholder="Account number"
                                name="acctNum"
                                className="col my-2 px-2"
                                defaultValue={customer.mnpInfo?.acctNum ?? ""}
                                register={register({
                                    required: {
                                        value: true,
                                        message: "Account number is required",
                                    },
                                    pattern: {
                                        value: /^[A-Za-z0-9]{1,20}$/,
                                        message: "Not a valid account number",
                                    },
                                })}
                                showError={errors.acctNum}
                                errorMessage={errors.acctNum?.message}
                            />
                            <Input
                                type="text"
                                placeholder="PIN"
                                name="pin"
                                className="col my-2 px-2"
                                defaultValue={customer.mnpInfo?.pin ?? ""}
                                register={register({
                                    required: {
                                        value: true,
                                        message: "PIN is required",
                                    },
                                    pattern: {
                                        value: /^[A-Za-z0-9]{1,15}$/,
                                        message: "Not a valid PIN",
                                    },
                                })}
                                showError={errors.pin}
                                errorMessage={errors.pin?.message}
                            />
                            <Input
                                type="text"
                                inputMode="numeric"
                                placeholder="Old ZIP code"
                                name="oldZip"
                                maxLength={5}
                                className="col my-2 px-2"
                                defaultValue={customer.mnpInfo?.oldZip ?? ""}
                                register={register({
                                    required: {
                                        value: true,
                                        message: "Zip is required",
                                    },
                                    pattern: {
                                        value: /^\d{5}$/,
                                        message: "Zip should be 5 digits long",
                                    },
                                })}
                                showError={errors.oldZip}
                                errorMessage={errors.oldZip?.message}
                            />
                            <Controller
                                defaultValue={customer.mnpInfo?.operator ?? ""}
                                name="operator"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Operator must be selected",
                                    },
                                }}
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        onChange={(event) => {
                                            onChange(event.target.value);
                                            setOperatorField(event.target.value === "Other");
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        name={name}
                                        className="col my-2 px-2"
                                        showError={errors.operator}
                                        errorMessage={errors.operator?.message}>
                                        <option value="">Select carrier</option>
                                        {operators.map((operator) => (
                                            <option key={operator.value} value={operator.value}>
                                                {operator.displayName}
                                            </option>
                                        ))}
                                    </Select>
                                )}
                            />
                            {showOperatorField && (
                                <Input
                                    type="text"
                                    placeholder="Enter operator name"
                                    className="col my-2 px-2"
                                    name="operatorText"
                                    defaultValue=""
                                    register={register({
                                        required: {
                                            value: true,
                                            message: "Operator is required",
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9 &]+$/,
                                            message: "Invalid carrier name",
                                        },
                                    })}
                                    showError={errors.operatorText}
                                    errorMessage={errors.operatorText?.message}
                                />
                            )}
                        </div>
                    </Card>
                )}
                {!isDeviceEditing && !isMNP && (
                    <Card className="mt-3">
                        <div className="font-family-bold" style={{ fontSize: 16 }}>
                            Enter your ZIP code
                        </div>
                        <div className="text-center mx-5 mt-2">
                            The new number will be assigned based on the ZIP code entered.
                        </div>
                        <Controller
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Zipcode is required",
                                },
                                pattern: {
                                    value: /^\d{5}$/,
                                    message: "Zipcode must be 5 digits.",
                                },
                            }}
                            name="zipcode"
                            defaultValue={customer.zipcode}
                            render={({ onChange, onBlur, value, name }) => (
                                <Input
                                    type="text"
                                    inputMode="numeric"
                                    placeholder="Enter Zipcode"
                                    className="col-12 col-md-6 col-xl-4 mt-3 px-0"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    name={name}
                                    maxLength={5}
                                    characterRestriction={5}
                                    showError={errors.zipcode != null}
                                    errorMessage={errors.zipcode?.message}
                                />
                            )}
                        />
                    </Card>
                )}

                <Button
                    type="submit"
                    color="secondary"
                    loading={formState.isSubmitting}
                    className="my-3 col-8 col-xl-4 align-self-center">
                    {isDeviceEditing ? "Save" : "Activate"}
                </Button>
            </form>

            {showModal && (
                <Modal title=" Activation failed" size="sm" show={showModal} onHide={() => setShowModal(false)}>
                    <div className="font-family-semibold text-center">
                        Sorry! We are currently unable to activate this 5G device. But we will be able to support 5G in
                        the fall. Would the customer like to be notified once we support 5G?
                    </div>
                    <Button
                        color="secondary"
                        className="my-3 col-12 col-xl-12 align-self-center"
                        onClick={handle5GNotification}>
                        Notify customer when 5G is supported
                    </Button>
                </Modal>
            )}
            {showSuccessModal && (
                <Modal
                    title="Customer Notification Successful"
                    size="sm"
                    show={showSuccessModal}
                    onHide={() => setShowSuccessModal(false)}>
                    <div className="font-family-semibold text-center">
                        Customer will be notified when this service will be available.
                    </div>
                    <Button
                        color="secondary"
                        className="my-3 col-12 col-xl-12 align-self-center"
                        onClick={() => {
                            setShowModal(false);
                            setShowSuccessModal(false);
                        }}>
                        Close
                    </Button>
                </Modal>
            )}
        </Layout>
    );
}
