/* eslint-disable react/prop-types */
import { navigate } from "gatsby";
import React from "react";
import { Button } from "./button";

export default class ErrorBoundaryFull extends React.Component<any, { hasError: boolean; error: any; errorInfo: any }> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        this.setState({ error: error, errorInfo: errorInfo });
    }

    render() {
        if (this.state?.hasError) {
            // You can render any custom fallback UI
            return (
                <OurFallbackComponent
                    error={this.state?.error}
                    componentStack={this.state?.errorInfo}
                    resetErrorBoundary={() => this.setState({ hasError: false })}
                />
            );
        }

        return this.props?.children;
    }
}

const OurFallbackComponent = ({
    error,
    componentStack,
    resetErrorBoundary,
}: {
    error: any;
    componentStack: any;
    resetErrorBoundary: any;
}) => {
    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}>
            <h1>An error occurred: {error?.message ?? "Something went wrong..."}</h1>
            {/* <div>{JSON.stringify(error)}</div> */}
            {/* <div>{JSON.stringify(componentStack)}</div> */}
            <Button color="secondary" className="my-2" onClick={resetErrorBoundary}>
                Try Again.
            </Button>
            <span className="my-2 text-cta" onClick={() => navigate("/")}>
                Go To Home
            </span>
        </div>
    );
};
