import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { checkIMEI, fetchProfile, updateProfile } from "../features/profile-slice";
import { useAppDispatch, useAppSelector, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile } from "../utils/types";
import { CustomerStatus } from "../utils/types/customer";
import { Button } from "./button";
import { Card } from "./card";
import { useErrorHandler } from "./error-snackbar";
import { Input } from "./input";
import Modal from "./modal";
import deviceCompatibleImg from "../images/device-compatible.png";
import deviceInompatibleImg from "../images/device-incompatible.png";
import branding from "../branding/branding.json";
import { provConfig } from "../features/config-slice";

export type DeviceDetailsCardProps = CustomerProfile;

interface FormData {
    imei: string;
}

enum UIState {
    CHECK_IMEI,
    IMEI_VALID,
    IMEI_INVALID,
}

export default function DeviceDetailsCard(props: DeviceDetailsCardProps) {
    const { ...customerProfile } = props;

    const [showModal, setShowModal] = useState(false);

    const { register, control, formState, handleSubmit, errors } = useForm<FormData>({
        mode: "onBlur",
    });

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const config = useAppSelector(provConfig);

    const [uiState, setUIState] = useState(UIState.CHECK_IMEI);

    const onSubmit = async (data: FormData) => {
        try {
            const { isValid, make, model, meta, errorText } = await unwrap(
                checkIMEI({
                    imei: data.imei,
                    reachMvne: customerProfile.source,
                })
            );
            if (isValid) {
                await unwrap(
                    updateProfile({
                        id: customerProfile.id,
                        customer: {
                            imei: data.imei,
                            make,
                            model,
                        },
                    })
                );
                await dispatch(fetchProfile(customerProfile.id));
                setUIState(UIState.IMEI_VALID);
            } else {
                setUIState(UIState.IMEI_INVALID);
            }
        } catch (error) {
            console.log(error);
            handleError(error);
            setShowModal(false);
            setUIState(UIState.CHECK_IMEI);
        }
    };

    return (
        <Card className="mt-3">
            <div
                className="d-flex w-100 font-family-bold align-self-start justify-content-between pb-1"
                style={{ fontSize: 16 }}>
                <div>Device Details</div>
                {customerProfile.status === CustomerStatus.INACTIVE && (
                    <div
                        className="reach-cs-edit cursor-pointer"
                        onClick={() => {
                            setShowModal(true);
                        }}
                    />
                )}
            </div>
            <div className="d-flex justify-content-between align-self-start w-100 py-1">
                <div>Device</div>
                <div className="text-truncate ml-3">{customerProfile.model ?? "-"}</div>
            </div>
            <div className="d-flex justify-content-between align-self-start w-100 py-1">
                <div>IMEI</div>
                <div>{customerProfile.imei ?? "-"}</div>
            </div>
            {customerProfile.simId && (
                <div className="d-flex justify-content-between align-self-start w-100 py-1">
                    <div>ICCID</div>
                    <div>{customerProfile.simId}</div>
                </div>
            )}
            <div className="d-flex justify-content-between align-self-start w-100 py-1">
                <div>App version (latest version {config.LATEST_APP_VERSION})</div>
                <div>{customerProfile.appInfo?.appVersion ?? "-"}</div>
            </div>
            {customerProfile.appInfo && (
                <div className="d-flex justify-content-between align-self-start w-100 py-1">
                    <div>OS version</div>
                    <div>
                        {customerProfile.appInfo.os === "ios" ? (
                            <span className="reach-cs-ios mr-1" />
                        ) : (
                            <span className="reach-cs-android mr-1" />
                        )}
                        {customerProfile.appInfo.osVersion}
                    </div>
                </div>
            )}
            <Modal
                title={
                    uiState === UIState.CHECK_IMEI
                        ? "Device details"
                        : uiState === UIState.IMEI_VALID
                        ? "IMEI updated"
                        : "Device incompatible"
                }
                size="sm"
                show={showModal}
                onHide={() => {
                    setShowModal(false);
                    setUIState(UIState.CHECK_IMEI);
                }}>
                {uiState === UIState.IMEI_VALID && <img src={deviceCompatibleImg} />}
                {uiState === UIState.IMEI_INVALID && (
                    <div className="w-100 d-flex flex-column align-items-center">
                        <img src={deviceInompatibleImg} />
                        <Button
                            color="secondary"
                            fullWidth
                            className="mt-3"
                            onClick={() => setUIState(UIState.CHECK_IMEI)}>
                            Try again
                        </Button>
                    </div>
                )}
                {uiState === UIState.CHECK_IMEI && (
                    <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "IMEI is required",
                                },
                                pattern: {
                                    value: /^\d{14,15}$/,
                                    message: "IMEI must be 14 or 15 digits.",
                                },
                            }}
                            name="imei"
                            defaultValue={customerProfile.imei ?? ""}
                            render={({ onChange, onBlur, value, name }) => (
                                <Input
                                    type="text"
                                    inputMode="numeric"
                                    placeholder="Enter your device IMEI or MEID Number"
                                    className="col-12 px-0"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    name={name}
                                    maxLength={15}
                                    characterRestriction={15}
                                    showError={errors.imei != null}
                                    errorMessage={errors.imei?.message}
                                />
                            )}
                        />

                        <Button color="secondary" fullWidth loading={formState.isSubmitting}>
                            Update
                        </Button>
                    </form>
                )}
            </Modal>
        </Card>
    );
}
