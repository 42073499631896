import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Link, navigate } from "gatsby";
import { useSelector } from "react-redux";
import { Card } from "../components/card";
import { useErrorHandler } from "../components/error-snackbar";
import Layout from "../components/layout";
import { profilesSelector, purchaseILDCredit } from "../features/profile-slice";
import { RootState, useUnwrapAsyncThunk } from "../utils/store";
import classNames from "classnames";
import { formatPhone } from "../utils/helpers";
import { CustomerStatus } from "../utils/types/customer";
import Counter from "../components/counter";
import { provConfig } from "../features/config-slice";
import { usePermissions } from "../features/hooks/use-permissions";
import { Button } from "../components/button";
import Modal from "../components/modal";
import { useForm } from "react-hook-form";
import { cardImages, getCardImage } from "../components/payment-cards";
import CustomerBadge from "../components/customer-badge";

export interface BuyILDCreditsProps extends RouteComponentProps {
    customerId: string;
}

export interface IldCountryUsage {
    voiceUsage: number;
    voiceCost: number;
    totalCost: number;
    ratePerMin: number;
    ratePerPulse: number;
    countryCode: string;
    countryName: string;
}

export interface IldCustomerUsage {
    customerId: string;
    voicePulseSize: number;
    totalUsage: {
        voiceUsage?: number;
        smsUsage?: number;
        voiceCost?: number;
        smsCost?: number;
        totalCost?: number;
        serviceCharge: number;
    };
    countryUsage?: IldCountryUsage[];
}

export interface IldMonthlyUsage {
    startDate: Date;
    endDate: Date;
    customerUsages: IldCustomerUsage[];
}

export default function BuyILDCredits(props: BuyILDCreditsProps) {
    const { customerId } = props;

    const customerProfile = useSelector((state: RootState) => profilesSelector.selectById(state, customerId));

    const handleError = useErrorHandler();
    const unwrap = useUnwrapAsyncThunk();

    const { hasOnePermission } = usePermissions();

    const config = useSelector(provConfig);

    const [customerCreditMap, setCustomerCreditMap] = useState<Record<string, number>>({});

    const totalAmount = Object.values(customerCreditMap).reduce((a, b) => a + b, 0);

    const [showModal, setShowModal] = useState(false);

    const { handleSubmit, formState } = useForm({
        mode: "onBlur",
    });

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    const searchParams = new URLSearchParams(props.location?.search);
    const isNoCredits = searchParams.get("noCredit");

    const onSubmit = async () => {
        try {
            const creditMap: Record<string, number> = {};
            // remove 0's from map
            for (const key in customerCreditMap) {
                if (Object.prototype.hasOwnProperty.call(customerCreditMap, key)) {
                    const element = customerCreditMap[key];
                    if (element !== 0) {
                        creditMap[key] = element;
                    }
                }
            }
            await unwrap(
                purchaseILDCredit({
                    primaryId: customerId,
                    customerCreditMap: creditMap,
                    totalAmount,
                    ccUUID: customerProfile.creditCards![0].uuid,
                    creditType: "ILD",
                    currency: "USD",
                    enableFeature: false,
                })
            );
            navigate(`/ild/manage/${customerId}`);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    return (
        <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - Buy credits`}>
            <div className="container col-10 d-flex flex-column my-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link
                        className="text-cta mb-3"
                        to={isNoCredits === "true" ? `/profile/${customerId}` : `/ild/manage/${customerId}`}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>

                <Card>
                    <div className="font-family-bold align-self-start mb-3" style={{ fontSize: 16 }}>
                        Buy credits
                    </div>

                    {customerProfile.groupLines
                        .filter((customer) => customer.status === CustomerStatus.ACTIVE)
                        .map((customer, index) => {
                            return (
                                <div className="w-100 font-family-semibold" key={customer.id}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <div>{customer.firstName}</div>
                                            <div>{formatPhone(customer.reachNumber)}</div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <Counter
                                                defaultValue={0}
                                                minValue={0}
                                                maxValue={config.ILD_MAX_CREDIT_AMOUNT}
                                                step={config.ILD_INCR_CREDIT_AMOUNT}
                                                disabledProps={
                                                    !hasOnePermission("user_management.intl_calling.purchase")
                                                }
                                                render={(value) => (
                                                    <span
                                                        className="font-family-semibold d-inline-block text-center"
                                                        style={{ minWidth: 25 }}>
                                                        ${value}
                                                    </span>
                                                )}
                                                onChange={(value) => {
                                                    setCustomerCreditMap((state) => ({
                                                        ...state,
                                                        [customer.id]: value,
                                                    }));
                                                }}
                                                className="ml-2"
                                            />
                                        </div>
                                    </div>
                                    <div className="divider my-2" />
                                </div>
                            );
                        })}
                    <div className="font-family-semibold align-self-end">Total credit: ${totalAmount}</div>

                    <div className="font-family-semibold">
                        (Includes ${config.ILD_SERVICE_CHRAGE} monthly service charge)
                    </div>

                    <Button
                        color="secondary"
                        className="col-12 col-md-4 mt-3"
                        disabled={totalAmount <= 0}
                        onClick={() => setShowModal(true)}>
                        Buy credit
                    </Button>
                </Card>
            </div>
            {customerProfile?.creditCards && (
                <Modal size="sm" title="Buy credit" show={showModal} onHide={() => setShowModal(false)}>
                    <div className="w-100 d-flex justify-content-between font-family-bold" style={{ fontSize: 16 }}>
                        <div>Total due today</div>
                        <div>${totalAmount.toFixed(2)}*</div>
                    </div>
                    <div className="divider my-2" />
                    <div>*Taxes &amp; fees will be reflected on your next bill based on actual usage</div>

                    <div className="align-self-center mt-3">We will charge the credit on file</div>
                    <div className="d-flex align-items-center my-3">
                        {getCardImage(customerProfile.creditCards[0].type) && (
                            <img
                                src={getCardImage(customerProfile.creditCards[0].type)}
                                className="mb-2"
                                width={45}
                                height={30}
                            />
                        )}
                        <div className="font-family-semibold ml-2">
                            XXXX-XXXX-XXXX-
                            {customerProfile.creditCards[0].ccNumberMask}
                        </div>
                    </div>

                    <Button
                        color="secondary"
                        className="col-12"
                        loading={formState.isSubmitting}
                        onClick={handleSubmit(onSubmit)}>
                        Confirm
                    </Button>
                </Modal>
            )}
        </Layout>
    );
}
