import React, { forwardRef, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Link, navigate } from "gatsby";
import { useSelector } from "react-redux";
import { Card } from "../components/card";
import { useErrorHandler } from "../components/error-snackbar";
import Layout from "../components/layout";
import { getInternationalCountryList, profilesSelector } from "../features/profile-slice";
import { RootState, useUnwrapAsyncThunk } from "../utils/store";
import { Input } from "../components/input";
import roamingIcon from "../images/roaming.png";
import intlCallIcon from "../images/intlcall.png";
import { IntlCountry } from "../utils/types";
import { Dropdown } from "react-bootstrap";
import classNames from "classnames";
import { provConfig } from "../features/config-slice";

export interface IntlRatesProps extends RouteComponentProps {
    customerId: string;
}

const ForwardedInput = forwardRef((props, ref) => (
    <Input
        {...props}
        type="text"
        icon="reach-cs-search"
        placeholder="Enter country name"
        className="w-100"
        register={ref}
    />
));
ForwardedInput.displayName = "ForwardedInput";

export default function IntlRates(props: IntlRatesProps) {
    const { customerId } = props;

    const customerProfile = useSelector((state: RootState) => profilesSelector.selectById(state, customerId));

    const config = useSelector(provConfig);

    const handleError = useErrorHandler();
    const unwrap = useUnwrapAsyncThunk();

    const [countries, setCountries] = useState<IntlCountry[]>();
    const [selectedCountry, setSelectedCountry] = useState<IntlCountry>();
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        async function fetchCountries() {
            try {
                const res = await unwrap(getInternationalCountryList(customerProfile!.source));
                setCountries(res.countriesList);
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
        fetchCountries();
    }, []);

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    if (!countries) {
        return (
            <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - International rates`}>
                <div className="container col-10 d-flex flex-column my-3 px-0">
                    <div className="d-flex justify-content-between">
                        <Link className="text-cta mb-3" to={`/profile/${customerId}`}>
                            <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                            Back
                        </Link>
                    </div>
                    <Card>
                        <div className="spinner-border text-primary" />
                    </Card>
                </div>
            </Layout>
        );
    }

    return (
        <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - International rates`}>
            <div className="container col-10 d-flex flex-column my-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to={`/profile/${customerId}`}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>

                <Card>
                    <div className="font-family-bold align-self-start mb-3" style={{ fontSize: 16 }}>
                        International rates
                    </div>

                    <Dropdown className="w-100">
                        <Dropdown.Toggle
                            id="search"
                            as={ForwardedInput}
                            // @ts-ignore
                            value={searchValue}
                            // @ts-ignore
                            onChange={({ target: { value } }) => {
                                setSearchValue(value);
                            }}
                        />
                        <Dropdown.Menu className="w-100">
                            {countries
                                .filter((country) => {
                                    return (
                                        country.countryName.toLowerCase().includes(searchValue.trim().toLowerCase()) &&
                                        (country.status === 1 || country.ildStatus === 1)
                                    );
                                })
                                .slice(0, 5)
                                .map((country) => (
                                    <Dropdown.Item
                                        key={country.countryCode}
                                        eventKey={country.countryCode}
                                        onClick={() => setSelectedCountry(country)}>
                                        {country.countryName}
                                    </Dropdown.Item>
                                ))}
                        </Dropdown.Menu>
                    </Dropdown>

                    {selectedCountry && (
                        <div className="w-100 d-flex flex-column align-items-center mt-3">
                            <div className="font-family-bold" style={{ fontSize: 16 }}>
                                {selectedCountry.countryName}
                            </div>
                            <div className="font-family-semibold mt-2">
                                Calling from the U.S. - premium quality and flexible pricing.
                            </div>
                            <div className="divider my-2" />
                            <div
                                className="w-100 d-flex font-family-semibold justify-content-around"
                                style={{ fontSize: 12 }}>
                                <div>
                                    <div>Voice</div>
                                    <div>
                                        ${selectedCountry.ildVoiceOut}/
                                        {config.ILD_PULSE_SIZE === 1 ? "" : `${config.ILD_PULSE_SIZE} `}
                                        Min
                                    </div>
                                </div>
                                <div>
                                    <div>SMS</div>
                                    <div>${selectedCountry.ildSmsOut}</div>
                                </div>
                            </div>
                            <div className="font-family-semibold mt-3">
                                Travelling outside the U.S. - stay connected while traveling the world.
                            </div>
                            <div className="divider my-2" />
                            <div
                                className={classNames("w-100 d-flex font-family-semibold justify-content-between", {
                                    disabled: selectedCountry.zone === "PAYGO",
                                })}
                                style={{ fontSize: 12 }}>
                                <div>
                                    <div>Data</div>
                                    <div>
                                        ${selectedCountry.pricePerPulse.toFixed(2)}/{config.DATA_PULSE}
                                        {"GB"}
                                    </div>
                                </div>
                                <div>
                                    <div>Voice</div>
                                    <div>${selectedCountry.voiceIn.toFixed(2)}/Min</div>
                                </div>
                                <div>
                                    <div>SMS In</div>
                                    <div>${selectedCountry.smsIn.toFixed(2)}</div>
                                </div>
                                <div>
                                    <div>SMS Out</div>
                                    <div>${selectedCountry.smsOut.toFixed(2)}</div>
                                </div>
                            </div>

                            {selectedCountry.zone === "PAYGO" && (
                                <div className="text-error text-center mt-3">
                                    We do not currently offer discounted data packs for {selectedCountry.countryName}.
                                    Here&apos;s a summary of the roaming charges applicable.
                                </div>
                            )}
                        </div>
                    )}

                    <div className="font-family-semibold align-self-start mt-3">
                        <img src={roamingIcon} className="mr-2" width={16} />
                        <span className="font-family-bold">Roaming: </span>
                        stay connected while traveling the world.
                    </div>
                    <div className="font-family-semibold align-self-start mt-3">
                        <img src={intlCallIcon} className="mr-2" width={16} />
                        <span className="font-family-bold">Call and text from the U.S.: </span>
                        premium quality and flexible pricing.
                    </div>
                </Card>
            </div>
        </Layout>
    );
}
