import React, { useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import Layout from "../../components/layout";
import { Link, navigate } from "gatsby";
import { Button } from "../../components/button";
import { useAppDispatch, useUnwrapAsyncThunk } from "../../utils/store";
import { useErrorHandler } from "../../components/error-snackbar";
import {
    additionalLinePriceSelector,
    planLinePriceSelector,
    selectAdditionalLinesPurchase,
    totalLinesSelector,
    welcomeKitPriceSelector,
    simTaxSelector,
    planTaxSelector,
    planTaxAmountSelector,
    totalDueSelector,
    monthlyBillSelector,
    planTaxReset,
    fetchPlanTax,
    fetchSIMTax,
    createSecondaryCustomer,
    createOrder,
    simTaxSkipped,
} from "../../features/additional-lines-purchase-slice";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { cardImages, getCardImage } from "../../components/payment-cards";
import { Customer } from "../../utils/types/customer";
import { addDays, format } from "date-fns";
import CustomerBadge from "../../components/customer-badge";

export type AddLinesCheckoutProps = RouteComponentProps;

export default function AddLinesCheckout(props: AddLinesCheckoutProps) {
    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const {
        customerProfile,
        additionalCustomers,
        reachPlan: selectedPlan,
        shippingAddress,
        shippingOption,
    } = useSelector(selectAdditionalLinesPurchase);

    const formMethods = useForm({
        mode: "onBlur",
    });

    const totalLines = useSelector(totalLinesSelector);
    const planLinePrice = useSelector(planLinePriceSelector);
    const additionalLinePrice = useSelector(additionalLinePriceSelector);
    const planTaxAmount = useSelector(planTaxAmountSelector);
    const planTax = useSelector(planTaxSelector);
    const simTax = useSelector(simTaxSelector);
    const monthlyBillAmount = useSelector(monthlyBillSelector);
    const welcomeKitPrice = useSelector(welcomeKitPriceSelector);
    const totalDue = useSelector(totalDueSelector);

    useEffect(() => {
        if (customerProfile && selectedPlan && shippingOption && shippingAddress) {
            dispatch(planTaxReset());
            dispatch(
                fetchPlanTax({
                    amount: planTaxAmount,
                    additionalLines: totalLines - 1,
                    address: customerProfile.addresses[0],
                })
            );
            if (welcomeKitPrice + shippingOption.shippingAmount === 0) {
                dispatch(simTaxSkipped());
            } else {
                dispatch(
                    fetchSIMTax({
                        amount: welcomeKitPrice + shippingOption.shippingAmount,
                        additionalLines: totalLines - 1,
                        address: shippingAddress,
                    })
                );
            }
        }
    }, [
        customerProfile,
        dispatch,
        planTaxAmount,
        selectedPlan,
        shippingAddress,
        shippingOption,
        totalLines,
        welcomeKitPrice,
    ]);

    if (!customerProfile || !selectedPlan || !shippingOption || !shippingAddress) {
        navigate("/customers");
        return null;
    }

    const onSubmit = async () => {
        try {
            const custIds = [];
            for (const cust of additionalCustomers) {
                const customer = {
                    ...cust,
                    addresses: customerProfile?.addresses,
                    reachPlanId: customerProfile?.reachPlan.name,
                    parentId: customerProfile!.id,
                    simPaid: true,
                    isSimInHand: true,
                };

                if (process.env.MOCK_ACTIVATION) {
                    customer.source = "DATAMI";
                }

                const c = await unwrap(createSecondaryCustomer(customer as Customer));
                custIds.push(c.id);
            }
            await unwrap(
                createOrder({
                    cId: customerProfile.id,
                    reachCarrierId: shippingOption.reachCarrierId,
                    deliveryDays: shippingOption.deliveryDays,
                    serviceType: shippingOption.serviceType,
                    serviceCode: shippingOption.serviceCode,
                    carrierCode: shippingOption.carrierCode,
                    shippingAmount: shippingOption.shippingAmount,
                    estimatedDeliveryDate: format(
                        addDays(new Date(), shippingOption.deliveryDays),
                        "yyyy-MM-dd'T'00:00:00.000+0000"
                    ),
                    category: "SIM",
                    taxAmount: simTax!.totalTax,
                    totalAmount: totalDue,
                    taxId: simTax!.taxId !== "" ? simTax!.taxId : undefined,
                    ccUUID: customerProfile.creditCards![0].uuid,
                    secondaryCustomers: custIds,
                    shipTo: {
                        city: shippingAddress.city,
                        country: "US",
                        name: shippingAddress.name,
                        phone: shippingAddress.phone,
                        postalCode: shippingAddress.zip,
                        residential: "false",
                        state: shippingAddress.state,
                        street1: shippingAddress.address1,
                    },
                    items: [
                        {
                            quantity: additionalCustomers.length,
                            unitPrice: welcomeKitPrice / additionalCustomers.length,
                        },
                    ],
                })
            );
            navigate("/add-lines/complete");
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    return (
        <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - Add lines checkout`}>
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <Link className="text-cta mb-3" to="/add-lines/shipping">
                    <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                    Back
                </Link>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h2>Monthly bill</h2>
                </div>
                <div className="divider" />

                <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="d-flex align-items-center">
                        <span>
                            <span className="text-cta">
                                {selectedPlan.name} ({selectedPlan.planData}
                                {selectedPlan.dataUnit}) for {totalLines}
                            </span>
                        </span>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2 ml-4">
                    <div>Your line</div>
                    <div>${planLinePrice.toFixed(2)}</div>
                </div>
                {totalLines > 1 && (
                    <div className="d-flex justify-content-between align-items-center mt-2 ml-4">
                        <div>Additional lines ({totalLines - 1})</div>
                        <div>${additionalLinePrice.toFixed(2)}</div>
                    </div>
                )}
                <div className="d-flex justify-content-between align-items-center mt-2">
                    <div>Taxes and fees</div>
                    <div>{planTax ? `$${planTax.totalTax.toFixed(2)}` : "-"}</div>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                    <div>Estimated monthly bill</div>
                    <div className="font-family-semibold">${monthlyBillAmount.toFixed(2)}</div>
                </div>

                <div className="font-family-bold mt-3">One-time charges</div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                    <div>Welcome Kit w/SIM card ({additionalCustomers.length})</div>
                    <div>${welcomeKitPrice.toFixed(2)}</div>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                    <div>{shippingOption.name}</div>
                    <div>${shippingOption.shippingAmount.toFixed(2)}</div>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                    <div>Taxes and fees</div>
                    <div>{simTax ? `$${simTax.totalTax.toFixed(2)}` : "-"}</div>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                    <div className="font-family-bold">Total due today</div>
                    <div className="font-family-bold">${totalDue.toFixed(2)}</div>
                </div>

                <div className="divider my-2" />

                <div className="font-family-bold" style={{ fontSize: 16 }}>
                    Payment
                </div>

                {customerProfile.creditCards?.[0] && (
                    <div className="d-flex align-items-center my-3">
                        {getCardImage(customerProfile.creditCards[0].type) && (
                            <img
                                src={getCardImage(customerProfile.creditCards[0].type)}
                                className="mb-2"
                                width={45}
                                height={30}
                            />
                        )}
                        <div className="font-family-semibold ml-2">
                            XXXX-XXXX-XXXX-
                            {customerProfile.creditCards[0].ccNumberMask}
                        </div>
                    </div>
                )}

                <Button
                    fullWidth
                    color="secondary"
                    disabled={simTax == null}
                    className="align-self-center my-3 col-12 col-md-6"
                    loading={formMethods.formState.isSubmitting}
                    onClick={() => {
                        formMethods.handleSubmit(onSubmit)();
                    }}>
                    Proceed
                </Button>
            </div>
        </Layout>
    );
}
