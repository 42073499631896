import React from "react";
import { useForm } from "react-hook-form";
import { getBillingHistory, regenerateBill } from "../features/billing-slice";
import { fetchProfile } from "../features/profile-slice";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile } from "../utils/types";
import { Button } from "./button";
import { useErrorHandler } from "./error-snackbar";
import Modal, { ModalProps } from "./modal";
import { useSuccessModal } from "./success-modal";

export interface RegenerateBillModalProps extends ModalProps {
    customerProfile: CustomerProfile;
}

export default function RegenerateBillModal(props: RegenerateBillModalProps) {
    const { customerProfile, ...rest } = props;

    const { handleSubmit, formState } = useForm({ mode: "onBlur" });

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();

    const onSubmit = async () => {
        try {
            await unwrap(regenerateBill(customerProfile.id));
            await unwrap(getBillingHistory(customerProfile.id));
            await dispatch(fetchProfile(customerProfile.id));
            showSuccessModal("Bill regenerated successfully");
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        rest.onHide();
    };

    return (
        <Modal {...rest} size="sm">
            <Button color="secondary" fullWidth loading={formState.isSubmitting} onClick={handleSubmit(onSubmit)}>
                Confirm
            </Button>
        </Modal>
    );
}
