import React, { ReactNode, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import PageLayout from "../components/page-layout";
import { PermissionsRes } from "../utils/types";
import { useAppSelector, useUnwrapAsyncThunk } from "../utils/store";
import { useErrorHandler } from "../components/error-snackbar";
import { ReachCsAPI } from "../utils/reach-cs-api";
import classNames from "classnames";
import { Checkbox } from "../components/checkbox";
import Toggle from "../components/toggle";
import { Button } from "../components/button";
import { Input } from "../components/input";
import MultiDropdownInput from "../components/multi-dropdown-input";
import { navigate } from "gatsby";
import { useUserPermissions } from "../features/hooks/use-user-permissions";
import { getRolesByEmailId, usersSelector } from "../features/operation-users-slice";
import { formatPhone } from "../utils/helpers";
import { selectUserInfo } from "../features/user-role-slice";
import { useViewport, ViewportBreakpoint } from "../utils/viewport-context";

export interface PermissionDetailsProps extends RouteComponentProps {
    emailId: string;
}

export default function PermissionDetails(props: PermissionDetailsProps) {
    const [permissions, setPermissions] = useState<PermissionsRes>();
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);

    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const { roleMap } = useAppSelector(selectUserInfo);
    const { width } = useViewport();

    const user = useAppSelector((state) => usersSelector.selectById(state, props.emailId));
    const { hasOnePermission, getLimitations } = useUserPermissions(props.emailId);

    useEffect(() => {
        async function getPermissions() {
            try {
                const res = await ReachCsAPI.getAllPermissions();
                setPermissions(res);
                if (!user?.permissions) {
                    await unwrap(getRolesByEmailId(props.emailId));
                }
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
        getPermissions();
    }, []);

    if (!permissions || !user?.permissions) {
        return (
            <PageLayout title="Permission details" prevUrl="/users">
                <div className="mt-3 d-flex justify-content-center">
                    <div className="spinner-border text-primary" />
                </div>
            </PageLayout>
        );
    }

    const categories = [...new Set(permissions.map((permission) => permission.categoryName))];

    const subCategories = [
        ...new Set(
            permissions
                .filter((permission) => permission.categoryName === categories[selectedCategoryIndex])
                .map((permission) => permission.subCategoryName)
        ),
    ];

    const availableIds = permissions
        .filter((permission) => permission.categoryName === categories[selectedCategoryIndex])
        .map((permission) => permission.id);

    let categoryBeforeEl: ReactNode;
    let categoryAfterEl: ReactNode;
    const categoryEl = categories.map((category, index) => (
        <div
            key={category}
            onClick={() => setSelectedCategoryIndex(index)}
            className={classNames(
                "d-flex justify-content-between align-items-center font-family-semibold p-3 cursor-pointer",
                selectedCategoryIndex === index ? "btn-secondary" : "btn-grey3"
            )}
            style={{
                marginBottom: 1,
            }}>
            {category}
            <span className="reach-cs-arrow-right" />
        </div>
    ));

    if (width < ViewportBreakpoint.LG) {
        categoryBeforeEl = <div className="col-12 col-lg-3 mb-3">{categoryEl.slice(0, selectedCategoryIndex + 1)}</div>;
        categoryAfterEl = <div className="col-12 col-lg-3 mb-3">{categoryEl.slice(selectedCategoryIndex + 1)}</div>;
    } else {
        categoryBeforeEl = <div className="col-12 col-lg-3 mb-3">{categoryEl}</div>;
        categoryAfterEl = null;
    }

    return (
        <PageLayout title="Permission details" prevUrl="/users">
            <div className="mb-3 pb-3 d-md-flex align-items-center justify-content-between divider">
                <div>
                    <div className="font-family-bold">
                        {user.firstName} {user.lastName}
                    </div>
                    <div>{formatPhone(user.phoneNumber)}</div>
                    <div>{user.emailId}</div>
                </div>
                <div>{user.roleIds.map((role) => roleMap?.[role]).join(", ")}</div>
            </div>
            <div className="row">
                {categoryBeforeEl}
                <div className="col-12 col-lg-9">
                    <div className="d-flex justify-content-between">
                        <div className="h1">{categories[selectedCategoryIndex]}</div>
                        <Toggle id="" name="" label="" checked={hasOnePermission(...availableIds)} disabled />
                    </div>
                    {subCategories.map((subCategory, index) => {
                        const ops = permissions.filter(
                            (permission) =>
                                permission.categoryName === categories[selectedCategoryIndex] &&
                                permission.subCategoryName === subCategory
                        );
                        return (
                            <div key={subCategory} className="row mt-3">
                                {index !== 0 && <div className="mb-3 divider mx-3" />}
                                <div className="col-4">{subCategory}</div>
                                <div className="col-8 row">
                                    {ops.map((operation) => (
                                        <Checkbox
                                            key={operation.operationId}
                                            style={{ minWidth: "15%" }}
                                            label={operation.operationName}
                                            id={operation.id}
                                            name={operation.id}
                                            small
                                            inline
                                            disabled
                                            defaultChecked={hasOnePermission(operation.id)}
                                        />
                                    ))}
                                    {ops.map((operation) => {
                                        if (!("supportedLimitations" in operation) || !operation.supportedLimitations) {
                                            return null;
                                        }

                                        const limitations = getLimitations(operation.id);

                                        return (
                                            <div key={operation.id} className="col-12 px-0 mt-2">
                                                {"max_value" in operation.supportedLimitations &&
                                                    (!limitations || "max_value" in limitations) &&
                                                    operation.supportedLimitations.max_value != null && (
                                                        <div className="d-flex align-items-center font-family-semibold">
                                                            Max amount:
                                                            <Input
                                                                disabled
                                                                className="ml-2"
                                                                style={{ width: 50 }}
                                                                defaultValue={
                                                                    limitations?.max_value?.value ??
                                                                    operation.supportedLimitations.max_value.value
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                {"descriptions" in operation.supportedLimitations &&
                                                    (!limitations || "descriptions" in limitations) && (
                                                        <div>
                                                            <MultiDropdownInput
                                                                placeholder="Credit descriptions"
                                                                inputClassName="custom-select"
                                                                containerClassName="mt-3"
                                                                defaultItems={limitations?.descriptions.value}
                                                                items={operation.supportedLimitations.descriptions.value.map(
                                                                    (item) => ({ label: item, value: item })
                                                                )}
                                                                disabled
                                                            />
                                                        </div>
                                                    )}
                                                {"assignableRoles" in operation.supportedLimitations &&
                                                    roleMap &&
                                                    user.roleMap && (
                                                        <div>
                                                            <MultiDropdownInput
                                                                placeholder="Select roles"
                                                                inputClassName="custom-select"
                                                                containerClassName="mt-3"
                                                                defaultItems={Object.keys(user.roleMap)}
                                                                items={Object.keys(roleMap).map((item) => ({
                                                                    label: roleMap[item],
                                                                    value: item,
                                                                }))}
                                                                disabled
                                                            />
                                                        </div>
                                                    )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}

                    <div className="d-flex align-items-center justify-content-center flex-column">
                        {selectedCategoryIndex < categories.length - 1 ? (
                            <Button
                                color="secondary"
                                className="my-3 col-12 col-md-4 col-lg-3"
                                onClick={() => setSelectedCategoryIndex(selectedCategoryIndex + 1)}>
                                Next
                            </Button>
                        ) : (
                            <Button color="secondary" className="my-3 col-3" onClick={() => navigate("/users")}>
                                Proceed
                            </Button>
                        )}
                        {selectedCategoryIndex < categories.length - 1 && (
                            <div
                                className="text-cta mb-3"
                                onClick={() => setSelectedCategoryIndex(categories.length - 1)}>
                                Skip
                            </div>
                        )}
                    </div>
                </div>
                {categoryAfterEl}
            </div>
        </PageLayout>
    );
}
