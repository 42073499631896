import React from "react";
import { Controller, useForm } from "react-hook-form";
import { fetchProfile, manageCDMAless } from "../features/profile-slice";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile } from "../utils/types";
import { Button } from "./button";
import { useErrorHandler } from "./error-snackbar";
import { Input } from "./input";
import Modal, { ModalProps } from "./modal";
import { useSuccessModal } from "./success-modal";
import Toggle from "./toggle";

export interface CDMAlessModalProps extends ModalProps {
    customerProfile: CustomerProfile;
}

export default function CDMAlessModal(props: CDMAlessModalProps) {
    const { customerProfile, ...rest } = props;

    const { handleSubmit, formState, watch } = useForm({ mode: "onBlur" });

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();

    const onSubmit = async (data: FormData) => {
        try {
            await unwrap(
                manageCDMAless({
                    customerId: customerProfile.id,
                    enable: !customerProfile.isCDMALessEnabled,
                })
            );
            await dispatch(fetchProfile(customerProfile.id));
            showSuccessModal(`CDMAless ${!customerProfile.isCDMALessEnabled ? "enabled" : "disabled"}`);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        rest.onHide();
    };

    const isCDMALessEnabled = watch("enableCDMAless", customerProfile.isCDMALessEnabled);

    return (
        <Modal {...rest} size="sm">
            <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
                <div className="align-self-start mr-3 mb-3">
                    <div className="font-family-bold">Please use this if:</div>
                    <div style={{ fontSize: 12 }} className="font-family-semibold">
                        1. Customer is on a non-iPhone
                    </div>
                    <div style={{ fontSize: 12 }} className="font-family-semibold">
                        2. Customer is on an iPhone but it's listed as Non-VZW
                    </div>
                </div>
                <Button color="secondary" fullWidth loading={formState.isSubmitting}>
                    Confirm
                </Button>
            </form>
        </Modal>
    );
}
