import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { getTicketsByCustomerId, ticketsSelector } from "../features/tickets-slice";
import { useAppDispatch, useAppSelector, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile } from "../utils/types";
import { Card } from "./card";
import CreateTicketModal from "./create-ticket-modal";
import { useErrorHandler } from "./error-snackbar";
import { navigate } from "gatsby";
import { Button } from "./button";
import classNames from "classnames";
import { usePermissions } from "../features/hooks/use-permissions";

export interface HelpdeskCardProps {
    customerProfile: CustomerProfile;
}

function HelpdeskCard(props: HelpdeskCardProps) {
    const { customerProfile } = props;

    const [showCreateHelpdeskModal, setShowCreateHelpdeskModal] = useState(false);

    const { tickets } = useAppSelector((state) => ticketsSelector.selectById(state, customerProfile.id)) ?? {};

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const { hasOnePermission } = usePermissions();

    useEffect(() => {
        async function fetchTickets() {
            try {
                await unwrap(getTicketsByCustomerId(customerProfile.id));
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
        fetchTickets();
    }, [customerProfile.id]);

    if (!tickets) {
        return (
            <Card className="mt-3">
                <div className="font-family-bold justify-content-between w-100 d-flex align-self-start align-items-center">
                    <div style={{ fontSize: 16 }}>Helpdesk</div>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary" />
                </div>
            </Card>
        );
    }

    const firstPageTickets = tickets.slice(0, 3);

    return (
        <Card className="mt-3">
            <div className="font-family-bold justify-content-between w-100 d-flex align-self-start align-items-center">
                <div style={{ fontSize: 16 }}>Helpdesk</div>

                {tickets.length > 0 && (
                    <div
                        className={classNames("text-cta", {
                            disabled: !hasOnePermission("user_management.helpdesk.create_ticket"),
                        })}
                        onClick={() =>
                            hasOnePermission("user_management.helpdesk.create_ticket") &&
                            setShowCreateHelpdeskModal(true)
                        }>
                        Create new
                    </div>
                )}
            </div>

            {firstPageTickets.map((ticket, index) => (
                <div key={ticket.id} className="w-100">
                    {index !== 0 && <div className="divider" />}
                    <div
                        className="w-100 d-flex justify-content-between my-2 align-items-center cursor-pointer"
                        onClick={() => navigate(`/tickets/${customerProfile.id}/id/${ticket.id}`)}>
                        <div className="font-family-semibold">
                            <div>Ticket ID #{ticket.ticketNumber}</div>
                            <div style={{ fontSize: 12 }}>{ticket.subject}</div>
                        </div>

                        <div className="font-family-semibold text-right">
                            <div>{ticket.status}</div>
                            <div>{format(new Date(ticket.createdTime), "MMM dd, yyyy")}</div>
                        </div>
                    </div>
                </div>
            ))}

            {tickets.length === 0 && (
                <Button
                    color="secondary"
                    disabled={!hasOnePermission("user_management.helpdesk.create_ticket")}
                    onClick={() => setShowCreateHelpdeskModal(true)}>
                    Create new
                </Button>
            )}

            {tickets.length > 3 && (
                <Button color="secondary" onClick={() => navigate(`/tickets/${customerProfile.id}`)}>
                    View more
                </Button>
            )}

            <CreateTicketModal
                customerProfile={customerProfile}
                title="Create new ticket"
                show={showCreateHelpdeskModal}
                onHide={() => setShowCreateHelpdeskModal(false)}
            />
        </Card>
    );
}

export default HelpdeskCard;
