import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Link, navigate } from "gatsby";
import { Button } from "../components/button";
import { Input } from "../components/input";
import Layout from "../components/layout";
import { usePermissions } from "../features/hooks/use-permissions";
import { selectUserInfo } from "../features/user-role-slice";
import noResultsImage from "../images/icon-noresultsfound.png";
import { useAppDispatch, useAppSelector } from "../utils/store";
import { useViewport, ViewportBreakpoint } from "../utils/viewport-context";

export type RolesListProps = RouteComponentProps;

export default function RolesList(props: RolesListProps) {
    const dispatch = useAppDispatch();
    const { width } = useViewport();

    const { roleMap } = useAppSelector(selectUserInfo);

    const [query, setQuery] = useState("");

    const { hasOnePermission } = usePermissions();

    return (
        <Layout title="Roles">
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to="/">
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="d-flex align-items-center justify-content-between pb-3">
                    <h1>Roles</h1>
                    <Button
                        color="secondary"
                        icon="reach-cs-plus"
                        mini={width < ViewportBreakpoint.MD}
                        disabled={!hasOnePermission("user_role_management.manage_role.create")}
                        onClick={() => navigate("/roles/new")}>
                        Create role
                    </Button>
                </div>
                <div className="row mb-2">
                    <Input
                        type="text"
                        icon="reach-cs-search"
                        placeholder="Search role"
                        className="col-12"
                        defaultValue={query}
                        onChange={({ target: { value } }) => {
                            // handleSearch(value);
                        }}
                    />
                </div>
                {!roleMap ? (
                    <div
                        className="d-flex align-items-center justify-content-center flex-column"
                        style={{ height: 200 }}>
                        <img src={noResultsImage} />
                        <div className="font-family-semibold" style={{ fontSize: 18 }}>
                            No results found
                        </div>
                    </div>
                ) : (
                    Object.entries(roleMap).map(([key, value]) => (
                        <div
                            className="row align-items-center mt-3"
                            key={key}
                            onClick={() => {
                                navigate(`/role/permissions/${key}`);
                            }}>
                            <div className="col cursor-pointer" style={{ overflowWrap: "break-word" }}>
                                {value}
                            </div>
                            <div className="col-auto reach-cs-edit cursor-pointer" />
                            <div className="divider mt-3 mx-3" style={{ opacity: 0.31 }} />
                        </div>
                    ))
                )}
            </div>
        </Layout>
    );
}
