import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Input } from "../../components/input";
import { Button } from "../../components/button";
import Layout from "../../components/layout";
import { Link, navigate } from "gatsby";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useUnwrapAsyncThunk } from "../../utils/store";
import {
    createAccount,
    customerSelector,
    customersLoaded,
    fetchAccount,
    purchaseReset,
} from "../../features/purchase-slice";
import { EMAIL_PATTERN, NAME_PATTERN } from "../../utils/helpers";
import { useErrorHandler } from "../../components/error-snackbar";
import Select from "../../components/select";
import { useSelector } from "react-redux";
import Modal from "../../components/modal";
import branding from "../../branding/branding.json";

interface FormData {
    firstName: string;
    lastName: string;
    emailId: string;
    primaryNumber: string;
    leadSource: string;
}

export type CreateCustomerProps = RouteComponentProps;

export default function CreateCustomer(props: CreateCustomerProps) {
    const { register, errors, handleSubmit, formState, control } = useForm<FormData>({
        mode: "onBlur",
    });

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const customer = useSelector(customerSelector);

    const [existingCustomerId, setExistingCustomerId] = useState<string>();

    /* useEffect(() => {
        dispatch(purchaseReset());
    }, []); */

    const fetchExistingProfile = async (customerId: string) => {
        try {
            const profile = await unwrap(fetchAccount(customerId));
            if (profile.isDraft) {
                dispatch(customersLoaded(profile));
                navigate("/purchase/plans");
            } else {
                setExistingCustomerId(profile.id);
            }
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const onSubmit = async (data: FormData) => {
        try {
            const customer = await unwrap(createAccount(data));
            dispatch(customersLoaded(customer));
            navigate("/purchase/plans");
        } catch (error) {
            console.log(error);
            if (error.resCode === 40053) {
                // CUSTOMER_ALREADY_EXISTS
                await fetchExistingProfile(error.meta.id);
            } else {
                handleError(error);
            }
        }
    };

    return (
        <Layout title="Create new customer">
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <Link className="text-cta mb-3" to="/customers">
                    <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                    Back
                </Link>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>Create New Customer</h1>
                </div>
                <div className="divider" />

                <form className="row" onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-lg-4 col-md-6 col-sm-12 offset-md-3 offset-lg-4">
                        <Input
                            type="text"
                            placeholder="First Name"
                            className="mt-4"
                            name="firstName"
                            defaultValue={customer.firstName ?? ""}
                            register={register({
                                required: {
                                    value: true,
                                    message: "First name is required",
                                },
                                pattern: {
                                    value: NAME_PATTERN,
                                    message: "Not a valid first name",
                                },
                            })}
                            showError={errors.firstName != null}
                            errorMessage={errors.firstName?.message}
                        />
                        <Input
                            type="text"
                            placeholder="Last Name"
                            className="mt-4"
                            name="lastName"
                            defaultValue={customer.lastName ?? ""}
                            register={register({
                                required: {
                                    value: true,
                                    message: "Last name is required",
                                },
                                pattern: {
                                    value: NAME_PATTERN,
                                    message: "Not a valid last name",
                                },
                            })}
                            showError={errors.lastName != null}
                            errorMessage={errors.lastName?.message}
                        />
                        <Controller
                            control={control}
                            name="primaryNumber"
                            defaultValue={customer.primaryNumber ?? ""}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Phone number is required",
                                },
                                pattern: {
                                    value: /^\d{10}$/,
                                    message: "Phone number should be 10 digits long",
                                },
                            }}
                            render={({ onChange, onBlur, value, name }) => (
                                <Input
                                    type="text"
                                    inputMode="numeric"
                                    placeholder="Billing phone number"
                                    className="mt-4"
                                    maxLength={10}
                                    mask="phone"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    name={name}
                                    showError={errors.primaryNumber != null}
                                    errorMessage={errors.primaryNumber?.message}
                                />
                            )}
                        />
                        <Input
                            type="text"
                            placeholder="Email address"
                            className="mt-4"
                            name="emailId"
                            defaultValue={customer.emailId ?? ""}
                            register={register({
                                required: {
                                    value: true,
                                    message: "Email ID is required",
                                },
                                pattern: {
                                    value: EMAIL_PATTERN,
                                    message: "Not a valid email id",
                                },
                            })}
                            showError={errors.emailId != null}
                            errorMessage={errors.emailId?.message}
                        />
                        <Select
                            className="mt-4"
                            register={register({
                                required: {
                                    value: true,
                                    message: "Field is required",
                                },
                            })}
                            name="leadSource"
                            // @ts-ignore
                            defaultValue={customer.leadSource ?? ""}
                            showError={errors.leadSource != null}
                            errorMessage={errors.leadSource?.message}>
                            <option value="">How did you hear about {branding.shortName}?</option>
                            <option value="Adwords">Adwords</option>
                            <option value="Facebook Ads">Facebook Ads</option>
                            <option value="Facebook">Facebook</option>
                            <option value="AndroidCentral">AndroidCentral</option>
                            <option value="BestMvno">BestMvno</option>
                            <option value="BestPhonePlans">BestPhonePlans</option>
                            <option value="Bing Ads">Bing Ads</option>
                            <option value="Bizx">Bizx</option>
                            <option value="Brochure">Brochure</option>
                            <option value="MSP">MoneySavingsPro</option>
                            <option value="Organic">Organic</option>
                            <option value="Social">Social</option>
                            <option value="Swappa">Swappa</option>
                            <option value="WO">WhistleOut</option>
                            <option value="YouTube">YouTube</option>
                            <option value="Others">Others</option>
                        </Select>

                        <Button color="secondary" fullWidth className="mt-4" loading={formState.isSubmitting}>
                            Continue
                        </Button>
                    </div>
                </form>
            </div>
            <Modal
                title="E-mail address already in use"
                size="sm"
                show={existingCustomerId != null}
                onHide={() => setExistingCustomerId(undefined)}>
                <div className="text-center">Provided email is already in use. </div>
                <Button
                    color="secondary"
                    fullWidth
                    className="mt-3"
                    onClick={() => navigate(`/profile/${existingCustomerId}`)}>
                    Proceed to profile
                </Button>
            </Modal>
        </Layout>
    );
}
