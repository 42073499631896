import { RouteComponentProps } from "@reach/router";
import { format } from "date-fns";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "../../components/button";
import CustomerPageLayout from "../../components/customer-page-layout";
import { useErrorHandler } from "../../components/error-snackbar";
import Modal from "../../components/modal";
import { TextArea } from "../../components/text-area";
import { usePermissions } from "../../features/hooks/use-permissions";
import { profilesSelector } from "../../features/profile-slice";
import {
    createTicketComment,
    getTicketAttachments,
    getTicketComments,
    getTicketsByCustomerId,
    ticketsSelector,
    updateTicketStatus,
} from "../../features/tickets-slice";
import { selectUserInfo } from "../../features/user-role-slice";
import { formatPhone } from "../../utils/helpers";
import { useAppDispatch, useAppSelector, useUnwrapAsyncThunk } from "../../utils/store";
import { TicketAttachment, TicketComment } from "../../utils/types";

export interface TicketSummaryProps extends RouteComponentProps {
    customerId: string;
    ticketId: string;
}

interface FormData {
    comment: string;
}

function TicketSummary(props: TicketSummaryProps) {
    const { customerId, ticketId } = props;

    const customerProfile = useAppSelector((state) => profilesSelector.selectById(state, customerId ?? ""));
    const { tickets } = useAppSelector((state) => ticketsSelector.selectById(state, customerId ?? "")) ?? {};
    const userInfo = useAppSelector(selectUserInfo);

    const ticket = tickets?.find((ticket) => ticket.id === ticketId);

    const { handleSubmit, register, errors, formState, reset } = useForm<FormData>({
        mode: "onBlur",
    });

    const [ticketComments, setTicketComments] = useState<TicketComment[]>();
    const [ticketAttachments, setTicketAttachments] = useState<TicketAttachment[]>();
    const [selectedAttachment, setSelectedAttachment] = useState<TicketAttachment>();

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const [openLoading, setOpenLoading] = useState(false);

    const { hasOnePermission } = usePermissions();

    async function fetchTickets() {
        if (customerProfile?.id) {
            try {
                await unwrap(getTicketsByCustomerId(customerProfile.id));
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
    }

    async function fetchAttachments() {
        if (ticket?.id) {
            try {
                await timeout(3000);
                const res = await unwrap(getTicketAttachments(ticket.id));
                console.log(res);
                setTicketAttachments(res);
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
    }

    async function timeout(delay: number) {
        return new Promise((res) => setTimeout(res, delay));
    }

    async function fetchComments() {
        if (ticket?.id) {
            try {
                const res = await unwrap(getTicketComments(ticket.id));
                console.log(res);
                setTicketComments(res);
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
    }

    useEffect(() => {
        fetchComments();
        fetchTickets();
        fetchAttachments();
    }, []);

    if (!ticket) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    if (!customerProfile) {
        navigate(`/customers`);
        return null;
    }

    const onComment = async (data: FormData) => {
        console.log(data);
        try {
            await unwrap(
                createTicketComment({
                    ticketId,
                    payload: {
                        content: `<b>${userInfo.firstName} ${userInfo.lastName}:</b> ${data.comment}`,
                        contentType: "html",
                        isPublic: "true",
                    },
                })
            );
            await fetchComments();
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        reset({ comment: "" });
    };

    const reopenTicket = async () => {
        try {
            await unwrap(updateTicketStatus({ ticketId, status: { status: "Open" } }));
            await fetchTickets();
            setOpenLoading(false);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    return (
        <CustomerPageLayout
            customerId={customerId}
            title={`#${ticket.ticketNumber} - ${ticket.subject}`}
            prevUrl={`/tickets/${customerId}`}>
            <div className="row">
                <div className="col-lg-4">
                    <div>
                        <div>First Name</div>
                        <div className="font-family-semibold">{ticket.contact.firstName}</div>
                    </div>
                    <div className="mt-2">
                        <div>Last Name</div>
                        <div className="font-family-semibold">{ticket.contact.lastName}</div>
                    </div>
                    <div className="mt-2">
                        <div>Email</div>
                        <div className="font-family-semibold">{ticket.contact.email}</div>
                    </div>
                    <div className="mt-2">
                        <div>MDN</div>
                        <div className="font-family-semibold">{formatPhone(customerProfile.reachNumber)}</div>
                    </div>
                    <div className="mt-2">
                        <div>Issue transaction ID</div>
                        <div className="font-family-semibold">{ticket.cf.cf_issue_transaction_id ?? "-"}</div>
                    </div>
                    <div className="mt-2" style={{ whiteSpace: "pre-line" }}>
                        <div>List of troubleshooting steps already performed</div>
                        <div className="font-family-semibold">
                            {ticket.cf.cf_list_of_troubleshooting_steps_already_performed ?? "-"}
                        </div>
                    </div>
                    <div className="mt-2">
                        <div>Date</div>
                        <div className="font-family-semibold">
                            Created: {format(new Date(ticket.createdTime), "MMM dd, yyyy")}
                        </div>
                        {ticket.closedTime && (
                            <div className="font-family-semibold">
                                Resolved: {format(new Date(ticket.closedTime), "MMM dd, yyyy")}
                            </div>
                        )}
                    </div>
                    <div className="mt-2">
                        <div>Priority</div>
                        <div className="font-family-semibold">{ticket.priority}</div>
                    </div>
                    <div className="mt-2">
                        <div>Status</div>
                        <div className="font-family-semibold">{ticket.status}</div>
                    </div>
                    <div className="mt-5">
                        {ticket.status &&
                            (ticket.status.toLowerCase() === "closed" ||
                                ticket.status.toLowerCase() === "resolved") && (
                                <Button
                                    color="secondary"
                                    loading={openLoading}
                                    onClick={() => {
                                        setOpenLoading(true);
                                        reopenTicket();
                                    }}
                                    className="col-4">
                                    Re-open ticket
                                </Button>
                            )}
                    </div>
                </div>
                <div className="col-lg-8 col-xl-6">
                    <div>
                        <div className="font-family-bold">Subject</div>
                        <div className="font-family-semibold">{ticket.subject}</div>
                    </div>
                    <div className="mt-2">
                        <div className="font-family-bold">Description</div>
                        <div className="font-family-semibold">{ticket.description}</div>
                    </div>
                    {hasOnePermission("user_management.helpdesk.read_attachment") && (
                        <div className="mt-2">
                            <div className="font-family-bold">Attachments</div>
                            {ticketAttachments == null ? (
                                <div className="spinner-border spinner-border-sm mt-2 text-primary" />
                            ) : ticketAttachments.length === 0 ? (
                                "-"
                            ) : (
                                ticketAttachments.map((attachment) => (
                                    <img
                                        key={attachment.id}
                                        src={attachment.href}
                                        height={96}
                                        className="mr-3 cursor-pointer"
                                        onClick={() => setSelectedAttachment(attachment)}
                                        style={{ borderRadius: 4, border: "solid 1px #c8c7c7" }}
                                    />
                                ))
                            )}
                        </div>
                    )}
                    {hasOnePermission("user_management.helpdesk.read_comment") && (
                        <div className="mt-2">
                            <div className="font-family-bold">Comments</div>

                            {ticketComments == null ? (
                                <div className="spinner-border spinner-border-sm mt-2 text-primary" />
                            ) : ticketComments.length === 0 ? (
                                "-"
                            ) : (
                                ticketComments.map((comment) => (
                                    <div key={comment.id} className="mt-2">
                                        <div style={{ fontSize: 12 }} className="mb-1">
                                            {format(new Date(comment.commentedTime), "MMM dd, yyyy, hh:mm a")}
                                        </div>
                                        {comment.content.startsWith("<b>") ? (
                                            <div
                                                className="font-family-semibold"
                                                dangerouslySetInnerHTML={{ __html: comment.content }}
                                            />
                                        ) : (
                                            <div className="font-family-semibold">
                                                <b>{comment.commenter.name}:</b>{" "}
                                                <span dangerouslySetInnerHTML={{ __html: comment.content }} />
                                            </div>
                                        )}
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                    <div className="divider my-3" />
                    {hasOnePermission("user_management.helpdesk.create_comment") && (
                        <form onSubmit={handleSubmit(onComment)}>
                            <TextArea
                                placeholder="Add a comment"
                                name="comment"
                                className="col my-3 px-0"
                                rows={2}
                                register={register({
                                    required: {
                                        value: true,
                                        message: "Comment required",
                                    },
                                })}
                                showError={errors.comment != null}
                                errorMessage={errors.comment?.message}
                            />
                            <Button color="secondary" loading={formState.isSubmitting} className="col-3">
                                Save
                            </Button>
                        </form>
                    )}
                </div>
            </div>
            {selectedAttachment && (
                <Modal
                    size="lg"
                    show={selectedAttachment != null}
                    onHide={() => setSelectedAttachment(undefined)}
                    title="">
                    <img src={selectedAttachment.href} style={{ width: "100%" }} />
                </Modal>
            )}
        </CustomerPageLayout>
    );
}

export default TicketSummary;
