import { useAppDispatch, useAppSelector, useUnwrapAsyncThunk } from "../../utils/store";
import { ImeiRes } from "../../utils/types";
import { Customer } from "../../utils/types/customer";
import {
    additionalLineUpdated,
    checkIMEI as checkAddLinesIMEI,
    selectAdditionalLinesPurchase,
} from "../additional-lines-purchase-slice";
import {
    checkIMEI as checkPurchaseIMEI,
    customerSelector,
    imeiAdded,
    updateAccount,
    updateHubspot,
} from "../purchase-slice";

interface DeviceCompatibilityMethods {
    customer: Partial<Customer>;
    isNextLine?: boolean;
    checkIMEI: typeof checkPurchaseIMEI | typeof checkAddLinesIMEI;
    onImeiValid: (imei: string, res: ImeiRes) => Promise<void>;
    onImeiInvalid?: (imei: string, res: ImeiRes) => Promise<void>;
}

function usePurchaseDeviceCompatibility(): DeviceCompatibilityMethods {
    const customer = useAppSelector(customerSelector);
    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();

    const onIMEISuccess = async (imei: string, res: ImeiRes) => {
        dispatch(imeiAdded(imei));
        await unwrap(
            updateAccount({
                id: customer.id!,
                customer: {
                    imei,
                    make: res.make,
                    model: res.model,
                },
            })
        );
    };

    const onIMEIFailure = async (imei: string, res: ImeiRes) => {
        const { meta, errorText, make, model } = res;
        let err = errorText ?? "No error from server, isValid:false";
        if (
            meta?.["x-discount-level"][0] &&
            meta["x-discount-level"][0].length > 0 &&
            (meta["x-discount-level"][0] === "DISCOUNT_2_ACCESS" || meta["x-discount-level"][0] === "DISCOUNT_1_ACCESS")
        ) {
            err = meta["x-discount-level"][0];
        }
        await dispatch(
            updateHubspot({
                email: customer.emailId!,
                device_make: make,
                device_model: model,
                device_imei: imei,
                app_imei_incompatibility_reason: err,
            })
        );
    };

    return {
        customer,
        onImeiValid: onIMEISuccess,
        onImeiInvalid: onIMEIFailure,
        checkIMEI: checkPurchaseIMEI,
    };
}

function useAddLinesDeviceCompatibility(index = 0): DeviceCompatibilityMethods {
    const { additionalCustomers } = useAppSelector(selectAdditionalLinesPurchase);
    const dispatch = useAppDispatch();
    const customer = additionalCustomers[index];
    const isNextLine = index < additionalCustomers.length - 1;

    const onIMEISuccess = async (imei: string, res: ImeiRes) => {
        await dispatch(
            additionalLineUpdated({
                index,
                customer: {
                    imei,
                    make: res.make,
                    model: res.model,
                },
            })
        );
    };

    return {
        customer,
        isNextLine,
        onImeiValid: onIMEISuccess,
        checkIMEI: checkAddLinesIMEI,
    };
}

export function useDeviceCompatibility(index?: number) {
    const purchaseMethods = usePurchaseDeviceCompatibility();
    const addLinesMethods = useAddLinesDeviceCompatibility(index);

    // if index is not present, it is the initial purchase flow
    if (index == null) {
        return purchaseMethods;
    }

    return addLinesMethods;
}
