import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "../components/button";
import { AuthState, authStateSelector, loginWayReset, loginWayUpdated } from "../features/auth-slice";
import { isReachSSOEnabled, isSSOEnabled } from "../utils/helpers";
import branding from "../branding/branding.json";
import Login from "./login-page";
import SessionTimeoutModal from "./session-timeout-modal";
import { useAppDispatch } from "../utils/store";
import brandLogo from "../images/brandLogo.png";

export interface LoginProps extends RouteComponentProps {
    redirect_url?: string;
}

enum UI_STATE {
    INITIAL = "INITIAL",
    REACH_LOGIN = "REACH_LOGIN",
}

const SsoLogin = (props: LoginProps) => {
    const [uiState, setUiState] = useState<UI_STATE>(UI_STATE.INITIAL);
    const [ssoRedirectLoading, setSsoRedirectLoading] = useState(false);
    const [reachSsoRedirectLoading, setReachSsoRedirectLoading] = useState(false);
    const [isAuthError, setIsAuthError] = useState<boolean>(false);

    const { authState } = useSelector(authStateSelector);
    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            setSsoRedirectLoading(false);
            setReachSsoRedirectLoading(false);
        };
    }, []);

    useEffect(() => {
        if (props && props.location && props.location.search) {
            const redirect_url = props.location.search?.split("?redirect_url=");
            if (redirect_url && redirect_url.length > 0) {
                window.location.href = redirect_url[1];
            }
        }
    }, []);

    useEffect(() => {
        const isTimedOut = localStorage.getItem("is_timeout") === "true" ? true : false;
        if (isTimedOut && props?.path === "/login") {
            setIsAuthError(true);
        }

        return () => {
            if (props?.path === "/login") {
                localStorage.removeItem("is_timeout");
            }
        };
    }, [props]);

    useEffect(() => {
        const local_redirect_url: any = localStorage.getItem("redirect_url");
        if (authState === AuthState.LOGGED_IN) {
            if (local_redirect_url) {
                navigate(local_redirect_url);
                setTimeout(() => {
                    localStorage.removeItem("redirect_url");
                }, 100);
            } else {
                navigate("/");
            }
            localStorage.removeItem("is_timeout");
        }
    }, [authState]);
    return (
        <>
            {uiState === UI_STATE.INITIAL && (
                <div className="container col-lg-4 col-md-8 col-sm-12 d-flex flex-column mt-5 pt-5">
                    <img className="align-self-center mt-5" src={brandLogo} height={68} />
                    <div className="fs-20 font-family-bold text-center mt-4 mb-5">Welcome to Reach Central</div>
                    {isSSOEnabled() ? (
                        <Button
                            color="secondary"
                            loading={ssoRedirectLoading}
                            className="fs-18 rounded-pill mb-3"
                            onClick={() => {
                                dispatch(loginWayReset());
                                dispatch(loginWayUpdated(false));
                                localStorage.setItem("isReachLogin", "false");
                                setSsoRedirectLoading(true);
                                window.open(process.env.SSO_LOGIN_URL, "_self");
                            }}>
                            {branding.shortName} Team Log In
                        </Button>
                    ) : null}
                    <Button
                        color="secondary"
                        loading={reachSsoRedirectLoading}
                        className="fs-18 rounded-pill my-2"
                        onClick={() => {
                            dispatch(loginWayReset());
                            dispatch(loginWayUpdated(true));
                            if (isReachSSOEnabled()) {
                                localStorage.setItem("isReachLogin", "true");
                                setReachSsoRedirectLoading(true);
                                window.open(process.env.SSO_REACH_LOGIN_URL, "_self");
                            } else {
                                setUiState(UI_STATE.REACH_LOGIN);
                            }
                        }}>
                        Reach Team Log In
                    </Button>
                    <div className="my-5" />
                </div>
            )}
            {uiState === UI_STATE.REACH_LOGIN && <Login changeUiState={() => setUiState(UI_STATE.INITIAL)} />}
            {isAuthError && <SessionTimeoutModal isAuthError={isAuthError} setIsAuthError={setIsAuthError} />}
        </>
    );
};
export default SsoLogin;
