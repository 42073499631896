import React, { useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import { RouteComponentProps } from "@reach/router";
import { Controller, useForm, UseFormMethods } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button } from "../../components/button";
import Counter from "../../components/counter";
import { Input } from "../../components/input";
import Layout from "../../components/layout";
import { Radio } from "../../components/radio";
import Select from "../../components/select";
import Toggle from "../../components/toggle";
import {
    couponRemoved,
    linesUpdated,
    selectedPlanSelector,
    totalLinesSelector,
    planLinePriceSelector,
    additionalLinePriceSelector,
    fetchPlanTax,
    planTaxAmountSelector,
    planTaxSelector,
    addressAdded,
    customerSelector,
    planTaxReset,
    monthlyBillSelector,
    welcomeKitPriceSelector,
    simTaxSelector,
    fetchSIMTax,
    totalDueSelector,
    getCoupon,
    couponSelector,
    promoDiscountSelector,
    planEstimatePerLineSelector,
    updateAccount,
    getCouponEligibility,
    sendPaymentAndPasswordLinks,
    isSimInHandSelector,
    setSimInHand,
    sendCardAndPasswordLinks,
} from "../../features/purchase-slice";
import { Address, FlowVersion } from "../../utils/types/customer";
import { states } from "../../utils/states";
import PlacesAutocompleteInput from "../../components/places-autocomplete-input";
import { useAppDispatch, useUnwrapAsyncThunk } from "../../utils/store";
import { useViewport } from "../../utils/viewport-context";
import { configSelector } from "../../features/config-slice";
import branding from "../../branding/branding.json";
import CouponInput from "../../components/coupon-input";
import perLineCostImg from "../../images/perLineCost.png";
import { CouponType } from "../../utils/types";
import { ReachCsAPI, reachEndpoint } from "../../utils/reach-cs-api";
import { useErrorHandler } from "../../components/error-snackbar";
import classNames from "classnames";
import { format, getDate } from "date-fns";
import CustomerBadge from "../../components/customer-badge";
import Modal from "../../components/modal";
import { isBrowser } from "../../utils/helpers";
import { generatePaymentPage } from "../../features/profile-slice";
import { usePermissions } from "../../features/hooks/use-permissions";

export type CheckoutProps = RouteComponentProps;

const componentForm: Record<string, "short_name" | "long_name"> = {
    street_number: "short_name",
    route: "long_name",
    sublocality_level_1: "long_name",
    locality: "long_name",
    administrative_area_level_1: "short_name",
    country: "long_name",
    postal_code: "short_name",
};

interface FormData {
    addresses: Address[];
}

interface AddressComponentProps extends UseFormMethods {
    index: number;
    address?: Address;
    onSubmit: (address: Address) => void;
    className?: string;
}

export function AddressComponent(props: AddressComponentProps) {
    const {
        index,
        address,
        register,
        errors,
        control,
        setValue,
        clearErrors,
        getValues,
        className = "row-cols-1 row-cols-md-2",
    } = props;

    const onSubmit = (data: FormData) => {
        console.log(data);
        const address = data.addresses[index];
        address.country = "USA";
        address.type = index === 0 ? "billing" : "shipping";
        props.onSubmit(address);
    };

    const onPlaceChanged = (addressComponents?: google.maps.GeocoderAddressComponent[]) => {
        if (addressComponents) {
            const obj: Record<string, string> = {};
            const address: Partial<Address> = {};

            for (const component of addressComponents) {
                const type = component.types[0];
                if (componentForm[type]) {
                    obj[type] = component[componentForm[type]];
                }
            }

            if (obj.street_number && obj.route) {
                address.address1 = `${obj.street_number} ${obj.route}`;
            } else if (obj.route) {
                address.address1 = obj.route;
            } else if (obj.street_number) {
                address.address1 = obj.street_number;
            }
            if (obj.postal_code) {
                address.zip = obj.postal_code;
            }

            if (obj.locality) {
                address.city = obj.locality;
            } else if (obj.sublocality_level_1) {
                address.city = obj.sublocality_level_1;
            }
            address.state = obj.administrative_area_level_1;
            address.country = "USA";
            address.type = index === 0 ? "billing" : "shipping";

            for (const key in address) {
                setValue(`addresses[${index}].${key}`, address[key as keyof Address]);
                clearErrors(`addresses[${index}].${key}`);
            }

            // handleSubmit(onSubmit)();
            onSubmit(getValues() as FormData);
        }
    };

    return (
        <div className={classNames("row mx-n2", className)}>
            <Controller
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: "Street address is required",
                    },
                    maxLength: {
                        value: 70,
                        message: "Street address must be less than 70 characters",
                    },
                }}
                name={`addresses[${index}].address1`}
                defaultValue={address?.address1 ?? ""}
                render={({ onChange, onBlur, value, name }) => (
                    <PlacesAutocompleteInput
                        type="text"
                        autoComplete="off"
                        placeholder="Street Address"
                        className="my-2 col px-2"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                        onPlaceChanged={onPlaceChanged}
                        showError={errors.addresses?.[index]?.address1}
                        errorMessage={errors.addresses?.[index]?.address1?.message}
                    />
                )}
            />
            <Input
                type="text"
                placeholder="Apt, unit or floor (optional)"
                className="my-2 col px-2"
                defaultValue={address?.address2}
                name={`addresses[${index}].address2`}
                showError={errors.addresses?.[index]?.address2}
                errorMessage={errors.addresses?.[index]?.address2?.message}
            />
            <Input
                type="text"
                placeholder="City"
                className="my-2 col px-2"
                defaultValue={address?.city}
                name={`addresses[${index}].city`}
                register={register({
                    required: {
                        value: true,
                        message: "City is required",
                    },
                    pattern: {
                        value: /^[A-Za-z -.]{2,34}$/,
                        message: "City is invalid",
                    },
                    validate: (value: string) => value.toUpperCase() !== "NA" || "City is invalid",
                })}
                showError={errors.addresses?.[index]?.city}
                errorMessage={errors.addresses?.[index]?.city?.message}
            />
            <Select
                className="my-2 col px-2"
                defaultValue={address?.state}
                name={`addresses[${index}].state`}
                register={register({
                    required: {
                        value: true,
                        message: "State is required",
                    },
                })}
                showError={errors.addresses?.[index]?.state}
                errorMessage={errors.addresses?.[index]?.state?.message}>
                <option value="">Select State</option>
                {states.USA.map((state) => (
                    <option key={state.code} value={state.code}>
                        {state.name}
                    </option>
                ))}
            </Select>
            <Input
                type="text"
                placeholder="Zip Code"
                className="my-2 col px-2"
                defaultValue={address?.zip}
                name={`addresses[${index}].zip`}
                maxLength={5}
                register={register({
                    required: {
                        value: true,
                        message: "Zip is required",
                    },
                    pattern: {
                        value: /^\d{5}$/,
                        message: "Invalid zip code",
                    },
                })}
                showError={errors.addresses?.[index]?.zip}
                errorMessage={errors.addresses?.[index]?.zip?.message}
            />
            <Select disabled className="my-2 col px-2" name={`addresses[${index}].country`} register={register}>
                <option value="USA">United States</option>
            </Select>
            <input type="submit" style={{ display: "none" }} />
        </div>
    );
}

export default function Checkout(props: CheckoutProps) {
    const [sameShippingAddress, setSameShippingAddress] = useState(true);
    const { width } = useViewport();

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const { hasOnePermission } = usePermissions();

    const selectedPlan = useSelector(selectedPlanSelector);

    const formMethods = useForm({
        mode: "onBlur",
    });

    const totalLines = useSelector(totalLinesSelector);
    const { shippingRates } = useSelector(configSelector);
    const planLinePrice = useSelector(planLinePriceSelector);
    const additionalLinePrice = useSelector(additionalLinePriceSelector);
    const planTaxAmount = useSelector(planTaxAmountSelector);
    const planTax = useSelector(planTaxSelector);
    const simTax = useSelector(simTaxSelector);
    const customer = useSelector(customerSelector);
    const promoDiscount = useSelector(promoDiscountSelector);
    const monthlyBillAmount = useSelector(monthlyBillSelector);
    const planEstimatePerLine = useSelector(planEstimatePerLineSelector);
    const welcomeKitPrice = useSelector(welcomeKitPriceSelector);
    const totalDue = useSelector(totalDueSelector);
    const coupon = useSelector(couponSelector);
    const isSimInHand = useSelector(isSimInHandSelector);

    const [selectedShippingOption, setShippingOption] = useState(shippingRates[0]);
    const [showNewCardModal, setShowNewCardModal] = useState(false);

    const currentDate = new Date();

    let nextBillingDate;

    if (getDate(currentDate) < 7) {
        nextBillingDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 10);
    } else {
        nextBillingDate = new Date(currentDate.getFullYear(), 1 + currentDate.getMonth(), 10);
    }

    useEffect(() => {
        dispatch(planTaxReset());
        if (customer.addresses && customer.addresses.length > 0 && planTaxAmount > 0) {
            dispatch(
                fetchPlanTax({
                    amount: planTaxAmount,
                    additionalLines: totalLines - 1,
                    address: customer.addresses[0],
                })
            );
        }
    }, [planTaxAmount, totalLines, customer.addresses]);

    useEffect(() => {
        if (
            customer.addresses &&
            customer.addresses.length > 0 &&
            welcomeKitPrice + selectedShippingOption.shippingAmount > 0
        ) {
            dispatch(
                fetchSIMTax({
                    amount: welcomeKitPrice + selectedShippingOption.shippingAmount,
                    additionalLines: totalLines - 1,
                    address: customer.addresses[1] || customer.addresses[0],
                })
            );
        }
    }, [selectedShippingOption, customer.addresses]);

    useEffect(() => {
        dispatch(getCouponEligibility(customer.emailId!));
    }, []);

    if (!selectedPlan) {
        navigate("/purchase/plans");
        return null;
    }

    const onSubmit = async (data: FormData) => {
        console.log(data);
        const addresses = data.addresses.map(
            (address, index): Address => ({
                ...address,
                country: "USA",
                type: index === 0 ? "billing" : "shipping",
                name: `${customer.firstName} ${customer.lastName}`,
                phone: customer.primaryNumber!,
            })
        );

        if (sameShippingAddress) {
            addresses[1] = { ...addresses[0], type: "shipping" };
        }

        try {
            await unwrap(
                updateAccount({
                    id: customer.id!,
                    customer: {
                        addresses,
                        extraLines: !isSimInHand ? totalLines - 1 : 0,
                        isSimInHand,
                        isDraft: false,
                        zipcode: data.addresses[0].zip,
                        selectedShippingMethod: selectedShippingOption,
                        flowVersion: FlowVersion.FLOW_1,
                    },
                })
            );

            if (coupon?.type === CouponType.PROMO) {
                await ReachCsAPI.reservePromo(customer.id!, coupon.coupon);
            }

            if (coupon?.type === CouponType.REFERRAL) {
                if (process.env.ENABLE_IR === "true") {
                    await ReachCsAPI.trackIR(
                        customer.id!,
                        customer.firstName!,
                        customer.emailId!,
                        coupon.coupon,
                        process.env.INVITE_CAMPAIGN_ID
                    );
                }
            }

            setShowNewCardModal(true);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const onPayment = async () => {
        try {
            const res = await unwrap(
                generatePaymentPage({
                    emailId: customer.emailId!,
                    redirectUrl: `${window.location.origin}/profile/${customer.id}`,
                    type: isSimInHand ? "card" : "purchase",
                })
            );
            window.location.href = `${reachEndpoint}/account/redirect/page/${res.id}`;
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const onSendPaymentLink = async () => {
        try {
            if (isSimInHand) {
                await dispatch(sendCardAndPasswordLinks(customer.id!));
            } else {
                await dispatch(sendPaymentAndPasswordLinks(customer.id!));
            }
            navigate("/purchase/complete");
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    return (
        <Layout title={`${customer.firstName} ${customer.lastName} - Checkout`}>
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <Link className="text-cta mb-3" to="/purchase/imei">
                    <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                    Back
                </Link>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customer.firstName} {customer.lastName} <CustomerBadge isPrimary={customer.isPrimary} />
                    </div>
                    <div>{customer.emailId}</div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>Enter billing address</h1>
                </div>
                <div className="divider" />

                <form
                    className="d-flex flex-column mt-2"
                    onSubmit={formMethods.handleSubmit(onSubmit)}
                    autoComplete="off">
                    <AddressComponent
                        index={0}
                        address={customer.addresses?.[0]}
                        onSubmit={(address) => dispatch(addressAdded(address))}
                        {...formMethods}
                    />

                    <div className="align-self-start mt-2">
                        <p className="font-family-semibold" style={{ fontSize: 16 }}>
                            Select your SIM preference
                        </p>

                        <Radio
                            inline
                            id="simPurchase"
                            checked={!isSimInHand}
                            onChange={() => dispatch(setSimInHand(false))}
                            label={`Send me a ${branding.shortName} SIM`}
                            name="simInHandGroup"
                        />
                        <Radio
                            inline
                            id="simInHand"
                            checked={isSimInHand}
                            onChange={() => dispatch(setSimInHand(true))}
                            label={`I already have a ${branding.shortName} SIM`}
                            name="simInHandGroup"
                        />
                    </div>

                    {!isSimInHand && (
                        <>
                            <div className="align-self-start mt-2 w-100">
                                <p className="font-family-semibold" style={{ fontSize: 16 }}>
                                    Select shipping option
                                </p>
                                <Select
                                    className="col-12 p-0"
                                    value={selectedShippingOption.name}
                                    onChange={({ target: { value } }) =>
                                        setShippingOption(
                                            shippingRates.find((shippingRate) => shippingRate.name === value)!
                                        )
                                    }>
                                    {shippingRates.map((shippingRate) => (
                                        <option key={shippingRate.name} value={shippingRate.name}>
                                            {shippingRate.name} - ${shippingRate.shippingAmount}
                                        </option>
                                    ))}
                                </Select>
                            </div>

                            <div className="align-self-start mt-4">
                                <Toggle
                                    id="sameAddressToggle"
                                    label="Billing & Shipping address are the same"
                                    checked={sameShippingAddress}
                                    onChange={({ target: { checked } }) => setSameShippingAddress(checked)}
                                />
                            </div>

                            {!sameShippingAddress && (
                                <AddressComponent
                                    index={1}
                                    onSubmit={(address) => dispatch(addressAdded(address))}
                                    {...formMethods}
                                />
                            )}
                        </>
                    )}
                </form>

                <div className="d-flex align-items-center justify-content-between my-2">
                    <h1>Summary</h1>
                    {width <= 500 && (
                        <Link to="/purchase/plans" className="text-cta align-self-center">
                            Change Plan
                        </Link>
                    )}
                </div>
                <div className="divider" />
                <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="d-flex align-items-center">
                        <span>
                            <span className="text-cta">
                                {selectedPlan.name} ({selectedPlan.planData}
                                {selectedPlan.dataUnit}){" "}
                            </span>{" "}
                            {selectedPlan.maxLines > 1 && "for"}
                        </span>
                        {selectedPlan.maxLines > 1 && (
                            <Counter
                                defaultValue={!isSimInHand ? totalLines : 1}
                                minValue={1}
                                maxValue={!isSimInHand ? selectedPlan.maxLines : 1}
                                onChange={(value) => {
                                    dispatch(linesUpdated(value));
                                }}
                                className="ml-2"
                            />
                        )}
                    </div>
                    {width > 500 && (
                        <Link to="/purchase/plans" className="text-cta align-self-center">
                            Change Plan
                        </Link>
                    )}
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2 ml-4">
                    <div>Your line</div>
                    <div>${planLinePrice.toFixed(2)}</div>
                </div>
                {totalLines > 1 && !isSimInHand && (
                    <div className="d-flex justify-content-between align-items-center mt-2 ml-4">
                        <div>Additional lines ({totalLines - 1})</div>
                        <div>${additionalLinePrice.toFixed(2)}</div>
                    </div>
                )}
                <div className="d-flex justify-content-between align-items-center mt-2">
                    <div>Taxes and fees</div>
                    <div>{planTax ? `$${planTax.totalTax.toFixed(2)}` : "-"}</div>
                </div>
                {promoDiscount > 0 && (
                    <div className="d-flex text-primary justify-content-between align-items-center mt-2">
                        <div>Promo discount</div>
                        <div className="font-family-semibold">-${promoDiscount.toFixed(2)}</div>
                    </div>
                )}
                <div className="d-flex justify-content-between align-items-center mt-2">
                    <div>Estimated monthly bill</div>
                    <div className="font-family-semibold">${monthlyBillAmount.toFixed(2)}</div>
                </div>

                {selectedPlan.maxLines > 1 && (
                    <div className="font-family-bold text-primary mt-3 mb-4" style={{ fontSize: 16 }}>
                        <img src={perLineCostImg} className="mr-2" />
                        That's ${planEstimatePerLine} per person :)
                    </div>
                )}

                <CouponInput
                    coupon={coupon}
                    onCouponChange={async (couponCode) => {
                        const coupon = await unwrap(getCoupon(couponCode));
                        if (coupon.expired) {
                            return undefined;
                        }
                        return coupon;
                    }}
                    onCouponRemoved={() => dispatch(couponRemoved())}
                />

                {!isSimInHand && (
                    <>
                        <div className="font-family-bold mt-3">One-time charges</div>
                        <div className="d-flex justify-content-between align-items-center mt-2">
                            <div>Welcome Kit w/SIM card ({totalLines})</div>
                            <div>${welcomeKitPrice.toFixed(2)}</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-2">
                            <div>{selectedShippingOption.name}</div>
                            <div>${selectedShippingOption.shippingAmount.toFixed(2)}</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-2">
                            <div>Taxes and fees</div>
                            <div>{simTax ? `$${simTax.totalTax.toFixed(2)}` : "-"}</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-2">
                            <div className="font-family-bold">Total due today</div>
                            <div className="font-family-bold">${totalDue.toFixed(2)}</div>
                        </div>
                    </>
                )}

                <div className="mt-3">
                    <div className="text-primary font-family-semibold">Note:</div>
                    <div>
                        1. Your service will be paid monthly via autopay, with your first bill payment set to process on{" "}
                        <span className="font-family-semibold">{format(nextBillingDate, "MMMM dd, yyyy")}</span>.
                    </div>
                    <div className="mt-3">
                        2. At the time of activation, a <span className="font-family-semibold">temporary hold</span>{" "}
                        will be placed on your credit card for the total value of the plan purchased.
                    </div>
                </div>

                <Button
                    fullWidth
                    color="secondary"
                    className="my-3"
                    loading={formMethods.formState.isSubmitting}
                    onClick={() => {
                        formMethods.handleSubmit(onSubmit)();
                    }}>
                    Next
                </Button>
            </div>

            <Modal
                show={showNewCardModal}
                size="sm"
                title=" Do you want to add a new card?"
                onHide={() => setShowNewCardModal(false)}>
                {hasOnePermission("user_management.payment.send_email") && (
                    <Button
                        color="secondary"
                        className="col-12"
                        loading={formMethods.formState.isSubmitting}
                        onClick={formMethods.handleSubmit(onSendPaymentLink)}>
                        Send email with Link to add card
                    </Button>
                )}
                {hasOnePermission("user_management.payment.collect_direct") && (
                    <Button
                        color="secondary"
                        className="col-12 mt-3"
                        loading={formMethods.formState.isSubmitting}
                        onClick={formMethods.handleSubmit(onPayment)}>
                        Collect card details online
                    </Button>
                )}
            </Modal>
        </Layout>
    );
}
