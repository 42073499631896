import React, { useState } from "react";
import classNames from "classnames";

export interface TabProps {
    active?: boolean;
    children: any;
    onClick?: () => void;
    index?: number;
    maxLength?: number;
}

export function Tab(props: TabProps) {
    const { children, active = false, onClick, index, maxLength = 1 } = props;
    return (
        <div
            className={classNames("tab flex-fill text-center", {
                "tab-active": active,
                "tab-left-end": index === 0,
                "tab-right-end": index === maxLength - 1,
            })}
            onClick={onClick}>
            {children}
        </div>
    );
}

export interface TabGroupProps {
    defaultValue?: number;
    children: any[];
    onChange?: (value: number) => void;
    className?: string;
}

export default function TabGroup(props: TabGroupProps) {
    const { children, defaultValue, onChange, className } = props;
    const [activeIndex, setActiveIndex] = useState(defaultValue ?? 0);

    return (
        <div className={classNames("d-flex", className)}>
            {children.map((child, index) => (
                <Tab
                    {...child.props}
                    key={index}
                    active={index === activeIndex}
                    maxLength={children.length}
                    index={index}
                    onClick={() => {
                        setActiveIndex(index);
                        onChange?.(index);
                    }}
                />
            ))}
        </div>
    );
}
