import React from "react";
import { formatPhone } from "../utils/helpers";
import { OrderStatus, SearchableCustomer } from "../utils/types";
import { CustomerStatus } from "../utils/types/customer";
import { navigate } from "gatsby";
import { useAppDispatch } from "../utils/store";
import { customersLoaded } from "../features/purchase-slice";
import CustomerBadge from "./customer-badge";

export interface CustomerListItemProps {
    customer: SearchableCustomer;
}

export default function CustomerListItem(props: CustomerListItemProps) {
    const { customer } = props;
    const dispatch = useAppDispatch();

    let statusUI;
    switch (customer.status) {
        case CustomerStatus.INACTIVE:
            if (customer.latestOrderStatus === OrderStatus.ORDERED) {
                statusUI = (
                    <>
                        <span className="reach-cs-alert" />
                        <span className="font-family-bold ml-2">Ordered</span>
                    </>
                );
            } else if (
                customer.latestOrderStatus === OrderStatus.LABELLED ||
                customer.latestOrderStatus === OrderStatus.SHIPPED
            ) {
                statusUI = (
                    <>
                        <span className="reach-cs-alert" />
                        <span className="font-family-bold ml-2">Order in transit</span>
                    </>
                );
            } else if (customer.latestOrderStatus === OrderStatus.DELIVERED) {
                statusUI = (
                    <>
                        <span className="reach-cs-alert" />
                        <span className="font-family-bold ml-2">Order delivered</span>
                    </>
                );
            } else if (customer.latestOrderStatus === OrderStatus.CANCELLED) {
                statusUI = (
                    <>
                        <span className="reach-cs-alert" />
                        <span className="font-family-bold ml-2">Order cancelled</span>
                    </>
                );
            } else if (customer.isDraft) {
                statusUI = (
                    <>
                        <span className="reach-cs-loading text-tertiary2" />
                        <span className="font-family-bold ml-2">Purchase pending</span>
                    </>
                );
            } else if (!customer.simPaid && customer.isPrimary) {
                statusUI = (
                    <>
                        <span className="reach-cs-loading text-tertiary2" />
                        <span className="font-family-bold ml-2">Payment pending</span>
                    </>
                );
            } else {
                statusUI = (
                    <>
                        <span className="reach-cs-cross text-tertiary1" />
                        <span className="font-family-bold ml-2">Inactive</span>
                    </>
                );
            }
            break;
        case CustomerStatus.ACTIVATION_IN_PROGRESS:
            statusUI = (
                <>
                    <span className="reach-cs-loading text-tertiary2" />
                    <span className="font-family-bold ml-2">Activation in progress</span>
                </>
            );
            break;
        case CustomerStatus.PORTING_IN_PROGRESS:
            statusUI = (
                <>
                    <span className="reach-cs-loading text-tertiary2" />
                    <span className="font-family-bold ml-2">Port in progress</span>
                </>
            );
            break;
        case CustomerStatus.ACTIVE:
            statusUI = (
                <>
                    <span className="reach-cs-tick text-primary" />
                    <span className="font-family-bold ml-2">Active</span>
                </>
            );
            break;
        case CustomerStatus.SUSPENDED:
            statusUI = (
                <>
                    <span className="reach-cs-cross text-tertiary1" />
                    <span className="font-family-bold ml-2">Suspended</span>
                </>
            );
            break;
        case CustomerStatus.DISCONNECTED:
            statusUI = (
                <>
                    <span className="reach-cs-cross text-tertiary1" />
                    <span className="font-family-bold ml-2">Disconnected</span>
                </>
            );
            break;
        case CustomerStatus.HOTLINED:
            statusUI = (
                <>
                    <span className="reach-cs-hotline text-black" />
                    <span className="font-family-bold ml-2">Hotlined</span>
                </>
            );
            break;
        case CustomerStatus.REJECTED:
            statusUI = (
                <>
                    <span className="reach-cs-cross text-tertiary1" />
                    <span className="font-family-bold ml-2">Rejected</span>
                </>
            );
            break;
        case CustomerStatus.ACTIVATION_FAILED:
            statusUI = (
                <>
                    <span className="reach-cs-cross text-tertiary1" />
                    <span className="font-family-bold ml-2">Activation failed</span>
                </>
            );
            break;
        case CustomerStatus.PORTING_FAILED:
            statusUI = (
                <>
                    <span className="reach-cs-cross text-tertiary1" />
                    <span className="font-family-bold ml-2">Porting failed</span>
                </>
            );
            break;
        case CustomerStatus.PORT_CANCELLED:
            statusUI = (
                <>
                    <span className="reach-cs-cross text-tertiary1" />
                    <span className="font-family-bold ml-2">Port cancelled</span>
                </>
            );
            break;
    }

    return (
        <div
            className="row align-items-center mt-2"
            style={{ cursor: "pointer" }}
            onClick={() => {
                if (customer.isDraft) {
                    dispatch(customersLoaded(customer));
                    navigate("/purchase/new");
                } else {
                    navigate(`/profile/${customer.id}`);
                }
            }}>
            <div className="col-sm-5 col-12" style={{ overflowWrap: "break-word" }}>
                <div className="font-family-bold">
                    {customer.firstName} {customer.lastName} <CustomerBadge isPrimary={customer.isPrimary} />
                </div>
                <div>
                    {customer.reachNumber
                        ? formatPhone(customer.reachNumber)
                        : customer.primaryNumber
                        ? formatPhone(customer.primaryNumber)
                        : "-"}
                </div>
                <div>{customer.emailId}</div>
            </div>
            <div className="col-sm-4 col-12">
                <div
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}>
                    {customer.model}
                </div>
                {customer.imei && <div>IMEI: {customer.imei}</div>}
            </div>
            <div className="col-sm-3 col-12">{statusUI}</div>
            <div className="divider mt-2 mx-3" style={{ opacity: 0.31 }} />
        </div>
    );
}
