import React from "react";
import { Controller, useForm } from "react-hook-form";
import { usePermissions } from "../features/hooks/use-permissions";
import { addAddonCharge, fetchProfile } from "../features/profile-slice";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile } from "../utils/types";
import { MaxValueLimitation } from "../utils/types/user-roles";
import { Button } from "./button";
import { useErrorHandler } from "./error-snackbar";
import { Input } from "./input";
import Modal, { ModalProps } from "./modal";
import Select from "./select";
import { useSuccessModal } from "./success-modal";

export interface AddonChargeModalProps extends ModalProps {
    customerProfile: CustomerProfile;
}

interface FormData {
    creditAmount: number;
    description: string;
}

export default function AddonChargeModal(props: AddonChargeModalProps) {
    const { customerProfile, ...rest } = props;

    const { register, handleSubmit, formState, watch, control, errors } = useForm<FormData>({ mode: "onBlur" });

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();
    const { getLimitations } = usePermissions();

    const creditLimitations = getLimitations("billing.addon_charge.allow");

    const onSubmit = async (data: FormData) => {
        try {
            await unwrap(
                addAddonCharge({
                    ...data,
                    customerId: customerProfile.id,
                })
            );
            await dispatch(fetchProfile(customerProfile.id));
            showSuccessModal(`Addon charge added`);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        rest.onHide();
    };

    return (
        <Modal {...rest}>
            <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
                <Input
                    type="number"
                    inputMode="numeric"
                    placeholder="Enter amount"
                    className="col-12 px-0 mb-3"
                    name="creditAmount"
                    step={0.01}
                    showError={errors.creditAmount != null}
                    errorMessage={errors.creditAmount?.message}
                    register={register({
                        required: "Amount required",
                        max: creditLimitations?.max_value?.value
                            ? {
                                  value: creditLimitations.max_value.value,
                                  message: `Amount has to be less than or equal to ${creditLimitations.max_value.value}`,
                              }
                            : undefined,
                        validate: (value) => parseFloat(value) > 0 || "Invalid amount",
                    })}
                />
                <Controller
                    defaultValue=""
                    name="description"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: "Description must be selected",
                        },
                    }}
                    render={({ onChange, onBlur, value, name }) => (
                        <Select
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                            className="col-12 px-0 mb-3"
                            showError={errors.description != null}
                            errorMessage={errors.description?.message}>
                            <option value="">Select description</option>
                            {creditLimitations?.descriptions.value.map((value) => (
                                <option value={value} key={value}>
                                    {value}
                                </option>
                            ))}
                        </Select>
                    )}
                />
                <Button color="secondary" className="mb-3" fullWidth loading={formState.isSubmitting}>
                    Confirm
                </Button>

                <div className="font-family-semibold">
                    <span className="font-family-bold">Note: </span>
                    Any amount added here will be added to the user's invoice to be paid at the end of the billing
                    cycle.
                </div>
            </form>
        </Modal>
    );
}
