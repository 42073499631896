import React, { useEffect, useState } from "react";
import { RouteComponentProps, useLocation } from "@reach/router";
import { Input } from "../components/input";
import { Button } from "../components/button";
import Layout from "../components/layout";
import { Link, navigate } from "gatsby";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../utils/store";
import { userSearchSelector, usersSelector, searchUsers, queryUpdated } from "../features/operation-users-slice";
import { useViewport, ViewportBreakpoint } from "../utils/viewport-context";
import debounce from "lodash.debounce";
import { usePermissions } from "../features/hooks/use-permissions";
import Select from "../components/select";
import noResultsImage from "../images/icon-noresultsfound.png";
import { selectUserInfo } from "../features/user-role-slice";
import { formatPhone } from "../utils/helpers";
import Modal from "../components/modal";
import { EditUser } from "./create-user-page";

export type UserListProps = RouteComponentProps;

export default function UserList(props: UserListProps) {
    const dispatch = useAppDispatch();
    const { width } = useViewport();

    const { roleMap } = useAppSelector(selectUserInfo);
    const [emailId, setEmailId] = useState<string>();

    const [query, setQuery] = useState("");

    const { hasOnePermission } = usePermissions();
    const allUsers = useSelector(usersSelector.selectAll);
    const { loading, loadingAdditional, lastKeys } = useSelector(userSearchSelector);

    const assignableRoles = roleMap ? Object.keys(roleMap) : [];

    const [filterValue, setFilterValue] = useState("");

    const initialBatchSize = width < ViewportBreakpoint.MD ? 5 : 10;

    const [batchSize, setBatchSize] = useState(initialBatchSize);

    const updateUsers = (update: boolean) => {
        if (update && batchSize < allUsers.length) {
            setBatchSize(batchSize + initialBatchSize);
            return;
        }

        dispatch(
            searchUsers({
                limit: 100,
                query,
                update,
                lastKeys: update ? lastKeys : undefined,
                roleIds: filterValue !== "" ? [filterValue] : assignableRoles,
            })
        );
    };

    useEffect(() => {
        updateUsers(false);
    }, [query, filterValue]);

    const handleSearch = debounce((query: string) => {
        setQuery(query);
        dispatch(queryUpdated());
    }, 1000);

    return (
        <Layout title="User List">
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to="/">
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="d-flex align-items-center justify-content-between pb-3">
                    <h1>User List</h1>
                    <Button
                        color="secondary"
                        icon="reach-cs-plus"
                        mini={width < ViewportBreakpoint.MD}
                        disabled={!hasOnePermission("user_role_management.manage_user.create")}
                        onClick={() => navigate("/users/new")}>
                        Create user
                    </Button>
                </div>
                <div className="row mb-2">
                    <Input
                        type="text"
                        icon="reach-cs-search"
                        placeholder="Enter name, phone number or email"
                        className="col-10 pr-1 pr-sm-3 col-sm-9"
                        defaultValue={query}
                        onChange={({ target: { value } }) => {
                            handleSearch(value);
                        }}
                    />
                    <Select
                        className="col-1 col-sm-3 pl-0"
                        value={filterValue}
                        onChange={({ target: { value } }) => setFilterValue(value)}>
                        <option value="">Filter by role</option>
                        {assignableRoles.map((role) => (
                            <option key={role} value={role}>
                                {roleMap?.[role]}
                            </option>
                        ))}
                    </Select>
                </div>
                {loading ? (
                    <div className="d-flex justify-content-center mt-5">
                        <div className="spinner-border text-primary" />
                    </div>
                ) : allUsers.length === 0 ? (
                    <div
                        className="d-flex align-items-center justify-content-center flex-column"
                        style={{ height: 200 }}>
                        <img src={noResultsImage} />
                        <div className="font-family-semibold" style={{ fontSize: 18 }}>
                            No results found
                        </div>
                    </div>
                ) : (
                    allUsers.slice(0, batchSize).map((user) => (
                        <div className="row align-items-center mt-2" key={user.emailId}>
                            <div
                                className="col-sm-7 col-12 cursor-pointer"
                                style={{ overflowWrap: "break-word" }}
                                onClick={() => {
                                    navigate(`/permissions/${user.emailId}`);
                                }}>
                                <div className="font-family-bold">
                                    {user.firstName} {user.lastName}
                                </div>
                                <div>
                                    {user.countryCode} {formatPhone(user.phoneNumber)}
                                </div>
                                <div>{user.emailId}</div>
                            </div>
                            <div className="col-sm-4 col-12">
                                {user.roleIds.map((role) => roleMap?.[role]).join(", ")}
                            </div>
                            {hasOnePermission("user_role_management.manage_user.update") && (
                                <div
                                    className="col-auto reach-cs-edit cursor-pointer"
                                    onClick={() => {
                                        setEmailId(user.emailId);
                                    }}
                                />
                            )}
                            <div className="divider mt-2 mx-3" style={{ opacity: 0.31 }} />
                        </div>
                    ))
                )}

                {!loading && (lastKeys || batchSize < allUsers.length) && (
                    <Button
                        color="secondary"
                        outline
                        loading={loadingAdditional}
                        className="col-8 col-md-4 align-self-center my-3"
                        onClick={() => updateUsers(true)}>
                        Load More
                    </Button>
                )}
            </div>
            <Modal title="Edit user details" show={emailId != null} onHide={() => setEmailId(undefined)}>
                <EditUser emailId={emailId} />
            </Modal>
        </Layout>
    );
}
