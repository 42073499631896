import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Input } from "../components/input";
import { Button } from "../components/button";
import Layout from "../components/layout";
import { Link, navigate } from "gatsby";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector, useUnwrapAsyncThunk } from "../utils/store";
import { EMAIL_PATTERN, NAME_PATTERN } from "../utils/helpers";
import { useErrorHandler } from "../components/error-snackbar";
import MultiDropdownInput from "../components/multi-dropdown-input";
import { selectUserInfo } from "../features/user-role-slice";
import { createUser, updateUser, usersSelector } from "../features/operation-users-slice";
import Select from "../components/select";
import countryCodes from "../utils/country-codes";
import Modal from "../components/modal";
import noResultsImage from "../images/icon-noresultsfound.png";

interface FormData {
    firstName: string;
    lastName: string;
    emailId: string;
    phoneNumber: string;
    countryCode: string;
    roleIds: string[];
}

export interface CreateUserProps extends RouteComponentProps {
    emailId?: string;
}

export function EditUser(props: CreateUserProps) {
    const { accountId } = useAppSelector(selectUserInfo);

    const [focus, setFocus] = useState(false);

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const user = useAppSelector((state) => usersSelector.selectById(state, props.emailId ?? ""));

    const { register, errors, handleSubmit, formState, control, setValue, setError } = useForm<FormData>({
        mode: "onBlur",
        defaultValues: {
            roleIds: user?.roleIds,
        },
    });

    const { roleMap } = useAppSelector(selectUserInfo);
    const assignableRoles = roleMap
        ? Object.entries(roleMap).map(([key, value]) => ({ label: value, value: key }))
        : [];

    useEffect(() => {
        register("roleIds", { required: "Roles required" });
    }, []);

    if (!accountId) {
        navigate("/");
        return null;
    }

    const onSubmit = async (data: FormData) => {
        try {
            if (user) {
                await unwrap(updateUser({ ...data, accountId }));
            } else {
                await unwrap(createUser({ ...data, accountId }));
            }
            navigate(`/permissions/${data.emailId}`);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    return (
        <>
            {assignableRoles.length > 0 ? (
                <form className="row w-100 justify-content-center" onSubmit={handleSubmit(onSubmit)}>
                    <div className={user != null ? "col-12 px-0" : "col-lg-4 col-md-6 col-sm-12"}>
                        <Input
                            type="text"
                            placeholder="First Name"
                            className="mt-4"
                            name="firstName"
                            defaultValue={user?.firstName ?? ""}
                            register={register({
                                required: {
                                    value: true,
                                    message: "First name is required",
                                },
                                pattern: {
                                    value: NAME_PATTERN,
                                    message: "Not a valid first name",
                                },
                            })}
                            showError={errors.firstName != null}
                            errorMessage={errors.firstName?.message}
                        />
                        <Input
                            type="text"
                            placeholder="Last Name"
                            className="mt-4"
                            name="lastName"
                            defaultValue={user?.lastName ?? ""}
                            register={register({
                                required: {
                                    value: true,
                                    message: "Last name is required",
                                },
                                pattern: {
                                    value: NAME_PATTERN,
                                    message: "Not a valid last name",
                                },
                            })}
                            showError={errors.lastName != null}
                            errorMessage={errors.lastName?.message}
                        />
                        <div className="mt-4 row mx-n2">
                            <Select
                                className="col-3 px-2"
                                onFocus={() => {
                                    setFocus(true);
                                }}
                                onBlur={() => {
                                    setFocus(false);
                                }}
                                register={register({
                                    required: {
                                        value: true,
                                        message: "Code is required",
                                    },
                                })}
                                name="countryCode"
                                defaultValue={user?.countryCode ?? "+1"}
                                showError={errors.countryCode != null}
                                errorMessage={errors.countryCode?.message}>
                                {countryCodes.map((countryCode) => (
                                    <option key={countryCode.e164_key} value={`+${countryCode.e164_cc}`}>
                                        {focus ? countryCode.display_name : `+${countryCode.e164_cc}`}
                                    </option>
                                ))}
                            </Select>
                            <Controller
                                control={control}
                                name="phoneNumber"
                                defaultValue={user?.phoneNumber ?? ""}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Phone number is required",
                                    },
                                    pattern: {
                                        value: /^\d{10}$/,
                                        message: "Phone number should be 10 digits long",
                                    },
                                }}
                                render={({ onChange, onBlur, value, name }) => (
                                    <Input
                                        type="text"
                                        inputMode="numeric"
                                        placeholder="Phone number"
                                        maxLength={10}
                                        className="col-9 px-2"
                                        mask="phone"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        name={name}
                                        showError={errors.phoneNumber != null}
                                        errorMessage={errors.phoneNumber?.message}
                                    />
                                )}
                            />
                        </div>
                        <div className="mt-2" style={{ fontSize: 12 }}>
                            * Phone number is required to send OTP
                        </div>
                        <Input
                            type="text"
                            placeholder="Email address"
                            className="mt-3"
                            name="emailId"
                            readOnly={user != null}
                            defaultValue={user?.emailId ?? ""}
                            register={register({
                                required: {
                                    value: true,
                                    message: "Email ID is required",
                                },
                                pattern: {
                                    value: EMAIL_PATTERN,
                                    message: "Not a valid email id",
                                },
                            })}
                            showError={errors.emailId != null}
                            errorMessage={errors.emailId?.message}
                        />
                        <MultiDropdownInput
                            type="text"
                            placeholder="Select role"
                            inputClassName="custom-select"
                            containerClassName="mt-4"
                            defaultItems={user?.roleIds}
                            onSelectionChange={(items) => {
                                if (items.length === 0) {
                                    setError("roleIds", { message: "Roles required" });
                                } else {
                                    setValue("roleIds", items);
                                }
                            }}
                            items={assignableRoles}
                            showError={errors.roleIds != null}
                            // @ts-ignore
                            errorMessage={errors.roleIds?.message}
                        />

                        <Button color="secondary" fullWidth className="mt-4" loading={formState.isSubmitting}>
                            {user != null ? "Confirm" : "Create"}
                        </Button>
                    </div>
                </form>
            ) : (
                <div className="d-flex align-items-center justify-content-center flex-column" style={{ height: 200 }}>
                    <img src={noResultsImage} />
                    <div className="font-family-semibold" style={{ fontSize: 18 }}>
                        Unfortunately, you can create a user but you are not assigned any assignable base role.
                    </div>
                </div>
            )}
        </>
    );
}

export default function CreateUser(props: CreateUserProps) {
    return (
        <Layout title="Create user">
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <Link className="text-cta mb-3" to="/users">
                    <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                    Back
                </Link>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>Create user</h1>
                </div>
                <div className="divider" />
                <EditUser {...props} />
            </div>
        </Layout>
    );
}
