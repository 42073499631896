import React, { useEffect } from "react";
import classNames from "classnames";
import bsCustomFileInput from "bs-custom-file-input";

export interface FileInputProps
    extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    className?: string;
    label: string;
    register?: any;
}

export default function FileInput(props: FileInputProps) {
    const { className, label, register, ...rest } = props;

    useEffect(() => {
        bsCustomFileInput.init();
    }, []);

    return (
        <div className={classNames("custom-file", className)}>
            <input type="file" className="custom-file-input" ref={register} {...rest} />
            <label className="custom-file-label">{label}</label>
        </div>
    );
}
