import React from "react";
import Modal from "../components/modal";

type SessionTimeoutModalProps = {
    isAuthError: boolean;
    setIsAuthError: any;
};

const SessionTimeoutModal = (props: SessionTimeoutModalProps) => {
    const { isAuthError, setIsAuthError } = props;

    return (
        <Modal
            title=""
            show={isAuthError}
            onHide={() => {
                setIsAuthError(false);
                localStorage.removeItem("is_timeout");
            }}>
            <span className="reach-cs-alert-tri text-secondary mt-n3 mb-3 fs-35" />
            <div style={{ marginBottom: "20px" }} className="font-family-bold text-center px-2 fs-17">
                Your session has expired!
                <br />
                <span className="fs-13 font-family-regular">Please log in again.</span>
            </div>
        </Modal>
    );
};
export default SessionTimeoutModal;
