import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Link, navigate } from "gatsby";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch, useAppSelector, useUnwrapAsyncThunk } from "../utils/store";
import { addCredit, fetchProfile, profilesSelector } from "../features/profile-slice";
import Layout from "../components/layout";
import { Card } from "../components/card";
import { Button } from "../components/button";
import { Input } from "../components/input";
import { useForm } from "react-hook-form";
import Select from "../components/select";
import { useErrorHandler } from "../components/error-snackbar";
import { authStateSelector } from "../features/auth-slice";
import CustomerBadge from "../components/customer-badge";
import { usePermissions } from "../features/hooks/use-permissions";
import { MaxValueLimitation } from "../utils/types/user-roles";
import { getUTCDate } from "../utils/helpers";
import { addDays, format, isBefore, max, subDays, subMonths } from "date-fns";
import { currentBillSelector } from "../features/billing-slice";
import { addMonths } from "date-fns/esm";

export interface AddCreditProps extends RouteComponentProps {
    customerId: string;
}

interface FormData {
    creditAmount: string;
    validityInMonths: string;
    description: string;
    startDate?: string;
}

export default function AddCredit(props: AddCreditProps) {
    const { customerId } = props;

    const customerProfile = useAppSelector((state: RootState) => profilesSelector.selectById(state, customerId));

    const { emailId } = useAppSelector(authStateSelector);
    const currentBill = useAppSelector(currentBillSelector(customerId));

    const { register, errors, handleSubmit, formState } = useForm<FormData>({
        mode: "onBlur",
    });

    const unwrap = useUnwrapAsyncThunk();
    const dispatch = useAppDispatch();
    const handleError = useErrorHandler();

    const { getLimitations } = usePermissions();

    const creditLimitations = getLimitations("billing.credit.create");

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    const onSubmit = async (data: FormData) => {
        try {
            await unwrap(
                addCredit({
                    customerIds: [customerId],
                    csId: emailId!,
                    creditAmount: data.creditAmount,
                    description: data.description,
                    validityInMonths: data.validityInMonths.length > 0 ? data.validityInMonths : undefined,
                    startDate: data.startDate && data.startDate.length > 0 ? data.startDate : undefined,
                })
            );
            await dispatch(fetchProfile(customerId));
            navigate(`/discounts/${customerId}`);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const dates: { value: string; startDate: Date; endDate: Date }[] = [];

    let billingStartDate = customerProfile.nextBillingDate
        ? getUTCDate(subMonths(new Date(customerProfile.nextBillingDate), 1))
        : undefined;
    const billingEndDate = customerProfile.nextBillingDate
        ? getUTCDate(subDays(new Date(customerProfile.nextBillingDate), 1))
        : undefined;

    if (billingStartDate && billingEndDate) {
        if (customerProfile.activationDate) {
            const activationDate = getUTCDate(new Date(customerProfile.activationDate));
            billingStartDate = max([billingStartDate, activationDate]);
            if (currentBill && !currentBill.billPaid) {
                const startDate = new Date(currentBill.billStartDate);
                const endDate = new Date(currentBill.billEndDate);
                dates.push({
                    value: startDate.toISOString(),
                    startDate,
                    endDate,
                });
            }
        }

        dates.push({
            value: billingStartDate.toISOString(),
            startDate: billingStartDate,
            endDate: billingEndDate,
        });

        for (let i = 1; i <= 2; i++) {
            const startDate = addMonths(addDays(billingEndDate, 1), i - 1);
            const endDate = addMonths(billingEndDate, i);
            dates.push({
                value: startDate.toISOString(),
                startDate,
                endDate,
            });
        }
    }

    return (
        <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - Add credit`}>
            <div className="container col-10 d-flex flex-column my-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to={`/discounts/${customerId}`}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>

                <Card>
                    <div className="h1 align-self-start">Add credit</div>
                    <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mx-n2">
                            <Input
                                type="number"
                                placeholder="Bill credit amount"
                                className="col-12 col-md-6 px-2"
                                name="creditAmount"
                                defaultValue=""
                                step={0.01}
                                register={register({
                                    required: {
                                        value: true,
                                        message: "Credit amount is required",
                                    },
                                    max: creditLimitations?.max_value?.value
                                        ? {
                                              value: creditLimitations.max_value.value,
                                              message: `Credit amount has to be less than ${creditLimitations.max_value.value}`,
                                          }
                                        : undefined,
                                    validate: (value) => parseFloat(value) > 0 || "Invalid amount",
                                })}
                                showError={errors.creditAmount != null}
                                errorMessage={errors.creditAmount?.message}
                            />
                            <Input
                                type="number"
                                placeholder="Repeat validity (in months)"
                                className="col-12 col-md-6 px-2 mt-3 mt-md-0"
                                name="validityInMonths"
                                defaultValue=""
                                register={register({
                                    validate: (value) =>
                                        value.length === 0 || parseInt(value, 10) > 0 || "Invalid amount",
                                })}
                                showError={errors.validityInMonths != null}
                                errorMessage={errors.validityInMonths?.message}
                            />
                            <Select
                                className="col-12 col-md-6 mt-3 px-2"
                                register={register({
                                    required: {
                                        value: true,
                                        message: "Description is required",
                                    },
                                })}
                                name="description"
                                showError={errors.description != null}
                                errorMessage={errors.description?.message}>
                                <option value="">Bill credit description</option>
                                {creditLimitations?.descriptions.value.map((value) => (
                                    <option value={value} key={value}>
                                        {value}
                                    </option>
                                ))}
                            </Select>
                        </div>
                        {customerProfile.nextBillingDate && (
                            <div className="row mx-n2">
                                {/* <div className="col-12 font-family-semibold mt-3 px-2">Effective date</div>
                            <Input
                                type="date"
                                placeholder="Effective date"
                                className="col-12 col-md-6 px-2 mt-3 mt-md-0"
                                name="startDate"
                                defaultValue=""
                                register={register}
                                showError={errors.startDate != null}
                                errorMessage={errors.startDate?.message}
                            /> */}
                                <Select
                                    className="col-12 col-md-6 px-2 mt-3"
                                    register={register}
                                    name="startDate"
                                    showError={errors.startDate != null}
                                    errorMessage={errors.startDate?.message}>
                                    <option value="">Effective date</option>
                                    {dates.map((entry) => (
                                        <option key={entry.value} value={entry.value}>
                                            {format(entry.startDate, "MMM dd, yyyy")} to{" "}
                                            {format(entry.endDate, "MMM dd, yyyy")}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        )}

                        <Button
                            color="secondary"
                            className="col-12 col-md-6 mt-3 offset-md-3"
                            loading={formState.isSubmitting}>
                            Confirm
                        </Button>
                    </form>
                </Card>
            </div>
        </Layout>
    );
}
