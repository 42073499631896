import React from "react";
import { Link, navigate } from "gatsby";
import { Button } from "../../components/button";
import Layout from "../../components/layout";

export default function BusinessDashboard() {
    return (
        <Layout title="Business Dashboard">
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <Link className="text-cta mb-3" to="/dashboards">
                    <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                    Back
                </Link>
                <div className="d-flex align-items-center justify-content-between">
                    <h1>Business Dashboard</h1>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="font-family-semibold my-3">Revenue by month</div>
                        <div>
                            <img src="/revenueByMonth.svg" style={{ width: "100%", height: "100%" }} />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="font-family-semibold my-3">Churn %</div>
                        <div>
                            <img src="/churn.svg" style={{ width: "100%", height: "100%" }} />
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12 col-md-6">
                        <div className="font-family-semibold my-3">Auto pay failed reason</div>
                        <div>
                            <img src="/autoPayFailedChart.svg" style={{ width: "100%", height: "100%" }} />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="font-family-semibold my-3">Payment failure by Acq source</div>
                        <div>
                            <img src="/paymentFailureByAcqChart.svg" style={{ width: "100%", height: "100%" }} />
                        </div>
                    </div>
                </div>

                <Button
                    color="secondary"
                    className="align-self-center my-4 col-12 col-md-3"
                    onClick={() => navigate("/business-dashboard-2")}>
                    More
                </Button>
            </div>
        </Layout>
    );
}
