import React from "react";
import { Button } from "react-bootstrap";
import Modal from "./modal";

type UserSessionTimeOutModalProps = {
    isIdleTimeOut: boolean;
    setIsIdleTimeOut: any;
    isLogout: boolean;
};

const UserSessionTimeOutModal = (props: UserSessionTimeOutModalProps) => {
    const { isIdleTimeOut, setIsIdleTimeOut, isLogout } = props;

    return (
        <>
            {isLogout ? (
                <Modal
                    title="Are you still there?"
                    size="md"
                    show={isIdleTimeOut}
                    onHide={() => {
                        setIsIdleTimeOut(true);
                    }}>
                    <div className="font-family-semibold fs-14 text-center">
                        To protect the personal info, browser session will time out in 2 minutes. Select countinue if
                        you want to keep working on your benefits.
                    </div>
                    <Button
                        className="mt-3 col-5 mx-auto btn-secondary"
                        type="submit"
                        onClick={() => {
                            setIsIdleTimeOut(true);
                        }}>
                        Continue
                    </Button>
                </Modal>
            ) : null}
        </>
    );
};

export default UserSessionTimeOutModal;
