import { useAppSelector } from "../../utils/store";
import { Limitations, PermissionKey } from "../../utils/types/user-roles";
import { usersSelector } from "../operation-users-slice";

export function useUserPermissions(emailId: string) {
    const users = useAppSelector((state) => usersSelector.selectById(state, emailId));

    const permissions = users?.permissions;
    const limitations = users?.limitations;

    /**
     *
     * @param permissions List of permissions
     * @returns If all of the permissions is satisfied
     */
    function hasAllPermissions(...perms: PermissionKey[]) {
        return perms.every((permission) => permissions?.[permission]);
    }

    /**
     *
     * @param permissions List of permissions
     * @returns If atleast one of the permissions is satisfied
     */
    function hasOnePermission(...perms: PermissionKey[]) {
        return perms.some((permission) => permissions?.[permission]);
    }

    /**
     *
     * @param permission Permission
     * @returns The limitations of the permission
     */
    function getLimitations<T extends keyof Limitations>(permission: T): Limitations[T] {
        return limitations?.[permission];
    }

    return { permissions, hasOnePermission, hasAllPermissions, getLimitations };
}
