import React, { useMemo, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { QuickActionCard } from "../components/quick-action-card";
import exploreIcon from "../images/icon-explore@2x.png";
import dashboardIcon from "../images/icon-dashboard@2x.png";
import reportsIcon from "../images/icon-reports@2x.png";
import toolsIcon from "../images/icon-tools@2x.png";
import helpIcon from "../images/icon-help@2x.png";
import hubspotTicketsIcon from "../images/hubspot_tickets.png";
import hubspotTasksIcon from "../images/hubspot_tasks.png";
import hubspotConversationsIcon from "../images/hubspot_conversations.png";
import usersIcon from "../images/icon-add-or-manage-users@2x.png";
import landingPageIcon from "../images/icon-landing-page-config@2x.png";
import invoiceMarketingIcon from "../images/icon-invoice-marketing-config@2x.png";
import appMarketingIcon from "../images/icon-app-marketing-config@2x.png";
import promocodeCreationEditIcon from "../images/icon-promocode-creation-edit@2x.png";
import plansCreationEditIcon from "../images/icon-plans-creation-edit@2x.png";
import irIldIcon from "../images/icon-ir-ild@2x.png";
import simCostAndShipmentIcon from "../images/icon-sim-cost-and-shipment@2x.png";
import uploadExternalReportsIcon from "../images/icon-upload-external-reports@2x.png";
import topupIcon from "../images/icon-topup@2x.png";
import billingConfigIcon from "../images/icon-billing-config@2x.png";
import taxConfigurationIcon from "../images/icon-tax-configuration@2x.png";
import revenueReconciliationIcon from "../images/icon-revenue-reconciliation@2x.png";
import signupIcon from "../images/icon-signup@2x.png";
import activeIcon from "../images/icon-active@2x.png";
import supportIcon from "../images/icon-support@2x.png";
import qrcIcon from "../images/icon-qrc@2x.png";
import screenshotIcon from "../images/icon-screenshot@2x.png";

import { graphql, navigate, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import { selectUserInfo } from "../features/user-role-slice";
import { usePermissions } from "../features/hooks/use-permissions";
import { Input } from "../components/input";
import Accordion, { AccordionBody } from "../components/accordion";
import { useAppSelector } from "../utils/store";
import { PermissionKey, Permissions } from "../utils/types/user-roles";
import { useViewport, ViewportBreakpoint } from "../utils/viewport-context";

export type DashboardProps = RouteComponentProps;

function filterResults(hasPermission: (...args: PermissionKey[]) => boolean, searchTerm?: string) {
    return homeCategories.map((homeCategory) => ({
        ...homeCategory,
        cards: homeCategory.cards.filter(
            (card) =>
                hasPermission(...card.permissions) &&
                (searchTerm == null || card.title.toLowerCase().includes(searchTerm.trim().toLowerCase()))
        ),
    }));
}

export default function Dashboard(props: DashboardProps) {
    const data = useStaticQuery(graphql`
        query {
            bannerImage: file(relativePath: { eq: "bannerImage@2x.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1440) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            bannerImageMobile: file(relativePath: { eq: "bannerImageMobile@2x.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 768) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    const { width } = useViewport();

    const [searchTerm, setSearchTerm] = useState("");
    const { hasOnePermission } = usePermissions();
    const userInfo = useAppSelector(selectUserInfo);

    const homeCategories = useMemo(() => filterResults(hasOnePermission, searchTerm), [hasOnePermission, searchTerm]);

    return (
        <Layout title="Home">
            <section className="d-flex col-12 col-lg-9 justify-content-center px-0 mx-auto">
                <div
                    className="position-relative w-100"
                    style={{
                        paddingBottom: `${100 * (200 / (width >= ViewportBreakpoint.MD ? 1090 : 360))}%`,
                        height: 0,
                        overflow: "hidden",
                    }}>
                    <div className="text-center font-family-bold mt-4" style={{ fontSize: 30 }}>
                        Welcome {userInfo.firstName}
                    </div>
                    <Img
                        fluid={[
                            data.bannerImageMobile.childImageSharp.fluid,
                            {
                                ...data.bannerImage.childImageSharp.fluid,
                                media: `(min-width: ${ViewportBreakpoint.MD}px)`,
                            },
                        ]}
                        style={{
                            position: "absolute",
                            width: "100%",
                            zIndex: -1,
                            top: 0,
                        }}
                    />
                </div>
            </section>

            <div className="container col-12 col-md-11 col-lg-9 d-flex flex-column px-0">
                <Input
                    type="text"
                    icon="reach-cs-search"
                    placeholder="Search actions"
                    className="mx-auto col-10 col-lg-5 my-4 px-0"
                    value={searchTerm}
                    onChange={({ target: { value } }) => setSearchTerm(value)}
                />
                <Accordion>
                    {homeCategories
                        .filter((homeCategory) => homeCategory.cards.length > 0)
                        .map((homeCategory) => (
                            <AccordionBody title={homeCategory.category} key={homeCategory.category}>
                                <div className="row row-cols-2 row-cols-md-4 row-cols-lg-5 mx-n2 mt-n2">
                                    {homeCategory.cards.map((card) => (
                                        <div className="p-2 col" key={card.title}>
                                            <QuickActionCard
                                                title={card.title}
                                                icon={card.icon}
                                                disabled={card.disabled}
                                                onClick={card.onClick}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </AccordionBody>
                        ))}
                </Accordion>
            </div>
        </Layout>
    );
}

interface HomeCard {
    title: string;
    icon: string;
    disabled?: boolean;
    onClick?: () => void;
    permissions: PermissionKey[];
}

interface HomeCategory {
    category: string;
    cards: HomeCard[];
}

const homeCategories: HomeCategory[] = [
    {
        category: "General",
        cards: [
            {
                title: "Explore",
                icon: exploreIcon,
                onClick: () => {
                    navigate("/customers");
                },
                permissions: ["user_management.customer.search"],
            },
            {
                title: "Dashboards",
                icon: dashboardIcon,
                onClick: () => {
                    navigate("/dashboards");
                },
                permissions: [
                    "reports.finance_dashboard.view",
                    "reports.marketing_dashboards.view",
                    "reports.operation_dashboards.view",
                ],
            },
            {
                title: "Reports",
                icon: reportsIcon,
                disabled: true,
                permissions: [
                    "reports.finance_dashboard.view",
                    "reports.marketing_dashboards.view",
                    "reports.operation_dashboards.view",
                ],
            },
            {
                title: "Tools",
                icon: toolsIcon,
                onClick: () => {
                    navigate("/tools");
                },
                permissions: [
                    "tools.imei_check.allow",
                    "tools.zipcheck.allow",
                    "tools.bill_estimate.allow",
                    "tools.sim_check.allow",
                    "tools.intl_rates.allow",
                    "tools.number_portability.allow",
                ],
            },
            {
                title: "Help Documents",
                icon: helpIcon,
                onClick: () => {
                    window.open(
                        "https://www.notion.so/After-Activation-Service-Not-Working-No-Signal-f8733a4a0648417bbd46f9a4d97a95eb"
                    );
                },
                permissions: ["customer_support.help.read"],
            },
            {
                title: "Users",
                icon: usersIcon,
                onClick: () => {
                    navigate("/users");
                },
                permissions: ["user_role_management.manage_user.read"],
            },
            {
                title: "Roles",
                icon: usersIcon,
                onClick: () => {
                    navigate("/roles");
                },
                permissions: ["user_role_management.manage_role.read"],
            },
        ],
    },
    {
        category: "Marketing",
        cards: [
            {
                icon: landingPageIcon,
                title: "Landing Page",
                disabled: true,
                permissions: [
                    "config_management.bestfit_lp.view",
                    "config_management.allplans_lp.view",
                    "config_management.crossout_lp.view",
                    "config_management.giveback_lp.view",
                    "config_management.individual_plan_lp.view",
                ],
            },
            {
                icon: invoiceMarketingIcon,
                title: "Invoice Marketing",
                disabled: true,
                permissions: ["config_management.invoice_marketing_tile.view"],
            },
            {
                icon: appMarketingIcon,
                title: "App Marketing Tiles",
                disabled: true,
                permissions: ["config_management.app_marketing_tile.view"],
            },
        ],
    },
    {
        category: "Operations",
        cards: [
            {
                icon: promocodeCreationEditIcon,
                title: "Promo Code",
                disabled: true,
                permissions: ["config_management.promo.read"],
            },
            {
                icon: plansCreationEditIcon,
                title: "Plans",
                disabled: true,
                permissions: ["config_management.plan.read"],
            },
            {
                icon: irIldIcon,
                title: "IR/ILD Updates",
                disabled: true,
                permissions: ["config_management.intl_roaming.read", "config_management.intl_calling.read"],
            },
            {
                icon: simCostAndShipmentIcon,
                title: "SIM Cost and Shipment",
                disabled: true,
                permissions: ["config_management.sim.cost", "config_management.sim.shipping"],
            },
            {
                icon: uploadExternalReportsIcon,
                title: "Upload External Reports",
                disabled: true,
                permissions: ["reports.upload_reports.read"],
            },
        ],
    },
    {
        category: "Finance",
        cards: [
            {
                icon: topupIcon,
                title: "Topups Configuration",
                disabled: true,
                permissions: ["config_management.topups.read"],
            },
            {
                icon: billingConfigIcon,
                title: "Billing Configuration",
                disabled: true,
                permissions: ["config_management.billing.read"],
            },
            {
                icon: taxConfigurationIcon,
                title: "Tax Configuration",
                disabled: true,
                permissions: ["config_management.tax.read"],
            },
            {
                icon: revenueReconciliationIcon,
                title: "Revenue Reconciliation",
                disabled: true,
                permissions: ["reports.revenue_reconciliation.read"],
            },
        ],
    },
    {
        category: "Customer Support",
        cards: [
            {
                icon: signupIcon,
                title: "Sign up",
                onClick: () => {
                    navigate("/purchase/new");
                },
                permissions: ["user_management.customer.create"],
            },
            {
                icon: activeIcon,
                title: "Activate",
                onClick: () => {
                    navigate("/customers?filter=INACTIVE");
                },
                permissions: ["user_management.activation.create"],
            },
            {
                icon: supportIcon,
                title: "Support",
                onClick: () => {
                    navigate("/support");
                },
                permissions: ["customer_support.support.allow"],
            },
            // {
            //     icon: qrcIcon,
            //     title: "QRC",
            //     onClick: () => {
            //         navigate("/customer-support/qrc");
            //     },
            //     permissions: ["customer_support.query_request_complaint.allow"],
            // },
            {
                icon: screenshotIcon,
                title: "App/Web Screenshot",
                disabled: true,
                permissions: ["customer_support.screenshot_library.read"],
            },
            {
                icon: qrcIcon,
                title: "All Tickets",
                onClick: () => {
                    navigate("/all-tickets");
                },
                permissions: ["user_management.helpdesk.read_ticket"],
            },
            {
                icon: hubspotTicketsIcon,
                title: "Hubspot Tickets",
                onClick: () => {
                    navigate("/hubspot-tickets");
                },
                permissions: ["customer_support.support.allow"],
            },
            {
                icon: hubspotTasksIcon,
                title: "Hubspot Tasks",
                onClick: () => {
                    navigate("/hubspot-tasks");
                },
                permissions: ["customer_support.support.allow"],
            },
            {
                icon: hubspotConversationsIcon,
                title: "Hubspot Conversations",
                onClick: () => {
                    navigate("/hubspot-conversations");
                },
                permissions: ["customer_support.support.allow"],
            },
            // {
            //     icon: qrcIcon,
            //     title: "All Tickets",
            //     onClick: () => {
            //         navigate("/all-tickets");
            //     },
            //     permissions: ["user_management.helpdesk.read_ticket"],
            // },
        ],
    },
];
