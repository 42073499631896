import React from "react";
import { Card } from "./card";
import classNames from "classnames";

export interface PlanLineSelectionProps {
    maxLines: number;
    value: number;
    onChange: (value: number) => void;
    className?: string;
}

export default function PlanLineSelection(props: PlanLineSelectionProps) {
    const { maxLines, value, onChange, className } = props;

    return (
        <Card className={classNames("flex-row py-0 px-3 px-md-4 justify-content-between", className)}>
            {Array.from(new Array(maxLines), (_, index) => index + 1).map((val) => (
                <div
                    key={val}
                    onClick={() => onChange(val)}
                    className={classNames("font-family-semibold", value === val ? "selected-line" : "unselected-line")}>
                    {val}
                </div>
            ))}
        </Card>
    );
}
