import classNames from "classnames";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { configSelector } from "../features/config-slice";
import { fetchProfile, generateNewMDN, newToPortNumber, setSimSwap } from "../features/profile-slice";
import { formatPhone } from "../utils/helpers";
import { useAppDispatch, useAppSelector, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile, NewToPortReq } from "../utils/types";
import { Button } from "./button";
import { Checkbox } from "./checkbox";
import CustomerVerification from "./customer-verification";
import { useErrorHandler } from "./error-snackbar";
import { Input } from "./input";
import Modal, { ModalProps } from "./modal";
import Select from "./select";
import Toggle from "./toggle";

export interface NewNumberModalProps extends ModalProps {
    customerProfile: CustomerProfile;
}

interface FormData {
    zipCode: string;
    chargable: boolean;
}

type PortFormData = NewToPortReq & {
    operatorText?: string;
    chargable: boolean;
};

enum UIState {
    INITIAL,
    NEW_NUMBER,
    PORT_NUMBER,
}

export default function NewNumberModal(props: NewNumberModalProps) {
    const { customerProfile, ...rest } = props;

    const newNumberFormMethods = useForm<FormData>({ mode: "onBlur" });
    const portFormMethods = useForm<PortFormData>({ mode: "onBlur" });

    const { operators } = useAppSelector(configSelector);

    const [uiState, setUIState] = useState(UIState.INITIAL);
    const [showOperatorField, setOperatorField] = useState(false);
    const [isConfirmed, setConfirmed] = useState(false);
    const [showCustomerVerification, setShowCustomerVerification] = useState(false);

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const onSubmit = async (data: FormData) => {
        try {
            await unwrap(
                generateNewMDN({
                    customerId: customerProfile.id,
                    ...data,
                })
            );
            await dispatch(fetchProfile(customerProfile.id));
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        rest.onHide();
    };

    const onPortSubmit = async (data: PortFormData) => {
        if (data.operatorText) {
            data.oldCarrier = data.operatorText;
        }
        try {
            await unwrap(
                newToPortNumber({
                    customerId: customerProfile.id,
                    ...data,
                })
            );
            await dispatch(fetchProfile(customerProfile.id));
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        rest.onHide();
    };

    if (uiState === UIState.PORT_NUMBER) {
        return (
            <Modal
                {...rest}
                title="Port in a number"
                titleClassName={classNames({ disabled: showCustomerVerification })}>
                <form
                    className={classNames("w-100", {
                        disabled: showCustomerVerification,
                    })}
                    style={{ pointerEvents: showCustomerVerification ? "none" : undefined }}
                    onSubmit={portFormMethods.handleSubmit(() => setShowCustomerVerification(true))}>
                    <div className="font-family-semibold mb-2">
                        Current Reach number: {formatPhone(customerProfile.reachNumber)}
                    </div>
                    <div className="font-family-semibold text-error my-2">
                        Performing this request will cancel the current number. Please make sure customer has given the
                        consent for the same.
                    </div>
                    <Controller
                        control={portFormMethods.control}
                        name="numberToPort"
                        rules={{
                            required: {
                                value: true,
                                message: "Phone number is required",
                            },
                            pattern: {
                                value: /^\d{10}$/,
                                message: "Phone number should be 10 digits long",
                            },
                        }}
                        defaultValue=""
                        render={({ onChange, onBlur, value, name }) => (
                            <Input
                                type="text"
                                inputMode="numeric"
                                placeholder="Current number"
                                maxLength={10}
                                className="col my-2 px-0"
                                mask="phone"
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                name={name}
                                showError={portFormMethods.errors.numberToPort != null}
                                errorMessage={portFormMethods.errors.numberToPort?.message}
                            />
                        )}
                    />
                    <Input
                        type="text"
                        placeholder="Account number"
                        name="oldCarrierAccountNumber"
                        className="col my-2 px-0"
                        defaultValue=""
                        register={portFormMethods.register({
                            required: {
                                value: true,
                                message: "Account number is required",
                            },
                            pattern: {
                                value: /^[A-Za-z0-9]{1,20}$/,
                                message: "Not a valid account number",
                            },
                        })}
                        showError={portFormMethods.errors.oldCarrierAccountNumber != null}
                        errorMessage={portFormMethods.errors.oldCarrierAccountNumber?.message}
                    />
                    <Input
                        type="text"
                        placeholder="PIN"
                        name="password"
                        className="col my-2 px-0"
                        defaultValue=""
                        register={portFormMethods.register({
                            required: {
                                value: true,
                                message: "PIN is required",
                            },
                            pattern: {
                                value: /^[A-Za-z0-9]{1,15}$/,
                                message: "Not a valid PIN",
                            },
                        })}
                        showError={portFormMethods.errors.pin != null}
                        errorMessage={portFormMethods.errors.pin?.message}
                    />
                    <Input
                        type="text"
                        inputMode="numeric"
                        placeholder="Old ZIP code"
                        name="oldZip"
                        maxLength={5}
                        className="col my-2 px-0"
                        defaultValue=""
                        register={portFormMethods.register({
                            required: {
                                value: true,
                                message: "Zip is required",
                            },
                            pattern: {
                                value: /^\d{5}$/,
                                message: "Zip should be 5 digits long",
                            },
                        })}
                        showError={portFormMethods.errors.oldZip != null}
                        errorMessage={portFormMethods.errors.oldZip?.message}
                    />
                    <Controller
                        defaultValue=""
                        name="oldCarrier"
                        control={portFormMethods.control}
                        rules={{
                            required: {
                                value: true,
                                message: "Operator must be selected",
                            },
                        }}
                        render={({ onChange, onBlur, value, name }) => (
                            <Select
                                onChange={(event) => {
                                    onChange(event.target.value);
                                    setOperatorField(event.target.value === "Other");
                                }}
                                onBlur={onBlur}
                                value={value}
                                name={name}
                                className="col my-2 px-0"
                                showError={portFormMethods.errors.oldCarrier != null}
                                errorMessage={portFormMethods.errors.oldCarrier?.message}>
                                <option value="">Select carrier</option>
                                {operators.map((operator) => (
                                    <option key={operator.value} value={operator.value}>
                                        {operator.displayName}
                                    </option>
                                ))}
                            </Select>
                        )}
                    />
                    {showOperatorField && (
                        <Input
                            type="text"
                            placeholder="Enter operator name"
                            className="col my-2 px-0"
                            name="operatorText"
                            defaultValue=""
                            register={portFormMethods.register({
                                required: {
                                    value: true,
                                    message: "Operator is required",
                                },
                                pattern: {
                                    value: /^[a-zA-Z0-9 &]+$/,
                                    message: "Invalid carrier name",
                                },
                            })}
                            showError={portFormMethods.errors.operatorText != null}
                            errorMessage={portFormMethods.errors.operatorText?.message}
                        />
                    )}
                    <Toggle
                        className="my-3 align-self-start"
                        id="chargable"
                        name="chargable"
                        label="Is Chargeable?"
                        register={portFormMethods.register}
                        defaultChecked={true}
                    />
                    <Checkbox
                        label="Are you sure you want to proceed?"
                        id="isConfirmed"
                        name="isConfirmed"
                        small
                        className="text-center"
                        checked={isConfirmed}
                        onChange={({ target: { checked } }) => setConfirmed(checked)}
                    />
                    <Button
                        color="secondary"
                        className="mt-3"
                        disabled={!isConfirmed}
                        fullWidth
                        loading={portFormMethods.formState.isSubmitting}>
                        Confirm
                    </Button>
                </form>

                {showCustomerVerification && (
                    <CustomerVerification
                        onSubmit={portFormMethods.handleSubmit(onPortSubmit)}
                        customerProfile={customerProfile}
                    />
                )}
            </Modal>
        );
    }

    if (uiState === UIState.NEW_NUMBER) {
        return (
            <Modal {...rest} titleClassName={classNames({ disabled: showCustomerVerification })}>
                <form
                    className={classNames("w-100", {
                        disabled: showCustomerVerification,
                    })}
                    style={{ pointerEvents: showCustomerVerification ? "none" : undefined }}
                    onSubmit={newNumberFormMethods.handleSubmit(() => {
                        setShowCustomerVerification(true);
                    })}>
                    <div className="font-family-semibold mb-2">
                        Current Reach number: {formatPhone(customerProfile.reachNumber)}
                    </div>
                    <div className="font-family-semibold text-error my-2">
                        Performing this request will cancel the current number. Please make sure customer has given the
                        consent for the same.
                    </div>
                    <Controller
                        control={newNumberFormMethods.control}
                        rules={{
                            required: {
                                value: true,
                                message: "Zipcode is required",
                            },
                            pattern: {
                                value: /^\d{5}$/,
                                message: "Zipcode must be 5 digits.",
                            },
                        }}
                        name="zipCode"
                        defaultValue=""
                        render={({ onChange, onBlur, value, name }) => (
                            <Input
                                type="text"
                                inputMode="numeric"
                                placeholder="Enter Zipcode"
                                className="col-12 px-0"
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                name={name}
                                maxLength={5}
                                characterRestriction={5}
                                showError={newNumberFormMethods.errors.zipCode != null}
                                errorMessage={newNumberFormMethods.errors.zipCode?.message}
                            />
                        )}
                    />
                    <Toggle
                        className="my-3 align-self-start"
                        id="chargable"
                        name="chargable"
                        label="Is Chargeable?"
                        register={newNumberFormMethods.register}
                        defaultChecked={true}
                    />
                    <Checkbox
                        label="Are you sure you want to proceed?"
                        id="isConfirmed"
                        name="isConfirmed"
                        small
                        className="text-center"
                        checked={isConfirmed}
                        onChange={({ target: { checked } }) => setConfirmed(checked)}
                    />
                    <Button
                        color="secondary"
                        className="mt-3"
                        disabled={!isConfirmed}
                        fullWidth
                        loading={newNumberFormMethods.formState.isSubmitting}>
                        Confirm
                    </Button>
                </form>

                {showCustomerVerification && (
                    <CustomerVerification
                        onSubmit={newNumberFormMethods.handleSubmit(onSubmit)}
                        customerProfile={customerProfile}
                    />
                )}
            </Modal>
        );
    }

    return (
        <Modal {...rest} size="sm">
            <Button color="secondary" fullWidth onClick={() => setUIState(UIState.NEW_NUMBER)}>
                New number
            </Button>
            <Button color="secondary" fullWidth onClick={() => setUIState(UIState.PORT_NUMBER)}>
                Port number
            </Button>
        </Modal>
    );
}
