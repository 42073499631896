import { formatDistance, isFuture, isPast } from "date-fns";
import React, { useEffect, useState } from "react";
import { CustomerProfile, PaymentLinkType } from "../utils/types";
import { Card } from "./card";
import infoIcon from "../images/info-icon.png";
import { Button } from "./button";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { addCreditCard, fetchProfile, generatePaymentPage, sendPaymentLink } from "../features/profile-slice";
import Modal from "./modal";
import { usePermissions } from "../features/hooks/use-permissions";
import { useForm } from "react-hook-form";
import { ReachCsAPI, reachEndpoint } from "../utils/reach-cs-api";
import { useErrorHandler } from "./error-snackbar";

export interface PaymentLinkCardProps {
    customerProfile: CustomerProfile;
}

export default function PaymentLinkCard(props: PaymentLinkCardProps) {
    const { customerProfile } = props;
    const dispatch = useAppDispatch();

    const [currentDate, setCurrentDate] = useState(Date.now());
    const { hasOnePermission } = usePermissions();

    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [showNewCardModal, setShowNewCardModal] = useState(false);
    const { handleSubmit, formState } = useForm<FormData>();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    useEffect(() => {
        const id = setInterval(() => {
            setCurrentDate(Date.now());
        }, 1 * 60 * 1000);

        return () => clearInterval(id);
    }, []);

    async function refreshProfile() {
        setRefreshLoading(true);
        await dispatch(fetchProfile(customerProfile.id));
        setRefreshLoading(false);
    }

    if (
        customerProfile.simPaid &&
        (!customerProfile.link ||
            customerProfile.link.type === PaymentLinkType.SIM_PURCHASE ||
            isPast(customerProfile.link.expireDate))
    ) {
        return null;
    }

    const onPayment = async () => {
        try {
            setLoading2(true);
            const res = await unwrap(
                generatePaymentPage({
                    emailId: customerProfile.emailId!,
                    redirectUrl: `${window.location.origin}/profile/${customerProfile.id}`,
                    type: customerProfile.isSimInHand ? "card" : "purchase",
                })
            );
            window.location.href = `${reachEndpoint}/account/redirect/page/${res.id}`;
        } catch (error) {
            console.log(error);
            setLoading2(false);
            handleError(error);
        }
    };

    return (
        <Card className="mt-3">
            {customerProfile.link && isFuture(customerProfile.link.expireDate) ? (
                <>
                    <div className="font-family-bold" style={{ fontSize: 16 }}>
                        {customerProfile.link.type === PaymentLinkType.SIM_PURCHASE
                            ? "Order status: Payment pending"
                            : "Add a new card"}
                    </div>
                    <div className="text-primary text-center font-family-bold" style={{ fontSize: 16 }}>
                        We emailed a payment link to {customerProfile.emailId}
                    </div>
                    <div className="font-family-semibold">
                        This link will expire in {formatDistance(customerProfile.link.expireDate, currentDate)}{" "}
                        {refreshLoading ? (
                            <span className="spinner-border spinner-border-sm ml-2 mb-1 text-primary" />
                        ) : (
                            <span
                                className="reach-cs-refresh cursor-pointer text-secondary ml-2"
                                onClick={refreshProfile}
                            />
                        )}
                    </div>
                    <div>
                        <a href={customerProfile.link.url} className="text-cta" target="_blank" rel="noreferrer">
                            {customerProfile.link.url}
                        </a>
                        <span
                            className="reach-cs-copy ml-1 text-secondary cursor-pointer"
                            onClick={() =>
                                customerProfile.link && navigator.clipboard.writeText(customerProfile.link.url)
                            }
                        />
                    </div>
                </>
            ) : (
                <>
                    <img src={infoIcon} />
                    <div className="font-family-semibold mt-2">
                        Order status: Payment pending{" "}
                        {refreshLoading ? (
                            <span className="spinner-border spinner-border-sm ml-2 mb-1 text-primary" />
                        ) : (
                            <span
                                className="reach-cs-refresh cursor-pointer text-secondary ml-2"
                                onClick={refreshProfile}
                            />
                        )}
                    </div>
                </>
            )}
            <Button
                color="secondary"
                className="mt-2 col-12 col-md-5"
                disabled={refreshLoading}
                loading={loading}
                onClick={async () => {
                    setLoading(true);
                    if (!customerProfile.link) {
                        setShowNewCardModal(true);
                        setLoading(false);
                    } else if (customerProfile.link.type === PaymentLinkType.SIM_PURCHASE) {
                        await dispatch(sendPaymentLink(customerProfile.id));
                    } else if (customerProfile.link.type === PaymentLinkType.ADD_CARD) {
                        await dispatch(addCreditCard(customerProfile.id));
                    }
                    await dispatch(fetchProfile(customerProfile.id));
                    setLoading(false);
                }}>
                {customerProfile.link ? "Resend Payment Link" : "Retry Payment"}
            </Button>

            <Modal
                show={showNewCardModal}
                size="sm"
                title=" Do you want to add a new card?"
                onHide={() => setShowNewCardModal(false)}>
                {hasOnePermission("user_management.payment.send_email") && (
                    <Button
                        color="secondary"
                        className="col-12"
                        loading={loading1}
                        onClick={async () => {
                            try {
                                setLoading1(true);
                                await dispatch(sendPaymentLink(customerProfile.id));
                                window.scrollTo(0, 0);
                                setShowNewCardModal(false);
                            } catch (error) {
                                handleError(error);
                            }
                            setLoading1(false);
                        }}>
                        Send email with Link to add card
                    </Button>
                )}
                {hasOnePermission("user_management.payment.collect_direct") && (
                    <Button
                        color="secondary"
                        className="col-12 mt-3"
                        loading={loading2}
                        onClick={handleSubmit(onPayment)}>
                        Collect card details online
                    </Button>
                )}
            </Modal>
        </Card>
    );
}
