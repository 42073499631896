import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { addDays, addMonths, format, max, subDays, subMonths } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { getBillingForecast } from "../features/leaving-reach-slice";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { BillingForecastItemType, CustomerProfile, EstimatedBill } from "../utils/types";
import { Button } from "./button";
import { useErrorHandler } from "./error-snackbar";
import { Input } from "./input";
import Modal, { ModalProps } from "./modal";
import { useSuccessModal } from "./success-modal";
import Select from "./select";
import { usePermissions } from "../features/hooks/use-permissions";
import { fetchProfile, payInAdvance } from "../features/profile-slice";
import { getUTCDate } from "../utils/helpers";

export interface PayInAdvanceModalProps extends ModalProps {
    customerProfile: CustomerProfile;
    billingDate?: Date;
}

interface FormData {
    advancePayAmount: number;
    description: string;
}

export default function PayInAdvanceModal(props: PayInAdvanceModalProps) {
    const { customerProfile, billingDate, ...rest } = props;

    const { register, handleSubmit, formState, watch, errors } = useForm<FormData>({ mode: "onBlur" });

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();
    const { getLimitations } = usePermissions();

    const creditLimitations = getLimitations("billing.advance_payment.allow");

    const [billingForecast, setBillingForecast] = useState<EstimatedBill>();
    const [billingEndDate, setBillingEndDate] = useState(billingDate ?? new Date());

    useEffect(() => {
        async function fetchForecast() {
            try {
                const res = await unwrap(
                    getBillingForecast({
                        customerId: customerProfile.id,
                        endDate: format(billingEndDate, "yyyy-MM-dd"),
                    })
                );
                setBillingForecast(res);
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
        fetchForecast();
    }, [billingEndDate]);

    if (!billingForecast) {
        return (
            <Modal {...rest}>
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary" />
                </div>
            </Modal>
        );
    }

    const onSubmit = async (data: FormData) => {
        try {
            await unwrap(
                payInAdvance({
                    ...data,
                    customerId: customerProfile.id,
                })
            );
            await dispatch(fetchProfile(customerProfile.id));
            showSuccessModal(`Advance payment completed successfully`);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        rest.onHide();
    };

    const billingStartDate = max([
        getUTCDate(subMonths(new Date(customerProfile.nextBillingDate!), 1)),
        new Date(customerProfile.activationDate!),
    ]);

    return (
        <Modal {...rest}>
            <h5 className="font-family-semibold" style={{ fontSize: 16 }}>
                Estimated bill is{" "}
                <span className="font-family-bold" style={{ fontSize: 20 }}>
                    ${billingForecast.amountToPay.toFixed(2)}
                </span>
            </h5>
            <div className="w-100 d-flex justify-content-between mb-3">
                <div className="font-family-semibold">
                    <div>Start date</div>
                    <div>{format(billingStartDate, "MMM dd, yyyy")}</div>
                </div>
                <div className="d-flex flex-column font-family-semibold align-items-end">
                    <div>End date</div>
                    {billingDate ? (
                        <div>{format(billingDate, "MMM dd, yyyy")}</div>
                    ) : (
                        <input
                            type="date"
                            min={format(new Date(), "yyyy-MM-dd")}
                            max={format(subDays(new Date(customerProfile.nextBillingDate!), 1), "yyyy-MM-dd")}
                            style={{ borderRadius: 4, border: "solid 1px #bbbbbb" }}
                            value={format(billingEndDate, "yyyy-MM-dd")}
                            onChange={(event) => {
                                if (event.target.valueAsDate) {
                                    setBillingEndDate(event.target.valueAsDate);
                                }
                            }}
                        />
                    )}
                </div>
            </div>
            {billingForecast.billing.map((lineItem, index) => {
                const isNegative =
                    lineItem.type === BillingForecastItemType.CREDIT ||
                    lineItem.type === BillingForecastItemType.DISCOUNT ||
                    lineItem.type === BillingForecastItemType.REFERRAL;

                return (
                    <div key={index} className="w-100 d-flex justify-content-between mb-3">
                        <div className="w-100">
                            <div className={classNames(isNegative ? "text-primary" : "font-family-bold")}>
                                {lineItem.title}{" "}
                                {lineItem.prorated && (
                                    <span className="font-family-medium text-tertiary2">(Prorated)</span>
                                )}
                            </div>
                            {lineItem.subtitle && (
                                <div className="font-family-semibold" style={{ fontSize: 12 }}>
                                    {lineItem.subtitle}
                                </div>
                            )}
                            {lineItem.details?.map((item, index) => {
                                const isNegative =
                                    item.type === BillingForecastItemType.CREDIT ||
                                    item.type === BillingForecastItemType.DISCOUNT ||
                                    item.type === BillingForecastItemType.REFERRAL;

                                return (
                                    <div className="w-100 d-flex justify-content-between" key={index}>
                                        <div key={index} className="font-family-semibold" style={{ fontSize: 12 }}>
                                            {item.subtitle}
                                        </div>
                                        {item.amount && (
                                            <div
                                                className={classNames("font-family-semibold", {
                                                    "text-primary": isNegative,
                                                })}
                                                style={{ fontSize: 12 }}>
                                                {isNegative && "-"}${item.amount.toFixed(2)}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                        {lineItem.amount && (
                            <div className={classNames("font-family-bold", { "text-primary": isNegative })}>
                                {isNegative && "-"}${lineItem.amount.toFixed(2)}
                            </div>
                        )}
                    </div>
                );
            })}

            {billingForecast.amountToPay > 0 ? (
                <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        type="number"
                        inputMode="numeric"
                        placeholder="Enter amount"
                        className="col-12 px-0 mb-3"
                        name="advancePayAmount"
                        step={0.01}
                        showError={errors.advancePayAmount != null}
                        errorMessage={errors.advancePayAmount?.message}
                        register={register({
                            required: "Amount required",
                            max: {
                                value: billingForecast.amountToPay,
                                message: `Amount has to be less than or equal to $${billingForecast.amountToPay}`,
                            },
                            validate: (value) => parseFloat(value) > 0 || "Invalid amount",
                        })}
                    />
                    <Select
                        defaultValue=""
                        name="description"
                        className="col-12 px-0 mb-3"
                        register={register({
                            required: {
                                value: true,
                                message: "Description must be selected",
                            },
                        })}
                        showError={errors.description != null}
                        errorMessage={errors.description?.message}>
                        <option value="">Select description</option>
                        {creditLimitations?.descriptions.value.map((value) => (
                            <option value={value} key={value}>
                                {value}
                            </option>
                        ))}
                    </Select>

                    <Button color="secondary" className="mt-3" fullWidth loading={formState.isSubmitting}>
                        Confirm
                    </Button>
                </form>
            ) : (
                <div className="font-family-semibold text-center" style={{ fontSize: 16 }}>
                    There are no pending bills to be paid.
                </div>
            )}
        </Modal>
    );
}
