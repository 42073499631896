import React, { useEffect, useState } from "react";
import {
    CustomerProfile,
    PortFailureCode,
    UpdatePortReq,
    UpdatePortType,
    NetworkResetStatus,
    DisconnectionReason,
    PortoutStatus,
} from "../utils/types";
import { Card } from "./card";
import activationInProgressIcon from "../images/activationInProgress.png";
import portingFailedIcon from "../images/portingFailed.png";
import infoIcon from "../images/info-icon.png";
import { CustomerStatus, OperationsInProcess } from "../utils/types/customer";
import { Button } from "./button";
import Modal from "./modal";
import { Controller, useForm } from "react-hook-form";
import { Input } from "./input";
import BottomSheet, { BottomSheetProps } from "./bottom-sheet";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import {
    fetchProfile,
    getActivation,
    getMDNSwapStatus,
    getNetworkResetStatus,
    getPortoutInfo,
    updatePort,
} from "../features/profile-slice";
import Select from "./select";
import { useSelector } from "react-redux";
import { configSelector } from "../features/config-slice";
import { useErrorHandler } from "./error-snackbar";
import classNames from "classnames";
import { useSuccessModal } from "./success-modal";
import { formatPhone, isPostActivationState } from "../utils/helpers";
import { reconnect, updateLeaveReach } from "../features/leaving-reach-slice";
import { navigate } from "gatsby";
import { Checkbox } from "./checkbox";
import { format, startOfTomorrow } from "date-fns";
import { activateLine } from "../features/activation-slice";
import { DisconnectionSelection } from "../screens/leave-reach-page";
import CustomerVerification from "./customer-verification";

interface PortFailureDetails {
    name?: string;
    buttonText: string;
    placeholderText?: string;
    failureMsg: string;
    code: number;
    maxLength?: number;
    characterRestriction?: number;
    pattern?: RegExp;
}

const PortingFailureMessage: Record<PortFailureCode, PortFailureDetails> = {
    1009: {
        name: "oldCarrierAccountNumber",
        buttonText: "Update Account No.",
        failureMsg: "To continue the activation, please resubmit some of the information.",
        placeholderText: "Enter current carrier account number",
        code: 1009,
        maxLength: 20,
        pattern: /^[A-Za-z0-9]{1,20}$/,
    },
    1010: {
        name: "password",
        buttonText: "Update PIN",
        failureMsg: "To continue the activation, please resubmit some of the information.",
        placeholderText: "Enter current carrier PIN",
        code: 1010,
        maxLength: 15,
        pattern: /^[A-Za-z0-9]{1,15}$/,
    },
    1011: {
        name: "oldZip",
        buttonText: "Update ZIP",
        failureMsg: "To continue the activation, please resubmit some of the information.",
        placeholderText: "Enter current carrier zip code",
        code: 1011,
        maxLength: 5,
        pattern: /^\d{5}$/,
        characterRestriction: 5,
    },
    1017: {
        buttonText: "Retry",
        failureMsg: "The port out request was rejected by the current carrier.",
        code: 1017,
    },
};

function OtherActionSheet(props: BottomSheetProps & { customerProfile: CustomerProfile }) {
    const { customerProfile, ...rest } = props;
    const [updatePortType, setUpdatePortType] = useState<UpdatePortType>();

    const customerId = customerProfile.id;

    let title = "";

    switch (updatePortType) {
        case UpdatePortType.RESUBMIT_PORT:
            title = "Resubmit port";
            break;
        case UpdatePortType.ALREADY_UPDATED:
            title = "Already updated on telispire";
            break;
        case UpdatePortType.CANCEL_AND_RESUBMIT:
            title = "Cancel and resubmit";
            break;
        case UpdatePortType.CANCEL_PORT:
            title = "Cancel port";
            break;
        case UpdatePortType.CANCEL_AND_NEW:
            title = "Cancel port and activate with new number";
            break;
        case UpdatePortType.UPDATE_PORT:
            title = "Resubmit port with new details";
            break;
    }
    const { control, handleSubmit, errors, register, formState } = useForm<
        Omit<UpdatePortReq, "type"> & { operatorText?: string }
    >({
        mode: "onBlur",
    });

    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const dispatch = useAppDispatch();

    const onSubmit = async (data: Omit<UpdatePortReq, "type"> & { operatorText?: string }) => {
        if (data.operatorText) {
            data.oldCarrier = data.operatorText;
        }
        try {
            await unwrap(
                updatePort({
                    customerId,
                    type: updatePortType!,
                    ...data,
                })
            );
            await dispatch(fetchProfile(customerId));
            setUpdatePortType(undefined);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const { operators } = useSelector(configSelector);
    const [showOperatorField, setOperatorField] = useState(false);

    return (
        <>
            <BottomSheet {...rest}>
                <div
                    className="text-cta my-2"
                    onClick={() => {
                        setUpdatePortType(UpdatePortType.RESUBMIT_PORT);
                        rest.onHide();
                    }}>
                    Resubmit
                </div>
                <div
                    className="text-cta my-2"
                    onClick={() => {
                        setUpdatePortType(UpdatePortType.ALREADY_UPDATED);
                        rest.onHide();
                    }}>
                    Already updated on telispire
                </div>
                <div
                    className="text-cta my-2"
                    onClick={() => {
                        setUpdatePortType(UpdatePortType.CANCEL_AND_RESUBMIT);
                        rest.onHide();
                    }}>
                    Cancel &amp; Resubmit
                </div>
                <div
                    className="text-cta my-2"
                    onClick={() => {
                        setUpdatePortType(UpdatePortType.CANCEL_PORT);
                        rest.onHide();
                    }}>
                    Cancel
                </div>
                <div
                    className="text-cta my-2"
                    onClick={() => {
                        setUpdatePortType(UpdatePortType.CANCEL_AND_NEW);
                        rest.onHide();
                    }}>
                    Cancel port and activate with new number
                </div>
                <div
                    className="text-cta my-2"
                    onClick={() => {
                        setUpdatePortType(UpdatePortType.UPDATE_PORT);
                        rest.onHide();
                    }}>
                    Resubmit port with new details
                </div>
            </BottomSheet>
            <Modal
                title={title}
                size={
                    updatePortType === UpdatePortType.CANCEL_AND_RESUBMIT ||
                    updatePortType === UpdatePortType.UPDATE_PORT ||
                    updatePortType === UpdatePortType.CANCEL_AND_NEW
                        ? undefined
                        : "sm"
                }
                show={updatePortType != null}
                onHide={() => {
                    setUpdatePortType(undefined);
                }}>
                <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
                    {(updatePortType === UpdatePortType.CANCEL_AND_RESUBMIT ||
                        updatePortType === UpdatePortType.UPDATE_PORT) && (
                        <>
                            <Controller
                                control={control}
                                name="numberToPort"
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Phone number is required",
                                    },
                                    pattern: {
                                        value: /^\d{10}$/,
                                        message: "Phone number should be 10 digits long",
                                    },
                                }}
                                defaultValue={customerProfile.mnpInfo?.oldNumber ?? ""}
                                render={({ onChange, onBlur, value, name }) => (
                                    <Input
                                        type="text"
                                        inputMode="numeric"
                                        placeholder="Current number"
                                        maxLength={10}
                                        className="col my-2 px-2"
                                        mask="phone"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        name={name}
                                        showError={errors.numberToPort != null}
                                        errorMessage={errors.numberToPort?.message}
                                    />
                                )}
                            />
                            <Input
                                type="text"
                                placeholder="Account number"
                                name="oldCarrierAccountNumber"
                                className="col my-2 px-2"
                                defaultValue={customerProfile.mnpInfo?.acctNum ?? ""}
                                register={register({
                                    required: {
                                        value: true,
                                        message: "Account number is required",
                                    },
                                    pattern: {
                                        value: /^[A-Za-z0-9]{1,20}$/,
                                        message: "Not a valid account number",
                                    },
                                })}
                                showError={errors.oldCarrierAccountNumber != null}
                                errorMessage={errors.oldCarrierAccountNumber?.message}
                            />
                            <Input
                                type="text"
                                placeholder="PIN"
                                name="password"
                                className="col my-2 px-2"
                                defaultValue={customerProfile.mnpInfo?.oldPassword ?? ""}
                                register={register({
                                    required: {
                                        value: true,
                                        message: "PIN is required",
                                    },
                                    pattern: {
                                        value: /^[A-Za-z0-9]{1,15}$/,
                                        message: "Not a valid PIN",
                                    },
                                })}
                                showError={errors.password != null}
                                errorMessage={errors.password?.message}
                            />
                            <Input
                                type="text"
                                inputMode="numeric"
                                maxLength={5}
                                placeholder="Old ZIP code"
                                name="oldZip"
                                className="col my-2 px-2"
                                defaultValue={customerProfile.mnpInfo?.oldZip ?? ""}
                                register={register({
                                    required: {
                                        value: true,
                                        message: "Zip is required",
                                    },
                                    pattern: {
                                        value: /^\d{5}$/,
                                        message: "Zip should be 5 digits long",
                                    },
                                })}
                                showError={errors.oldZip != null}
                                errorMessage={errors.oldZip?.message}
                            />
                            <Controller
                                name="oldCarrier"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Operator must be selected",
                                    },
                                }}
                                defaultValue={customerProfile.mnpInfo?.operator ?? ""}
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        onChange={(event) => {
                                            onChange(event.target.value);
                                            setOperatorField(event.target.value === "Other");
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        name={name}
                                        className="col my-2 px-2"
                                        showError={errors.oldCarrier != null}
                                        errorMessage={errors.oldCarrier?.message}>
                                        <option value="">Select carrier</option>
                                        {operators.map((operator) => (
                                            <option key={operator.value} value={operator.value}>
                                                {operator.displayName}
                                            </option>
                                        ))}
                                    </Select>
                                )}
                            />
                            {showOperatorField && (
                                <Input
                                    type="text"
                                    placeholder="Enter operator name"
                                    className="col my-2 px-2"
                                    name="operatorText"
                                    defaultValue={customerProfile.mnpInfo?.operator ?? ""}
                                    register={register({
                                        required: {
                                            value: true,
                                            message: "Operator is required",
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9 &]+$/,
                                            message: "Invalid carrier name",
                                        },
                                    })}
                                    showError={errors.operatorText != null}
                                    errorMessage={errors.operatorText?.message}
                                />
                            )}
                            <Controller
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "IMEI is required",
                                    },
                                    pattern: {
                                        value: /^\d{14,15}$/,
                                        message: "IMEI must be 14 or 15 digits.",
                                    },
                                }}
                                name="imei"
                                defaultValue={customerProfile.imei ?? ""}
                                render={({ onChange, onBlur, value, name }) => (
                                    <Input
                                        type="text"
                                        inputMode="numeric"
                                        placeholder="Enter IMEI"
                                        className="col my-2 px-2"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        name={name}
                                        maxLength={15}
                                        characterRestriction={15}
                                        showError={errors.imei != null}
                                        errorMessage={errors.imei?.message}
                                    />
                                )}
                            />
                        </>
                    )}

                    {updatePortType === UpdatePortType.CANCEL_AND_NEW && (
                        <>
                            <Controller
                                control={control}
                                rules={{
                                    pattern: {
                                        value: /^\d{14,15}$/,
                                        message: "IMEI must be 14 or 15 digits.",
                                    },
                                    setValueAs: (value) => (value === "" ? undefined : value),
                                }}
                                name="imei"
                                defaultValue=""
                                render={({ onChange, onBlur, value, name }) => (
                                    <Input
                                        type="text"
                                        inputMode="numeric"
                                        placeholder="New IMEI (if using a different IMEI)"
                                        className="col my-2 px-2"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        name={name}
                                        maxLength={15}
                                        characterRestriction={15}
                                        showError={errors.imei != null}
                                        errorMessage={errors.imei?.message}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                rules={{
                                    pattern: {
                                        value: /^\d{20}$/,
                                        message: "ICCID must be 20 digits.",
                                    },
                                    setValueAs: (value) => (value === "" ? undefined : value),
                                }}
                                name="iccId"
                                defaultValue=""
                                render={({ onChange, onBlur, value, name }) => (
                                    <Input
                                        type="text"
                                        inputMode="numeric"
                                        placeholder="New ICCID (if using a different SIM)"
                                        className="col my-2 px-2"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        name={name}
                                        maxLength={20}
                                        characterRestriction={20}
                                        showError={errors.iccId != null}
                                        errorMessage={errors.iccId?.message}
                                    />
                                )}
                            />
                        </>
                    )}

                    <Button color="secondary" className="col-12" fullWidth loading={formState.isSubmitting}>
                        Submit
                    </Button>
                </form>
            </Modal>
        </>
    );
}

export interface ActivationStatusCardProps extends CustomerProfile {
    className?: string;
}

export default function ActivationStatusCard(props: ActivationStatusCardProps) {
    const { className, ...customerProfile } = props;

    const portFailureMsg = customerProfile.activation?.portFailureCode
        ? PortingFailureMessage[customerProfile.activation.portFailureCode] ?? PortingFailureMessage[1017]
        : undefined;

    const [showMNPUpdateModal, setShowMNPUpdateModal] = useState(false);
    const [showReconnectionModal, setShowReconnectionModal] = useState(false);
    const [showActivationRetryModal, setShowActivationRetryModal] = useState(false);
    const [disconnectionModalType, setDisconnectionModalType] = useState<"change" | "cancel">();
    const [showOtherActions, setShowOtherActions] = useState(false);
    const [showCustomerVerification, setShowCustomerVerification] = useState(false);

    const [loading, setLoading] = useState(false);

    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const dispatch = useAppDispatch();
    const showSnackbar = useSuccessModal();

    const { provConfig } = useSelector(configSelector);

    const primaryCustomer = customerProfile.groupLines.find((customer) => customer.isPrimary)!;

    useEffect(() => {
        let id: number | undefined = undefined;
        if (
            customerProfile.status === CustomerStatus.ACTIVATION_IN_PROGRESS ||
            customerProfile.status === CustomerStatus.PORTING_IN_PROGRESS
        ) {
            refreshStatus();
            id = setInterval(() => {
                if (
                    customerProfile.status === CustomerStatus.ACTIVATION_IN_PROGRESS ||
                    customerProfile.status === CustomerStatus.PORTING_IN_PROGRESS
                ) {
                    refreshStatus();
                }
            }, 2 * 60 * 1000);
        }
        return () => clearInterval(id);
    }, []);

    const onClickPortUpdate = async () => {
        if (portFailureMsg?.code === 1017) {
            setLoading(true);
            try {
                await unwrap(
                    updatePort({
                        customerId: customerProfile.id,
                        type: UpdatePortType.UPDATE_PORT,
                    })
                );
                await dispatch(fetchProfile(customerProfile.id));
            } catch (error) {
                console.log(error);
                handleError(error);
            }
            setLoading(false);
        } else {
            setShowMNPUpdateModal(true);
        }
    };

    const onSubmitPortUpdate = async (data: Partial<UpdatePortReq>) => {
        try {
            await unwrap(
                updatePort({
                    customerId: customerProfile.id,
                    type: UpdatePortType.UPDATE_PORT,
                    ...data,
                })
            );
            await dispatch(fetchProfile(customerProfile.id));
            setShowMNPUpdateModal(false);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const onActivationRetry = async (data: { imei: string; iccId: string }) => {
        try {
            await unwrap(
                activateLine({
                    customerId: customerProfile.id,
                    iccId: data.iccId,
                    imei: data.imei,
                    make: customerProfile.make,
                    model: customerProfile.model,
                    reachPlanId: customerProfile.reachPlan.name,
                    skipHold: !customerProfile.isCardHold,
                    isPort: customerProfile.isMNP ?? false,
                    numberToPort: customerProfile.mnpInfo?.oldNumber,
                    oldCarrier: customerProfile.mnpInfo?.operator,
                    oldCarrierAccountNumber: customerProfile.mnpInfo?.acctNum,
                    oldZip: customerProfile.mnpInfo?.oldZip,
                    password: customerProfile.mnpInfo?.pin,
                })
            );
            await dispatch(fetchProfile(customerProfile.id));
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        setShowActivationRetryModal(false);
    };

    const onReconnection = async (data: { skipReconnectFee: boolean }) => {
        console.log(data);
        if (customerProfile.lastDisconnectedReason !== DisconnectionReason.PORTED_OUT) {
            try {
                await unwrap(
                    reconnect({
                        customerId: customerProfile.id,
                        skipReconnectFee: data.skipReconnectFee,
                        isPortIn: false,
                    })
                );
                await dispatch(fetchProfile(customerProfile.id));
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        } else {
            navigate(`/reconnect-port/${customerProfile.id}?skipReconnectFee=${data.skipReconnectFee}`);
        }
        setShowReconnectionModal(false);
    };

    const onUpdateDisconnection = async (data: { laterDisconnectionDate?: Date }) => {
        console.log(data);
        try {
            await unwrap(
                updateLeaveReach({
                    customerId: primaryCustomer.id,
                    payload: {
                        customers: {
                            [customerProfile.id]: {
                                type:
                                    disconnectionModalType === "change"
                                        ? DisconnectionSelection.DISCONNECT_LATER
                                        : DisconnectionSelection.CANCEL,
                                laterDisconnectionDate: data.laterDisconnectionDate
                                    ? data.laterDisconnectionDate.toISOString()
                                    : undefined,
                            },
                        },
                    },
                })
            );
            await dispatch(fetchProfile(customerProfile.id));
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        setDisconnectionModalType(undefined);
    };

    const refreshStatus = async () => {
        setLoading(true);
        try {
            const res = await unwrap(getActivation(customerProfile.id));
            if (
                res.status !== CustomerStatus.ACTIVATION_IN_PROGRESS &&
                res.status !== CustomerStatus.PORTING_IN_PROGRESS
            ) {
                await dispatch(fetchProfile(customerProfile.id));
            }
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        setLoading(false);
    };

    const refreshProfile = async () => {
        setLoading(true);
        try {
            await dispatch(fetchProfile(customerProfile.id));
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        setLoading(false);
    };

    const refreshNetworkStatus = async () => {
        setLoading(true);
        try {
            const res = await unwrap(getNetworkResetStatus(customerProfile.id));
            if (res.status === NetworkResetStatus.CUSTOMER_IS_ACTIVE) {
                await dispatch(fetchProfile(customerProfile.id));
                showSnackbar("Network reset successful");
            }
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        setLoading(false);
    };

    const refreshMDNSwapStatus = async () => {
        setLoading(true);
        try {
            const res = await unwrap(getMDNSwapStatus(customerProfile.id));
            if (res.newReachNumber) {
                await dispatch(fetchProfile(customerProfile.id));
                showSnackbar(`New number generated: ${formatPhone(res.newReachNumber)}`);
            }
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        setLoading(false);
    };

    const refreshPortOutStatus = async () => {
        setLoading(true);
        try {
            const res = await unwrap(getPortoutInfo(customerProfile.id));
            if (res.status !== customerProfile.portOutInfo?.status) {
                await dispatch(fetchProfile(customerProfile.id));
            }
        } catch (error) {
            console.log(error);
            if (error.resCode !== 40229) {
                handleError(error);
            }
        }
        setLoading(false);
    };

    const { control, errors, formState, register, handleSubmit } = useForm({
        mode: "onBlur",
    });

    if (customerProfile.operationsInProcess?.includes(OperationsInProcess.RESET)) {
        return (
            <Card className={classNames(className)}>
                <img src={activationInProgressIcon} />
                <div className="font-family-bold" style={{ fontSize: 16 }}>
                    Network reset in progress{" "}
                    {loading ? (
                        <span className="spinner-border spinner-border-sm ml-2 mb-1 text-primary" />
                    ) : (
                        <span
                            className="reach-cs-refresh cursor-pointer text-secondary ml-2"
                            onClick={refreshNetworkStatus}
                        />
                    )}
                </div>
            </Card>
        );
    } else if (customerProfile.operationsInProcess?.includes(OperationsInProcess.SUSPEND)) {
        return (
            <Card className={classNames(className)}>
                <img src={activationInProgressIcon} />
                <div className="font-family-bold" style={{ fontSize: 16 }}>
                    Suspend in progress{" "}
                    {loading ? (
                        <span className="spinner-border spinner-border-sm ml-2 mb-1 text-primary" />
                    ) : (
                        <span
                            className="reach-cs-refresh cursor-pointer text-secondary ml-2"
                            onClick={refreshProfile}
                        />
                    )}
                </div>
            </Card>
        );
    } else if (customerProfile.operationsInProcess?.includes(OperationsInProcess.RESTORE)) {
        return (
            <Card className={classNames(className)}>
                <img src={activationInProgressIcon} />
                <div className="font-family-bold" style={{ fontSize: 16 }}>
                    Restore in progress{" "}
                    {loading ? (
                        <span className="spinner-border spinner-border-sm ml-2 mb-1 text-primary" />
                    ) : (
                        <span
                            className="reach-cs-refresh cursor-pointer text-secondary ml-2"
                            onClick={refreshProfile}
                        />
                    )}
                </div>
            </Card>
        );
    } else if (customerProfile.operationsInProcess?.includes(OperationsInProcess.NEW_NUMBER_GENERATION)) {
        return (
            <Card className={classNames(className)}>
                <img src={activationInProgressIcon} />
                <div className="font-family-bold" style={{ fontSize: 16 }}>
                    New number generation in progress{" "}
                    {loading ? (
                        <span className="spinner-border spinner-border-sm ml-2 mb-1 text-primary" />
                    ) : (
                        <span
                            className="reach-cs-refresh cursor-pointer text-secondary ml-2"
                            onClick={refreshMDNSwapStatus}
                        />
                    )}
                </div>
            </Card>
        );
    } else if (
        customerProfile.operationsInProcess?.includes(OperationsInProcess.PORT_OUT) &&
        customerProfile.portOutInfo?.status === PortoutStatus.RESOLUTIONREQUIRED
    ) {
        return (
            <Card className={classNames(className)}>
                <img src={portingFailedIcon} />
                <div className="font-family-bold" style={{ fontSize: 16 }}>
                    Port out rejected
                    {loading ? (
                        <span className="spinner-border spinner-border-sm ml-2 mb-1 text-primary" />
                    ) : (
                        <span
                            className="reach-cs-refresh cursor-pointer text-secondary ml-2"
                            onClick={refreshPortOutStatus}
                        />
                    )}
                </div>
                <div className="font-family-semibold mt-3">{customerProfile.portOutInfo.reasonDescription}</div>
                <Button
                    color="secondary"
                    className="mt-3 col-12 col-md-4"
                    fullWidth
                    onClick={() => navigate(`/port-out-details/${customerProfile.id}`)}>
                    View details
                </Button>
            </Card>
        );
    } else if (customerProfile.operationsInProcess?.includes(OperationsInProcess.PORT_OUT)) {
        return (
            <Card className={classNames(className)}>
                <img src={infoIcon} />
                <div className="font-family-bold" style={{ fontSize: 16 }}>
                    {!customerProfile.portOutInfo ? "Port out requested" : "Port out pending"}
                    {loading ? (
                        <span className="spinner-border spinner-border-sm ml-2 mb-1 text-primary" />
                    ) : (
                        <span
                            className="reach-cs-refresh cursor-pointer text-secondary ml-2"
                            onClick={refreshPortOutStatus}
                        />
                    )}
                </div>
                {!customerProfile.portOutInfo && (
                    <div className="font-family-semibold mt-3">
                        We have not received any information for the port out yet
                    </div>
                )}
                <Button
                    color="secondary"
                    className="mt-3 col-12 col-md-4"
                    fullWidth
                    onClick={() => navigate(`/port-out-details/${customerProfile.id}`)}>
                    View details
                </Button>
            </Card>
        );
    }

    if (isPostActivationState(customerProfile.status) && customerProfile.laterDisconnectionDate) {
        return (
            <Card className={classNames(className)}>
                <img src={portingFailedIcon} />
                <div className="font-family-bold text-tertiary5 mt-2" style={{ fontSize: 16 }}>
                    Disconnection pending
                </div>
                <div className="font-family-semibold">
                    This line will be disconnected on{" "}
                    {format(new Date(customerProfile.laterDisconnectionDate), "MMM dd, yyyy")}.
                </div>

                <div
                    className="text-cta mt-3"
                    onClick={() => {
                        setDisconnectionModalType("change");
                        setShowCustomerVerification(false);
                    }}>
                    Change date
                </div>
                <Button
                    color="secondary"
                    className="mt-3 col-12 col-md-4"
                    fullWidth
                    onClick={() => {
                        setDisconnectionModalType("cancel");
                        setShowCustomerVerification(false);
                    }}>
                    Cancel
                </Button>

                <Modal
                    title={disconnectionModalType === "change" ? "Change disconnection date" : "Cancel disconnection?"}
                    size="sm"
                    show={disconnectionModalType != null}
                    onHide={() => setDisconnectionModalType(undefined)}
                    titleClassName={classNames({ disabled: showCustomerVerification })}>
                    {disconnectionModalType === "change" && (
                        <div className="align-self-start font-family-semibold">Select date</div>
                    )}
                    <form
                        className={classNames("w-100", {
                            disabled: showCustomerVerification,
                        })}
                        style={{ pointerEvents: showCustomerVerification ? "none" : undefined }}
                        onSubmit={handleSubmit(
                            disconnectionModalType === "change"
                                ? onUpdateDisconnection
                                : () => setShowCustomerVerification(true)
                        )}>
                        {disconnectionModalType === "change" && (
                            <Input
                                type="date"
                                className="col my-3 px-0"
                                name="laterDisconnectionDate"
                                defaultValue={
                                    customerProfile.laterDisconnectionDate
                                        ? format(new Date(customerProfile.laterDisconnectionDate), "yyyy-MM-dd")
                                        : undefined
                                }
                                min={format(startOfTomorrow(), "yyyy-MM-dd")}
                                max={
                                    !customerProfile.isPrimary && primaryCustomer.laterDisconnectionDate != null
                                        ? format(new Date(primaryCustomer.laterDisconnectionDate), "yyyy-MM-dd")
                                        : undefined
                                }
                                register={register({
                                    required: "Date is required",
                                    valueAsDate: true,
                                })}
                            />
                        )}
                        <Button color="secondary" className="col-12" fullWidth loading={formState.isSubmitting}>
                            Confirm
                        </Button>
                    </form>

                    {showCustomerVerification && (
                        <CustomerVerification
                            onSubmit={handleSubmit(onUpdateDisconnection)}
                            customerProfile={customerProfile}
                        />
                    )}
                </Modal>
            </Card>
        );
    }

    switch (customerProfile.status) {
        case CustomerStatus.ACTIVATION_IN_PROGRESS:
            return (
                <Card className={classNames(className)}>
                    <img src={activationInProgressIcon} />
                    <div className="font-family-bold" style={{ fontSize: 16 }}>
                        Activation in progress{" "}
                        {loading ? (
                            <span className="spinner-border spinner-border-sm ml-2 mb-1 text-primary" />
                        ) : (
                            <span
                                className="reach-cs-refresh cursor-pointer text-secondary ml-2"
                                onClick={refreshStatus}
                            />
                        )}
                    </div>
                </Card>
            );
        case CustomerStatus.PORTING_IN_PROGRESS:
            return (
                <Card className={classNames(className)}>
                    <img src={infoIcon} />
                    <div className="font-family-bold" style={{ fontSize: 16 }}>
                        Porting in progress{" "}
                        {loading ? (
                            <span className="spinner-border spinner-border-sm ml-2 mb-1 text-primary" />
                        ) : (
                            <span
                                className="reach-cs-refresh cursor-pointer text-secondary ml-2"
                                onClick={refreshStatus}
                            />
                        )}
                    </div>
                    <div>{customerProfile.activation?.mvneStatus}</div>
                    {customerProfile.mnpInfo && (
                        <div className="w-100 mt-3">
                            <h3 className="font-family-bold pb-2 divider">Current carrier information</h3>
                            <div className="row">
                                <div className="col-12 col-md-4 mt-2 mt-md-0">
                                    <div>Current number</div>
                                    <div className="font-family-semibold">
                                        {customerProfile.mnpInfo.oldNumber
                                            ? formatPhone(customerProfile.mnpInfo.oldNumber)
                                            : "-"}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 mt-2 mt-md-0">
                                    <div>Account number</div>
                                    <div className="font-family-semibold">{customerProfile.mnpInfo.acctNum}</div>
                                </div>
                                <div className="col-12 col-md-4 mt-2 mt-md-0">
                                    <div>Pin</div>
                                    <div className="font-family-semibold">
                                        {customerProfile.mnpInfo.oldPassword ?? "-"}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 mt-2">
                                    <div>Zip code</div>
                                    <div className="font-family-semibold">{customerProfile.mnpInfo.oldZip ?? "-"}</div>
                                </div>
                                <div className="col-12 col-md-4 mt-2">
                                    <div>Carrier</div>
                                    <div className="font-family-semibold">
                                        {customerProfile.mnpInfo.operator ?? "-"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="text-cta mt-2" onClick={() => setShowOtherActions(true)}>
                        Other Actions
                    </div>
                    <OtherActionSheet
                        show={showOtherActions}
                        onHide={() => setShowOtherActions(false)}
                        customerProfile={customerProfile}
                    />
                </Card>
            );
        case CustomerStatus.PORTING_FAILED:
            return (
                <Card className={classNames(className)}>
                    <img src={portingFailedIcon} />
                    <div className="font-family-bold text-tertiary5" style={{ fontSize: 16 }}>
                        Porting failed
                    </div>
                    <div>{customerProfile.activation?.mvneStatus}</div>
                    {portFailureMsg?.failureMsg && (
                        <div className="font-family-semibold">{portFailureMsg.failureMsg}</div>
                    )}
                    {portFailureMsg?.placeholderText && <div className="mt-3">{portFailureMsg.placeholderText}</div>}
                    {portFailureMsg?.code === 1017 && customerProfile.activation?.portFailureCode !== 1017 && (
                        <>
                            <div className="text-center mt-3">{customerProfile.activation?.activationMsg}</div>
                            {customerProfile.activation?.suggestedMsg && (
                                <div className="text-center mt-2">{customerProfile.activation.suggestedMsg}</div>
                            )}
                        </>
                    )}
                    {customerProfile.mnpInfo && (
                        <div className="w-100 mt-3">
                            <h3 className="font-family-bold pb-2 divider">Current carrier information</h3>
                            <div className="row">
                                <div className="col-12 col-md-4 mt-2 mt-md-0">
                                    <div>Current number</div>
                                    <div className="font-family-semibold">
                                        {customerProfile.mnpInfo.oldNumber
                                            ? formatPhone(customerProfile.mnpInfo.oldNumber)
                                            : "-"}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 mt-2 mt-md-0">
                                    <div>Account number</div>
                                    <div className="font-family-semibold">{customerProfile.mnpInfo.acctNum}</div>
                                </div>
                                <div className="col-12 col-md-4 mt-2 mt-md-0">
                                    <div>Pin</div>
                                    <div className="font-family-semibold">
                                        {customerProfile.mnpInfo.oldPassword ?? "-"}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 mt-2">
                                    <div>Zip code</div>
                                    <div className="font-family-semibold">{customerProfile.mnpInfo.oldZip ?? "-"}</div>
                                </div>
                                <div className="col-12 col-md-4 mt-2">
                                    <div>Carrier</div>
                                    <div className="font-family-semibold">
                                        {customerProfile.mnpInfo.operator ?? "-"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {portFailureMsg?.buttonText && (
                        <Button
                            color="secondary"
                            className="mt-3 col-12 col-md-4"
                            loading={loading}
                            onClick={onClickPortUpdate}>
                            {portFailureMsg.buttonText}
                        </Button>
                    )}
                    <div
                        className={classNames("text-cta mt-2", { disabled: loading })}
                        onClick={() => !loading && setShowOtherActions(true)}>
                        Other Actions
                    </div>
                    <OtherActionSheet
                        show={showOtherActions}
                        onHide={() => setShowOtherActions(false)}
                        customerProfile={customerProfile}
                    />
                    {portFailureMsg && (
                        <Modal
                            title={portFailureMsg.buttonText}
                            size="sm"
                            show={showMNPUpdateModal}
                            onHide={() => setShowMNPUpdateModal(false)}>
                            <form className="col-12" onSubmit={handleSubmit(onSubmitPortUpdate)}>
                                <Controller
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Field is required",
                                        },
                                        pattern: portFailureMsg.pattern
                                            ? {
                                                  value: portFailureMsg.pattern,
                                                  message: "Invalid field",
                                              }
                                            : undefined,
                                    }}
                                    name={portFailureMsg.name!}
                                    defaultValue=""
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Input
                                            type="text"
                                            placeholder={portFailureMsg.placeholderText!}
                                            className="mb-3"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            name={name}
                                            maxLength={portFailureMsg.maxLength}
                                            characterRestriction={portFailureMsg.characterRestriction}
                                            showError={errors[portFailureMsg.name!]}
                                            errorMessage={errors[portFailureMsg.name!]?.message}
                                        />
                                    )}
                                />

                                <Button color="secondary" fullWidth loading={formState.isSubmitting}>
                                    Submit
                                </Button>
                            </form>
                        </Modal>
                    )}
                </Card>
            );
        case CustomerStatus.DISCONNECTED:
            return (
                <Card className={classNames(className)}>
                    <img src={portingFailedIcon} />
                    <div className="font-family-bold text-tertiary5" style={{ fontSize: 16 }}>
                        {customerProfile.lastDisconnectedReason === DisconnectionReason.PORTED_OUT
                            ? "Ported out"
                            : "Disconnected"}
                    </div>
                    <Button
                        color="secondary"
                        className="mt-3 col-12 col-md-4"
                        disabled={!customerProfile.isPrimary && !isPostActivationState(primaryCustomer.status)}
                        onClick={() => setShowReconnectionModal(true)}>
                        Reconnect
                    </Button>
                    <Modal
                        title="Do you want to reconnect?"
                        size="sm"
                        show={showReconnectionModal}
                        onHide={() => setShowReconnectionModal(false)}>
                        <div className="font-family-semibold text-center mb-3" style={{ fontSize: 16 }}>
                            Reconnection fee of ${provConfig.RECONNECTION_FEE} will be applicable.
                        </div>
                        <Checkbox
                            label="Skip reconnection fees"
                            register={register}
                            className="align-self-start"
                            id="skipReconnectFee"
                            name="skipReconnectFee"
                            defaultChecked={false}
                        />
                        <Button
                            color="secondary"
                            fullWidth
                            loading={formState.isSubmitting}
                            onClick={handleSubmit(onReconnection)}>
                            Confirm
                        </Button>
                        <div className="text-cta mt-2" onClick={() => setShowReconnectionModal(false)}>
                            Cancel
                        </div>
                    </Modal>
                </Card>
            );
        case CustomerStatus.ACTIVATION_FAILED:
            return (
                <Card className={classNames(className)}>
                    <img src={portingFailedIcon} />
                    <div className="font-family-bold text-tertiary5" style={{ fontSize: 16 }}>
                        Activation failed
                    </div>
                    <div className="text-center mt-3">{customerProfile.activation?.activationMsg}</div>
                    {customerProfile.activation?.suggestedMsg && (
                        <div className="text-center mt-2">{customerProfile.activation.suggestedMsg}</div>
                    )}
                    <Button
                        color="secondary"
                        className="mt-3 col-12 col-md-4"
                        loading={formState.isSubmitting}
                        onClick={() => setShowActivationRetryModal(true)}>
                        Retry
                    </Button>
                    <Modal
                        title="Retry activation"
                        show={showActivationRetryModal}
                        onHide={() => {
                            setShowActivationRetryModal(false);
                        }}>
                        <form className="w-100" onSubmit={handleSubmit(onActivationRetry)}>
                            <Controller
                                control={control}
                                rules={{
                                    pattern: {
                                        value: /^\d{14,15}$/,
                                        message: "IMEI must be 14 or 15 digits.",
                                    },
                                    setValueAs: (value) => (value === "" ? undefined : value),
                                }}
                                name="imei"
                                defaultValue={customerProfile.imei ?? ""}
                                render={({ onChange, onBlur, value, name }) => (
                                    <Input
                                        type="text"
                                        inputMode="numeric"
                                        placeholder="New IMEI (if using a different IMEI)"
                                        className="col my-2 px-2"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        name={name}
                                        maxLength={15}
                                        characterRestriction={15}
                                        showError={errors.imei != null}
                                        errorMessage={errors.imei?.message}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                rules={{
                                    pattern: {
                                        value: /^\d{20}$/,
                                        message: "ICCID must be 20 digits.",
                                    },
                                    setValueAs: (value) => (value === "" ? undefined : value),
                                }}
                                name="iccId"
                                defaultValue={customerProfile.simId ?? ""}
                                render={({ onChange, onBlur, value, name }) => (
                                    <Input
                                        type="text"
                                        inputMode="numeric"
                                        placeholder="New ICCID (if using a different SIM)"
                                        className="col my-2 px-2"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        name={name}
                                        maxLength={20}
                                        characterRestriction={20}
                                        showError={errors.iccId != null}
                                        errorMessage={errors.iccId?.message}
                                    />
                                )}
                            />

                            <Button color="secondary" className="col-12" fullWidth loading={formState.isSubmitting}>
                                Submit
                            </Button>
                        </form>
                    </Modal>
                </Card>
            );
        default:
            return null;
    }
}
