import React from "react";
import Layout from "../components/layout";

export default function HubspotConversations() {
    return (
        <Layout title="Hubspot Conversations">
            <iframe
                style={{ width: "100%", height: "100vh", border: 0 }}
                src="https://app.hubspot.com/live-messages/4888946/inbox"></iframe>
        </Layout>
    );
}
