import React from "react";
import { Card } from "./card";
import { CustomerProfile } from "../utils/types";
import { navigate } from "gatsby";

export interface ToolsCardProps extends CustomerProfile {
    className?: string;
}

export default function ToolsCard(props: ToolsCardProps) {
    const { className, ...customerProfile } = props;

    return (
        <Card className={"mt-3"}>
            <div className="font-family-bold align-self-start" style={{ fontSize: 16 }}>
                Tools
            </div>

            <div onClick={() => navigate("/tools/coverage")} className="text-cta mt-3">
                Check Coverage
            </div>

            <div onClick={() => navigate("/tools/imei")} className="text-cta mt-3">
                Device Compatibility
            </div>

            <div onClick={() => navigate("/tools/check-portability")} className="text-cta mt-3">
                Number Portability
            </div>

            <div onClick={() => navigate("/tools/bill-calculator")} className="text-cta mt-3">
                Bill Calculator
            </div>

            <div onClick={() => navigate("/tools/intl-rates")} className="text-cta mt-3">
                International Services
            </div>

            <div onClick={() => navigate("/tools/scan-sim")} className="text-cta mt-3">
                Scan Sim
            </div>
        </Card>
    );
}
