import { format } from "date-fns";
import { navigate } from "gatsby";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { currentBillSelector } from "../features/billing-slice";
import { usePermissions } from "../features/hooks/use-permissions";
import { fetchProfile, manageFeature } from "../features/profile-slice";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile, ManageFeatureOperation, ManageFeatureReason, ManageFeatureReq } from "../utils/types";
import { AutoTopUpStatus } from "../utils/types/customer";
import { PermissionKey } from "../utils/types/user-roles";
import { Button } from "./button";
import CallerIDModal from "./caller-id-modal";
import { Card } from "./card";
import CDMAlessModal from "./cdmaless-modal";
import { Checkbox } from "./checkbox";
import { useErrorHandler } from "./error-snackbar";
import Modal from "./modal";
import Select from "./select";
import { useSuccessModal } from "./success-modal";
import Toggle from "./toggle";

export interface CustomerAccountInfoProps extends CustomerProfile {
    onRecurringTopup: () => void;
    showRemoveCustomer?: boolean;
    hideRemoveCustomer: () => void;
}

export const reasonMap: Record<ManageFeatureReason, string> = {
    [ManageFeatureReason.OVERUSE]: "Over use",
    [ManageFeatureReason.OTHER]: "Other",
    [ManageFeatureReason.BILLDUE]: "Bill due",
    [ManageFeatureReason.BILLPAID]: "Bill paid",
    [ManageFeatureReason.PORTOUT]: "Port-out",
};

export interface Feature {
    name: string;
    type: ManageFeatureOperation;
    reasons: ManageFeatureReason[];
    successMessage?: string;
    permissions: PermissionKey[];
}

export const ManageFeaturesMap: Record<string, Feature> = {
    AddThrottle: {
        name: "Throttle",
        type: ManageFeatureOperation.ADD_THROTTLE,
        permissions: ["network.manage_feature.throttle"],
        reasons: [ManageFeatureReason.OVERUSE, ManageFeatureReason.BILLDUE, ManageFeatureReason.OTHER],
        successMessage: "Throttled successfully",
    },
    RemoveThrottle: {
        name: "De-throttle",
        type: ManageFeatureOperation.REMOVE_THROTTLE,
        permissions: ["network.manage_feature.throttle"],
        reasons: [ManageFeatureReason.BILLPAID, ManageFeatureReason.OTHER],
        successMessage: "De-throttled successfully",
    },
    Hotline: {
        name: "Hotline",
        type: ManageFeatureOperation.HOTLINE,
        permissions: ["network.manage_feature.hotline"],
        reasons: [ManageFeatureReason.BILLDUE, ManageFeatureReason.OTHER],
        successMessage: "Hotlined successfully",
    },
    Suspend: {
        name: "Suspend",
        type: ManageFeatureOperation.SUSPEND,
        permissions: ["network.manage_feature.suspend"],
        reasons: [ManageFeatureReason.BILLDUE, ManageFeatureReason.OTHER],
    },
    Restore: {
        name: "Restore",
        type: ManageFeatureOperation.RESTORE,
        permissions: ["network.manage_feature.restore"],
        reasons: [ManageFeatureReason.BILLPAID, ManageFeatureReason.OTHER],
    },
    AddDataCutoff: {
        name: "Add data cut-off",
        type: ManageFeatureOperation.ADD_DATA_CUTOFF,
        permissions: ["user_management.data_cutoff.add"],
        reasons: [ManageFeatureReason.OVERUSE, ManageFeatureReason.BILLDUE, ManageFeatureReason.OTHER],
        successMessage: "Data paused successfully",
    },
    RemoveDataCutoff: {
        name: "Remove data cut-off",
        type: ManageFeatureOperation.REMOVE_DATA_CUTOFF,
        permissions: ["user_management.data_cutoff.remove"],
        reasons: [ManageFeatureReason.BILLPAID, ManageFeatureReason.OTHER],
        successMessage: "Data unpaused successfully",
    },
    RemoveCustomer: {
        name: "Remove customer",
        type: ManageFeatureOperation.REMOVE_CUSTOMER,
        permissions: ["user_management.customer.delete"],
        reasons: [ManageFeatureReason.OTHER],
        successMessage: "Customer removed successfully",
    },
    /* {
        name: "Release card hold",
        type: ManageFeatureOperation.RELEASE_CARD_HOLD,
        isPrimary: true,
        isSecondary: false,
        reasons: [ManageFeatureReason.OTHER],
    }, */
};

export default function CustomerAccountInfo(props: CustomerAccountInfoProps) {
    const { onRecurringTopup, showRemoveCustomer, hideRemoveCustomer, ...customerProfile } = props;

    const { hasOnePermission } = usePermissions();

    const [showCDMAlessModal, setShowCDMAlessModal] = useState(false);
    const [showCIDBModal, setShowCIDBModal] = useState(false);
    const [feature, setFeature] = useState<Feature>();

    const { handleSubmit, register, errors, formState } = useForm<ManageFeatureReq>({ mode: "onBlur" });

    const unwrap = useUnwrapAsyncThunk();
    const dispatch = useAppDispatch();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();
    const currentBill = useSelector(currentBillSelector(customerProfile.id));

    const selectedFeature = showRemoveCustomer ? ManageFeaturesMap.RemoveCustomer : feature;

    const onSubmit = async (data: Omit<ManageFeatureReq, "operation">) => {
        console.log(data);
        const customerId = customerProfile.id;
        const primaryCustomer = customerProfile.groupLines.find((customer) => customer.isPrimary);

        try {
            const res = await unwrap(
                manageFeature({ customerId, payload: { ...data, operation: selectedFeature!.type } })
            );
            if (showRemoveCustomer) {
                await navigate(`/profile/${primaryCustomer?.id}`);
            }
            await dispatch(fetchProfile(customerId));
            if (res.failureReasonMap) {
                handleError({
                    message: Object.entries(res.failureReasonMap).map(([key, value]) => {
                        const customer = customerProfile.groupLines.find((customer) => customer.id === key);
                        if (!customer) {
                            return `${key}: ${value}`;
                        }
                        return `${customer.firstName} ${customer.lastName}: ${value}`;
                    }),
                });
            } else if (selectedFeature?.successMessage) {
                showSuccessModal(selectedFeature.successMessage);
            }
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        setFeature(undefined);
        if (showRemoveCustomer) {
            hideRemoveCustomer();
        }
    };

    return (
        <Card className="mt-3">
            <div
                className="font-family-bold d-flex align-self-start justify-content-between w-100"
                style={{ fontSize: 16 }}>
                <div>Other account info</div>
                {hasOnePermission("user_management.account_history.read") && (
                    <div className="text-cta" onClick={() => navigate(`/account/history/${customerProfile.id}`)}>
                        View History
                    </div>
                )}
            </div>

            {customerProfile.simPurchaseDate && (
                <div className="font-family-semibold d-flex justify-content-between align-self-start divider py-2">
                    <div>Purchase date</div>
                    <div>{format(new Date(customerProfile.simPurchaseDate), "MMM dd, yyyy")}</div>
                </div>
            )}
            {customerProfile.activationDate && (
                <div className="font-family-semibold d-flex justify-content-between align-self-start divider py-2">
                    <div>Activation date</div>
                    <div>{format(new Date(customerProfile.activationDate), "MMM dd, yyyy")}</div>
                </div>
            )}
            {customerProfile.billGenerationDate && (
                <div className="font-family-semibold d-flex justify-content-between align-self-start divider py-2">
                    <div>Next Bill Generation date</div>
                    <div>{format(new Date(customerProfile.billGenerationDate), "MMM dd, yyyy")}</div>
                </div>
            )}
            {hasOnePermission("billing.invoice.read") && currentBill && (
                <div className="font-family-semibold d-flex justify-content-between align-self-start divider py-2">
                    <div>Bill Generated date</div>
                    <div>{format(new Date(currentBill.billDate), "MMM dd, yyyy")}</div>
                </div>
            )}
            {hasOnePermission("billing.invoice.read") && currentBill && !currentBill.billPaid && (
                <div className="font-family-semibold d-flex justify-content-between align-self-start divider py-2">
                    <div>Bill Due date</div>
                    <div>{format(new Date(currentBill.billDueDate), "MMM dd, yyyy")}</div>
                </div>
            )}
            <div className="font-family-semibold d-flex justify-content-between align-self-start divider py-2">
                <div>Is Data Throttled</div>
                <div>
                    <Toggle
                        id="isThrottled"
                        name="isThrottled"
                        label=""
                        className="mr-n2"
                        disabled={!hasOnePermission("network.manage_feature.throttle")}
                        checked={customerProfile.isThrottled}
                        onChange={() =>
                            setFeature(
                                customerProfile.isThrottled
                                    ? ManageFeaturesMap.RemoveThrottle
                                    : ManageFeaturesMap.AddThrottle
                            )
                        }
                    />
                </div>
            </div>
            <div className="font-family-semibold d-flex justify-content-between align-self-start divider py-2">
                <div>Is Data Paused</div>
                <div>
                    <Toggle
                        id="isDataCutoff"
                        name="isDataCutoff"
                        label=""
                        className="mr-n2"
                        disabled={
                            !hasOnePermission("user_management.data_cutoff.add", "user_management.data_cutoff.remove")
                        }
                        checked={customerProfile.isDataCutoff}
                        onChange={() =>
                            setFeature(
                                customerProfile.isDataCutoff
                                    ? ManageFeaturesMap.RemoveDataCutoff
                                    : ManageFeaturesMap.AddDataCutoff
                            )
                        }
                    />
                </div>
            </div>
            <div className="font-family-semibold d-flex justify-content-between align-self-start divider py-2">
                <div>Is Recurring Topup Enabled</div>
                <div>
                    <Toggle
                        id="autoTopUpStatus"
                        name="autoTopUpStatus"
                        label=""
                        className="mr-n2"
                        disabled={!hasOnePermission("user_management.topup.recurring")}
                        checked={customerProfile.autoTopUpStatus === AutoTopUpStatus.ENABLED}
                        onChange={() => onRecurringTopup()}
                    />
                </div>
            </div>

            {customerProfile.lastDisconnectedDate && (
                <div className="font-family-semibold d-flex justify-content-between align-self-start divider py-2">
                    <div>Disconnection date</div>
                    <div>{format(new Date(customerProfile.lastDisconnectedDate), "MMM dd, yyyy")}</div>
                </div>
            )}
            <div className="font-family-semibold d-flex justify-content-between align-self-start divider py-2">
                <div>Is Credit Card hold Active</div>
                <div>{customerProfile.isCardHold ? "Yes" : "No"}</div>
            </div>
            <div className="font-family-semibold d-flex justify-content-between align-self-start divider py-2">
                <div>Is Port in</div>
                <div>{customerProfile.isMNP ? "Yes" : "No"}</div>
            </div>
            <div className="font-family-semibold d-flex justify-content-between align-self-start divider py-2">
                <div>International Calling Enabled</div>
                <div>{customerProfile.isILDEnabled ? "Yes" : "No"}</div>
            </div>
            <div className="w-100 font-family-semibold d-flex justify-content-between align-self-start divider py-2">
                <div>International Roaming Enabled</div>
                <div>{customerProfile.isRoaming ? "Yes" : "No"}</div>
            </div>
            <div className="w-100 font-family-semibold d-flex justify-content-between align-self-start divider py-2">
                <div>CDMAless</div>
                <div>
                    <Toggle
                        id="enableCDMAless"
                        name="enableCDMAless"
                        label=""
                        className="mr-n2"
                        disabled={!hasOnePermission("network.cdmaless.create")}
                        checked={customerProfile.isCDMALessEnabled}
                        onChange={() => setShowCDMAlessModal(true)}
                    />
                </div>
            </div>
            <div className="w-100 font-family-semibold d-flex justify-content-between align-self-start py-2">
                <div>Caller ID Block</div>
                <div>
                    <Toggle
                        id="isBlocked"
                        name="isBlocked"
                        label=""
                        className="mr-n2"
                        disabled={!hasOnePermission("network.cidb.allow")}
                        checked={customerProfile.isCIDBEnabled}
                        onChange={() => setShowCIDBModal(true)}
                    />
                </div>
            </div>

            <CDMAlessModal
                title={customerProfile.isCDMALessEnabled ? "Disable CDMAless" : "Enable CDMAless"}
                show={showCDMAlessModal}
                onHide={() => setShowCDMAlessModal(false)}
                customerProfile={customerProfile}
            />
            <CallerIDModal
                title={customerProfile.isCIDBEnabled ? "Disable Caller ID Block" : "Enable Caller ID Block"}
                show={showCIDBModal}
                onHide={() => setShowCIDBModal(false)}
                customerProfile={customerProfile}
            />

            <Modal
                title={selectedFeature?.name ?? ""}
                show={selectedFeature != null}
                onHide={() => {
                    setFeature(undefined);
                    if (showRemoveCustomer) {
                        hideRemoveCustomer();
                    }
                }}
                size="sm">
                <Select
                    className="mb-3 w-100"
                    register={register({
                        required: {
                            value: true,
                            message: "Reason is required",
                        },
                    })}
                    name="reason"
                    showError={errors.reason != null}
                    errorMessage={errors.reason?.message}>
                    <option value="">Select the reason</option>
                    {selectedFeature?.reasons.map((reason) => (
                        <option key={reason} value={reason}>
                            {reasonMap[reason]}
                        </option>
                    ))}
                </Select>
                {customerProfile.isPrimary && !showRemoveCustomer && (
                    <Checkbox
                        small
                        label="Want to perform an action for the group?"
                        register={register}
                        id="groupAction"
                        name="groupAction"
                        className="mb-3"
                        defaultChecked={false}
                    />
                )}
                <Button color="secondary" fullWidth loading={formState.isSubmitting} onClick={handleSubmit(onSubmit)}>
                    Confirm
                </Button>
            </Modal>
        </Card>
    );
}
