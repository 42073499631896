import React, { useState } from "react";
import { useSelector } from "react-redux";
import { plansSelector } from "../features/plans-slice";
import { RootState, useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile } from "../utils/types";
import { Button } from "./button";
import { Card } from "./card";
import Modal, { ModalProps } from "./modal";
import { ReachPlan } from "../utils/types";
import { useErrorHandler } from "./error-snackbar";
import classNames from "classnames";
import { changePlan, fetchProfile } from "../features/profile-slice";
import { CustomerStatus } from "../utils/types/customer";
import { usePermissions } from "../features/hooks/use-permissions";
import { useSuccessModal } from "./success-modal";
import { getBytes } from "../utils/data-conversion";
import { Link } from "gatsby";
import { isPostActivationState } from "../utils/helpers";
import Toggle from "./toggle";

export type ReachPlanCardProps = CustomerProfile;

function getTotalLineCost(plan: ReachPlan, lines: number) {
    const actualPrice = ((100 - plan.discountPctg) * plan.baseLinePrice) / 100;

    let prices = 0;
    const additionalLines = lines - 1;
    if (plan.addLineSplitPrice) {
        for (let i = 1; i <= additionalLines; i++) {
            prices = prices + plan.addLineSplitPrice[`${i}`];
        }
    } else {
        prices = additionalLines * plan.additionalLinePrice;
    }
    return actualPrice + prices;
}

function ChangePlanModal(props: { customerProfile: CustomerProfile } & ModalProps) {
    const { customerProfile, ...rest } = props;

    const plans = useSelector(plansSelector.selectAll);

    const [selectedPlanName, setSelectedPlanName] = useState(customerProfile.reachPlan!.name);
    const [showConfirmation, setConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);

    const selectedPlan = useSelector((state: RootState) => plansSelector.selectById(state, selectedPlanName))!;

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();
    const [isBroadbandEnabled, setIsBroadbandEnabled] = useState(true);

    const onSubmit = async (immediateEffect: boolean) => {
        setLoading(true);
        try {
            await unwrap(
                changePlan({
                    customerId: customerProfile.id,
                    newReachPlanId: selectedPlanName,
                    immediateEffect,
                })
            );
            await dispatch(fetchProfile(customerProfile.id));
            if (immediateEffect) {
                showSuccessModal(`Current plan changed to ${selectedPlanName}`);
            } else {
                showSuccessModal(`Current plan will be changed to ${selectedPlanName} in the next billing cycle`);
            }
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        rest.onHide();
        setLoading(false);
    };

    if (showConfirmation) {
        const totalLines = customerProfile.groupLines.filter((customer) =>
            isPostActivationState(customer.status)
        ).length;

        const currentPlanCost = getTotalLineCost(customerProfile.reachPlan!, totalLines);
        const newPlanCost = getTotalLineCost(selectedPlan, totalLines);

        const isDowngrade =
            getBytes(customerProfile.reachPlan!.planData, customerProfile.reachPlan!.dataUnit) >
            getBytes(selectedPlan.planData, selectedPlan.dataUnit);

        return (
            <Modal size="sm" {...rest}>
                <div className="w-100 d-flex justify-content-between align-items-end">
                    <div>
                        <div className="font-family-bold" style={{ fontSize: 16 }}>
                            Current Plan
                        </div>
                        <div className="font-family-semibold" style={{ fontSize: 12 }}>
                            {customerProfile.reachPlan!.planData}
                            {customerProfile.reachPlan!.dataUnit} for {totalLines} + Tax
                        </div>
                    </div>
                    <div className="font-family-semibold" style={{ fontSize: 12 }}>
                        ${currentPlanCost.toFixed(2)}
                    </div>
                </div>
                <div className="divider my-3" />
                <div className="w-100 d-flex justify-content-between align-items-end mb-3">
                    <div>
                        <div className="font-family-bold" style={{ fontSize: 16 }}>
                            New Plan
                        </div>
                        <div className="font-family-semibold" style={{ fontSize: 12 }}>
                            {selectedPlan.planData}
                            {selectedPlan.dataUnit} for {totalLines} + Tax
                        </div>
                    </div>
                    <div className="font-family-semibold" style={{ fontSize: 12 }}>
                        ${newPlanCost.toFixed(2)}
                    </div>
                </div>
                <Button color="secondary" fullWidth onClick={() => onSubmit(true)} loading={loading}>
                    {isDowngrade ? "Downgrade Now" : "Upgrade Now"}
                </Button>
                {customerProfile.status === CustomerStatus.ACTIVE && (
                    <div
                        className={classNames("text-cta mt-2", { disabled: loading })}
                        onClick={() => !loading && onSubmit(false)}>
                        {isDowngrade ? "Downgrade later" : "Upgrade later"}
                    </div>
                )}
            </Modal>
        );
    }

    return (
        <Modal size="xl" {...rest}>
            <div className="row mx-0 mt-3 justify-content-center w-100">
                {plans.map((plan, index) => {
                    const actualPrice = ((100 - plan.discountPctg) * plan.baseLinePrice) / 100;

                    return (
                        <div key={plan.name} className="p-2 col-12 col-md-6 col-lg-3">
                            <div
                                className={classNames("reach-card cursor-pointer", {
                                    disabled: customerProfile.additionalLines + 1 > plan.maxLines,
                                })}
                                onClick={() =>
                                    customerProfile.additionalLines + 1 <= plan.maxLines &&
                                    setSelectedPlanName(plan.name)
                                }>
                                <div>
                                    <div className="font-family-medium" style={{ fontSize: 16 }}>
                                        {plan.name}
                                    </div>
                                    <div className="font-family-bold">
                                        {plan.planData}
                                        {plan.dataUnit}{" "}
                                        <span className="font-family-semibold text-center">
                                            {" "}
                                            - {plan.isUnlimited ? "Per line + uncapped 2G speed" : "Sharable data"}
                                        </span>
                                        <div
                                            className="thick-divider my-3 mx-auto bg-secondary text-center"
                                            style={{
                                                width: 60,
                                                height: 6,
                                                borderRadius: 3,
                                                backgroundColor: "#3a8580",
                                                margin: "10px auto",
                                            }}></div>
                                        <div className="mb-3 text-center">
                                            <span className="font-family-bold">${actualPrice}</span>
                                            <span className="font-family-semibold">/Month (+ taxes & fees)</span>
                                        </div>
                                    </div>
                                </div>
                                {plan.name === selectedPlanName && (
                                    <div
                                        className="bg-primary text-center"
                                        style={{
                                            borderRadius: 12,
                                            width: 24,
                                            height: 24,
                                            position: "absolute",
                                            left: 10,
                                            top: 10,
                                        }}>
                                        <span
                                            className="reach-cs-tick text-white"
                                            style={{ verticalAlign: "middle" }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
            <Button
                color="secondary"
                className="my-2 col-5 mx-auto w-25"
                fullWidth
                disabled={customerProfile.reachPlan!.name === selectedPlanName}
                onClick={() => setConfirmation(true)}>
                Confirm
            </Button>
            <Toggle
                className="my-3 text-center"
                id="Broadband Facts"
                name="Broadband Facts"
                label="Broadband Facts"
                checked={isBroadbandEnabled}
                defaultChecked={true}
                onChange={() => {
                    setIsBroadbandEnabled(!isBroadbandEnabled);
                }}
            />
            {isBroadbandEnabled && (
                <div className="row mx-0 mt-3 justify-content-center w-100">
                    {plans.map((plan, index) => {
                        const actualPrice = ((100 - plan.discountPctg) * plan.baseLinePrice) / 100;
                        return (
                            <div className="nutrition-lable p-2 col-12 col-md-6 col-lg-3">
                                <div
                                    style={{
                                        border: "1px solid #E5E5E5",
                                        borderRadius: "8px",
                                        padding: "5px",
                                    }}>
                                    <div className="font-family-bold letter-sp-1 fs-24 secondary-dark-18">
                                        Broadband Facts
                                    </div>
                                    <hr className="my-1 border-bottom border-3 border-secondary-dark-18" />
                                    <div className="fs-16 font-family-bold secondary-dark-18 letter-sp-1">
                                        {plan?.name}
                                    </div>
                                    <div className="fs-14 secondary-dark-18 font-family-semibold">
                                        Mobile Broadband Consumer Disclosure
                                    </div>
                                    <hr
                                        className="my-1 border-bottom border-secondary-dark-18 my-2"
                                        style={{ borderWidth: "2px" }}
                                    />
                                    <div className="fs-14 font-family-semibold d-flex justify-content-between secondary-dark-18">
                                        <div>Monthly Price</div>
                                        <div>${actualPrice.toFixed(2)}</div>
                                    </div>
                                    <hr className="my-1 border-secondary-dark-18" />
                                    <div className="fs-11 secondary-dark-10 my-2 font-family-semibold">
                                        This monthly price ${actualPrice.toFixed(2)} is not an introductory rate. This
                                        monthly price does not require a contract.
                                    </div>
                                    <hr className="my-1 border-secondary-dark-18" />
                                    <span className="fs-13 font-family-bold secondary-dark-18">
                                        Additional Charges & Terms
                                    </span>{" "}
                                    <div className="fs-11 font-family-semibold pl-2 secondary-dark-18">
                                        Provider Monthly Fees{" "}
                                    </div>
                                    <div className="fs-12 d-flex justify-content-between pl-2 secondary-dark-10 my-1 ml-1">
                                        <div className="text-nowrap font-family-semibold">Cost Recovery Fee</div>
                                        <div className="text-nowrap">6% of total invoice</div>
                                    </div>
                                    <div className="fs-11 font-family-semibold pl-2 secondary-dark-18">
                                        One-time Fees at the Time of Purchase
                                    </div>
                                    <div className="my-1 ml-1">
                                        <div className="fs-12 d-flex justify-content-between pl-2 secondary-dark-10">
                                            <div className="font-family-semibold">Welcome Kit with SIM card</div>
                                            <div className="font-family-semibold">$10</div>
                                        </div>

                                        <div className="fs-12 d-flex justify-content-between align-items-center pl-2 secondary-dark-10 font-family-semibold ">
                                            <div className="my-1">SIM Card Shipping Fee</div>
                                            <div>$5.00 - $25.00</div>
                                        </div>
                                    </div>
                                    <div className="fs-12 secondary-dark-10 mt-2">
                                        Features, such as data top-ups, international long distance calling, and
                                        roaming, are available at additional charge. Please visit{" "}
                                        <span
                                            className="text-cta font-family-regular secondary-dark-10 fs-11"
                                            style={{
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                            }}
                                            onClick={() =>
                                                window.open("https://4g.reachmobile.com", "_blank", "noreferrer")
                                            }>
                                            https://4g.reachmobile.com
                                        </span>{" "}
                                        for details.
                                    </div>
                                    <div className="fs-12 d-flex justify-content-between mt-2 secondary-dark-10 my-1">
                                        <div className="font-family-semibold">Early Termination Fee</div>
                                        <div className="fs-12 font-family-bold  secondary-dark-10">N/A</div>
                                    </div>
                                    <div className="d-flex justify-content-between ">
                                        <div className="fs-12 secondary-dark-10 font-family-semibold">
                                            Government Taxes
                                        </div>
                                        <div className="fs-12 secondary-dark-10 font-family-semibold">
                                            Varies by location
                                        </div>
                                    </div>
                                    <hr className="mt-1 mb-2 mt-2 border-bottom border-bottom-nutri  border-secondary-dark-18" />
                                    <div className="fs-13 font-family-bold secondary-dark-18 ">
                                        Speeds Provided with Plan
                                    </div>
                                    {plan?.labelInfo?.speedInfos?.map((info: any) => (
                                        <div key={info.band}>
                                            <div className="fs-12 font-family-bold secondary-dark-18 my-1">
                                                {info?.band}{" "}
                                            </div>
                                            <div className="pl-1">
                                                <div className="d-flex justify-content-between my-1">
                                                    <div className="fs-12 pl-2 secondary-dark-10 font-family-semibold">
                                                        Typical Download Speed
                                                    </div>
                                                    <div className="fs-12 font-family-bold secondary-dark-10 text-nowrap">
                                                        {info?.dlSpeed ?? "-"}
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <div className="fs-12 pl-2 secondary-dark-10 font-family-semibold">
                                                        Typical Upload Speed
                                                    </div>
                                                    <div className="fs-12 font-family-bold secondary-dark-10">
                                                        {info?.ulSpeed ?? "-"}
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between my-1">
                                                    <div className="fs-12 pl-2 secondary-dark-10 font-family-semibold">
                                                        Typical Latency
                                                    </div>
                                                    <div className="fs-12 font-family-bold secondary-dark-10">
                                                        {info?.latency ?? "-"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <hr className="my-2 border-bottom border-bottom-nutri  border-dark" />
                                    <div className="fs-12 d-flex justify-content-between mt-2">
                                        <div className="secondary-dark-18 fs-13 font-family-bold">
                                            Data Included with Monthly Price
                                        </div>
                                        {plan?.planSubType == "HIGH_SPEED" ? (
                                            <div className="secondary-dark-10 font-family-bold">
                                                {plan?.planData ?? 0} {plan?.dataUnit ?? "GB"}
                                            </div>
                                        ) : plan?.planSubType == "PREMIUM" ? (
                                            <div className="secondary-dark-10 text-right fs-11 font-family-bold">
                                                {plan?.planData ?? 0} {plan?.dataUnit ?? "GB"}
                                            </div>
                                        ) : (
                                            <div className="secondary-dark-10 font-family-bold">{plan.name ?? "-"}</div>
                                        )}
                                    </div>
                                    <hr className="my-2 border-bottom border-bottom-nutri  border-dark" />
                                    <div className="fs-12 d-flex justify-content-between mt-1">
                                        <div className="font-family-bold secondary-dark-18 text-nowrap">
                                            Network Management
                                        </div>

                                        <div className="secondary-dark-10 cursor-pointer text-nowrap font-family-semibold">
                                            <span
                                                style={{
                                                    textDecoration: "underline",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    window.open(
                                                        "https://4888946.fs1.hubspotusercontent-na1.net/hubfs/4888946/Verizon_Reach%20Mobile_MVNO%20Website_Disclosure.pdf",
                                                        "_blank",
                                                        "noreferrer"
                                                    )
                                                }>
                                                Read our Policy
                                            </span>
                                        </div>
                                    </div>
                                    <div className="fs-12 d-flex justify-content-between mt-1">
                                        <div className="font-family-bold secondary-dark-18">Privacy</div>
                                        <div className="secondary-dark-10 cursor-pointer font-family-semibold">
                                            {" "}
                                            <span
                                                style={{
                                                    textDecoration: "underline",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    window.open(
                                                        "https://www.4g.reachmobile.com/privacy",
                                                        "_blank",
                                                        "noreferrer"
                                                    )
                                                }>
                                                Read our Policy
                                            </span>
                                        </div>
                                    </div>
                                    <hr className="my-2 border-bottom border-bottom-nutri  border-dark" />
                                    <div className="fs-13 mt-2 font-family-bold secondary-dark-18">
                                        Customer Support
                                    </div>
                                    <div className="fs-12  secondary-dark-10">
                                        <div className="my-1 font-family-semibold">Contact Us: </div>
                                        <div
                                            style={{ textDecoration: "underline" }}
                                            className="text-cta mr-1 secondary-dark-10">
                                            reach@reachmobile.com
                                        </div>
                                        <div className="my-1 font-family-semibold">844-445-3665</div>
                                    </div>
                                    <hr className="my-2 border-bottom border-bottom-nutri border-dark" />
                                    <div className="fs-12 mt-2 secondary-dark-10">
                                        Learn more about the terms used on this label by visiting the Federal
                                        Communications Commission Consumer Resource Center.
                                    </div>
                                    <span
                                        className="text-cta fs-13 font-family-bold secondary-dark-18 text-right w-100"
                                        style={{ display: "inline-block", cursor: "pointer" }}
                                        onClick={() => window.open("https://fcc.gov/consumer", "_blank", "noreferrer")}>
                                        fcc.gov/consumer
                                    </span>
                                    <hr className="my-2 border-bottom border-bottom-nutri border-dark" />
                                    <div className="fs-13 mt-2 secondary-dark-18 pb-2 font-family-semibold">
                                        Unique Plan Identifier: <br />
                                        {plan.uniqueIdentifier ?? "-"}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </Modal>
    );
}

export default function ReachPlanCard(props: ReachPlanCardProps) {
    const { ...customerProfile } = props;

    const [showChangePlanModal, setShowChangePlanModal] = useState(false);

    const { hasOnePermission } = usePermissions();

    if (!customerProfile.reachPlan) {
        return null;
    }

    let numOfExtraLines = 0;
    if (customerProfile.extraLines > customerProfile.additionalLines) {
        numOfExtraLines = customerProfile.extraLines - customerProfile.additionalLines;
    }
    const lines = customerProfile.groupLines.length + numOfExtraLines;

    if (!hasOnePermission("user_management.reach_plan.read")) {
        return null;
    }

    return (
        <Card>
            <div className="font-family-bold align-self-start" style={{ fontSize: 16 }}>
                Plan details
            </div>
            <div className="font-family-bold my-2" style={{ fontSize: 16 }}>
                {customerProfile.reachPlan.name} {customerProfile.reachPlan.planData}
                {customerProfile.reachPlan.dataUnit} -{" "}
                <span className="font-family-medium">
                    for {lines} {lines > 1 ? "lines" : "line"}
                </span>
            </div>
            {customerProfile.isPrimary && (
                <Button
                    color="secondary"
                    className="col-10"
                    disabled={!hasOnePermission("user_management.reach_plan.change")}
                    onClick={() => setShowChangePlanModal(true)}>
                    Change Plan
                </Button>
            )}
            {customerProfile.isPrimary && isPostActivationState(customerProfile.status) && (
                <Link to={`/plan/history/${customerProfile.id}`} className="text-cta mt-3">
                    View details
                </Link>
            )}
            {showChangePlanModal && (
                <ChangePlanModal
                    customerProfile={customerProfile}
                    title="Change plan"
                    show={showChangePlanModal}
                    onHide={() => setShowChangePlanModal(false)}
                />
            )}
        </Card>
    );
}
