import React, { useState } from "react";
import { Card } from "./card";
import { OrderStatus, ShipmentOrder } from "../utils/types";
import { format } from "date-fns";
import classNames from "classnames";
import cancelIcon from "../images/cancel-icon.png";
import Modal from "./modal";
import { Button } from "./button";
import Select from "./select";
import { useForm } from "react-hook-form";
import { cancelOrder, fetchProfile } from "../features/profile-slice";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import Toggle from "./toggle";
import { usePermissions } from "../features/hooks/use-permissions";
import { useSelector } from "react-redux";
import { useErrorHandler } from "./error-snackbar";

export interface ShipmentOrderCardProps extends ShipmentOrder {
    customerId: string;
}

const positionMapping: Record<string, number> = {
    ORDERED: 0,
    LABELLED: 1,
    SHIPPED: 2,
    INTRANSIT: 2,
    DELIVERED: 3,
};

const cancellationReasons = [
    "Accidental duplicate purchase (already customer)",
    "CS cancelled order (wrong address, bad history)",
    "Decided to stay with existing provider",
    "Device locked",
    "Unresponsive customer",
    "Device not compatible",
    "Did not mean to purchase",
    "Found a plan + phone deal",
    "Found cheaper plan",
    "Locked in contract",
    "Other",
    "Won't receive SIM in time",
    "Changed mind for any reason - primary line",
    "Changed mind for any reason - secondary line",
    "Wanted to but device incompatible",
    "Wasn't real account - test or similar",
    "Isn't ready yet - reason unknown",
    "Isn't ready yet - billing cycle",
    "Very low coverage",
    "Thought we were Verizon",
    "Thought it included a phone",
    "Features not available",
    "Wanted unlimited hotspot",
    "Card Hold Failure",
];

export default function ShipmentOrderCard(props: ShipmentOrderCardProps) {
    const { customerId, ...shipmentOrder } = props;

    const position = positionMapping[shipmentOrder.orderStatus];

    const { hasOnePermission } = usePermissions();

    const [showCancelModal, setShowCancelModal] = useState(false);

    const { handleSubmit, errors, register, formState } = useForm({
        mode: "onBlur",
    });

    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const dispatch = useAppDispatch();

    if (shipmentOrder.orderStatus === OrderStatus.CANCELLED && shipmentOrder.orderCanceledOn) {
        return (
            <Card className="mt-3">
                <img src={cancelIcon} />
                <div className="font-family-semibold">
                    Order cancelled on {format(new Date(shipmentOrder.orderCanceledOn), "MMMM dd, yyyy")} at{" "}
                    {format(new Date(shipmentOrder.orderCanceledOn), "hh:mm a")}
                </div>
            </Card>
        );
    }

    return (
        <Card className="mt-3">
            <div className="font-family-bold align-self-start" style={{ fontSize: 16 }}>
                Order status
            </div>
            <div className="w-100 d-flex justify-content-between align-items-center">
                <span style={{ fontSize: 12 }}>
                    <span className="font-family-semibold">Order No:</span> {shipmentOrder.orderId}
                </span>
                <div className="flex-column">
                    <div
                        className={classNames("text-cta text-right", {
                            disabled: !hasOnePermission("order_management.shipment.cancel"),
                        })}
                        onClick={() =>
                            hasOnePermission("order_management.shipment.cancel") && setShowCancelModal(true)
                        }>
                        Cancel Order
                    </div>
                    {shipmentOrder.trackingNumber && (
                        <div style={{ fontSize: 12 }}>
                            <span className="font-family-bold">Track your order:</span>{" "}
                            {shipmentOrder.trackingUrl ? (
                                <a
                                    href={shipmentOrder.trackingUrl}
                                    target="_blank"
                                    className="text-cta"
                                    rel="noreferrer">
                                    {shipmentOrder.trackingNumber}
                                </a>
                            ) : (
                                shipmentOrder.trackingNumber
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="w-100 row row-cols-2 row-cols-md-4 mt-3 mx-n2">
                <div className="col px-2">
                    <div className="d-flex flex-column align-items-center text-center">
                        <div className={classNames(position >= 0 ? "order-step-complete" : "order-step-incomplete")} />
                        <div className="font-family-bold mt-1" style={{ fontSize: 12 }}>
                            Order received
                        </div>
                        <div
                            className={classNames("align-items-center", { disabled: position < 0 })}
                            style={{ fontSize: 12 }}>
                            {format(new Date(shipmentOrder.orderDate), "hh:mm a")}
                        </div>
                        <div
                            className={classNames("align-items-center", { disabled: position < 0 })}
                            style={{ fontSize: 12 }}>
                            {format(new Date(shipmentOrder.orderDate), "MMMM d yyyy")}
                        </div>
                        <div
                            className={classNames("align-items-center", { disabled: position < 0 })}
                            style={{ fontSize: 12 }}>
                            SIM cards: {shipmentOrder.items[0].quantity}
                        </div>
                    </div>
                </div>
                <div className="col px-2">
                    <div className="d-flex flex-column align-items-center text-center">
                        <div className={classNames(position >= 1 ? "order-step-complete" : "order-step-incomplete")} />
                        <div className="font-family-bold mt-1" style={{ fontSize: 12 }}>
                            {shipmentOrder.labelledOn ? "Processed" : "In process"}
                        </div>
                        <div
                            className={classNames("align-items-center", { disabled: position < 1 })}
                            style={{ fontSize: 12 }}>
                            {shipmentOrder.labelledOn
                                ? "Your Welcome Kit will be hitting the mail soon"
                                : "We're packaging up your Welcome Kit now"}
                        </div>
                        {shipmentOrder.labelledOn && (
                            <>
                                <div
                                    className={classNames("align-items-center", {
                                        disabled: position < 1,
                                    })}
                                    style={{ fontSize: 12 }}>
                                    {format(new Date(shipmentOrder.labelledOn), "hh:mm a")}
                                </div>
                                <div
                                    className={classNames("align-items-center", {
                                        disabled: position < 1,
                                    })}
                                    style={{ fontSize: 12 }}>
                                    {format(new Date(shipmentOrder.labelledOn), "MMMM d yyyy")}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="col px-2 mt-3 mt-md-0">
                    <div className="d-flex flex-column align-items-center text-center">
                        <div className={classNames(position >= 2 ? "order-step-complete" : "order-step-incomplete")} />
                        <div className="font-family-bold mt-1" style={{ fontSize: 12 }}>
                            Order shipped
                        </div>
                        <div
                            className={classNames("align-items-center", { disabled: position < 2 })}
                            style={{ fontSize: 12 }}>
                            Your order is on the way!
                        </div>
                        {shipmentOrder.shippedOn && (
                            <>
                                <div
                                    className={classNames("align-items-center", {
                                        disabled: position < 2,
                                    })}
                                    style={{ fontSize: 12 }}>
                                    {format(new Date(shipmentOrder.shippedOn), "hh:mm a")}
                                </div>
                                <div
                                    className={classNames("align-items-center", {
                                        disabled: position < 2,
                                    })}
                                    style={{ fontSize: 12 }}>
                                    {format(new Date(shipmentOrder.shippedOn), "MMMM d yyyy")}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="col px-2 mt-3 mt-md-0">
                    <div className="d-flex flex-column align-items-center text-center">
                        <div className={classNames(position >= 3 ? "order-step-complete" : "order-step-incomplete")} />
                        <div className="font-family-bold mt-1" style={{ fontSize: 12 }}>
                            {shipmentOrder.deliveredOn ? "Delivered" : "Delivery scheduled"}
                        </div>
                        {shipmentOrder.deliveredOn ? (
                            <>
                                <div
                                    className={classNames("align-items-center", {
                                        disabled: position < 3,
                                    })}
                                    style={{ fontSize: 12 }}>
                                    {format(new Date(shipmentOrder.deliveredOn), "hh:mm a")}
                                </div>
                                <div
                                    className={classNames("align-items-center", {
                                        disabled: position < 3,
                                    })}
                                    style={{ fontSize: 12 }}>
                                    {format(new Date(shipmentOrder.deliveredOn), "MMMM d yyyy")}
                                </div>
                            </>
                        ) : (
                            <div
                                className={classNames("align-items-center", { disabled: position < 3 })}
                                style={{ fontSize: 12 }}>
                                Arriving{" "}
                                {shipmentOrder.estimatedDeliveryDate
                                    ? format(new Date(shipmentOrder.estimatedDeliveryDate), "MMMM d")
                                    : ""}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Modal
                title="Do you want to cancel the order?"
                show={showCancelModal}
                onHide={() => setShowCancelModal(false)}>
                <Select
                    className="mb-3 col-12"
                    register={register({
                        required: {
                            value: true,
                            message: "Reason is required",
                        },
                    })}
                    name="reason"
                    showError={errors.reason}
                    errorMessage={errors.reason?.message}>
                    <option value="">Select the reason</option>
                    {cancellationReasons.map((reason) => (
                        <option key={reason} value={reason}>
                            {reason}
                        </option>
                    ))}
                </Select>
                <Toggle
                    className="align-self-start mx-3 mb-3"
                    id="reimburse"
                    name="reimburse"
                    label="Initiate reimbursement"
                    register={register}
                    defaultChecked={true}
                />
                <Button
                    color="secondary"
                    className="col-12 col-md-6"
                    loading={formState.isSubmitting}
                    onClick={handleSubmit(async (data: { reason: string; reimburse: boolean }) => {
                        try {
                            await unwrap(
                                cancelOrder({
                                    customerId,
                                    orderIds: [shipmentOrder.orderId],
                                    ...data,
                                })
                            );
                            await dispatch(fetchProfile(customerId));
                        } catch (error) {
                            console.log(error);
                            handleError(error);
                        }
                        setShowCancelModal(false);
                    })}>
                    Confirm
                </Button>
            </Modal>
        </Card>
    );
}
