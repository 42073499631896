import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Link, navigate } from "gatsby";
import { Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import CustomerBadge from "../components/customer-badge";
import { useErrorHandler } from "../components/error-snackbar";
import Layout from "../components/layout";
import { fetchProfile, getPortoutInfo, profilesSelector, updatePortout } from "../features/profile-slice";
import { useAppDispatch, useAppSelector, useUnwrapAsyncThunk } from "../utils/store";
import { Button } from "../components/button";
import { useViewport, ViewportBreakpoint } from "../utils/viewport-context";
import classNames from "classnames";
import { CustomerProfile, PortoutInfo, PortoutStatus, PortRejectReason, UpdatePortoutType } from "../utils/types";
import Modal, { ModalProps } from "../components/modal";
import Select from "../components/select";
import { formatPhone } from "../utils/helpers";

export interface PortOutDetailsProps extends RouteComponentProps {
    customerId: string;
}

interface FormData {
    reason: PortRejectReason;
}

export default function PortOutDetails(props: PortOutDetailsProps) {
    const { customerId } = props;

    const customerProfile = useAppSelector((state) => profilesSelector.selectById(state, customerId));

    const { handleSubmit, register, control, formState, errors } = useForm<FormData>({
        mode: "onBlur",
    });

    const { width } = useViewport();

    const [portoutInfo, setPortoutInfo] = useState<PortoutInfo>();
    const [loading, setLoading] = useState(false);

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    useEffect(() => {
        async function fetchPortOutDetails() {
            setLoading(true);
            try {
                const res = await unwrap(getPortoutInfo(customerId));
                setPortoutInfo(res);
            } catch (error) {
                console.log(error);
                if (error.resCode === 40229) {
                    setPortoutInfo({});
                } else {
                    handleError(error);
                }
            }
            setLoading(false);
        }
        fetchPortOutDetails();
    }, []);

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    const onCancel = async (data: FormData) => {
        try {
            await unwrap(
                updatePortout({
                    customerId,
                    payload: {
                        responseType: UpdatePortoutType.RESOLUTION_REQUIRED,
                        reasonCode: data.reason,
                    },
                })
            );
            await dispatch(fetchProfile(customerId));
            navigate(`/profile/${customerId}`);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const portOutAddress = [portoutInfo?.address2, portoutInfo?.address1, portoutInfo?.city, portoutInfo?.state]
        .filter(Boolean)
        .join(", ");

    return (
        <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - Port out details`}>
            <div className="container col-10 d-flex flex-column my-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to={`/profile/${customerId}`}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>
                <h2>Port out details</h2>
                {loading ? (
                    <div className="spinner-border text-primary align-self-center" />
                ) : (
                    <Card>
                        <div className="font-family-semibold mt-3 ml-3 pl-3">
                            <span className="font-family-bold">MDN:</span> {formatPhone(customerProfile.reachNumber)}
                        </div>
                        <div className="row m-3 row-cols-1 row-cols-md-2">
                            <div className="col pr-0">
                                <div className="font-family-bold" style={{ fontSize: 16 }}>
                                    Current value
                                </div>
                                <div className={width >= ViewportBreakpoint.MD ? "border-right" : ""}>
                                    <div>
                                        <div>Account number</div>
                                        <div className="font-family-semibold">
                                            {customerProfile.customerNumber ?? "-"}
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <div>Billing address</div>
                                        <div className="font-family-semibold" style={{ width: 200 }}>
                                            {Object.entries(customerProfile.addresses[0])
                                                .map(
                                                    ([key, value]) =>
                                                        key !== "name" &&
                                                        key !== "type" &&
                                                        key !== "zip" &&
                                                        key !== "phone" &&
                                                        value
                                                )
                                                .filter(Boolean)
                                                .join(", ")}
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <div>Billing zip code</div>
                                        <div className="font-family-semibold">{customerProfile.zipcode}</div>
                                    </div>
                                    <div className="mt-2">
                                        <div>Pin</div>
                                        <div className="font-family-semibold">{customerProfile.zipcode}</div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={classNames("col", {
                                    "mt-2 pt-3 border-top": width < ViewportBreakpoint.MD,
                                })}>
                                <div className="font-family-bold" style={{ fontSize: 16 }}>
                                    Requested value
                                </div>
                                <div>
                                    <div>
                                        Account number
                                        <span
                                            style={{ fontSize: 12 }}
                                            className={classNames(
                                                "ml-2",
                                                customerProfile.customerNumber === portoutInfo?.accountNumber
                                                    ? "reach-cs-tick text-primary"
                                                    : "reach-cs-cross text-error"
                                            )}
                                        />
                                    </div>
                                    <div className="font-family-semibold">{portoutInfo?.accountNumber ?? "-"}</div>
                                </div>
                                <div className="mt-2">
                                    <div>
                                        Billing address
                                        <span
                                            style={{ fontSize: 12 }}
                                            className={classNames(
                                                "ml-2",
                                                customerProfile.addresses[0].address1 === portoutInfo?.address1 &&
                                                    customerProfile.addresses[0].city === portoutInfo?.city &&
                                                    customerProfile.addresses[0].state === portoutInfo?.state
                                                    ? "reach-cs-tick text-primary"
                                                    : "reach-cs-cross text-error"
                                            )}
                                        />
                                    </div>
                                    <div className="font-family-semibold" style={{ width: 200 }}>
                                        {portOutAddress ? `${portOutAddress}, USA` : "-"}
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <div>
                                        Billing zip code
                                        <span
                                            style={{ fontSize: 12 }}
                                            className={classNames(
                                                "ml-2",
                                                customerProfile.zipcode === portoutInfo?.zipCode
                                                    ? "reach-cs-tick text-primary"
                                                    : "reach-cs-cross text-error"
                                            )}
                                        />
                                    </div>
                                    <div className="font-family-semibold">{portoutInfo?.zipCode ?? "-"}</div>
                                </div>
                                <div className="mt-2">
                                    <div>
                                        Pin
                                        <span
                                            style={{ fontSize: 12 }}
                                            className={classNames(
                                                "ml-2",
                                                customerProfile.zipcode === portoutInfo?.pin
                                                    ? "reach-cs-tick text-primary"
                                                    : "reach-cs-cross text-error"
                                            )}
                                        />
                                    </div>
                                    <div className="font-family-semibold">{portoutInfo?.pin ?? "-"}</div>
                                </div>
                            </div>
                        </div>
                        {portoutInfo?.status === PortoutStatus.RESOLUTIONREQUIRED && (
                            <div className="text-center mb-3 mx-3">
                                The MDN:{" "}
                                <span className="font-family-semibold">{formatPhone(customerProfile.reachNumber)}</span>{" "}
                                is in Port out rejected state with reason:{" "}
                                <span className="font-family-semibold">"{portoutInfo.reasonDescription}"</span>. Please
                                wait for new carrier to respond.
                            </div>
                        )}
                        {portoutInfo?.status === PortoutStatus.DELAYED && (
                            <>
                                <Button
                                    type="submit"
                                    color="secondary"
                                    disabled={portoutInfo == null}
                                    loading={formState.isSubmitting}
                                    onClick={() => setShowConfirmModal(true)}
                                    className="mb-3 col-8 col-md-4 align-self-center">
                                    Confirm
                                </Button>
                                <div
                                    className="text-cta align-self-center mb-3"
                                    onClick={() => setShowCancelModal(true)}>
                                    Resolution required
                                </div>
                            </>
                        )}
                    </Card>
                )}
            </div>
            {showCancelModal && (
                <Modal
                    title="Reject port-out?"
                    size="sm"
                    show={showCancelModal}
                    onHide={() => setShowCancelModal(false)}>
                    <Select
                        className="mb-3 w-100"
                        register={register({
                            required: {
                                value: true,
                                message: "Reason is required",
                            },
                        })}
                        name="reason"
                        showError={errors.reason != null}
                        errorMessage={errors.reason?.message}>
                        <option value="">Select reason</option>
                        <option value={PortRejectReason.CUSTOMER_INFORMATION_DOES_NOT_MATCH}>
                            Customer information does not match
                        </option>
                        <option value={PortRejectReason.ACCOUNT_NUMBER_REQUIRED_OR_INCORRECT}>
                            Account number required or incorrect
                        </option>
                        <option value={PortRejectReason.SSN_TAX_ID_REQUIRED_OR_INCORRECT}>
                            Ssn tax id required or incorrect
                        </option>
                        <option value={PortRejectReason.PASSWORD_PIN_REQUIRED_OR_INCORRECT}>
                            Password pin required or incorrect
                        </option>
                        <option value={PortRejectReason.ZIP_CODE_REQUIRED_OR_INCORRECT}>
                            Zip code required or incorrect
                        </option>
                        <option value={PortRejectReason.FIRST_NAME_REQUIRED_OR_INCORRECT}>
                            First name required or incorrect
                        </option>
                        <option value={PortRejectReason.LAST_NAME_REQUIRED_OR_INCORRECT}>
                            Last name required or incorrect
                        </option>
                        <option value={PortRejectReason.BUSINESS_NAME_REQUIRED_OR_INCORRECT}>
                            Business name required or incorrect
                        </option>
                        <option value={PortRejectReason.MDN_NOT_ACTIVE}>Mdn not active</option>
                        <option value={PortRejectReason.PORT_COMPLEXITY}>Port complexity</option>
                        <option value={PortRejectReason.OTHER}>Other</option>
                    </Select>
                    <Button
                        color="secondary"
                        fullWidth
                        loading={formState.isSubmitting}
                        onClick={handleSubmit(onCancel)}>
                        Confirm
                    </Button>
                </Modal>
            )}
            {showConfirmModal && portoutInfo && (
                <PortConfirmModal
                    customerId={customerId}
                    customerProfile={customerProfile}
                    portoutInfo={portoutInfo}
                    title="Confirm port-out?"
                    size="sm"
                    show={showConfirmModal}
                    onHide={() => setShowConfirmModal(false)}
                />
            )}
        </Layout>
    );
}
function PortConfirmModal(
    props: ModalProps & { customerId: string; customerProfile: CustomerProfile; portoutInfo: PortoutInfo }
) {
    const { customerId, customerProfile, portoutInfo, ...rest } = props;

    const { handleSubmit, formState } = useForm<FormData>({
        mode: "onBlur",
    });

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const onSubmit = async () => {
        try {
            await unwrap(
                updatePortout({
                    customerId,
                    payload: {
                        responseType: UpdatePortoutType.CONFIRMED,
                    },
                })
            );
            await dispatch(fetchProfile(customerId));
            navigate(`/profile/${customerId}`);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        rest.onHide();
    };

    const isAccountNumberValid = customerProfile.customerNumber === portoutInfo.accountNumber;
    const isAddressValid =
        customerProfile.addresses[0].address1 === portoutInfo.address1 &&
        customerProfile.addresses[0].city === portoutInfo.city &&
        customerProfile.addresses[0].state === portoutInfo.state;
    const isZipValid = customerProfile.zipcode === portoutInfo.zipCode;
    const isPinValid = customerProfile.zipcode === portoutInfo.pin;

    return (
        <Modal {...rest}>
            {isAccountNumberValid && isAddressValid && isZipValid && isPinValid ? (
                <div className="font-family-semibold">All fields match</div>
            ) : (
                <div className="font-family-semibold mb-3">Some fields don't match</div>
            )}
            <div className="align-self-start mb-3">
                {!isAccountNumberValid && (
                    <div>
                        <div>
                            Account number
                            <span style={{ fontSize: 11 }} className="reach-cs-cross text-error ml-2" />
                        </div>
                        <div className="font-family-semibold">{portoutInfo?.accountNumber ?? "-"}</div>
                    </div>
                )}
                {!isAddressValid && (
                    <div className="mt-2">
                        <div>
                            Billing address
                            <span style={{ fontSize: 11 }} className="reach-cs-cross text-error ml-2" />
                        </div>
                        <div className="font-family-semibold" style={{ width: 200 }}>
                            {portoutInfo?.address2} {portoutInfo?.address1},{"\n"}
                            {portoutInfo?.city}, {portoutInfo?.state}, USA
                        </div>
                    </div>
                )}
                {!isZipValid && (
                    <div className="mt-2">
                        <div>
                            Billing zip code
                            <span style={{ fontSize: 11 }} className="reach-cs-cross text-error ml-2" />
                        </div>
                        <div className="font-family-semibold">{portoutInfo?.zipCode ?? "-"}</div>
                    </div>
                )}
                {!isPinValid && (
                    <div className="mt-2">
                        <div>
                            Pin
                            <span style={{ fontSize: 11 }} className="reach-cs-cross text-error ml-2" />
                        </div>
                        <div className="font-family-semibold">
                            {customerProfile?.portOutInfo && customerProfile?.portOutInfo.generatedPin
                                ? customerProfile?.portOutInfo.generatedPin
                                : customerProfile.zipcode}
                        </div>
                    </div>
                )}
            </div>
            <Button color="secondary" fullWidth loading={formState.isSubmitting} onClick={handleSubmit(onSubmit)}>
                Confirm
            </Button>
        </Modal>
    );
}
