import React, { useState } from "react";
import { ReachPlan } from "../utils/types";
import { Button } from "./button";
import { Card } from "./card";
import classNames from "classnames";

export interface PlanCardProps extends ReachPlan {
    ctaLabel: string;
    disabled?: boolean;
    loading?: boolean;
    lines: number;
    onClick: () => void;
}

export default function PlanCard(props: PlanCardProps) {
    const {
        name,
        planData,
        dataUnit,
        baseLinePrice,
        discountPctg,
        additionalLinePrice,
        addLineSplitPrice,
        isUnlimited,
        maxLines,
        ctaLabel,
        lines,
        disabled = false,
        loading = false,
        onClick,
    } = props;

    const [expanded, setExpanded] = useState(false);

    const actualPrice = ((100 - discountPctg) * baseLinePrice) / 100;

    let prices = 0;
    const additionalLines = lines - 1;
    if (addLineSplitPrice) {
        for (let i = 1; i <= additionalLines; i++) {
            prices = prices + addLineSplitPrice[`${i}`];
        }
    } else {
        prices = additionalLines * additionalLinePrice;
    }
    const totalPrice = actualPrice + prices;

    return (
        <Card className="text-center p-0" disabled={disabled}>
            <div className="card-body px-2 py-0 pt-2">
                <h6>{name}</h6>
                <div>
                    <span className="h5">
                        {planData}
                        {dataUnit}
                    </span>
                    <span> - {isUnlimited ? "Per line" : "Sharable data"}</span>
                </div>
                <div className="thick-divider bg-secondary my-3 mx-auto" />
                {lines > 1 && (
                    <div className="text-primary mb-2">
                        <span className="font-family-bold" style={{ fontSize: 20 }}>
                            ${disabled ? "--" : totalPrice}
                        </span>
                        <span className="font-family-medium" style={{ fontSize: 14 }}>
                            /Mo for {lines} lines
                        </span>
                    </div>
                )}
                <div>
                    <span className="h5">${disabled ? "--" : actualPrice}</span>
                    <span className="font-family-bold" style={{ fontSize: 14 }}>
                        /Month (+ taxes & fees)
                    </span>
                </div>

                <Button
                    color="secondary"
                    fullWidth
                    className="mt-4"
                    disabled={disabled}
                    loading={loading}
                    onClick={onClick}>
                    {ctaLabel}
                </Button>

                {expanded && (
                    <div className="w-100 text-left mt-3">
                        <div className="font-family-semibold">Extras you'll love</div>
                        <div className="font-family-semibold mt-2">
                            <span className="reach-cs-no-contract mr-2" style={{ fontSize: 16 }} />
                            No Contract
                        </div>
                        <div className="font-family-semibold mt-2">
                            <span className="reach-cs-info mr-2" style={{ fontSize: 16 }} />
                            24/7 Helpful humans
                        </div>
                        <div className="font-family-bold mt-3">Features you need</div>
                        <div className="font-family-medium" style={{ fontSize: 12, lineHeight: 2.3 }}>
                            <span className="reach-cs-tick text-secondary mr-2" />
                            Unlimited talk and text
                        </div>
                        <div className="font-family-medium" style={{ fontSize: 12, lineHeight: 2.3 }}>
                            <span className="reach-cs-tick text-secondary mr-2" />
                            Hotspot enabled
                        </div>
                        <div className="font-family-medium" style={{ fontSize: 12, lineHeight: 2.3 }}>
                            <span className="reach-cs-tick text-secondary mr-2" />
                            Up to {maxLines} lines
                        </div>
                        <div className="font-family-medium" style={{ fontSize: 12, lineHeight: 2.3 }}>
                            <span className="reach-cs-tick text-secondary mr-2" />
                            HD streaming
                        </div>
                        <div className="font-family-medium" style={{ fontSize: 12, lineHeight: 2.3 }}>
                            <span className="reach-cs-tick text-secondary mr-2" />
                            Keep your number (optional)
                        </div>
                        <div className="font-family-medium" style={{ fontSize: 12, lineHeight: 2.3 }}>
                            <span className="reach-cs-tick text-secondary mr-2" />
                            International Services (rates apply)
                        </div>
                    </div>
                )}
            </div>

            <div className="mt-3 divider" />
            <div
                className="w-100 px-4 py-2 d-flex align-items-center justify-content-between cursor-pointer"
                onClick={() => setExpanded(!expanded)}>
                <span className="text-secondary font-family-bold">{expanded ? "Hide details" : "View details"}</span>
                <span
                    className={classNames("text-secondary", {
                        "reach-cs-arrow-down-solid": !expanded,
                        "reach-cs-arrow-up-solid": expanded,
                    })}
                    style={{ fontSize: 8 }}
                />
            </div>
        </Card>
    );
}
