import React, { DetailedHTMLProps } from "react";
import classNames from "classnames";
import { formatPhone } from "../utils/helpers";

export interface InputProps extends DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    icon?: string;
    iconPosition?: "left" | "right";
    errorMessage?: string;
    showError?: boolean;
    register?: any;
    characterRestriction?: number;
    inputClassName?: string;
    mask?: "phone";
}

export function Input(props: InputProps) {
    const {
        icon,
        iconPosition = "left",
        className,
        showError = false,
        errorMessage: error,
        register,
        characterRestriction,
        mask,
        value,
        maxLength,
        inputClassName,
        ...rest
    } = props;

    if ((characterRestriction || mask) && rest.defaultValue) {
        console.warn("Use controlled input component when using `characterRestriction`");
    }

    return (
        <div className={classNames("position-relative", className)}>
            {icon && (
                <span
                    className={classNames(
                        icon,
                        iconPosition === "left" ? "form-control-icon" : "form-control-icon-right"
                    )}
                />
            )}
            <input
                {...rest}
                maxLength={mask === "phone" ? 14 : maxLength}
                ref={register}
                value={mask && value ? formatPhone(value.toString()) : value}
                onChange={(event) => {
                    console.log(rest);
                    if (mask === "phone" || rest.name === "imei" || rest.name === "simId") {
                        event.target.value = event.target.value.replace(/(\D)/g, "");
                    }
                    rest.onChange?.(event);
                }}
                className={classNames("form-control", inputClassName, {
                    "pl-5": icon && iconPosition === "left",
                    "is-invalid": showError,
                })}
            />
            <div className="d-flex">
                {showError && error && <div className="d-block invalid-feedback font-family-medium">{error}</div>}
                {characterRestriction && (
                    <small
                        className={classNames("form-text ml-auto", {
                            "text-danger": showError && error,
                        })}>
                        {value?.toString().length}/{characterRestriction}
                    </small>
                )}
            </div>
        </div>
    );
}
