import React from "react";
import { getMonth, getYear } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { Input } from "./input";
import Select from "./select";
import { range } from "lodash";
import { Button } from "./button";
import { CustomerProfile } from "../utils/types";

interface CustomerVerificationProps {
    customerProfile: CustomerProfile;
    onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
}

interface FormData {
    imei: string;
    cardNumber: string;
    month: string;
    year: string;
}

function CustomerVerification(props: CustomerVerificationProps) {
    const { customerProfile } = props;

    const { control, register, errors, formState, handleSubmit, setError } = useForm<FormData>({
        mode: "onBlur",
    });

    const currentYear = getYear(new Date());

    const onSubmit = async (data: FormData) => {
        console.log(data);
        let numOfInputs = 0;
        let correctInputs = 0;
        if (data.imei !== "") {
            numOfInputs++;
        }
        if (data.cardNumber !== "") {
            numOfInputs++;
        }

        if (data.month !== "" && data.year !== "") {
            numOfInputs++;
        }

        if (numOfInputs < 2) {
            setError("year", { message: "2 out of the 3 inputs required" });
            return;
        }

        if (data.imei === customerProfile.imei) {
            correctInputs++;
        }

        if (data.cardNumber === customerProfile.creditCards?.[0].ccNumberMask) {
            correctInputs++;
        }

        if (`${data.month}${data.year.substring(2)}` === customerProfile.creditCards?.[0].dateOfExpiry) {
            correctInputs++;
        }

        if (correctInputs < 2 && data.imei !== "" && data.imei !== customerProfile.imei) {
            setError("imei", { message: "IMEI does not match" });
            return;
        }

        if (
            correctInputs < 2 &&
            data.cardNumber !== "" &&
            data.cardNumber !== customerProfile.creditCards?.[0].ccNumberMask
        ) {
            setError("cardNumber", { message: "Card number does not match" });
            return;
        }

        if (
            correctInputs < 2 &&
            data.month !== "" &&
            data.year !== "" &&
            `${data.month}${data.year.substring(2)}` !== customerProfile.creditCards?.[0].dateOfExpiry
        ) {
            setError("year", { message: "Expiry date does not match" });
            return;
        }

        console.log("valid input");
        await props.onSubmit();
    };

    return (
        <form className="w-100 mt-3 d-flex flex-column align-items-center" onSubmit={handleSubmit(onSubmit)}>
            <div className="font-family-bold" style={{ fontSize: 16 }}>
                Please verify the details
            </div>
            <div className="font-family-semibold mt-2">Please verify 2 out of 3 entries below</div>
            <Controller
                control={control}
                rules={{
                    pattern: {
                        value: /^\d{14,15}$/,
                        message: "IMEI must be 14 or 15 digits.",
                    },
                }}
                name="imei"
                defaultValue=""
                render={({ onChange, onBlur, value, name }) => (
                    <Input
                        type="text"
                        inputMode="numeric"
                        placeholder="Enter IMEI"
                        className="col mt-3 px-0"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                        maxLength={15}
                        characterRestriction={15}
                        showError={errors.imei != null}
                        errorMessage={errors.imei?.message}
                    />
                )}
            />
            <Input
                type="text"
                inputMode="numeric"
                name="cardNumber"
                defaultValue=""
                placeholder="Credit card number (last 4 digits)"
                className="col mt-2 px-0"
                maxLength={4}
                register={register}
                showError={errors.cardNumber != null}
                errorMessage={errors.cardNumber?.message}
            />
            <div className="mt-3 align-self-start">
                <div className="font-family-semibold">Expiry date</div>
                <div className="d-flex">
                    <Select defaultValue="" register={register} name="month">
                        <option value="">MM</option>
                        {range(1, 13).map((num) => {
                            const month = ("0" + num).slice(-2);
                            return (
                                <option key={month} value={month}>
                                    {month}
                                </option>
                            );
                        })}
                    </Select>
                    <Select defaultValue="" className="ml-3" name="year" register={register}>
                        <option value="">YYYY</option>
                        {range(currentYear, 2100).map((num) => (
                            <option key={num} value={num}>
                                {num}
                            </option>
                        ))}
                    </Select>
                </div>

                {errors.year && <div className="text-error">{errors.year.message}</div>}
            </div>
            <Button color="secondary" className="mt-3" loading={formState.isSubmitting} fullWidth>
                Submit
            </Button>
        </form>
    );
}

export default CustomerVerification;
