import { addDays, format, isFuture, isPast, isSameDay, max, startOfTomorrow } from "date-fns";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { getBillingHistory, payBill, setPromiseToPayDate } from "../features/billing-slice";
import { CardExpiryState, fetchProfile, ParsedCreditCard } from "../features/profile-slice";
import { usePermissions } from "../features/hooks/use-permissions";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { Bill } from "../utils/types/bill";
import { Button } from "./button";
import { Card } from "./card";
import { useErrorHandler } from "./error-snackbar";
import Modal from "./modal";
import { PaymentReceipt } from "./payment-history-card";
import { useSuccessModal } from "./success-modal";
import { CustomerProfile } from "../utils/types";
import { cardImages, getCardImage } from "./payment-cards";
import classNames from "classnames";
import infoIcon from "../images/info-icon.png";
import { Input } from "./input";

export interface CurrentBillCardProps {
    currentBill: Bill;
    customerProfile: CustomerProfile;
}

interface PtpFormData {
    ptpScheduledDate: Date;
}

export default function CurrentBillCard(props: CurrentBillCardProps) {
    const { currentBill, customerProfile } = props;

    const [showPaymentReceipt, setShowPaymentReceipt] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [addNewCardModal, setAddNewCardModal] = useState(false);
    const [showPtpModal, setShowPtpModal] = useState(false);
    const [showPtpInfoModal, setShowPtpInfoModal] = useState(false);

    const { handleSubmit, formState, register, errors, watch } = useForm();

    const unwrap = useUnwrapAsyncThunk();
    const dispatch = useAppDispatch();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();

    const { hasOnePermission } = usePermissions();

    const [selectedCard, setSelectedCard] = useState(customerProfile.creditCards?.[0]);

    const onSubmit = async () => {
        try {
            await unwrap(
                payBill({ customerId: currentBill.customerId, ccUUID: selectedCard!.uuid, billId: currentBill.id })
            );
            await unwrap(getBillingHistory(currentBill.customerId));
            await dispatch(fetchProfile(currentBill.customerId));
            showSuccessModal("Bill payment successful");
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        setShowModal(false);
    };

    const onSetPromiseToPay = async (data: PtpFormData) => {
        try {
            await unwrap(
                setPromiseToPayDate({
                    customerId: currentBill.customerId,
                    date: format(data.ptpScheduledDate, "yyyy-MM-dd"),
                })
            );
            await unwrap(getBillingHistory(currentBill.customerId));
            await dispatch(fetchProfile(currentBill.customerId));
            showSuccessModal("Pay later date set successfully");
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        setShowPtpModal(false);
    };

    const totalDue =
        currentBill.latePayment && !currentBill.waiveOffLatePaymentFees
            ? (currentBill.amountToPay + currentBill.latePaymentFees).toFixed(2)
            : currentBill.amountToPay?.toFixed(2);

    const totalDueWithLate =
        currentBill.latePaymentFees && !currentBill.waiveOffLatePaymentFees
            ? (currentBill.amountToPay + currentBill.latePaymentFees).toFixed(2)
            : currentBill.amountToPay?.toFixed(2);

    const watchPtpScheduledDate = watch(
        "ptpScheduledDate",
        currentBill.ptpScheduledDate ? new Date(currentBill.ptpScheduledDate) : undefined
    );

    return (
        <Card className="mt-3">
            <div className="font-family-bold align-self-start" style={{ fontSize: 16 }}>
                Total due
            </div>
            <div className="font-family-bold" style={{ fontSize: 24 }}>
                ${totalDue}
            </div>
            <div style={{ fontSize: 12 }} className="font-family-semibold">
                Due on {format(new Date(currentBill.billDueDate), "MMMM dd, yyyy")}
            </div>
            <div style={{ fontSize: 12 }} className="font-family-semibold mt-2">
                Service period
            </div>
            <div style={{ fontSize: 12 }} className="font-family-semibold">
                {format(new Date(currentBill.billStartDate), "MMMM dd, yyyy")} -{" "}
                {format(new Date(currentBill.billEndDate), "MMMM dd, yyyy")}
            </div>
            {customerProfile.ptpEligibility?.isEligible &&
                (currentBill.ptpScheduled && currentBill.ptpScheduledDate ? (
                    <div className="text-grey1 mt-2 font-family-semibold d-flex align-items-center">
                        <img
                            src={infoIcon}
                            height={14}
                            width={14}
                            className="mr-2 cursor-pointer"
                            onClick={() => setShowPtpInfoModal(true)}
                        />
                        Pay later date set to {format(new Date(currentBill.ptpScheduledDate), "MMM dd, yyyy")}
                        {customerProfile.ptpEligibility.toBeDisconnected &&
                            !isPast(new Date(customerProfile.ptpEligibility.toBeDisconnected)) && (
                                <span
                                    className={classNames("text-cta ml-2", {
                                        disabled: !hasOnePermission("billing.promise_to_pay.allow"),
                                    })}
                                    onClick={() =>
                                        hasOnePermission("billing.promise_to_pay.allow") && setShowPtpModal(true)
                                    }>
                                    Edit
                                </span>
                            )}
                    </div>
                ) : (
                    customerProfile.ptpEligibility.toBeDisconnected &&
                    isFuture(new Date(customerProfile.ptpEligibility.toBeDisconnected)) && (
                        <div
                            className={classNames("text-cta mt-2", {
                                disabled: !hasOnePermission("billing.promise_to_pay.allow"),
                            })}
                            onClick={() => hasOnePermission("billing.promise_to_pay.allow") && setShowPtpModal(true)}>
                            Set a Pay Later date
                        </div>
                    )
                ))}
            <div className="text-cta mt-2" onClick={() => setShowPaymentReceipt(true)}>
                View invoice
            </div>
            <Button
                color="secondary"
                disabled={!hasOnePermission("billing.initiate_payment.allow")}
                className="col-10 mt-2"
                onClick={() => setShowModal(true)}>
                Pay now
            </Button>

            {currentBill.paymentFailureCause && (
                <div className="font-family-semibold text-center text-error mt-2">
                    Payment error: {currentBill.paymentFailureCause} ({currentBill.paymentFailureCode})
                </div>
            )}

            {showPaymentReceipt && (
                <PaymentReceipt bill={currentBill} setSelectedBill={() => setShowPaymentReceipt(false)} />
            )}
            <Modal title="Pay now" size="sm" show={showModal} onHide={() => setShowModal(false)}>
                {customerProfile.creditCards?.map((card, index) => (
                    <div
                        key={card.uuid}
                        className="row w-100 align-items-center mt-2 cursor-pointer"
                        onClick={() => setSelectedCard(card)}>
                        {index !== 0 && <div className="divider mb-2" />}
                        {getCardImage(card.type) && (
                            <img
                                src={getCardImage(card.type)}
                                className="align-self-start mt-1"
                                width={45}
                                height={30}
                            />
                        )}
                        <div className="col px-2">
                            <div>XXXX-XXXX-XXXX-{card.ccNumberMask}</div>
                            <div style={{ fontSize: 12 }}>
                                {card.nameOnCard} |{" "}
                                {card.cardExpiryState === CardExpiryState.EXPIRED ? (
                                    <span className="text-danger">Expired date {format(card.parsedDate, "MM/yy")}</span>
                                ) : (
                                    <span
                                        className={classNames({
                                            "text-tertiary2": card.cardExpiryState === CardExpiryState.ALMOST_EXPIRED,
                                        })}>
                                        Expiration {format(card.parsedDate, "MM/yy")}
                                    </span>
                                )}
                            </div>
                        </div>
                        {selectedCard?.uuid === card.uuid && (
                            <span className="reach-cs-tick text-primary align-self-start mt-1" />
                        )}
                    </div>
                ))}
                <Button
                    color="secondary"
                    disabled={
                        !hasOnePermission("billing.initiate_payment.allow") ||
                        selectedCard?.cardExpiryState === CardExpiryState.EXPIRED
                    }
                    fullWidth
                    className="mt-3"
                    loading={formState.isSubmitting}
                    onClick={handleSubmit(onSubmit)}>
                    Confirm
                </Button>
                <div
                    className="mt-3 text-cta text-center"
                    onClick={() => {
                        setShowModal(false);
                        setAddNewCardModal(true);
                    }}>
                    Add new card
                </div>
            </Modal>
            <Modal title="Add new card" size="sm" show={addNewCardModal} onHide={() => setAddNewCardModal(false)}>
                <div className="font-family-semibold text-center">
                    A new card can be added from the saved cards section in the customer profile.
                </div>
            </Modal>

            {customerProfile.ptpEligibility &&
                customerProfile.ptpEligibility.toBeHotlined &&
                customerProfile.ptpEligibility.toBeDisconnected && (
                    <Modal
                        title="What happens if the payment is late?"
                        show={showPtpInfoModal}
                        onHide={() => setShowPtpInfoModal(false)}>
                        <div className="font-family-semibold text-center">
                            If the payment fails:
                            <div className="font-family-bold mt-2">
                                {format(new Date(customerProfile.ptpEligibility.toBeHotlined), "MMM dd, yyyy")} - number
                                will get hotlined
                            </div>
                            <div className="font-family-bold">
                                {format(new Date(customerProfile.ptpEligibility.toBeDisconnected), "MMM dd, yyyy")} -
                                number will get disconnected
                            </div>
                        </div>
                    </Modal>
                )}

            {customerProfile.ptpEligibility &&
                customerProfile.ptpEligibility.isEligible &&
                customerProfile.ptpEligibility.toBeDisconnected && (
                    <Modal
                        title={`Amount to pay $${totalDueWithLate}`}
                        show={showPtpModal}
                        onHide={() => setShowPtpModal(false)}>
                        <div className="w-100 d-flex justify-content-between divider py-3">
                            <div>
                                <div className="font-family-bold" style={{ fontSize: 16 }}>
                                    Last bill charges
                                </div>
                                <div className="font-family-semibold">
                                    {format(new Date(currentBill.billStartDate), "MMM dd, yyyy")} -{" "}
                                    {format(new Date(currentBill.billEndDate), "MMM dd, yyyy")}
                                </div>
                            </div>
                            <div className="font-family-bold" style={{ fontSize: 16 }}>
                                ${currentBill.amountToPay.toFixed(2)}
                            </div>
                        </div>
                        {currentBill.latePaymentFees && !currentBill.waiveOffLatePaymentFees && (
                            <div className="w-100 d-flex justify-content-between divider py-3">
                                <div className="font-family-bold" style={{ fontSize: 16 }}>
                                    Late payment fee
                                </div>
                                <div className="font-family-bold" style={{ fontSize: 16 }}>
                                    ${currentBill.latePaymentFees.toFixed(2)}
                                </div>
                            </div>
                        )}

                        <div className="w-100 d-flex align-items-center justify-content-between py-3">
                            <div className="font-family-semibold">Pay later date</div>
                            <Input
                                type="date"
                                placeholder="Select date"
                                className="col-5 px-0"
                                name="ptpScheduledDate"
                                defaultValue={
                                    currentBill.ptpScheduledDate
                                        ? format(new Date(currentBill.ptpScheduledDate), "yyyy-MM-dd")
                                        : format(
                                              max([addDays(new Date(currentBill.billDueDate), 1), startOfTomorrow()]),
                                              "yyyy-MM-dd"
                                          )
                                }
                                min={format(
                                    max([addDays(new Date(currentBill.billDueDate), 1), startOfTomorrow()]),
                                    "yyyy-MM-dd"
                                )}
                                max={format(new Date(customerProfile.ptpEligibility.toBeDisconnected), "yyyy-MM-dd")}
                                register={register({
                                    required: "Date is required" || true,
                                    valueAsDate: true,
                                })}
                                showError={errors.ptpScheduledDate != null}
                                errorMessage={errors.ptpScheduledDate?.message}
                            />
                        </div>

                        <div className="text-grey1 mt-3 align-self-start">
                            The last date for Pay Later is{" "}
                            {format(new Date(customerProfile.ptpEligibility.toBeDisconnected), "MMMM dd, yyyy")}.
                        </div>

                        <Button
                            color="secondary"
                            className="mt-3"
                            fullWidth
                            disabled={
                                watchPtpScheduledDate && currentBill.ptpScheduledDate
                                    ? isSameDay(watchPtpScheduledDate, new Date(currentBill.ptpScheduledDate))
                                    : false
                            }
                            onClick={handleSubmit(onSetPromiseToPay)}
                            loading={formState.isSubmitting}>
                            Confirm
                        </Button>
                    </Modal>
                )}
        </Card>
    );
}
