import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { useAppDispatch, useAppSelector, useUnwrapAsyncThunk } from "../utils/store";
import { fetchProfile, profilesSelector } from "../features/profile-slice";
import Layout from "../components/layout";
import { Link, navigate } from "gatsby";
import CustomerBadge from "../components/customer-badge";
import { Card } from "react-bootstrap";
import { MnpInfo } from "../utils/types/customer";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../components/input";
import Select from "../components/select";
import { configSelector } from "../features/config-slice";
import { Button } from "../components/button";
import { useErrorHandler } from "../components/error-snackbar";
import { reconnect } from "../features/leaving-reach-slice";

export interface ReconnectPortProps extends RouteComponentProps {
    customerId: string;
}

interface FormData {
    oldCarrier: string;
    operatorText: string;
    oldCarrierAccountNumber: string;
    oldZip: string;
    password?: string;
}

export default function ReconnectPort(props: ReconnectPortProps) {
    const { customerId } = props;

    const customerProfile = useAppSelector((state) => profilesSelector.selectById(state, customerId));

    const searchParams = new URLSearchParams(props.location?.search);

    const skipReconnectFee = searchParams.get("skipReconnectFee") === "true";

    const { operators } = useAppSelector(configSelector);
    const [showOperatorField, setOperatorField] = useState(false);

    const { handleSubmit, register, control, formState, errors } = useForm<FormData>({
        mode: "onBlur",
    });

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    const onSubmit = async (data: FormData) => {
        try {
            await unwrap(
                reconnect({
                    customerId,
                    isPortIn: true,
                    skipReconnectFee,
                    portInInfo: {
                        ...data,
                        numberToPort: customerProfile.reachNumber,
                        oldCarrier: data.oldCarrier === "Other" ? data.operatorText : data.oldCarrier,
                    },
                })
            );
            await dispatch(fetchProfile(customerId));
            navigate(`/profile/${customerId}`);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    return (
        <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - Reconnect port`}>
            <div className="container col-10 d-flex flex-column my-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to={`/profile/${customerId}`}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>

                <h2>Enter port details</h2>
                <Card>
                    <form className="d-flex flex-column w-100 mt-2" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mx-n2 px-3 row-cols-1 row-cols-md-2">
                            <Input
                                type="text"
                                placeholder="Account number"
                                name="oldCarrierAccountNumber"
                                className="col my-2 px-2"
                                defaultValue=""
                                register={register({
                                    required: {
                                        value: true,
                                        message: "Account number is required",
                                    },
                                    pattern: {
                                        value: /^[A-Za-z0-9]{1,20}$/,
                                        message: "Not a valid account number",
                                    },
                                })}
                                showError={errors.oldCarrierAccountNumber != null}
                                errorMessage={errors.oldCarrierAccountNumber?.message}
                            />
                            <Input
                                type="text"
                                placeholder="PIN"
                                name="password"
                                className="col my-2 px-2"
                                defaultValue=""
                                register={register({
                                    required: {
                                        value: true,
                                        message: "PIN is required",
                                    },
                                    pattern: {
                                        value: /^[A-Za-z0-9]{1,15}$/,
                                        message: "Not a valid PIN",
                                    },
                                })}
                                showError={errors.password != null}
                                errorMessage={errors.password?.message}
                            />
                            <Input
                                type="text"
                                inputMode="numeric"
                                placeholder="ZIP code"
                                name="oldZip"
                                maxLength={5}
                                className="col my-2 px-2"
                                defaultValue=""
                                register={register({
                                    required: {
                                        value: true,
                                        message: "Zip is required",
                                    },
                                    pattern: {
                                        value: /^\d{5}$/,
                                        message: "Zip should be 5 digits long",
                                    },
                                })}
                                showError={errors.oldZip != null}
                                errorMessage={errors.oldZip?.message}
                            />
                            <Controller
                                defaultValue=""
                                name="oldCarrier"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Operator must be selected",
                                    },
                                }}
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        onChange={(event) => {
                                            onChange(event.target.value);
                                            setOperatorField(event.target.value === "Other");
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        name={name}
                                        className="col my-2 px-2"
                                        showError={errors.oldCarrier != null}
                                        errorMessage={errors.oldCarrier?.message}>
                                        <option value="">Select carrier</option>
                                        {operators.map((operator) => (
                                            <option key={operator.value} value={operator.value}>
                                                {operator.displayName}
                                            </option>
                                        ))}
                                    </Select>
                                )}
                            />
                            {showOperatorField && (
                                <Input
                                    type="text"
                                    placeholder="Enter operator name"
                                    className="col my-2 px-2"
                                    name="operatorText"
                                    defaultValue=""
                                    register={register({
                                        required: {
                                            value: true,
                                            message: "Operator is required",
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9 &]+$/,
                                            message: "Invalid carrier name",
                                        },
                                    })}
                                    showError={errors.operatorText != null}
                                    errorMessage={errors.operatorText?.message}
                                />
                            )}
                        </div>

                        <Button
                            type="submit"
                            color="secondary"
                            loading={formState.isSubmitting}
                            className="my-3 col-8 col-xl-4 align-self-center">
                            Confirm
                        </Button>
                    </form>
                </Card>
            </div>
        </Layout>
    );
}
