import React, { useEffect, useState } from "react";
import { RouteComponentProps, useLocation } from "@reach/router";
import { Input } from "../components/input";
import { Button } from "../components/button";
import Layout from "../components/layout";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../utils/store";
import { customerSearchSelector, customersSelector, searchCustomers, queryUpdated } from "../features/customers-slice";
import { useViewport, ViewportBreakpoint } from "../utils/viewport-context";
import debounce from "lodash.debounce";
import { usePermissions } from "../features/hooks/use-permissions";
import CustomerListItem from "../components/customer-list-item";
import Select from "../components/select";
import noResultsImage from "../images/icon-noresultsfound.png";
import { CustomerStatus } from "../utils/types/customer";
import { purchaseReset } from "../features/purchase-slice";

export type CustomerListProps = RouteComponentProps;

enum SearchFilter {
    ALL = "ALL",
    ACTIVATION_IN_PROGRESS = "ACTIVATION_IN_PROGRESS",
    ACTIVE = "ACTIVE",
    SUSPENDED = "SUSPENDED",
    DISCONNECTED = "DISCONNECTED",
    HOTLINED = "HOTLINED",
    REJECTED = "REJECTED",
    DISABLED = "DISABLED",
    ACTIVATION_FAILED = "ACTIVATION_FAILED",
    PORTING_IN_PROGRESS = "PORTING_IN_PROGRESS",
    PORTING_FAILED = "PORTING_FAILED",
    PORT_CANCELLED = "PORT_CANCELLED",
    INACTIVE = "INACTIVE",
    ORDERED = "ORDERED",
    IN_TRANSIT = "IN_TRANSIT",
    DELIVERED = "DELIVERED",
    CANCELLED = "CANCELLED",
    PURCHASE_PENDING = "PURCHASE_PENDING",
    PAYMENT_PENDING = "PAYMENT_PENDING",
}

export default function CustomerList(props: CustomerListProps) {
    const dispatch = useAppDispatch();
    const { width } = useViewport();

    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const filterParam = searchParams.get("filter") as keyof typeof SearchFilter;

    const [filterValue, setFilterValue] = useState<SearchFilter>(
        filterParam && SearchFilter[filterParam] ? SearchFilter[filterParam] : SearchFilter.ALL
    );

    const { hasOnePermission } = usePermissions();
    const allCustomers = useSelector(customersSelector.selectAll);
    const { loading, loadingAdditional, lastKeys, query } = useSelector(customerSearchSelector);

    const initialBatchSize = width < ViewportBreakpoint.MD ? 5 : 10;

    const [batchSize, setBatchSize] = useState(initialBatchSize);

    const updateCustomers = (update: boolean) => {
        const searchFilter = getSearchFilterFromValue(filterValue);

        if (update && batchSize < allCustomers.length) {
            setBatchSize(batchSize + initialBatchSize);
            return;
        }

        dispatch(
            searchCustomers({
                limit: 100,
                query,
                update,
                partialMatch: hasOnePermission("user_management.customer.partial_match"),
                lastKeys: update ? lastKeys : undefined,
                ...searchFilter,
            })
        );
    };

    useEffect(() => {
        updateCustomers(false);
    }, [query, filterValue]);

    const handleSearch = debounce((query: string) => {
        dispatch(queryUpdated(query));
    }, 1000);

    return (
        <Layout title="Customer List">
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <div className="d-flex align-items-center justify-content-between pb-3">
                    <h1>Customer List</h1>
                    <Button
                        color="secondary"
                        icon="reach-cs-plus"
                        mini={width < ViewportBreakpoint.MD}
                        disabled={!hasOnePermission("user_management.customer.create")}
                        onClick={() => {
                            dispatch(purchaseReset());
                            navigate("/purchase/new");
                        }}>
                        Create New Customer
                    </Button>
                </div>
                <div className="row mb-2">
                    <Input
                        type="text"
                        icon="reach-cs-search"
                        placeholder="Enter name, phone number, email or IMEI"
                        className={
                            hasOnePermission("user_management.customer.partial_match")
                                ? "col-10 col-sm-9 pr-1 pr-sm-3"
                                : "col-12"
                        }
                        defaultValue={query}
                        onChange={({ target: { value } }) => {
                            handleSearch(value);
                        }}
                    />
                    {hasOnePermission("user_management.customer.partial_match") && (
                        <Select
                            className="col-1 col-sm-3 pl-0"
                            value={filterValue}
                            onChange={({ target: { value } }) => setFilterValue(value as SearchFilter)}>
                            <option value={SearchFilter.ALL}>Filter by</option>
                            <optgroup label="Purchase">
                                <option value={SearchFilter.PURCHASE_PENDING}>Purchase pending</option>
                                <option value={SearchFilter.PAYMENT_PENDING}>Payment pending</option>
                            </optgroup>
                            <optgroup label="Activation pending">
                                <option value={SearchFilter.ACTIVATION_IN_PROGRESS}>Activation in progress</option>
                                <option value={SearchFilter.PORTING_IN_PROGRESS}>Porting in progress</option>
                                <option value={SearchFilter.INACTIVE}>Inactive</option>
                                <option value={SearchFilter.ORDERED}>Ordered</option>
                                <option value={SearchFilter.IN_TRANSIT}>Order in transit</option>
                                <option value={SearchFilter.DELIVERED}>Order delivered</option>
                                <option value={SearchFilter.CANCELLED}>Order cancelled</option>
                                <option value={SearchFilter.ACTIVATION_FAILED}>Activation failed</option>
                                <option value={SearchFilter.PORTING_FAILED}>Porting failed</option>
                                <option value={SearchFilter.REJECTED}>Activation rejected</option>
                                <option value={SearchFilter.PORT_CANCELLED}>Port cancelled</option>
                            </optgroup>
                            <optgroup label="Active">
                                <option value={SearchFilter.ACTIVE}>Active</option>
                                <option value={SearchFilter.SUSPENDED}>Suspended</option>
                                <option value={SearchFilter.HOTLINED}>Hotlined</option>
                                <option value={SearchFilter.DISABLED}>Disabled</option>
                            </optgroup>
                            <optgroup label="Disconnections">
                                <option value={SearchFilter.DISCONNECTED}>Disconnected</option>
                            </optgroup>
                        </Select>
                    )}
                </div>
                {loading ? (
                    <div className="d-flex justify-content-center mt-5">
                        <div className="spinner-border text-primary" />
                    </div>
                ) : allCustomers.length === 0 ? (
                    <div
                        className="d-flex align-items-center justify-content-center flex-column"
                        style={{ height: 200 }}>
                        <img src={noResultsImage} />
                        <div className="font-family-semibold" style={{ fontSize: 18 }}>
                            {query === "" ? "Please start searching" : "No results found"}
                        </div>
                    </div>
                ) : (
                    allCustomers
                        .slice(0, batchSize)
                        .map((customer) => <CustomerListItem customer={customer} key={customer.id} />)
                )}

                {!loading && (lastKeys || batchSize < allCustomers.length) && (
                    <Button
                        color="secondary"
                        outline
                        loading={loadingAdditional}
                        className="col-8 col-md-4 align-self-center my-3"
                        onClick={() => updateCustomers(true)}>
                        Load More
                    </Button>
                )}
            </div>
        </Layout>
    );
}

function getSearchFilterFromValue(filterValue: SearchFilter) {
    const searchFilter: {
        status?: CustomerStatus[];
        orderStatus?: string[];
        simPaid?: boolean;
        isDraft?: boolean;
    } = {};
    switch (filterValue) {
        case SearchFilter.ALL:
            break;
        case SearchFilter.ACTIVE:
            searchFilter.status = [CustomerStatus.ACTIVE];
            break;
        case SearchFilter.ACTIVATION_IN_PROGRESS:
            searchFilter.status = [CustomerStatus.ACTIVATION_IN_PROGRESS];
            break;
        case SearchFilter.SUSPENDED:
            searchFilter.status = [CustomerStatus.SUSPENDED];
            break;
        case SearchFilter.DISCONNECTED:
            searchFilter.status = [CustomerStatus.DISCONNECTED];
            break;
        case SearchFilter.HOTLINED:
            searchFilter.status = [CustomerStatus.HOTLINED];
            break;
        case SearchFilter.REJECTED:
            searchFilter.status = [CustomerStatus.REJECTED];
            break;
        case SearchFilter.DISABLED:
            searchFilter.status = [CustomerStatus.DISABLED];
            break;
        case SearchFilter.ACTIVATION_FAILED:
            searchFilter.status = [CustomerStatus.ACTIVATION_FAILED];
            break;
        case SearchFilter.PORTING_IN_PROGRESS:
            searchFilter.status = [CustomerStatus.PORTING_IN_PROGRESS];
            break;
        case SearchFilter.PORTING_FAILED:
            searchFilter.status = [CustomerStatus.PORTING_FAILED];
            break;
        case SearchFilter.PORT_CANCELLED:
            searchFilter.status = [CustomerStatus.PORT_CANCELLED];
            break;
        case SearchFilter.INACTIVE:
            searchFilter.status = [CustomerStatus.INACTIVE];
            break;
        case SearchFilter.ORDERED:
            searchFilter.status = [CustomerStatus.INACTIVE];
            searchFilter.orderStatus = ["ORDERED"];
            break;
        case SearchFilter.IN_TRANSIT:
            searchFilter.status = [CustomerStatus.INACTIVE];
            searchFilter.orderStatus = ["LABELLED", "SHIPPED"];
            break;
        case SearchFilter.DELIVERED:
            searchFilter.status = [CustomerStatus.INACTIVE];
            searchFilter.orderStatus = ["DELIVERED"];
            break;
        case SearchFilter.CANCELLED:
            searchFilter.status = [CustomerStatus.INACTIVE];
            searchFilter.orderStatus = ["CANCELLED"];
            break;
        case SearchFilter.PURCHASE_PENDING:
            searchFilter.status = [CustomerStatus.INACTIVE];
            searchFilter.simPaid = false;
            searchFilter.isDraft = true;
            break;
        case SearchFilter.PAYMENT_PENDING:
            searchFilter.status = [CustomerStatus.INACTIVE];
            searchFilter.simPaid = false;
            searchFilter.isDraft = false;
            break;
    }
    return searchFilter;
}
