import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Coupon, CouponType } from "../utils/types";
import { Button } from "./button";
import { Input } from "./input";
import { usePermissions } from "../features/hooks/use-permissions";

export interface CouponInputProps {
    coupon?: Coupon;
    onCouponChange: (couponCode: string) => Promise<Coupon | undefined>;
    onCouponRemoved?: () => void;
    removable?: boolean;
}

enum CouponInputState {
    ADD_COUPON,
    ENTER_COUPON,
    VALID_COUPON,
}

export default function CouponInput(props: CouponInputProps) {
    const { hasOnePermission } = usePermissions();

    const { removable = true } = props;
    const [currentState, setCurrentState] = useState(
        props.coupon ? CouponInputState.VALID_COUPON : CouponInputState.ADD_COUPON
    );

    const [coupon, setCoupon] = useState<Coupon | undefined>(props.coupon);

    const { register, errors, handleSubmit, formState, setError } = useForm<{ couponCode: string }>({
        mode: "onBlur",
    });

    if (currentState === CouponInputState.ADD_COUPON && hasOnePermission("billing.coupon.referral")) {
        return (
            <div
                className="px-2 coupon-box d-flex align-items-center"
                style={{ paddingTop: 10, paddingBottom: 10 }}
                onClick={() => setCurrentState(CouponInputState.ENTER_COUPON)}>
                <span className="reach-cs-offercode mr-2" style={{ fontSize: 25 }} />
                <span className="font-family-bold ">Have an offer or referral code?</span>
            </div>
        );
    }

    const onSubmit = async ({ couponCode }: { couponCode: string }) => {
        try {
            const coupon = await props.onCouponChange(couponCode);
            setCoupon(coupon);
            if (coupon) {
                setCurrentState(CouponInputState.VALID_COUPON);
            } else {
                setError("couponCode", { message: "This coupon code is invalid or has expired." });
            }
        } catch (error) {
            console.log(error);
            setCoupon(undefined);
            setError("couponCode", { message: "This coupon code is invalid or has expired." });
        }
    };

    if (currentState === CouponInputState.ENTER_COUPON) {
        return (
            <form className="d-flex" onSubmit={handleSubmit(onSubmit)}>
                <Input
                    type="text"
                    placeholder="Enter offer code"
                    className="flex-fill"
                    name="couponCode"
                    register={register({
                        required: {
                            value: true,
                            message: "This field is required.",
                        },
                    })}
                    showError={errors.couponCode != null}
                    errorMessage={errors.couponCode?.message}
                />
                <Button color="primary" className="font-family-bold offer-btn" loading={formState.isSubmitting}>
                    Apply Offer
                </Button>
                <span
                    className="reach-cs-cross mt-3 ml-2"
                    onClick={() => setCurrentState(CouponInputState.ADD_COUPON)}
                />
            </form>
        );
    }

    return (
        <div className="px-2 py-2 coupon-box d-flex align-items-center">
            <span className="reach-cs-offercode mr-2" style={{ fontSize: 25 }} />
            <div className="flex-fill">
                <div className="font-family-bold">Savings</div>
                <div>
                    {coupon?.type === CouponType.REFERRAL ? "Referral code" : "Promo code"}{" "}
                    {coupon?.type === CouponType.REFERRAL ? coupon?.coupon : coupon?.name}{" "}
                    {coupon?.delayByMonths && coupon.delayByMonths > 0
                        ? `will be applied after ${coupon?.delayByMonths} billing cycles`
                        : "applied"}
                </div>
            </div>
            {removable && (
                <div
                    className="font-family-semibold text-primary"
                    onClick={() => {
                        setCoupon(undefined);
                        props.onCouponRemoved?.();
                        setCurrentState(CouponInputState.ADD_COUPON);
                    }}>
                    Remove
                </div>
            )}
        </div>
    );
}
