import React, { DetailedHTMLProps, InputHTMLAttributes } from "react";
import classNames from "classnames";

export interface ToggleProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    label: string;
    register?: any;
    className?: string;
    id: string;
}

export default function Toggle(props: ToggleProps) {
    const { label, id, register, className, ...rest } = props;

    return (
        <div className={classNames("custom-control custom-switch", className)}>
            <input type="checkbox" className="custom-control-input" id={id} ref={register} {...rest} />
            <label className="custom-control-label" htmlFor={id}>
                {label}
            </label>
        </div>
    );
}
