import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import Layout from "../../components/layout";
import { Link, navigate } from "gatsby";
import { Button } from "../../components/button";
import { Input } from "../../components/input";
import Select from "../../components/select";
import { Controller, useForm } from "react-hook-form";
import { submitMNPValidate, validateMNP } from "../../features/purchase-slice";
import { useSelector } from "react-redux";
import { useUnwrapAsyncThunk } from "../../utils/store";
import { configSelector } from "../../features/config-slice";
import { useErrorHandler } from "../../components/error-snackbar";
import { useSuccessModal } from "../../components/success-modal";

export type NumberPreferenceProps = RouteComponentProps;

interface FormData {
    oldNumber: string;
    operator: string;
    operatorText: string;
}

export default function PortNumber(props: NumberPreferenceProps) {
    const { register, handleSubmit, errors, formState, setError, control } = useForm<FormData>({
        mode: "onBlur",
    });

    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();

    const { operators } = useSelector(configSelector);
    const [showOperatorField, setOperatorField] = useState(false);

    const onSubmit = async (data: FormData) => {
        const mnpInfo = {
            oldNumber: data.oldNumber,
            operator: data.operator === "Other" ? data.operatorText : data.operator,
        };
        try {
            const refNumber = await unwrap(submitMNPValidate(mnpInfo.oldNumber));
            const res = await unwrap(validateMNP(refNumber));

            if (res.res.isPortValidate) {
                showSuccessModal("Number is portable");
            } else {
                setError("oldNumber", { message: "Cannot port given number" });
            }
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    return (
        <Layout title="Tools - Number Portability">
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <Link className="text-cta mb-3" to="/tools">
                    <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                    Back
                </Link>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>Number Portability</h1>
                </div>
                <div className="divider" />

                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-xl-4 ">
                        <form className="col-12" onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                control={control}
                                name="oldNumber"
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Phone number is required",
                                    },
                                    pattern: {
                                        value: /^\d{10}$/,
                                        message: "Phone number should be 10 digits long",
                                    },
                                }}
                                defaultValue=""
                                render={({ onChange, onBlur, value, name }) => (
                                    <Input
                                        type="text"
                                        inputMode="numeric"
                                        placeholder="Current phone number"
                                        className="mt-4"
                                        maxLength={10}
                                        mask="phone"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        name={name}
                                        showError={errors.oldNumber != null}
                                        errorMessage={errors.oldNumber?.message}
                                    />
                                )}
                            />
                            <Controller
                                defaultValue=""
                                name="operator"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Operator must be selected",
                                    },
                                }}
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        onChange={(event) => {
                                            console.log(event.target.value);
                                            onChange(event.target.value);
                                            setOperatorField(event.target.value === "Other");
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        name={name}
                                        className="my-4"
                                        showError={errors.operator != null}
                                        errorMessage={errors.operator?.message}>
                                        <option value="">Select carrier</option>
                                        {operators.map((operator) => (
                                            <option key={operator.value} value={operator.value}>
                                                {operator.displayName}
                                            </option>
                                        ))}
                                    </Select>
                                )}
                            />
                            {showOperatorField && (
                                <Input
                                    type="text"
                                    placeholder="Enter operator name"
                                    className="my-4"
                                    name="operatorText"
                                    defaultValue=""
                                    register={register({
                                        required: {
                                            value: true,
                                            message: "Operator is required",
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9 &]+$/,
                                            message: "Invalid carrier name",
                                        },
                                    })}
                                    showError={errors.operatorText != null}
                                    errorMessage={errors.operatorText?.message}
                                />
                            )}

                            <Button color="secondary" fullWidth loading={formState.isSubmitting}>
                                Check
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
