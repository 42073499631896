import React from "react";
import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";
import Layout from "../components/layout";
import { QuickActionCard } from "../components/quick-action-card";
import image1 from "../images/checkCoverage@2x.png";
import image2 from "../images/deviceCompatibility@2x.png";
import image3 from "../images/numberPortability@2x.png";
import image4 from "../images/billCalculator@2x.png";
import image5 from "../images/intlServices@2x.png";
import image6 from "../images/simChecker@2x.png";
import PageLayout from "../components/page-layout";
import Accordion, { AccordionBody } from "../components/accordion";
import { usePermissions } from "../features/hooks/use-permissions";

export type ToolsProps = RouteComponentProps;

export default function Tools(props: ToolsProps) {
    const { hasOnePermission } = usePermissions();

    return (
        <PageLayout title="Tools" prevUrl="/">
            <Accordion>
                <AccordionBody title="">
                    <div className="row row-cols-2 row-cols-md-4 row-cols-lg-5 mx-n2">
                        {hasOnePermission("tools.zipcheck.allow") && (
                            <div className="p-2 col">
                                <QuickActionCard
                                    icon={image1}
                                    title="Check coverage"
                                    onClick={() => {
                                        navigate("/tools/coverage");
                                    }}
                                />
                            </div>
                        )}
                        {hasOnePermission("tools.imei_check.allow") && (
                            <div className="p-2 col">
                                <QuickActionCard
                                    icon={image2}
                                    title="Device compatibility"
                                    onClick={() => {
                                        navigate("/tools/imei");
                                    }}
                                />
                            </div>
                        )}
                        {hasOnePermission("tools.number_portability.allow") && (
                            <div className="p-2 col">
                                <QuickActionCard
                                    icon={image3}
                                    title="Number portability"
                                    onClick={() => {
                                        navigate("/tools/check-portability");
                                    }}
                                />
                            </div>
                        )}
                        {hasOnePermission("tools.bill_estimate.allow") && (
                            <div className="p-2 col">
                                <QuickActionCard
                                    icon={image4}
                                    title="Bill calculator"
                                    onClick={() => {
                                        navigate("/tools/bill-calculator");
                                    }}
                                />
                            </div>
                        )}
                        {hasOnePermission("tools.intl_rates.allow") && (
                            <div className="p-2 col">
                                <QuickActionCard
                                    icon={image5}
                                    title="International service"
                                    onClick={() => {
                                        navigate("/tools/intl-rates");
                                    }}
                                />
                            </div>
                        )}
                        {hasOnePermission("tools.sim_check.allow") && (
                            <div className="p-2 col">
                                <QuickActionCard
                                    icon={image6}
                                    title="SIM checker"
                                    onClick={() => {
                                        navigate("/tools/scan-sim");
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </AccordionBody>
            </Accordion>
        </PageLayout>
    );
}
