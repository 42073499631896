import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import Layout from "../../components/layout";
import PlanCard from "../../components/plan-card";
import { useAppDispatch, useUnwrapAsyncThunk } from "../../utils/store";
import { customerSelector, linesUpdated, totalLinesSelector, updateAccount } from "../../features/purchase-slice";
import { useSelector } from "react-redux";
import PlanLineSelection from "../../components/plan-line-selection";
import { Link, navigate } from "gatsby";
import { fetchReachPlans, plansSelector } from "../../features/plans-slice";
import { useErrorHandler } from "../../components/error-snackbar";
import CustomerBadge from "../../components/customer-badge";

export type SelectPlanProps = RouteComponentProps;

export default function SelectPlan(props: SelectPlanProps) {
    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const plans = useSelector(plansSelector.selectAll);
    const totalLines = useSelector(totalLinesSelector);
    const customer = useSelector(customerSelector);

    const [lines, setLines] = useState(totalLines);

    const [loading, setLoading] = useState(false);

    const handleError = useErrorHandler();

    useEffect(() => {
        dispatch(fetchReachPlans());
    }, []);

    if (!customer.id) {
        navigate("/purchase/new");
        return null;
    }

    return (
        <Layout title={`${customer.firstName} ${customer.lastName} - Plan Choice`}>
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <Link className="text-cta mb-3" to="/customers">
                    <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                    Back
                </Link>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customer.firstName} {customer.lastName} <CustomerBadge isPrimary={customer.isPrimary} />
                    </div>
                    <div>{customer.emailId}</div>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>Plan Choice</h1>
                </div>
                <div className="divider" />
                <span className="text-center font-family-semibold mt-4 mb-3" style={{ fontSize: 16 }}>
                    How many lines do you need?
                </span>
                <PlanLineSelection
                    className="row col-md-6 col-lg-4 align-self-center"
                    maxLines={6}
                    value={lines}
                    onChange={setLines}
                />
                <div className="row mx-3 mx-md-5">
                    {plans.map((plan) => (
                        <div key={plan.name} className="p-3 col-12 col-md-6 col-lg-3">
                            <PlanCard
                                {...plan}
                                ctaLabel="Select Plan"
                                lines={lines}
                                disabled={plan.maxLines < lines}
                                loading={loading}
                                onClick={async () => {
                                    setLoading(true);
                                    try {
                                        dispatch(linesUpdated(lines));
                                        await unwrap(
                                            updateAccount({
                                                id: customer.id!,
                                                customer: {
                                                    reachPlanId: plan.name,
                                                },
                                            })
                                        );
                                        navigate("/purchase/coverage");
                                    } catch (error) {
                                        console.log(error);
                                        handleError(error);
                                    }
                                    setLoading(false);
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    );
}
