import { useAppSelector, useUnwrapAsyncThunk } from "../../utils/store";
import { OpsEvent } from "../../utils/types";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import { selectUserInfo } from "../user-role-slice";
import { addOpsEvents } from "../events-slice";

const SESSION_COOKIE_NAME = "ops_session_id";

export function useOpsEvents() {
    let sessionId: string;
    const unwrap = useUnwrapAsyncThunk();
    const userInfo = useAppSelector(selectUserInfo);

    const cookie = Cookies.get(SESSION_COOKIE_NAME);

    if (!cookie) {
        sessionId = uuidv4();
        Cookies.set(SESSION_COOKIE_NAME, sessionId, { expires: 1 });
    } else {
        sessionId = cookie;
    }

    function addEvent(payload: Omit<OpsEvent, "session_id">) {
        return unwrap(
            addOpsEvents({
                payload: [
                    {
                        ...payload,
                        session_id: sessionId,
                    },
                ],
                accountSrc: userInfo.accountId ?? "",
            })
        );
    }

    return addEvent;
}
