import React, { DetailedHTMLProps } from "react";
import classNames from "classnames";

export interface TextAreaProps
    extends DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
    icon?: string;
    register?: any;
    errorMessage?: string;
    showError?: boolean;
}

export function TextArea(props: TextAreaProps) {
    const { icon, className, register, showError = false, errorMessage: error, maxLength, ...rest } = props;

    return (
        <div className={className}>
            {icon && <span className={`${icon} form-control-icon`} />}
            <textarea
                {...rest}
                ref={register}
                className={classNames("form-control", {
                    "pl-5": icon,
                    "is-invalid": showError,
                })}
            />
            <div className="d-flex">
                {showError && error && <div className="d-block invalid-feedback font-family-medium">{error}</div>}
            </div>
        </div>
    );
}
