import React from "react";
import { ModalProps as BSModalProps } from "react-bootstrap/esm/Modal";
import { useForm } from "react-hook-form";
import { createCustomer, getCustomerByEmail } from "../features/activation-slice";
import { fetchProfile } from "../features/profile-slice";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile } from "../utils/types";
import { Customer, FlowVersion } from "../utils/types/customer";
import { Button } from "./button";
import { Input } from "./input";
import Modal from "./modal";
import { navigate } from "gatsby";
import { EMAIL_PATTERN, NAME_PATTERN } from "../utils/helpers";
import { useErrorHandler } from "./error-snackbar";

export interface LineInfoModalProps extends BSModalProps {
    customerProfile: CustomerProfile;
}

interface FormData {
    firstName: string;
    lastName: string;
    emailId?: string;
}

export default function LineInfoModal(props: LineInfoModalProps) {
    const { customerProfile, ...rest } = props;

    const { handleSubmit, errors, register, setError, formState } = useForm({
        mode: "onBlur",
    });

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const onSubmit = async (data: FormData) => {
        if (data.emailId?.length === 0) {
            data.emailId = undefined;
        }
        const customer: Partial<Customer> = { ...data };
        const primaryCustomer = customerProfile.groupLines[0];
        try {
            if (data.emailId) {
                // get customer and check if it already exists
                const cust = await unwrap(getCustomerByEmail(data.emailId));
                if (cust.groupId !== primaryCustomer.groupId) {
                    setError("emailId", { message: "This email is currently being used with another group plan" });
                } else {
                    setError("emailId", { message: "Please use a unique email address for each line" });
                }
            }
        } catch (error) {
            // if error is not "customer not found"
            if (error.resCode !== 40003) {
                console.log(error);
                handleError(error);
                return;
            }
        }
        customer.source = primaryCustomer.source;
        customer.isPrimary = false;
        customer.parentId = primaryCustomer.id;
        customer.simPaid = true;
        customer.isMNP = false;
        customer.flowVersion = FlowVersion.FLOW_1;
        customer.reachPlanId = primaryCustomer.reachPlanId;
        customer.addresses = primaryCustomer.addresses;
        // create secondary customer
        try {
            await unwrap(createCustomer(customer as Customer));
            await dispatch(fetchProfile(customerProfile.id));
            navigate("/activation/scan-sim");
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        rest.onHide();
    };

    return (
        <Modal {...rest} title="Activate Line">
            <form className="row w-100" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-12">
                    <Input
                        type="text"
                        placeholder="First Name"
                        className="mt-4"
                        name="firstName"
                        register={register({
                            required: {
                                value: true,
                                message: "First name is required",
                            },
                            pattern: {
                                value: NAME_PATTERN,
                                message: "Not a valid first name",
                            },
                        })}
                        showError={errors.firstName}
                        errorMessage={errors.firstName?.message}
                    />
                    <Input
                        type="text"
                        placeholder="Last Name"
                        className="mt-4"
                        name="lastName"
                        register={register({
                            required: {
                                value: true,
                                message: "Last name is required",
                            },
                            pattern: {
                                value: NAME_PATTERN,
                                message: "Not a valid last name",
                            },
                        })}
                        showError={errors.lastName}
                        errorMessage={errors.lastName?.message}
                    />
                    <Input
                        type="text"
                        placeholder="Email address (Optional)"
                        className="mt-4"
                        name="emailId"
                        register={register({
                            pattern: {
                                value: EMAIL_PATTERN,
                                message: "Not a valid email id",
                            },
                        })}
                        showError={errors.emailId}
                        errorMessage={errors.emailId?.message}
                    />

                    <Button color="secondary" fullWidth className="mt-4" loading={formState.isSubmitting}>
                        Continue
                    </Button>
                </div>
            </form>
        </Modal>
    );
}
