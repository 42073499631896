import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Link, navigate } from "gatsby";
import Layout from "../../components/layout";
import {
    additionalLineGroupUpdated,
    additionalLineShippingUpdated,
    primaryCustomerAddressesUpdated,
    selectAdditionalLinesPurchase,
} from "../../features/additional-lines-purchase-slice";
import { useSelector } from "react-redux";
import Modal from "../../components/modal";
import { AddressComponent } from "../purchase/checkout-page";
import { Button } from "../../components/button";
import { Address } from "../../utils/types/customer";
import { useForm } from "react-hook-form";
import { useErrorHandler } from "../../components/error-snackbar";
import { useAppDispatch, useUnwrapAsyncThunk } from "../../utils/store";
import Select from "../../components/select";
import { configSelector } from "../../features/config-slice";
import { fetchProfile, updateProfile } from "../../features/profile-slice";
import AddLineSummaryModal from "../../components/add-line-summary-modal";
import CustomerBadge from "../../components/customer-badge";

export type ShippingAddressProps = RouteComponentProps;

export default function ShippingAddress(props: ShippingAddressProps) {
    const { customerProfile, additionalCustomers } = useSelector(selectAdditionalLinesPurchase);
    const { shippingRates } = useSelector(configSelector);

    const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [selectedShippingOption, setShippingOption] = useState(shippingRates[0]);
    const [showSummaryModal, setShowSummaryModal] = useState(false);

    const unwrap = useUnwrapAsyncThunk();
    const dispatch = useAppDispatch();
    const handleError = useErrorHandler();

    const modalFormMethods = useForm({
        mode: "onBlur",
    });

    if (!customerProfile) {
        navigate("/customers");
        return null;
    }

    const onAddressSubmit = async (data: { addresses: Address[] }) => {
        try {
            const addresses = [...customerProfile.addresses];
            const address = data.addresses[0];
            address.name = `${customerProfile.firstName} ${customerProfile.lastName}`;
            address.phone = customerProfile.primaryNumber!;
            address.country = "USA";
            address.type = "shipping";
            addresses.push(address);
            setSelectedAddressIndex(customerProfile.addresses.filter((address) => address.type === "shipping").length);
            dispatch(primaryCustomerAddressesUpdated(addresses));
            await unwrap(
                updateProfile({
                    id: customerProfile.id,
                    customer: { addresses },
                })
            );
            const profile = await unwrap(fetchProfile(customerProfile.id));
            dispatch(additionalLineGroupUpdated(profile));
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        setShowAddressModal(false);
    };

    const proceed = () => {
        const shippingAddress = customerProfile.addresses.filter((address) => address.type === "shipping")[
            selectedAddressIndex
        ];
        dispatch(additionalLineShippingUpdated({ shippingOption: selectedShippingOption, shippingAddress }));
        setShowSummaryModal(true);
    };

    return (
        <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - Shipping address`}>
            <div className="container col-10 d-flex flex-column my-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to={`/add-lines/imei/${additionalCustomers.length - 1}`}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-1">
                    <h2>Choosing a shipping address</h2>
                </div>
                <div className="divider mb-2" />

                {customerProfile.addresses
                    .filter((address) => address.type === "shipping")
                    .map((address, index) => (
                        <div
                            key={index}
                            className="font-family-semibold cursor-pointer"
                            onClick={() => {
                                setSelectedAddressIndex(index);
                            }}>
                            {index !== 0 && <div className="divider my-2" />}
                            {address.name} <br />
                            {address.address2 && `${address.address2}, `} {address.address1} <br />
                            {address.city}, {address.state} {address.zip} <br />
                            {index === selectedAddressIndex && (
                                <div className="d-flex align-items-center font-family-bold">
                                    <span className="reach-cs-tick text-primary mr-1" />
                                    Active
                                </div>
                            )}
                        </div>
                    ))}

                <div className="align-self-center text-cta mt-3" onClick={() => setShowAddressModal(true)}>
                    <span className="reach-cs-plus mr-1" style={{ fontSize: 12 }} />
                    Add a new shipping address
                </div>

                <div className="mt-2">
                    <p className="h2" style={{ fontSize: 16 }}>
                        Shipping options
                    </p>
                    <Select
                        className="col-12 p-0"
                        value={selectedShippingOption.name}
                        onChange={({ target: { value } }) =>
                            setShippingOption(shippingRates.find((shippingRate) => shippingRate.name === value)!)
                        }>
                        {shippingRates.map((shippingRate) => (
                            <option key={shippingRate.name} value={shippingRate.name}>
                                {shippingRate.name} - ${shippingRate.shippingAmount}
                            </option>
                        ))}
                    </Select>
                </div>

                <Button color="secondary" className="align-self-center mt-3 col-12 col-md-6" onClick={proceed}>
                    Proceed
                </Button>
            </div>

            <Modal
                title="Update billing address"
                size="sm"
                show={showAddressModal}
                onHide={() => setShowAddressModal(false)}>
                <AddressComponent
                    index={0}
                    onSubmit={(address) => console.log(address)}
                    className="row-cols-1"
                    {...modalFormMethods}
                />
                <Button
                    color="secondary"
                    fullWidth
                    loading={modalFormMethods.formState.isSubmitting}
                    onClick={modalFormMethods.handleSubmit(onAddressSubmit)}>
                    Save
                </Button>
            </Modal>

            <AddLineSummaryModal
                title="Review new montly statement"
                show={showSummaryModal}
                onHide={() => setShowSummaryModal(false)}
            />
        </Layout>
    );
}
