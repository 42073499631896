import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { differenceInCalendarDays, format } from "date-fns";
import { Link, navigate } from "gatsby";
import { Button } from "../../components/button";
import Counter from "../../components/counter";
import CustomerBadge from "../../components/customer-badge";
import { useErrorHandler } from "../../components/error-snackbar";
import Layout from "../../components/layout";
import Modal from "../../components/modal";
import { configSelector } from "../../features/config-slice";
import {
    activateTrip,
    createIntlTrips,
    dataCapUpdated,
    estimateTripCredits,
    irPurchaseReset,
    purchaseCredit,
    selectIrPurchase,
} from "../../features/ir-purchase-slice";
import { CardExpiryState, profilesSelector } from "../../features/profile-slice";
import { formatPhone } from "../../utils/helpers";
import { useAppDispatch, useAppSelector, useUnwrapAsyncThunk } from "../../utils/store";
import { EstimateTripRes, IRTrip, TripStatus } from "../../utils/types";
import { cardImages, getCardImage } from "../../components/payment-cards";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { useSuccessModal } from "../../components/success-modal";
import { intlCountriesSelector } from "../../features/intl-countries-slice";
import { TripFilter } from "./manage-ir-page";

export interface IRPurchaseConfirmProps extends RouteComponentProps {
    customerId: string;
}

export default function IRPurchaseConfirm(props: IRPurchaseConfirmProps) {
    const { customerId } = props;

    const customerProfile = useAppSelector((state) => profilesSelector.selectById(state, customerId));
    const { travelers, destinations, tentativeStartDate, dataCap } = useAppSelector(selectIrPurchase);
    const { provConfig } = useAppSelector(configSelector);

    const { countryRes } =
        useAppSelector((state) => intlCountriesSelector.selectById(state, customerProfile?.source ?? "")) ?? {};

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();

    const [estimateCredits, setEstimateCredits] = useState<EstimateTripRes[]>();
    const [credits, setCredits] = useState<Record<string, number>>({});
    const [dataCapLocal, setDataCap] = useState<Record<string, number>>({});
    const [showDataCapModal, setShowDataCapModal] = useState(false);
    const [showSummaryModal, setShowSummaryModal] = useState(false);

    const { handleSubmit, formState } = useForm({
        mode: "onBlur",
    });

    const pricePerPulse = Math.max(...destinations.map((country) => country.pricePerPulse));

    useEffect(() => {
        for (const traveler of travelers) {
            if (!dataCapLocal[traveler]) {
                dataCapLocal[traveler] = 2 * dataPulse;
            }
        }
        dispatch(dataCapUpdated(dataCapLocal));
        async function fetchTripCredits() {
            try {
                const credits: Record<string, number> = {};
                const res = await Promise.all(
                    travelers.map((id) =>
                        unwrap(
                            estimateTripCredits({
                                countries: destinations.map((country) => country.countryCode),
                                customerId: id,
                            })
                        )
                    )
                );
                for (let i = 0; i < travelers.length; i++) {
                    const id = travelers[i];
                    credits[id] = res[i].minimumTripCredits;
                }

                setCredits(credits);
                setEstimateCredits(res);
            } catch (error) {
                handleError(error);
            }
        }
        fetchTripCredits();
    }, []);

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    if (!tentativeStartDate) {
        return null;
    }

    const dataPulse = countryRes?.dataPulse ?? 0;
    const maxNoOfPulses = countryRes?.maxNoOfPulses ?? 0;

    const totalCredits = Object.values(credits).reduce((a, b) => a + b, 0);
    const dataCapSum = Object.values(dataCapLocal).reduce((a, b) => a + b, 0);

    const totalBalanceCredits = estimateCredits?.map((c) => c.availableCredits).reduce((a, b) => a + b, 0) ?? 0;

    const total = totalCredits - totalBalanceCredits;

    const onSubmit = async () => {
        try {
            const countriesList = destinations.map((country) => country.countryCode);
            const customers = travelers.map((id) => customerProfile.groupLines.find((customer) => customer.id === id)!);

            const trips = customers.map(
                (customer): IRTrip => ({
                    customerId: customer.id,
                    primaryCustomerId: customerProfile.id,
                    prepaid: true,
                    tentativeStartDate: format(tentativeStartDate, "yyyy-MM-dd"),
                    countriesList,
                    dataCap: dataCap[customer.id],
                })
            );

            console.log(trips);

            const createdTrips = await unwrap(createIntlTrips(trips));

            if (total > 0) {
                const customerCreditMap: Record<string, number> = {};

                for (let i = 0; i < travelers.length; i++) {
                    const customerId = travelers[i];
                    const value = credits[customerId];
                    if (value > 0) {
                        customerCreditMap[customerId] = value;
                        const customerCredit = estimateCredits?.[i].availableCredits || 0;

                        customerCreditMap[customerId] = value - customerCredit;
                    }
                }

                await unwrap(
                    purchaseCredit({
                        primaryId: customerProfile.id,
                        customerCreditMap,
                        ccUUID: customerProfile.creditCards![0].uuid,
                        creditType: "IR",
                        totalAmount: total,
                        enableFeature: differenceInCalendarDays(tentativeStartDate, new Date()) < 1,
                    })
                );
            } else {
                if (differenceInCalendarDays(tentativeStartDate, new Date()) < 1) {
                    await Promise.all(createdTrips.map((tt) => unwrap(activateTrip(tt.id!))));
                }
            }
            const status = createdTrips[0].status === TripStatus.ACTIVATED ? TripFilter.Current : TripFilter.Upcoming;
            await navigate(`/ir/manage/${customerId}?status=${status}`);
            dispatch(irPurchaseReset());
            showSuccessModal("Trip created successfully");
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        setShowSummaryModal(false);
    };

    return (
        <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - International roaming`}>
            <div className="container col-10 d-flex flex-column my-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to={`/ir/purchase/${customerProfile.id}`}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h1>Confirm details</h1>
                </div>
                <div className="divider" />

                <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex align-items-center">
                        <div className="reach-cs-pin mr-3" style={{ fontSize: 24 }} />
                        <div>
                            <div className="font-family-bold">Destinations ({destinations.length})</div>
                            <div className="font-family-semibold">
                                {destinations.map((country) => country.countryName).join(";  ")}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="reach-cs-calendar mr-3" style={{ fontSize: 24 }} />
                        <div>
                            <div className="font-family-bold">Departure date</div>
                            <div className="font-family-semibold">{format(tentativeStartDate, "MMMM dd, yyyy")}</div>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                    <div className="reach-cs-user mr-3" style={{ fontSize: 24 }} />
                    <div className="font-family-bold">Travelers ({travelers.length})</div>
                </div>

                {estimateCredits == null ? (
                    <div className="spinner-border text-primary align-self-center mt-5" />
                ) : (
                    <>
                        {travelers.map((customerId, index) => {
                            const customer = customerProfile.groupLines.find((customer) => customer.id === customerId)!;
                            return (
                                <div key={customer.id} className="mt-3">
                                    <div className="font-family-semibold">
                                        <span className="font-family-bold">
                                            {customer.firstName} {customer.lastName}
                                        </span>{" "}
                                        {formatPhone(customer.reachNumber)}
                                    </div>

                                    <div className="mt-2 divider d-flex justify-content-between pb-2">
                                        <div className="font-family-semibold">Data usage limit</div>
                                        <div className="font-family-semibold">Add Credits</div>
                                    </div>

                                    <div className="mt-2 d-flex justify-content-between">
                                        <div>
                                            <span className="font-family-bold">{dataCap[customerId].toFixed(2)}GB</span>
                                            <span className="ml-3 text-cta" onClick={() => setShowDataCapModal(true)}>
                                                Set data limit
                                            </span>
                                        </div>
                                        <Counter
                                            defaultValue={estimateCredits[index].minimumTripCredits}
                                            minValue={estimateCredits[index].minimumTripCredits}
                                            maxValue={provConfig.IR_MAX_CREDIT_AMOUNT}
                                            step={provConfig.IR_INCR_CREDIT_AMOUNT}
                                            render={(value) => (
                                                <span className="font-family-bold">${value.toFixed(2)}</span>
                                            )}
                                            onChange={(value) => setCredits({ ...credits, [customerId]: value })}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        <div className="align-self-center font-family-semibold mt-3">
                            Total credit: <span className="font-family-bold">${totalCredits.toFixed(2)}</span>
                        </div>
                        <div className="align-self-center font-family-semibold mt-3">
                            Note: If the credit falls below ${provConfig.IR_MIN_CREDIT_AMOUNT} for any line, an amount
                            of ${provConfig.AUTO_TOP_UP_AMOUNT} will be automatically charged to the card on file.
                        </div>

                        <Button
                            color="secondary"
                            className="align-self-center col-12 col-md-6 col-lg-4 mt-3"
                            onClick={() => setShowSummaryModal(true)}>
                            Confirm
                        </Button>
                    </>
                )}
            </div>

            <Modal title="Set data usage limit" show={showDataCapModal} onHide={() => setShowDataCapModal(false)}>
                <div className="mt-2 divider d-flex justify-content-between pb-2">
                    <div className="font-family-bold">Travelers</div>
                    <div className="font-family-bold">Data usage limit</div>
                </div>
                {travelers.map((customerId) => {
                    const customer = customerProfile.groupLines.find((customer) => customer.id === customerId)!;
                    return (
                        <div key={customer.id} className="w-100 mt-3 d-flex align-items-center justify-content-between">
                            <div className="font-family-semibold">
                                <span className="font-family-bold">
                                    {customer.firstName} {customer.lastName}
                                </span>{" "}
                                {formatPhone(customer.reachNumber)}
                            </div>
                            <Counter
                                defaultValue={dataCapLocal[customerId]}
                                minValue={dataPulse}
                                maxValue={dataPulse * maxNoOfPulses}
                                step={dataPulse}
                                render={(value) => <span>{value.toFixed(2)}GB</span>}
                                onChange={(value) => setDataCap({ ...dataCapLocal, [customerId]: value })}
                            />
                        </div>
                    );
                })}
                <div className="align-self-end mt-3 font-family-semibold">
                    Estimated data cost:{" "}
                    <span className="font-family-bold">${((dataCapSum * pricePerPulse) / dataPulse).toFixed(2)}</span> (
                    {dataPulse}GB/ ${pricePerPulse.toFixed(2)})
                </div>

                <div className="my-3 text-center">
                    The service (data, voice, text) is automatically paused once you reach the data usage limit.
                </div>

                <Button
                    color="secondary"
                    fullWidth
                    onClick={() => {
                        dispatch(dataCapUpdated(dataCapLocal));
                        setShowDataCapModal(false);
                    }}>
                    Save
                </Button>
            </Modal>

            {estimateCredits != null && (
                <Modal title="Summary" show={showSummaryModal} onHide={() => setShowSummaryModal(false)}>
                    <div className="my-2 divider row pb-2">
                        <div className="font-family-bold col px-0">Travelers</div>
                        <div className="font-family-bold col px-0">Balance Credit</div>
                        <div className="font-family-bold">Credit</div>
                    </div>
                    {travelers.map((customerId, index) => {
                        const customer = customerProfile.groupLines.find((customer) => customer.id === customerId)!;
                        return (
                            <div
                                key={customer.id}
                                className="w-100 mb-3 row align-items-center justify-content-between">
                                <div className="font-family-semibold col px-0">
                                    {customer.firstName} {customer.lastName}
                                </div>
                                <div className="font-family-semibold col px-0">
                                    ${estimateCredits?.[index].availableCredits.toFixed(2)}
                                </div>
                                <div className="font-family-semibold">${credits[customerId].toFixed(2)}</div>
                            </div>
                        );
                    })}
                    {totalBalanceCredits !== 0 && (
                        <div className="align-self-end font-family-bold">Total credits: ${totalCredits.toFixed(2)}</div>
                    )}
                    {totalBalanceCredits !== 0 && (
                        <div className="align-self-end font-family-semibold">
                            {totalBalanceCredits < 0 ? "Outstanding Balance: $" : "Balance credits: -$"}
                            {totalBalanceCredits < 0
                                ? (totalBalanceCredits * -1).toFixed(2)
                                : totalBalanceCredits.toFixed(2)}
                        </div>
                    )}
                    <div className="align-self-end font-family-bold">
                        Total due today: {total < 0 && "-"}${total < 0 ? (total * -1).toFixed(2) : total.toFixed(2)}
                    </div>
                    <div className="align-self-end" style={{ fontSize: 12 }}>
                        (Includes ${provConfig.IR_MIN_COVER_CREDIT_AMOUNT.toFixed(2)}/line advance charges)
                    </div>

                    {customerProfile.creditCards && (
                        <div className="row align-items-center mt-3 cursor-pointer">
                            {getCardImage(customerProfile.creditCards[0].type) && (
                                <img
                                    src={getCardImage(customerProfile.creditCards[0].type)}
                                    className="align-self-start mt-1"
                                    width={45}
                                    height={30}
                                />
                            )}
                            <div className="col px-2">
                                <div>XXXX-XXXX-XXXX-{customerProfile.creditCards[0].ccNumberMask}</div>
                                <div style={{ fontSize: 12 }}>
                                    {customerProfile.creditCards[0].nameOnCard} |{" "}
                                    {customerProfile.creditCards[0].cardExpiryState === CardExpiryState.EXPIRED ? (
                                        <span className="text-danger">
                                            Expired date {format(customerProfile.creditCards[0].parsedDate, "MM/yy")}
                                        </span>
                                    ) : (
                                        <span
                                            className={classNames({
                                                "text-tertiary2":
                                                    customerProfile.creditCards[0].cardExpiryState ===
                                                    CardExpiryState.ALMOST_EXPIRED,
                                            })}>
                                            Expiration {format(customerProfile.creditCards[0].parsedDate, "MM/yy")}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    <Button
                        color="secondary"
                        fullWidth
                        className="mt-3"
                        loading={formState.isSubmitting}
                        onClick={handleSubmit(onSubmit)}>
                        Confirm
                    </Button>
                </Modal>
            )}
        </Layout>
    );
}
