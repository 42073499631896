import React from "react";
import { RouteComponentProps } from "@reach/router";
import Layout from "../../components/layout";
import { navigate } from "gatsby";
import purchaseCompletedImg from "../../images/purchaseCompleted.png";
import { Button } from "../../components/button";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../utils/store";
import {
    additionalLinesPurchaseReset,
    selectAdditionalLinesPurchase,
} from "../../features/additional-lines-purchase-slice";
import CustomerBadge from "../../components/customer-badge";

type AddLinesCompleteProps = RouteComponentProps;

export default function AddLinesComplete(props: AddLinesCompleteProps) {
    const { customerProfile } = useSelector(selectAdditionalLinesPurchase);
    const dispatch = useAppDispatch();

    if (!customerProfile) {
        return null;
    }

    return (
        <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - Add lines complete`}>
            <div className="container col-10 d-flex flex-column mt-3 px-0">
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>
                <div className="divider" />

                <div className="d-flex flex-column align-items-center mt-5">
                    <img src={purchaseCompletedImg} />
                    <div className="font-family-bold text-primary text-center mt-5" style={{ fontSize: 16 }}>
                        Order placed successfully.
                    </div>
                    <Button
                        fullWidth
                        color="secondary"
                        className="col-12 col-md-6 my-3"
                        onClick={async () => {
                            await navigate(`/profile/${customerProfile.id}`);
                            dispatch(additionalLinesPurchaseReset());
                        }}>
                        Next
                    </Button>
                </div>
            </div>
        </Layout>
    );
}
