import { compareDesc, format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { billsSelector, currentBillSelector, getBillingHistory, paidBillsSelector } from "../features/billing-slice";
import { ReachCsAPI } from "../utils/reach-cs-api";
import { RootState, useUnwrapAsyncThunk } from "../utils/store";
import { BillingForecastItemType, CustomizedBill, OrderStatus, ShipmentOrder } from "../utils/types";
import { Bill } from "../utils/types/bill";
import { Button } from "./button";
import { Card } from "./card";
import { useErrorHandler } from "./error-snackbar";
import Modal from "./modal";
import classNames from "classnames";

export interface OrderHistoryCardProps {
    customerId: string;
}

const positionMapping: Record<string, number> = {
    ORDERED: 0,
    CANCELLED: 0,
    LABELLED: 1,
    SHIPPED: 2,
    INTRANSIT: 2,
    DELIVERED: 3,
};

const orderStatusMapping: Record<OrderStatus, string> = {
    ORDERED: "Ordered",
    LABELLED: "Labelled",
    SHIPPED: "In transit",
    INTRANSIT: "In transit",
    DELIVERED: "Delivered",
    CANCELLED: "Cancelled",
};

export function OrderDetails({
    shipmentOrder,
    setSelectedOrder,
}: {
    shipmentOrder: ShipmentOrder;
    setSelectedOrder: (shipmentOrder?: ShipmentOrder) => void;
}) {
    const position = positionMapping[shipmentOrder.orderStatus];

    return (
        <Modal
            title={`Order Number: ${shipmentOrder.orderId}`}
            size="sm"
            show={true}
            onHide={() => setSelectedOrder(undefined)}>
            <div className="w-100">
                <div className="font-family-semibold">Order number</div>
                <div>{shipmentOrder.orderId}</div>
                {shipmentOrder.trackingNumber && (
                    <>
                        <div className="font-family-semibold mt-3">Tracking number</div>
                        <div className="text-cta">
                            {shipmentOrder.trackingUrl ? (
                                <a
                                    href={shipmentOrder.trackingUrl}
                                    className="text-cta"
                                    target="_blank"
                                    rel="noreferrer">
                                    {shipmentOrder.trackingNumber}
                                </a>
                            ) : (
                                shipmentOrder.trackingNumber
                            )}
                        </div>
                    </>
                )}
                <div className="font-family-semibold mt-3">Order received</div>
                <div className={classNames("align-items-center", { disabled: position < 0 })}>
                    {format(new Date(shipmentOrder.orderDate), "hh:mm a, MMMM d yyyy")}
                </div>
                <div className={classNames("align-items-center", { disabled: position < 0 })}>
                    SIM cards: {shipmentOrder.items[0].quantity}
                </div>
                <div className="font-family-semibold mt-3">{shipmentOrder.labelledOn ? "Processed" : "In process"}</div>
                <div className={classNames("align-items-center", { disabled: position < 1 })}>
                    {shipmentOrder.labelledOn
                        ? "Your Welcome Kit will be hitting the mail soon"
                        : "We're packaging up your Welcome Kit now"}
                </div>
                {shipmentOrder.labelledOn && (
                    <div
                        className={classNames("align-items-center", {
                            disabled: position < 1,
                        })}>
                        {format(new Date(shipmentOrder.labelledOn), "hh:mm a, MMMM d yyyy")}
                    </div>
                )}
                <div className="font-family-semibold mt-3">Order shipped</div>
                <div className={classNames("align-items-center", { disabled: position < 2 })}>
                    Your order is on the way!
                </div>
                {shipmentOrder.shippedOn && (
                    <div
                        className={classNames("align-items-center", {
                            disabled: position < 2,
                        })}>
                        {format(new Date(shipmentOrder.shippedOn), "hh:mm a, MMMM d yyyy")}
                    </div>
                )}
                <div className="font-family-semibold mt-3">
                    {shipmentOrder.deliveredOn ? "Delivered" : "Delivery scheduled"}
                </div>
                {shipmentOrder.deliveredOn ? (
                    <div
                        className={classNames("align-items-center", {
                            disabled: position < 3,
                        })}>
                        {format(new Date(shipmentOrder.deliveredOn), "hh:mm a, MMMM d yyyy")}
                    </div>
                ) : (
                    <div className={classNames("align-items-center", { disabled: position < 3 })}>
                        Arriving{" "}
                        {shipmentOrder.estimatedDeliveryDate
                            ? format(new Date(shipmentOrder.estimatedDeliveryDate), "MMMM d")
                            : ""}
                    </div>
                )}
                <div className="font-family-semibold mt-3">Shipment Cost</div>
                <div className={classNames("align-items-center", { disabled: position < 2 })}>
                    ${(shipmentOrder.shippingAmount + shipmentOrder.taxAmount).toFixed(2)}
                </div>
            </div>
        </Modal>
    );
}

function OrderItem({
    shipmentOrder,
    index,
    setSelectedOrder: setSelectedOrder,
}: {
    shipmentOrder: ShipmentOrder;
    index: number;
    setSelectedOrder: (shipmentOrder?: ShipmentOrder) => void;
}) {
    return (
        <div key={shipmentOrder.orderId} className="w-100">
            {index !== 0 && <div className="divider" />}
            <div className="w-100 d-flex justify-content-between my-2 align-items-center">
                <div className="font-family-semibold">
                    <div>Order ID. {shipmentOrder.orderId}</div>
                    <div style={{ fontSize: 12 }}>{shipmentOrder.items[0].quantity} SIM cards</div>
                </div>

                <div className="font-family-semibold text-right">
                    <div>{orderStatusMapping[shipmentOrder.orderStatus]}</div>
                    <div className="text-cta" onClick={() => setSelectedOrder(shipmentOrder)}>
                        View more
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function OrderHistoryCard(props: OrderHistoryCardProps) {
    const { customerId } = props;

    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const [shipmentOrders, setShipmentOrders] = useState<ShipmentOrder[]>();

    const [showModal, setShowModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<ShipmentOrder>();

    useEffect(() => {
        async function fetchOrders() {
            try {
                const res = await ReachCsAPI.getOrderHistory(customerId);
                setShipmentOrders(res.sort((a, b) => compareDesc(new Date(a.orderDate), new Date(b.orderDate))));
            } catch (error) {
                console.log(error);
                if (error.response?.data?.resCode !== 40167) {
                    handleError(error);
                }
            }
        }
        fetchOrders();
    }, []);

    if (!shipmentOrders || shipmentOrders.length === 0) {
        return null;
    }

    const firstPageOrders = shipmentOrders.slice(0, 3);

    return (
        <Card className="mt-3">
            <div className="font-family-bold align-self-start" style={{ fontSize: 16 }}>
                Order history
            </div>
            {firstPageOrders.map((shipmentOrder, index) => (
                <OrderItem
                    key={shipmentOrder.orderId}
                    shipmentOrder={shipmentOrder}
                    index={index}
                    setSelectedOrder={setSelectedOrder}
                />
            ))}
            {shipmentOrders.length > 3 && (
                <Button color="secondary" className="col-10" onClick={() => setShowModal(true)}>
                    View more
                </Button>
            )}
            <Modal title="Order history" show={showModal} onHide={() => setShowModal(false)}>
                {shipmentOrders.map((shipmentOrder, index) => (
                    <OrderItem
                        key={shipmentOrder.orderId}
                        shipmentOrder={shipmentOrder}
                        index={index}
                        setSelectedOrder={(shipmentOrder) => {
                            setShowModal(false);
                            setSelectedOrder(shipmentOrder);
                        }}
                    />
                ))}
            </Modal>
            {selectedOrder && <OrderDetails shipmentOrder={selectedOrder} setSelectedOrder={setSelectedOrder} />}
        </Card>
    );
}
