import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { format, max } from "date-fns";
import { useSelector } from "react-redux";
import { dataUsageSelector, getCDRDataUsage } from "../features/data-usage-slice";
import { formatPhone, getUTCDate, isPostActivationState } from "../utils/helpers";
import { RootState, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile } from "../utils/types";
import { Card } from "./card";
import { useErrorHandler } from "./error-snackbar";
import TopupHistoryModal from "./topup-history-modal";

export type DataUsageCardProps = CustomerProfile;

export default function DataUsageCard(props: DataUsageCardProps) {
    const { ...customerProfile } = props;

    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const id = customerProfile.groupLines.find((cust) => cust.isPrimary)!.id;

    const overallUsage = useSelector((state: RootState) => dataUsageSelector.selectById(state, id));
    const [loading, setLoading] = useState(overallUsage == null);
    const [topupModalDetails, setTopupModalDetails] = useState<{ customerId?: string; billMonth?: string }>({});

    useEffect(() => {
        async function fetchCDR() {
            try {
                await unwrap(getCDRDataUsage(id));
            } catch (error) {
                console.log(error);
                handleError(error);
            }
            setLoading(false);
        }
        fetchCDR();
    }, []);

    useEffect(() => {
        setSelectedIndex((overallUsage?.overall?.length ?? 1) - 1);
    }, [overallUsage]);

    const [selectedIndex, setSelectedIndex] = useState((overallUsage?.overall?.length ?? 1) - 1);

    if (loading) {
        return (
            <Card className="mt-3">
                <div className="font-family-bold align-self-start mb-2" style={{ fontSize: 16 }}>
                    Data usage
                </div>
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary" />
                </div>
            </Card>
        );
    } else if (!overallUsage || overallUsage.overall.length === 0) {
        return (
            <Card className="mt-3">
                <div className="font-family-bold align-self-start mb-2" style={{ fontSize: 16 }}>
                    Data usage
                </div>
                <div className="d-flex justify-content-center">No data usage available</div>
            </Card>
        );
    }

    const usage = overallUsage.overall[selectedIndex];

    const leftDisabled = selectedIndex === 0;
    const rightDisabled = selectedIndex === overallUsage.overall.length - 1;

    let startDate = new Date(usage.startDate);

    if (selectedIndex === 0 && customerProfile.activationDate) {
        startDate = max([startDate, getUTCDate(new Date(customerProfile.activationDate))]);
    }

    return (
        <Card className="mt-3">
            <div className="font-family-bold align-self-start mb-2" style={{ fontSize: 16 }}>
                Data usage
            </div>
            <div className="d-flex align-items-center">
                <span
                    style={{ fontSize: 10 }}
                    className={classNames("reach-cs-arrow-left cursor-pointer", { disabled: leftDisabled })}
                    onClick={() => !leftDisabled && setSelectedIndex(selectedIndex - 1)}
                />
                <div className="font-family-semibold mx-2">
                    {format(new Date(startDate), "MMM dd, yyyy")} - {format(new Date(usage.endDate), "MMM dd, yyyy")}
                </div>
                <span
                    style={{ fontSize: 10 }}
                    className={classNames("reach-cs-arrow-right cursor-pointer", {
                        disabled: rightDisabled,
                    })}
                    onClick={() => !rightDisabled && setSelectedIndex(selectedIndex + 1)}
                />
            </div>
            <div className="d-flex align-items-center">
                {usage.group ? (
                    <div className="d-flex flex-column align-items-center">
                        <div className="h1 mt-3">
                            <span className="text-primary">
                                {usage.group.cappedUsage.size}
                                {usage.group.cappedUsage.unit}
                            </span>
                            <span className="font-family-semibold"> of </span>
                            <span className="text-primary">
                                {usage.group.maxUsage.size}
                                {usage.group.maxUsage.unit}
                            </span>
                        </div>
                        <div className="text-center font-family-semibold text-grey1 mt-2" style={{ fontSize: 12 }}>
                            {[
                                usage.group.topupUsage && usage.group.topupUsage.size > 0
                                    ? `Topup ${usage.group.topupUsage.size}${usage.group.topupUsage.unit}`
                                    : undefined,
                                usage.group.extraUsage && usage.group.extraUsage.size > 0
                                    ? `Overage ${usage.group.extraUsage.size}${usage.group.extraUsage.unit}`
                                    : undefined,
                            ]
                                .filter(Boolean)
                                .join(", ")}
                        </div>
                        {usage.group.topupUsage && usage.group.topupUsage.size > 0 && (
                            <div
                                className="text-cta mt-2"
                                onClick={() => setTopupModalDetails({ billMonth: usage.group?.billMonth })}>
                                View details
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="d-flex flex-column align-items-center mt-3">
                        <div className="font-size-bold text-primary h1 mb-0">
                            {customerProfile.reachPlan.planData}
                            {customerProfile.reachPlan.dataUnit}
                        </div>
                        for each line
                    </div>
                )}
            </div>
            {customerProfile.groupLines
                .filter((customer) => isPostActivationState(customer.status))
                .map((customer, index) => {
                    const userUsage = usage.users.find((user) => user.userId === customer.id);
                    if (!userUsage) {
                        return null;
                    }

                    return (
                        <div key={customer.id} className="w-100">
                            {index !== 0 && <div className="divider mb-2" />}
                            <div className="d-flex justify-content-between align-items-center mb-1">
                                <div>
                                    <div className="font-family-semibold">{customer.firstName}</div>
                                    {customer.reachNumber && (
                                        <div
                                            style={{
                                                fontSize: 12,
                                            }}>
                                            {formatPhone(customer.reachNumber)}
                                        </div>
                                    )}
                                </div>
                                {usage.group ? (
                                    <div>
                                        {userUsage.cappedUsage.size}
                                        {userUsage.cappedUsage.unit}
                                    </div>
                                ) : (
                                    <div>
                                        {userUsage.cappedUsage.size}
                                        {userUsage.cappedUsage.unit}/{userUsage.maxUsage.size}
                                        {userUsage.maxUsage.unit}
                                    </div>
                                )}
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                {!usage.group && (
                                    <div className="font-family-semibold text-grey1" style={{ fontSize: 12 }}>
                                        {[
                                            userUsage.topupUsage && userUsage.topupUsage.size > 0
                                                ? `Topup ${userUsage.topupUsage.size}${userUsage.topupUsage.unit}`
                                                : undefined,
                                            userUsage.extraUsage && userUsage.extraUsage.size > 0
                                                ? `Overage ${userUsage.extraUsage.size}${userUsage.extraUsage.unit}`
                                                : undefined,
                                        ]
                                            .filter(Boolean)
                                            .join(", ")}
                                    </div>
                                )}
                                {!usage.group && userUsage.topupUsage && userUsage.topupUsage.size > 0 && (
                                    <div
                                        className="text-cta"
                                        style={{ fontSize: 12 }}
                                        onClick={() =>
                                            setTopupModalDetails({
                                                customerId: userUsage.userId,
                                                billMonth: userUsage.billMonth,
                                            })
                                        }>
                                        View details
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}

            {topupModalDetails.billMonth && (
                <TopupHistoryModal
                    title="Data purchase history"
                    show={topupModalDetails.billMonth != null}
                    onHide={() => setTopupModalDetails({})}
                    billMonth={topupModalDetails.billMonth}
                    selectedCustomerId={topupModalDetails.customerId}
                    customerProfile={customerProfile}
                />
            )}
        </Card>
    );
}
