import React from "react";
import { Controller, useForm } from "react-hook-form";
import { fetchProfile, setSimSwap } from "../features/profile-slice";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile } from "../utils/types";
import { Button } from "./button";
import { useErrorHandler } from "./error-snackbar";
import { Input } from "./input";
import Modal, { ModalProps } from "./modal";
import { useSuccessModal } from "./success-modal";

export interface SimSwapModalProps extends ModalProps {
    customerProfile: CustomerProfile;
}

interface FormData {
    simId: string;
}

export default function SimSwapModal(props: SimSwapModalProps) {
    const { customerProfile, ...rest } = props;

    const { control, handleSubmit, formState, errors } = useForm<FormData>({ mode: "onBlur" });

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();

    const onSubmit = async (data: FormData) => {
        try {
            await unwrap(
                setSimSwap({
                    customerId: customerProfile.id,
                    simId: data.simId,
                })
            );
            await dispatch(fetchProfile(customerProfile.id));
            showSuccessModal("SIM ID swapped successfully");
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        rest.onHide();
    };

    return (
        <Modal {...rest} size="sm">
            <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
                <div className="font-family-semibold mb-2">Current Sim ID: {customerProfile.simId}</div>
                <Controller
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: "ICCID is required",
                        },
                        pattern: {
                            value: /^\d{20}$/,
                            message: "ICCID must be 20 digits.",
                        },
                    }}
                    name="simId"
                    defaultValue=""
                    render={({ onChange, onBlur, value, name }) => (
                        <Input
                            type="text"
                            inputMode="numeric"
                            placeholder="Enter ICCID"
                            className="col-12 px-0"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                            maxLength={20}
                            characterRestriction={20}
                            showError={errors.simId != null}
                            errorMessage={errors.simId?.message}
                        />
                    )}
                />
                <Button color="secondary" fullWidth loading={formState.isSubmitting}>
                    Confirm
                </Button>
            </form>
        </Modal>
    );
}
