import React from "react";
import Layout from "../components/layout";

export default function HubspotTasks() {
    return (
        <Layout title="Hubspot Tasks">
            <iframe
                style={{ width: "100%", height: "100vh", border: 0 }}
                src="https://app.hubspot.com/tasks/4888946/view/all"></iframe>
        </Layout>
    );
}
