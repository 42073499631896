import React, { forwardRef, ReactNode } from "react";
import { Dropdown } from "react-bootstrap";
import { Input, InputProps } from "./input";

export interface DropdownInputProps extends InputProps {
    children: ReactNode;
    dropdownClassName?: string;
}

const ForwardedInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => (
    <Input {...props} className="w-100" register={ref} />
));
ForwardedInput.displayName = "ForwardedInput";

export default function DropdownInput(props: DropdownInputProps) {
    const { children, dropdownClassName, ...rest } = props;
    return (
        <Dropdown className={dropdownClassName}>
            {/* @ts-ignore */}
            <Dropdown.Toggle as={ForwardedInput} {...rest} />

            <Dropdown.Menu className="w-100 p-0">{children}</Dropdown.Menu>
        </Dropdown>
    );
}

export const DropdownItem = Dropdown.Item;
