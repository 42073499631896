import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { cancelTopup, fetchProfile, refundTopup } from "../features/profile-slice";
import { getCDRDataUsage } from "../features/data-usage-slice";
import { usePermissions } from "../features/hooks/use-permissions";
import { useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile } from "../utils/types";
import { CustomerStatus } from "../utils/types/customer";
import { formatPhone } from "../utils/helpers";
import { Button } from "./button";
import { useErrorHandler } from "./error-snackbar";
import Modal, { ModalProps } from "./modal";
import Select from "./select";
import { useSuccessModal } from "./success-modal";

export interface RemoveTopupModalProps extends ModalProps {
    customerProfile: CustomerProfile;
}

interface FormData {
    customerId?: string;
    reason: "cancel" | "refund";
}

export default function RemoveTopupModal(props: RemoveTopupModalProps) {
    const { customerProfile, ...rest } = props;

    const [topupRemoved, setTopupRemoved] = useState(false);

    const { register, handleSubmit, formState, errors } = useForm<FormData>({ mode: "onBlur" });

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();

    const { hasOnePermission } = usePermissions();

    const onSubmit = async (data: FormData) => {
        try {
            let res;
            if (data.reason === "cancel") {
                // res = await unwrap(cancelTopup(customerProfile.id));
                res = await unwrap(cancelTopup(data.customerId ?? customerProfile.id));
                await dispatch(fetchProfile(customerProfile.id));
                setTopupRemoved(true);
                const id = customerProfile.groupLines.find((cust) => cust.isPrimary)!.id;
                await unwrap(getCDRDataUsage(id));
                showSuccessModal(
                    `The unused topup ${res.cancelledDataInGBs}GB has been removed from the account. The amount $${res.cancelledDataCost} will be added to customer’s account as credit.`
                );
            } else {
                // res = await unwrap(refundTopup(customerProfile.id));
                res = await unwrap(refundTopup(data.customerId ?? customerProfile.id));
                await dispatch(fetchProfile(customerProfile.id));
                setTopupRemoved(true);
                const id = customerProfile.groupLines.find((cust) => cust.isPrimary)!.id;
                await unwrap(getCDRDataUsage(id));
                showSuccessModal(
                    `The unused topup ${res.refundedDataInGBs}GB has been removed from the account. The amount $${res.refundDataCost} will be refunded back to customer’s account.`
                );
            }
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        rest.onHide();
    };

    if (!customerProfile.reachPlan.isUnlimited && customerProfile.totalTopupInGB === 0 && !topupRemoved) {
        return (
            <Modal {...rest} size="sm">
                <div className="font-family-semibold">No topups in this billing cycle</div>
            </Modal>
        );
    }
    if (customerProfile.reachPlan.isUnlimited && !topupRemoved) {
        let count = 0;
        customerProfile.groupLines.map((customer) => {
            console.log(customer.totalTopupInGB);
            if (customer.totalTopupInGB === 0) {
                count++;
            }
        });
        console.log(count);
        if (count === customerProfile.groupLines.length) {
            return (
                <Modal {...rest} size="sm">
                    <div className="font-family-semibold">No topups in this billing cycle</div>
                </Modal>
            );
        }
    }

    return (
        <Modal {...rest} size="sm">
            {customerProfile.reachPlan.isUnlimited && (
                <Select
                    className="mb-3 w-100"
                    register={register({
                        required: {
                            value: true,
                            message: "Customer is required",
                        },
                    })}
                    name="customerId"
                    showError={errors.customerId != null}
                    errorMessage={errors.customerId?.message}>
                    <option value="">Select customer</option>
                    {customerProfile.groupLines
                        .filter((customer) => customer.status === CustomerStatus.ACTIVE)
                        .filter((customer) => customer.totalTopupInGB != 0)
                        .map((customer) => (
                            <option key={customer.id} value={customer.id}>
                                {customer.firstName} {customer.lastName} {formatPhone(customer.reachNumber)}
                            </option>
                        ))}
                </Select>
            )}
            <div className="font-family-semibold my-2">
                Topup cannot be canceled if it has already been used by the user.
            </div>
            <div className="font-family-semibold">Cancel Amount to be Refunded/Credited</div>
            <Select
                className="mb-3 w-100"
                register={register({
                    required: {
                        value: true,
                        message: "Option is required",
                    },
                })}
                name="reason"
                showError={errors.reason != null}
                errorMessage={errors.reason?.message}>
                <option value="">What do you want to do?</option>
                <option value="cancel" disabled={!hasOnePermission("user_management.topup.cancel")}>
                    Credit
                </option>
                <option value="refund" disabled={!hasOnePermission("user_management.topup.refund")}>
                    Refund
                </option>
            </Select>
            <Button color="secondary" fullWidth loading={formState.isSubmitting} onClick={handleSubmit(onSubmit)}>
                Confirm
            </Button>
        </Modal>
    );
}
